/**
 * Utilities for cart functionality
 */

// Local storage key for cart
export const CART_STORAGE_KEY = 'menu_cart';

/**
 * Helper to check if user is logged in
 * @returns {boolean} True if user is logged in
 */
export const isUserLoggedIn = () => {
  return !!localStorage.getItem('token');
};

/**
 * Generate a unique ID for local cart items
 * @returns {string} Unique ID string
 */
export const generateLocalItemId = () => {
  return 'local_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);
};

/**
 * Save cart items to local storage
 * @param {Array} items - Cart items to save
 */
export const saveCartItemsToStorage = (items) => {
  localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(items));
};

/**
 * Load cart items from local storage
 * @returns {Array} Cart items from storage or empty array
 */
export const loadCartItemsFromStorage = () => {
  const storedItems = localStorage.getItem(CART_STORAGE_KEY);
  return storedItems ? JSON.parse(storedItems) : [];
}; 