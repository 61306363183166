import React, { useState, useEffect, useCallback } from 'react';
import { Card, Select, Spin, Alert, Table, Tag, Space, Timeline, Progress, Collapse, Tabs, Empty, Button } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { 
    WarningOutlined, 
    BugOutlined, 
    ClockCircleOutlined, 
    CheckCircleOutlined,
    FileTextOutlined,
    DownloadOutlined,
    ReloadOutlined,
    DeleteOutlined,
    FilterOutlined,
    ExperimentOutlined
} from '@ant-design/icons';
import { AdminService } from '../../services/AdminService';
import { logError, ERROR_CATEGORIES } from '../../utils/logger';
import LogViewer from '../components/LogViewer';
import './ErrorTracking.css';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

const ErrorTracking = ({ section = 'errors' }) => {
    console.log("🔴 ErrorTracking component is being rendered with section:", section);
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('24h');
    const [stats, setStats] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [selectedEnvironment, setSelectedEnvironment] = useState('all');
    const [activeTab, setActiveTab] = useState('1');
    const isDev = process.env.NODE_ENV === 'development';

    // Function to generate test logs (for development mode)
    const generateTestLogs = () => {
        if (!isDev) return;
        
        // Generate a few test errors with different categories and severities
        const generateLog = (category, message, severity) => {
            logError(category, message, { 
                severity, 
                timestamp: new Date().toISOString(),
                requestUrl: window.location.href,
                userAgent: navigator.userAgent,
                stack: new Error().stack
            });
        };
        
        // Generate some sample errors
        generateLog(ERROR_CATEGORIES.AUTH, 'Failed login attempt', 'medium');
        generateLog(ERROR_CATEGORIES.API, 'API request timeout', 'high');
        generateLog(ERROR_CATEGORIES.IMAGE_UPLOAD, 'Invalid image format', 'low');
        generateLog(ERROR_CATEGORIES.MENU_CRUD, 'Failed to save menu item', 'medium');
        generateLog(ERROR_CATEGORIES.FORM, 'Form validation error', 'low');
        
        // Refresh the data
        fetchErrorStats();
        
        // Show success message
        alert('Test logs generated. Check the Application Logs tab to see them.');
    };

    // Fetch available error categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoryList = await AdminService.getErrorCategories();
                setCategories(['all', ...categoryList]);
            } catch (err) {
                console.error('Failed to fetch error categories:', err);
            }
        };
        
        fetchCategories();
    }, []);

    // Fetch error stats based on current filters
    const fetchErrorStats = useCallback(async () => {
        try {
            setLoading(true);
            console.log(`ErrorTracking: Fetching error stats for section: ${section}, category: ${selectedCategory}, timeRange: ${timeRange}, environment: ${selectedEnvironment}`);
            
            const data = await AdminService.getErrorStats(timeRange, selectedEnvironment, selectedCategory);
            setStats(data);
            setError(null);
        } catch (err) {
            setError(`Failed to fetch error statistics: ${err.message}`);
            console.error(`Error fetching ${section} stats:`, err);
        } finally {
            setLoading(false);
        }
    }, [section, timeRange, selectedEnvironment, selectedCategory]);

    // Setup effect to fetch data
    useEffect(() => {
        console.log(`ErrorTracking: Component mounted with section: ${section}`);
        if (activeTab === '1') {
            fetchErrorStats();
        }
        
        // Only set up WebSocket in production environment
        let wsConnection;
        if (process.env.NODE_ENV !== 'development') {
            // Set up real-time error monitoring
            wsConnection = new WebSocket('ws://localhost:8080/error-events');
            wsConnection.onmessage = (event) => {
                const errorEvent = JSON.parse(event.data);
                if (errorEvent.severity === 'critical') {
                    // Update stats immediately for critical errors
                    fetchErrorStats();
                }
            };
        } else {
            console.log('Development environment detected, skipping WebSocket connection');
        }
        
        return () => {
            if (wsConnection) {
                wsConnection.close();
            }
        };
    }, [fetchErrorStats, section, activeTab]);

    const getSeverityColor = (severity) => {
        switch (severity?.toLowerCase()) {
            case 'critical': return '#ff4d4f';
            case 'high': return '#faad14';
            case 'medium': return '#1890ff';
            case 'low': return '#52c41a';
            default: return '#d9d9d9';
        }
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    // Update the error state to clear when using mock data
    if (process.env.NODE_ENV === 'development' && error) {
        setError(null);
    }

    // Add a special tab for the specific category if one is selected
    const renderCategorySpecificTab = () => {
        if (selectedCategory === 'all' || !selectedCategory) return null;
        
        return (
            <TabPane tab={`${selectedCategory.toUpperCase()} Errors`} key="3">
                <Card title={`${selectedCategory.toUpperCase()} Specific Errors`}>
                    <p>This tab can contain specialized visualizations and metrics for {selectedCategory} errors.</p>
                    
                    {/* Example category-specific metrics could go here */}
                    <div style={{ marginTop: 20 }}>
                        <h3>Common {selectedCategory} Error Patterns</h3>
                        <p>Specialized analysis for this error category would appear here.</p>
                    </div>
                </Card>
            </TabPane>
        );
    };

    // Handle clearing logged errors in development mode
    const handleClearErrors = () => {
        if (process.env.NODE_ENV === 'development') {
            localStorage.removeItem('appErrors');
            fetchErrorStats(); // Refresh the data
        }
    };

    if (loading && activeTab === '1' && !stats) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error && activeTab === '1' && process.env.NODE_ENV !== 'development') {
        return <Alert type="error" message={error} />;
    }

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>Error Tracking & Logs</h2>
                <Space>
                    <Select
                        value={timeRange}
                        onChange={setTimeRange}
                        style={{ width: 120 }}
                        disabled={activeTab !== '1'}
                    >
                        <Option value="1h">Last Hour</Option>
                        <Option value="24h">Last 24 Hours</Option>
                        <Option value="7d">Last 7 Days</Option>
                        <Option value="30d">Last 30 Days</Option>
                    </Select>
                    
                    <Select
                        value={selectedEnvironment}
                        onChange={setSelectedEnvironment}
                        style={{ width: 120 }}
                        disabled={activeTab !== '1'}
                    >
                        <Option value="all">All Environments</Option>
                        <Option value="production">Production</Option>
                        <Option value="staging">Staging</Option>
                        <Option value="development">Development</Option>
                    </Select>
                    
                    <Select
                        value={selectedCategory}
                        onChange={setSelectedCategory}
                        style={{ width: 150 }}
                        disabled={activeTab !== '1'}
                    >
                        <Option value="all">All Categories</Option>
                        {Object.entries(ERROR_CATEGORIES).map(([key, value]) => (
                            <Option key={value} value={value}>
                                {key.replace(/_/g, ' ')}
                            </Option>
                        ))}
                    </Select>
                    
                    {isDev && (
                        <Button 
                            icon={<ExperimentOutlined />} 
                            onClick={generateTestLogs}
                            type="primary"
                            ghost
                            title="Generate test error logs for development"
                        >
                            Generate Test Logs
                        </Button>
                    )}
                    
                    {process.env.NODE_ENV === 'development' && (
                        <Button 
                            icon={<DeleteOutlined />} 
                            onClick={handleClearErrors}
                            danger
                            title="Clear logged errors (development only)"
                        >
                            Clear Dev Logs
                        </Button>
                    )}
                </Space>
            </div>

            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Error Statistics" key="1">
                    {(!stats?.totalErrors && !loading) ? (
                        <Empty description={
                            <span>
                                No errors found for the selected filters.
                                {process.env.NODE_ENV === 'development' && 
                                    " You can generate test errors by using the logError function."}
                            </span>
                        } />
                    ) : (
                        <>
                            <div className="cards-grid">
                                <Card title="Total Errors" className="metric-card">
                                    <h3>
                                        <BugOutlined style={{ marginRight: '8px', color: '#ff4d4f' }}/>
                                        {stats?.totalErrors || 0}
                                    </h3>
                                    <p>Change: {stats?.errorChange || 0}% from last period</p>
                                </Card>

                                <Card title="Average Resolution Time" className="metric-card">
                                    <h3>
                                        <ClockCircleOutlined style={{ marginRight: '8px', color: '#1890ff' }}/>
                                        {stats?.avgResolutionTime || '0h 0m'}
                                    </h3>
                                    <p>Target: {stats?.resolutionTarget || '2h 0m'}</p>
                                </Card>

                                <Card title="Error Rate" className="metric-card">
                                    <h3>
                                        <WarningOutlined style={{ marginRight: '8px', color: '#faad14' }}/>
                                        {stats?.errorRate || 0}%
                                    </h3>
                                    <p>Of total requests</p>
                                </Card>

                                <Card title="Resolved Issues" className="metric-card">
                                    <h3>
                                        <CheckCircleOutlined style={{ marginRight: '8px', color: '#52c41a' }}/>
                                        {stats?.resolvedIssues || 0}
                                    </h3>
                                    <p>In the last {timeRange}</p>
                                </Card>
                            </div>

                            <Card title="Error Trends" className="chart-card">
                                <LineChart
                                    width={1000}
                                    height={300}
                                    data={stats?.errorTrends || []}
                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="timestamp" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line 
                                        type="monotone" 
                                        dataKey="errors" 
                                        stroke="#ff4d4f" 
                                        name="Errors"
                                    />
                                    <Line 
                                        type="monotone" 
                                        dataKey="resolved" 
                                        stroke="#52c41a" 
                                        name="Resolved"
                                    />
                                </LineChart>
                            </Card>

                            <div className="cards-grid">
                                <Card title="Error Distribution" className="metric-card">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        {stats?.errorDistribution?.map((item, index) => (
                                            <div key={index}>
                                                <span>{item.type}:</span>
                                                <Progress 
                                                    percent={item.percentage} 
                                                    size="small"
                                                    strokeColor={getSeverityColor(item.severity)}
                                                />
                                            </div>
                                        ))}
                                    </Space>
                                </Card>

                                <Card title="Most Affected Components" className="metric-card">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        {stats?.affectedComponents?.map((component, index) => (
                                            <div key={index}>
                                                <span>{component.name}:</span>
                                                <Progress 
                                                    percent={component.errorRate} 
                                                    size="small"
                                                    strokeColor="#ff4d4f"
                                                />
                                            </div>
                                        ))}
                                    </Space>
                                </Card>
                            </div>

                            <Card title="Recent Error Timeline" className="timeline-card">
                                <Timeline mode="left">
                                    {stats?.recentErrors?.map((error, index) => (
                                        <Timeline.Item 
                                            key={index}
                                            color={getSeverityColor(error.severity)}
                                            label={error.timestamp}
                                        >
                                            <p><strong>{error.type}</strong></p>
                                            <p>{error.message}</p>
                                            <Tag color={error.status === 'resolved' ? 'success' : 'processing'}>
                                                {error.status}
                                            </Tag>
                                        </Timeline.Item>
                                    ))}
                                </Timeline>
                            </Card>

                            <Card title="Detailed Error Log" className="table-card">
                                <Table
                                    dataSource={stats?.errorLog || []}
                                    columns={[
                                        {
                                            title: 'Timestamp',
                                            dataIndex: 'timestamp',
                                            key: 'timestamp',
                                            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
                                        },
                                        {
                                            title: 'Type',
                                            dataIndex: 'type',
                                            key: 'type',
                                            filters: [
                                                ...Object.values(ERROR_CATEGORIES).map(cat => ({ 
                                                    text: cat, 
                                                    value: cat 
                                                }))
                                            ],
                                            onFilter: (value, record) => record.type === value,
                                        },
                                        {
                                            title: 'Severity',
                                            dataIndex: 'severity',
                                            key: 'severity',
                                            render: severity => (
                                                <Tag color={getSeverityColor(severity)}>
                                                    {severity?.toUpperCase() || 'UNKNOWN'}
                                                </Tag>
                                            ),
                                            filters: [
                                                { text: 'Critical', value: 'critical' },
                                                { text: 'High', value: 'high' },
                                                { text: 'Medium', value: 'medium' },
                                                { text: 'Low', value: 'low' },
                                            ],
                                            onFilter: (value, record) => record.severity === value,
                                        },
                                        {
                                            title: 'Message',
                                            dataIndex: 'message',
                                            key: 'message',
                                            ellipsis: true,
                                        },
                                        {
                                            title: 'Status',
                                            dataIndex: 'status',
                                            key: 'status',
                                            render: status => (
                                                <Tag color={status === 'resolved' ? 'success' : 'processing'}>
                                                    {status?.toUpperCase() || 'UNKNOWN'}
                                                </Tag>
                                            ),
                                        },
                                    ]}
                                    expandable={{
                                        expandedRowRender: record => (
                                            <Collapse>
                                                <Panel header="Error Details" key="1">
                                                    <Space direction="vertical">
                                                        <p><strong>Stack Trace:</strong></p>
                                                        <pre>{record.stackTrace}</pre>
                                                        <p><strong>Request URL:</strong> {record.requestUrl}</p>
                                                        <p><strong>User Agent:</strong> {record.userAgent}</p>
                                                        <p><strong>Environment:</strong> {record.environment}</p>
                                                        {record.userId && (
                                                            <p><strong>User ID:</strong> {record.userId}</p>
                                                        )}
                                                    </Space>
                                                </Panel>
                                                {record.context && (
                                                    <Panel header="Error Context" key="2">
                                                        <pre>
                                                            {JSON.stringify(record.context, null, 2)}
                                                        </pre>
                                                    </Panel>
                                                )}
                                            </Collapse>
                                        ),
                                    }}
                                    pagination={{ pageSize: 10 }}
                                />
                            </Card>
                        </>
                    )}
                </TabPane>
                
                <TabPane tab="Application Logs" key="2">
                    <LogViewer />
                </TabPane>
                
                {renderCategorySpecificTab()}
            </Tabs>
        </div>
    );
};

export default ErrorTracking; 