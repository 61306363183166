import React, { useState, useEffect } from 'react';
import { Card, Select, Spin, Alert, Table, Progress, Space } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { DollarOutlined, UserOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { AdminService } from '../../services/AdminService';

const SubscriptionMonitoring = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('30d');
    const [stats, setStats] = useState(null);

    useEffect(() => {
        fetchSubscriptionStats();
    }, [timeRange]);

    const fetchSubscriptionStats = async () => {
        try {
            setLoading(true);
            const data = await AdminService.getSubscriptionStats(timeRange);
            setStats(data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch subscription statistics');
            console.error('Error fetching subscription stats:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error) {
        return <Alert type="error" message={error} />;
    }

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>Subscription Monitoring</h2>
                <Select
                    value={timeRange}
                    onChange={setTimeRange}
                    style={{ width: 120 }}
                >
                    <Select.Option value="7d">Last 7 Days</Select.Option>
                    <Select.Option value="30d">Last 30 Days</Select.Option>
                    <Select.Option value="90d">Last 90 Days</Select.Option>
                    <Select.Option value="1y">Last Year</Select.Option>
                </Select>
            </div>

            <div className="cards-grid">
                <Card title="Monthly Recurring Revenue" className="metric-card">
                    <h3>
                        <DollarOutlined style={{ marginRight: '8px', color: '#52c41a' }}/>
                        ${stats?.mrr || 0}
                    </h3>
                    <p className={stats?.mrrGrowth >= 0 ? 'trend-up' : 'trend-down'}>
                        {stats?.mrrGrowth >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                        {Math.abs(stats?.mrrGrowth || 0)}% from last period
                    </p>
                </Card>

                <Card title="Active Subscribers" className="metric-card">
                    <h3>
                        <UserOutlined style={{ marginRight: '8px', color: '#1890ff' }}/>
                        {stats?.activeSubscribers || 0}
                    </h3>
                    <p className={stats?.subscriberGrowth >= 0 ? 'trend-up' : 'trend-down'}>
                        {stats?.subscriberGrowth >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                        {Math.abs(stats?.subscriberGrowth || 0)}% from last period
                    </p>
                </Card>

                <Card title="Churn Rate" className="metric-card">
                    <Progress
                        type="dashboard"
                        percent={stats?.churnRate || 0}
                        strokeColor={{
                            '0%': '#87d068',
                            '100%': '#ff4d4f',
                        }}
                    />
                    <p>Monthly churn rate</p>
                </Card>

                <Card title="Customer Lifetime Value" className="metric-card">
                    <h3>
                        <DollarOutlined style={{ marginRight: '8px', color: '#722ed1' }}/>
                        ${stats?.ltv || 0}
                    </h3>
                    <p>Average LTV</p>
                </Card>
            </div>

            <Card title="Revenue Trends" className="chart-card">
                <LineChart
                    width={1000}
                    height={300}
                    data={stats?.revenueTrends || []}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line 
                        type="monotone" 
                        dataKey="mrr" 
                        stroke="#52c41a" 
                        name="MRR"
                    />
                    <Line 
                        type="monotone" 
                        dataKey="arr" 
                        stroke="#1890ff" 
                        name="ARR"
                    />
                </LineChart>
            </Card>

            <Card title="Subscription Details" className="table-card">
                <Table
                    dataSource={stats?.subscriptionDetails || []}
                    columns={[
                        {
                            title: 'Plan',
                            dataIndex: 'plan',
                            key: 'plan',
                        },
                        {
                            title: 'Subscribers',
                            dataIndex: 'subscribers',
                            key: 'subscribers',
                            sorter: (a, b) => a.subscribers - b.subscribers,
                        },
                        {
                            title: 'Revenue',
                            dataIndex: 'revenue',
                            key: 'revenue',
                            render: value => `$${value}`,
                            sorter: (a, b) => a.revenue - b.revenue,
                        },
                        {
                            title: 'Churn Rate',
                            dataIndex: 'churnRate',
                            key: 'churnRate',
                            render: value => `${value}%`,
                            sorter: (a, b) => a.churnRate - b.churnRate,
                        },
                    ]}
                    pagination={false}
                />
            </Card>

            <div className="cards-grid">
                <Card title="Retention Analysis" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div>
                            <span>30-day Retention:</span>
                            <Progress percent={stats?.retention?.thirtyDay || 0} size="small" />
                        </div>
                        <div>
                            <span>60-day Retention:</span>
                            <Progress percent={stats?.retention?.sixtyDay || 0} size="small" />
                        </div>
                        <div>
                            <span>90-day Retention:</span>
                            <Progress percent={stats?.retention?.ninetyDay || 0} size="small" />
                        </div>
                    </Space>
                </Card>

                <Card title="Upgrade/Downgrade Analysis" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div>
                            <span>Upgrade Rate:</span>
                            <Progress 
                                percent={stats?.planChanges?.upgradeRate || 0} 
                                size="small"
                                strokeColor="#52c41a"
                            />
                        </div>
                        <div>
                            <span>Downgrade Rate:</span>
                            <Progress 
                                percent={stats?.planChanges?.downgradeRate || 0} 
                                size="small"
                                strokeColor="#faad14"
                            />
                        </div>
                    </Space>
                </Card>
            </div>
        </div>
    );
};

export default SubscriptionMonitoring; 