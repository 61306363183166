import axios from 'axios';
import { getMockData } from './mockData';
import { logError, ERROR_CATEGORIES, getLoggedErrors } from '../utils/logger';
import API_ENDPOINTS from '../config/api';

// This will only be used for development mode checks
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

// Create an axios instance with default config
const api = axios.create({
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add request interceptor for debugging
api.interceptors.request.use(config => {
    console.log(`Making ${config.method.toUpperCase()} request to: ${config.url}`);
    return config;
});

// Add response interceptor for debugging and error logging
api.interceptors.response.use(
    response => {
        console.log(`Response from ${response.config.url}:`, response.status);
        return response;
    }, 
    error => {
        console.error('API Error:', error.message);
        
        // Log API errors
        logError(
            ERROR_CATEGORIES.API, 
            `API request failed: ${error.message}`,
            {
                url: error.config?.url,
                method: error.config?.method,
                status: error.response?.status,
                responseData: error.response?.data,
            }
        );
        
        if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
        }
        return Promise.reject(error);
    }
);

export class AdminService {
    static async getAuthStats(timeRange) {
        // Use mock data in development
        if (IS_DEVELOPMENT) {
            console.log(`[DEV] Using mock data for auth stats with timeRange: ${timeRange}`);
            return getMockData('auth');
        }
        
        try {
            console.log(`Fetching auth stats with timeRange: ${timeRange}`);
            const response = await api.get(`${API_ENDPOINTS.admin}/auth-stats.php`, {
                params: { timeRange }
            });
            return response.data.stats;
        } catch (error) {
            console.error('Error fetching auth stats:', error);
            throw error;
        }
    }

    static async getMenuStats(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/menu-stats.php`, {
                params: { timeRange }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching menu stats:', error);
            throw error;
        }
    }

    static async getOrderStats(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/order-stats.php`, {
                params: { timeRange }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching order stats:', error);
            throw error;
        }
    }

    static async getUserActivityStats(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/user-activity.php`, {
                params: { timeRange }
            });
            return response.data.stats;
        } catch (error) {
            console.error('Error fetching user activity stats:', error);
            throw error;
        }
    }

    static async getSubscriptionStats(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/subscription-stats.php`, {
                params: { timeRange }
            });
            return response.data.stats;
        } catch (error) {
            console.error('Error fetching subscription stats:', error);
            throw error;
        }
    }

    static async getSystemHealth() {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/system-health.php`);
            return response.data;
        } catch (error) {
            console.error('Error fetching system health:', error);
            throw error;
        }
    }

    static async getSecurityEvents(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/security-events.php`, {
                params: { timeRange }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching security events:', error);
            throw error;
        }
    }

    static async getBusinessAnalytics(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/analytics.php`, {
                params: { timeRange }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching business analytics:', error);
            throw error;
        }
    }

    static async getErrorStats(timeRange, environment = 'all', category = null) {
        // Use mock data in development
        if (IS_DEVELOPMENT) {
            console.log(`[DEV] Using mock data for error stats with timeRange: ${timeRange}, environment: ${environment}, category: ${category}`);
            
            // Get basic mock data
            let data = getMockData('errors');
            
            // If we have real logged errors and a specific category, use those instead
            if (category && category !== 'all') {
                const loggedErrors = getLoggedErrors(category, 100);
                
                // Only override if we have actual logged errors
                if (loggedErrors.length > 0) {
                    // Create error logs from the logged errors
                    const errorLog = loggedErrors.map((err, index) => ({
                        key: index.toString(),
                        timestamp: new Date(err.timestamp).toLocaleString(),
                        type: err.category,
                        severity: err.details.severity || 'medium',
                        message: err.message,
                        status: err.details.status || 'new',
                        stackTrace: err.details.stack || 'No stack trace available',
                        requestUrl: err.url,
                        userAgent: err.browser,
                        environment: 'development',
                        userId: err.userInfo?.userId
                    }));
                    
                    // Update the data with real errors
                    data = {
                        ...data,
                        totalErrors: errorLog.length,
                        errorLog
                    };
                }
            }
            
            return data;
        }
        
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/error-stats.php`, {
                params: { timeRange, environment, category }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching error statistics:', error);
            throw error;
        }
    }

    static async getErrorLogs(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/error-logs.php`, {
                params: { timeRange }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching error logs:', error);
            throw error;
        }
    }

    static async getNotificationStats(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/notification-stats.php`, {
                params: { timeRange }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching notification stats:', error);
            throw error;
        }
    }

    static async logoutAllDevices() {
        try {
            const response = await api.post(`${API_ENDPOINTS.admin}/logout-all-devices.php`);
            return response.data;
        } catch (error) {
            console.error('Error logging out all devices:', error);
            throw error;
        }
    }

    static async getBusinessStats(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/business-stats.php`, {
                params: { timeRange }
            });
            return response.data.stats;
        } catch (error) {
            console.error('Error fetching business stats:', error);
            throw error;
        }
    }

    static async getSecurityStats(timeRange) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/security-events.php`, {
                params: { timeRange }
            });
            return response.data.stats;
        } catch (error) {
            console.error('Error fetching security stats:', error);
            throw error;
        }
    }

    static async getSystemHealthStats() {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/system-health.php`);
            return response.data.stats;
        } catch (error) {
            console.error('Error fetching system health stats:', error);
            throw error;
        }
    }

    static async getApplicationLogs(category, lines = 100) {
        try {
            const response = await api.get(`${API_ENDPOINTS.admin}/application-logs.php`, {
                params: { category, lines }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching application logs:', error);
            throw error;
        }
    }

    // Add a static method to check if the backend is available
    static async checkBackendStatus() {
        // In development, simply return success without making an API call
        if (IS_DEVELOPMENT) {
            console.log('Development environment detected, skipping backend status check');
            return {
                isAvailable: true,
                status: 200,
                data: { status: 'ok' }
            };
        }
        
        try {
            // Use direct API endpoint with .php extension to ensure it works
            const statusUrl = `${API_ENDPOINTS.status}`;
            console.log(`Checking backend status at ${statusUrl}`);
            
            const response = await api.get(statusUrl, { 
                timeout: 5000,
                // Ensure credentials are sent
                withCredentials: true
            });
            
            console.log('Backend status response:', response.data);
            return {
                isAvailable: true,
                status: response.status,
                data: response.data
            };
        } catch (error) {
            console.error('Backend status check failed:', error);
            return {
                isAvailable: false,
                error: error.message
            };
        }
    }

    // New methods for error logging and retrieval
    static async getErrorsByCategory(category, limit = 100) {
        if (IS_DEVELOPMENT) {
            // In development, get errors from localStorage
            return getLoggedErrors(category, limit);
        } else {
            // In production, get from API
            try {
                const response = await api.get(`${API_ENDPOINTS.admin}/errors-by-category.php`, {
                    params: { category, limit }
                });
                return response.data.errors;
            } catch (error) {
                console.error(`Error fetching errors for category ${category}:`, error);
                throw error;
            }
        }
    }
    
    static async getErrorCategories() {
        if (IS_DEVELOPMENT) {
            // In development, return predefined categories
            return Object.values(ERROR_CATEGORIES);
        } else {
            // In production, get categories from API
            try {
                const response = await api.get(`${API_ENDPOINTS.admin}/error-categories.php`);
                return response.data.categories;
            } catch (error) {
                console.error('Error fetching error categories:', error);
                throw error;
            }
        }
    }
} 