import React from 'react';
import axios from 'axios';
import { getApiUrl, getAuthHeaders } from '../utils/apiUtils';

const FixPassword = () => {
    const handleFixPassword = async () => {
        try {
            const response = await axios.post(getApiUrl('fix_password.php'), {}, {
                headers: getAuthHeaders()
            });
            alert(response.data.message);
            console.log(response.data);
        } catch (error) {
            alert('Error: ' + (error.response?.data?.message || error.message));
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Fix Password</h2>
            <button 
                onClick={handleFixPassword}
                style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    cursor: 'pointer'
                }}
            >
                Reset Password
            </button>
        </div>
    );
};

export default FixPassword; 