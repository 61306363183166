import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './Auth.css';

const VerifyEmailLink = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { checkAuthStatus } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const email = params.get('email');
    
    if (!token || !email) {
      console.error('Missing token or email');
      navigate('/login');
      return;
    }

    // The PHP script will handle the verification and localStorage setup
    // We just need to wait for it to complete
    const checkAuth = async () => {
      await checkAuthStatus();
      // The PHP script will redirect us to /verify?verified=true
    };

    checkAuth();
  }, [navigate, location, checkAuthStatus]);

  return (
    <div className="auth-container">
      <div className="auth-form verification-result">
        <h2>Email Verification</h2>
        <div className="verification-message">
          <p>Verifying your email address...</p>
          <div className="loader"></div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailLink; 