import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import './TeamAccess.css';

const TeamAccess = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [showAddMember, setShowAddMember] = useState(false);
  const [newMember, setNewMember] = useState({
    name: '',
    email: '',
    role: 'Staff',
    permissions: []
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Available permissions
  const availablePermissions = [
    { key: 'orders_management', name: 'Orders Management' },
    { key: 'menu_builder', name: 'Menu Builder' },
    { key: 'reports_analytics', name: 'Reports & Analytics' },
    { key: 'payments_settings', name: 'Payments Settings' },
    { key: 'site_settings', name: 'Site Settings' }
  ];

  // API url helper
  const getApiUrl = () => {
    const isDevelopment = window.location.hostname === 'localhost';
    return isDevelopment ? 
      (window.location.port === '3001' ? 'http://localhost:9000/api' : '/api') : 
      '/api';
  };

  // Fetch team members
  const fetchTeamMembers = async () => {
    setLoading(true);
    try {
      const apiUrl = `${getApiUrl()}/team-access`;
      
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch team members');
      }
      
      const data = await response.json();
      if (data.success && data.data) {
        setTeamMembers(data.data);
      }
    } catch (error) {
      console.error('Error fetching team members:', error);
      setErrorMessage('Failed to load team members. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember({
      ...newMember,
      [name]: value
    });
  };

  const handlePermissionChange = (permissionKey, checked) => {
    if (checked) {
      // Add permission
      setNewMember({
        ...newMember,
        permissions: [...newMember.permissions, permissionKey]
      });
    } else {
      // Remove permission
      setNewMember({
        ...newMember,
        permissions: newMember.permissions.filter(p => p !== permissionKey)
      });
    }
  };

  const toggleMemberStatus = async (memberId, currentStatus) => {
    try {
      const apiUrl = `${getApiUrl()}/team-access`;
      const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';
      
      // Find member to update
      const memberToUpdate = teamMembers.find(m => m.id === memberId);
      if (!memberToUpdate) return;
      
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          id: memberId,
          name: memberToUpdate.name,
          role: memberToUpdate.role,
          status: newStatus,
          permissions: memberToUpdate.permissions
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to update team member');
      }
      
      // Update local state
      setTeamMembers(members => 
        members.map(member => 
          member.id === memberId ? { ...member, status: newStatus } : member
        )
      );
      
      setSuccessMessage(`Team member ${newStatus === 'Active' ? 'activated' : 'deactivated'} successfully`);
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error updating team member:', error);
      setErrorMessage('Failed to update team member. Please try again.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const handleRemoveMember = async (memberId) => {
    if (!window.confirm('Are you sure you want to remove this team member?')) {
      return;
    }
    
    try {
      const apiUrl = `${getApiUrl()}/team-access?id=${memberId}`;
      
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to remove team member');
      }
      
      // Remove from local state
      setTeamMembers(members => members.filter(member => member.id !== memberId));
      
      setSuccessMessage('Team member removed successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error removing team member:', error);
      setErrorMessage('Failed to remove team member. Please try again.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const handleAddMember = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!newMember.name || !newMember.email) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }
    
    try {
      setLoading(true);
      const apiUrl = `${getApiUrl()}/team-access`;
      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(newMember)
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add team member');
      }
      
      // Refresh team members list
      await fetchTeamMembers();
      
      setSuccessMessage('Team member added successfully!');
      
      // Reset form
      setNewMember({
        name: '',
        email: '',
        role: 'Staff',
        permissions: []
      });
      
      // Hide form after successful submission
      setShowAddMember(false);
      
      // Clear success message after a few seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error adding team member:', error);
      setErrorMessage(`Error adding team member: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getRoleBadgeClass = (role) => {
    switch (role) {
      case 'Admin':
        return 'badge-admin';
      case 'Manager':
        return 'badge-manager';
      case 'Staff':
        return 'badge-staff';
      default:
        return '';
    }
  };

  if (loading && teamMembers.length === 0) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading team access...</p>
      </div>
    );
  }

  return (
    <div className="team-access-container">
      <div className="team-header">
        <h2>Team Access</h2>
        <p className="section-description">
          Manage team members and their access to different parts of your GoodeEats account.
        </p>
      </div>
      
      {successMessage && (
        <div className="alert alert-success">
          <i className="fas fa-check-circle"></i> {successMessage}
        </div>
      )}
      
      {errorMessage && (
        <div className="alert alert-error">
          <i className="fas fa-exclamation-circle"></i> {errorMessage}
        </div>
      )}
      
      <div className="team-content">
        <div className="team-actions">
          <button 
            className="btn btn-primary"
            onClick={() => setShowAddMember(!showAddMember)}
          >
            {showAddMember ? 'Cancel' : (
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <i className="fas fa-plus"></i>
                <span>ADD TEAM MEMBER</span>
              </span>
            )}
          </button>
        </div>
        
        {showAddMember && (
          <div className="add-member-form">
            <h3>Add New Team Member</h3>
            <form onSubmit={handleAddMember}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={newMember.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={newMember.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="role">Role</label>
                  <select
                    id="role"
                    name="role"
                    value={newMember.role}
                    onChange={handleInputChange}
                  >
                    <option value="Staff">Staff</option>
                    <option value="Manager">Manager</option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>
              </div>
              
              <div className="permissions-section">
                <h4>Permissions</h4>
                <p className="help-text">
                  {newMember.role === 'Admin' 
                    ? 'Admins automatically have access to all features.' 
                    : 'Select which features this team member can access.'}
                </p>
                
                <div className="permissions-grid">
                  {availablePermissions.map(permission => (
                    <div key={permission.key} className="permission-item">
                      <label>
                        <input
                          type="checkbox"
                          name={permission.key}
                          checked={newMember.permissions.includes(permission.key) || newMember.role === 'Admin'}
                          onChange={(e) => handlePermissionChange(permission.key, e.target.checked)}
                          disabled={newMember.role === 'Admin'} // Admins always have all permissions
                        />
                        {permission.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="form-actions">
                <button 
                  type="button" 
                  className="btn btn-secondary"
                  onClick={() => setShowAddMember(false)}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Adding...' : 'Add Team Member'}
                </button>
              </div>
            </form>
          </div>
        )}
        
        <div className="team-table">
          <div className="team-table-header">
            <div className="team-cell name-cell">Name</div>
            <div className="team-cell role-cell">Role</div>
            <div className="team-cell last-login-cell">Last Login</div>
            <div className="team-cell permissions-cell">Permissions</div>
            <div className="team-cell status-cell">Status</div>
            <div className="team-cell actions-cell">Actions</div>
          </div>
          
          <div className="team-table-body">
            {teamMembers.length === 0 ? (
              <div className="empty-state">
                <p>No team members yet. Add your first team member to get started.</p>
              </div>
            ) : (
              teamMembers.map(member => (
                <div key={member.id} className="team-row">
                  <div className="team-cell name-cell">
                    <div className="member-name">{member.name}</div>
                    <div className="member-email">{member.email}</div>
                  </div>
                  
                  <div className="team-cell role-cell">
                    <span className={`role-badge ${getRoleBadgeClass(member.role)}`}>
                      {member.role}
                    </span>
                  </div>
                  
                  <div className="team-cell last-login-cell">
                    {formatDate(member.last_login)}
                  </div>
                  
                  <div className="team-cell permissions-cell">
                    <div className="permissions-list">
                      {member.permissions.map(permission => {
                        const permInfo = availablePermissions.find(p => p.key === permission);
                        return (
                          <span key={permission} className="permission-tag">
                            {permInfo ? permInfo.name : permission}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  
                  <div className="team-cell status-cell">
                    <span className={`status-badge ${member.status === 'Active' ? 'active' : 'inactive'}`}>
                      {member.status}
                    </span>
                  </div>
                  
                  <div className="team-cell actions-cell">
                    <button 
                      className="action-btn"
                      onClick={() => toggleMemberStatus(member.id, member.status)}
                      title={member.status === 'Active' ? 'Deactivate' : 'Activate'}
                    >
                      <i className={`fas ${member.status === 'Active' ? 'fa-toggle-on' : 'fa-toggle-off'}`}></i>
                    </button>
                    <button 
                      className="action-btn"
                      onClick={() => handleRemoveMember(member.id)}
                      title="Remove"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamAccess; 