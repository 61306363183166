import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './Welcome.css';

const Welcome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [username, setUsername] = useState('');
  
  // Get username from various possible sources
  useEffect(() => {
    // Try to get username from different sources in order of preference
    const storedUsername = localStorage.getItem('username');
    const usernameFromState = location.state?.username;
    const usernameFromUser = user?.username;
    
    // Set the username using the first available source
    const foundUsername = usernameFromUser || storedUsername || usernameFromState || 'there';
    setUsername(foundUsername);
    
    console.log('Welcome Component - Username sources:', { 
      fromUser: usernameFromUser,
      fromLocalStorage: storedUsername,
      fromState: usernameFromState,
      final: foundUsername
    });
  }, [location.state, user]);

  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <h1>Welcome to GoodeEats, {username}! 🎉</h1>
        
        <div className="welcome-card">
          <h2>Your account is ready!</h2>
          <p>We're excited to have you join our platform. Here are some things you can do:</p>
          
          <div className="features-grid">
            <div className="feature-item">
              <span className="feature-icon">🍔</span>
              <h3>Browse Local Menus</h3>
              <p>Discover food trucks and restaurants near you</p>
            </div>
            
            <div className="feature-item">
              <span className="feature-icon">👤</span>
              <h3>Complete Your Profile</h3>
              <p>Add your delivery address and payment methods</p>
            </div>
            
            <div className="feature-item">
              <span className="feature-icon">📱</span>
              <h3>Track Your Orders</h3>
              <p>Follow your meal from preparation to delivery</p>
            </div>
            
            <div className="feature-item">
              <span className="feature-icon">⭐</span>
              <h3>Save Favorites</h3>
              <p>Keep track of your favorite meals and restaurants</p>
            </div>
          </div>

          <div className="welcome-actions">
            <button 
              className="primary-button"
              onClick={() => navigate('/restaurants')}
            >
              Find Restaurants
            </button>
            <button 
              className="secondary-button"
              onClick={() => navigate('/profile/edit')}
            >
              Complete Your Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome; 