import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import PhoneFrame from '../../components/PhoneFrame/PhoneFrame';
import MenuItemForm from '../../components/MenuItemForm/MenuItemForm';
import MenuItemEditForm from '../../components/MenuItemForm/MenuItemEditForm';
import Categories from '../../components/Categories/Categories';
import MobileMenuList from '../../components/MobileMenuList/MobileMenuList';
import Theme from '../../components/Theme/Theme';
import { getApiUrl, fetchWithAuth, getAuthHeaders } from '../../utils/apiUtils';
import './AppBuilder.css';

const AppBuilder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  
  // Get initial section from URL or default to 'menu'
  const getInitialSection = () => {
    const path = location.pathname.split('/');
    const section = path[path.length - 1];
    return ['menu', 'categories', 'theme'].includes(section) ? section : 'menu';
  };

  const [activeSection, setActiveSection] = useState(getInitialSection);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [previewContent, setPreviewContent] = useState(null);
  const [loading, setLoading] = useState(true);

  // Add updatePhonePreview function
  const updatePhonePreview = () => {
    if (previewContent) {
      setPreviewContent(null);
    }
    const content = getPhoneContent();
    setPreviewContent(content);
  };

  // Function to fetch menu items - moved outside useEffect for reusability
  const fetchMenuItems = async () => {
    try {
      const response = await fetchWithAuth(getApiUrl('menu_items'), {
        method: 'GET'
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          setMenuItems(data.data || []);
        } else {
          console.error('Error fetching menu items:', data.message);
        }
      } else {
        console.error('Failed to fetch menu items:', response.status);
      }
    } catch (error) {
      console.error('Error fetching menu items:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch categories function - moved outside useEffect for reusability
  const fetchCategories = async () => {
    try {
      // Fixed API endpoint with .php extension and proper query parameter format
      const response = await fetchWithAuth(getApiUrl('categories.php') + '?action=getAllCategories', {
        method: 'GET'
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'error') {
          console.error('Error fetching categories:', data.message);
          return;
        }
        setCategories(Array.isArray(data.categories) ? data.categories : []);
      } else {
        console.error('Failed to fetch categories:', response.status);
        setCategories([]);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  // Handle section changes and navigation
  useEffect(() => {
    const loadSectionData = async () => {
      console.log("Loading section data for:", activeSection);
      
      // Reset preview content
      setPreviewContent(null);
      
      if (activeSection === 'menu') {
        // Only fetch menu items if we don't have any
        if (menuItems.length === 0) {
          await fetchMenuItems();
        }
      } 
      
      if (activeSection === 'categories') {
        // Only set categories preview if we have categories
        if (categories.length > 0) {
          console.log("Setting categories preview");
          const categoriesPreview = <MobileMenuList 
            items={[]} 
            categories={categories}
            skipFetch={true}
            forceFullView={true}
          />;
          setPreviewContent(categoriesPreview);
        }
      }
    };

    loadSectionData();
    
    // Update URL without triggering a re-render
    const newPath = `/builder/${activeSection}`;
    if (window.location.pathname !== newPath) {
      navigate(newPath, { replace: true });
    }
  }, [activeSection]); // Only depend on activeSection

  // Separate effect for location changes
  useEffect(() => {
    const path = location.pathname;
    const section = path.split('/').pop() || 'menu';
    
    // Only update if different and valid
    if (section !== activeSection && ['menu', 'categories', 'theme'].includes(section)) {
      console.log(`URL changed to ${section}`);
      setActiveSection(section);
    }
  }, [location.pathname]); // Only depend on pathname

  // Create global helper functions that stay consistent
  useEffect(() => {
    // Make the categories renderer available immediately
    window.renderCategoriesDirectly = () => {
      console.log('renderCategoriesDirectly called - FORCE IMMEDIATE RENDER');
      
      // Directly construct the Categories component 
      const categoriesComponent = (
        <Categories 
          key={`categories-force-immediate-${Date.now()}`}
          categories={categories}
          onUpdate={fetchCategories}
          isPreview={true}
        />
      );
      
      // Set active section and content at once
      setActiveSection('categories');
      
      // Force an immediate render without dependencies
      setTimeout(() => {
        console.log('FORCING preview content reset and update');
        // Reset to null first to ensure update
        setPreviewContent(null);
        // Force immediate update
        setPreviewContent(categoriesComponent);
      }, 0);
    };
    
    // Cleanup
    return () => {
      delete window.renderCategoriesDirectly;
    };
  }, [categories, fetchCategories]);
  
  // Initial data fetch - only once
  useEffect(() => {
    let isMounted = true;
    
    const initializeData = async () => {
      try {
        // Fetch categories first
        await fetchCategories();
        
        // Then fetch menu items if we're on the menu section
        if (isMounted && activeSection === 'menu') {
          await fetchMenuItems();
        }
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    };

    // Set up global functions
    window.updatePhonePreview = (content) => {
      if (isMounted) setPreviewContent(content);
    };

    window.refreshPhonePreview = async () => {
      if (isMounted) {
        setPreviewContent(null);
        await fetchMenuItems();
      }
    };
    
    initializeData();

    return () => {
      isMounted = false;
      delete window.updatePhonePreview;
      delete window.setActiveSection;
      delete window.refreshPhonePreview;
    };
  }, []); // Only run once on mount

  useEffect(() => {
    // Make menu items available globally for the categories view
    window.menuItems = menuItems;
    
    // Make setActiveSection available globally (simplified version)
    window.setActiveSection = (section) => {
      console.log(`setActiveSection called with: ${section}`);
      
      // For categories, use the direct method if available
      if (section === 'categories' && window.renderCategoriesDirectly) {
        console.log('Using renderCategoriesDirectly for better performance');
        window.renderCategoriesDirectly();
        return;
      }
      
      // Set the active section
      setActiveSection(section);
      
      // Force immediate render of content based on section
      if (section === 'categories') {
        console.log("Directly rendering Categories component");
        // Ensure we immediately render the Categories component
        const categoriesComponent = (
          <Categories 
            key={`categories-direct-${Date.now()}`}
            categories={categories}
            onUpdate={fetchCategories}
            isPreview={true}
          />
        );
        
        // Force immediate update
        setTimeout(() => {
          setPreviewContent(categoriesComponent);
        }, 0);
      } else if (section === 'items' || section === 'menu') {
        console.log("Directly rendering Menu component");
        setPreviewContent(
          <MobileMenuList 
            key={`menu-${Date.now()}`}
            items={menuItems} 
            categories={categories}
            menuTitle="Fresh from the Kitchen"
          />
        );
      }
    };

    // Update preview when items change
    if (!loading) {
      updatePhonePreview();
    }
  }, [menuItems, categories, loading]);

  // Add function to show all items
  window.showAllItems = () => {
    setActiveSection('menu');
    setPreviewContent(
      <MobileMenuList 
        key={`menu-${Date.now()}`}
        items={menuItems} 
        categories={categories}
        forceFullView={false}
        menuTitle="Fresh from the Kitchen"
      />
    );
  };

  // Add function to set selected category
  window.setSelectedCategory = (categoryId) => {
    setActiveSection('menu');
    // Find the category in the list
    const category = categories.find(c => c.id === categoryId);
    if (category) {
      // Filter items that belong to this category
      const filteredItems = menuItems.filter(item => {
        // Check both primary category and additional categories
        if (item.category_id === categoryId) return true;
        if (item.additional_category_ids) {
          const additionalIds = item.additional_category_ids.split(',').map(id => parseInt(id));
          return additionalIds.includes(categoryId);
        }
        return false;
      });

      // Update preview content to show filtered items with category name
      setPreviewContent(
        <MobileMenuList 
          key={`menu-${Date.now()}`}
          items={filteredItems}
          categories={categories}
          forceFullView={false}
          selectedCategory={category}
          menuTitle={category.name} // Pass the category name to display
        />
      );
    }
  };

  const handleSectionClick = (section) => {
    console.log(`handleSectionClick called with: ${section}`);
    
    // Force render the proper preview when clicking Categories
    if (section === 'categories') {
      // IMPORTANT: Set both state changes at once to avoid race conditions
      console.log('DIRECTLY rendering Categories component');
      
      // Force navigation first
      navigate(`/builder/${section}`);
      
      // This is critical: set state and previewContent together
      setActiveSection(section);
      
      // IMMEDIATE render - not dependent on state updates
      const categoriesComponent = (
        <Categories 
          key={`categories-force-${Date.now()}`}
          categories={categories}
          onUpdate={fetchCategories}
          isPreview={true}
        />
      );
      
      // Force immediate replacement of preview content
      setPreviewContent(categoriesComponent);
      return;
    }
    
    // For other sections, just set the active section
    setActiveSection(section);
    navigate(`/builder/${section}`);
  };

  const handleAddItem = () => {
    setIsModalOpen(true);
  };

  const handleFormSubmit = async (formData) => {
    try {
      const response = await fetch(getApiUrl('menu_items'), {
        method: 'POST',
        body: formData,
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === 'success') {
          // Add the new item to the menu items list
          setMenuItems(prev => [...prev, result.item]);
          setIsModalOpen(false);

          // Refresh the menu items
          fetchMenuItems();
        } else {
          console.error('Error adding menu item:', result.message);
        }
      } else {
        console.error('Failed to add menu item:', response.status);
      }
    } catch (error) {
      console.error('Error adding menu item:', error);
    }
  };

  const handleFormCancel = () => {
    setIsModalOpen(false);
  };

  // Get the appropriate content for the phone preview based on active section
  const getPhoneContent = () => {
    if (previewContent) {
      console.log('Using existing previewContent');
      return previewContent;
    }

    console.log(`getPhoneContent called for section: ${activeSection}`);
    
    // Force a deterministic key to ensure proper rendering
    const renderKey = `${activeSection}-${Date.now()}`;

    switch (activeSection) {
      case 'menu':
        console.log('Rendering menu content');
        return <MobileMenuList 
          key={`menu-${renderKey}`} 
          items={menuItems} 
          categories={categories}
        />;
      case 'categories':
        console.log("FORCE RENDERING categories view with", categories.length, "categories");
        // DIRECTLY create and return the Categories component
        // Do not depend on any state updates
        return (
          <Categories 
            key={`categories-getPhoneContent-${Date.now()}`}
            categories={categories}
            onUpdate={fetchCategories}
            isPreview={true}
          />
        );
      case 'theme':
        console.log('Rendering theme content');
        return <MobileMenuList 
          key={`theme-${renderKey}`} 
          items={menuItems} 
          categories={categories}
        />;
      default:
        return null;
    }
  };

  // Add new function to handle menu item edit
  const handleEditItem = async (item) => {
    // First set the item from the list to show something immediately
    setEditingItem(item);
    setIsEditModalOpen(true);
    
    try {
      // Now fetch the complete item data with options
      const response = await fetchWithAuth(getApiUrl('menu_items') + `?id=${item.id}`, {
        method: 'GET'
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          // Update with complete item data including options
          setEditingItem(data.data);
        } else {
          console.error('Error fetching item details:', data.message);
        }
      } else {
        console.error('Failed to fetch item details:', response.status);
      }
    } catch (error) {
      console.error('Error fetching item details:', error);
    }
  };
  
  // Handle saving edited menu item
  const handleEditSave = (updatedItem, shouldCloseModal = false) => {
    // Update the menu items list
    setMenuItems(prevItems => 
      prevItems.map(item => 
        item.id === updatedItem.id ? updatedItem : item
      )
    );
    
    // Refresh phone preview
    if (window.refreshPhonePreview) {
      window.refreshPhonePreview();
    }
    
    // Only close the modal if explicitly requested
    if (shouldCloseModal) {
      setIsEditModalOpen(false);
      setEditingItem(null);
    }
  };
  
  // Handle edit cancel
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
    setEditingItem(null);
  };

  // Update MobileMenuList to make items clickable
  useEffect(() => {
    window.editMenuItem = (itemId) => {
      const item = menuItems.find(item => item.id === itemId);
      if (item) {
        handleEditItem(item);
      }
    };
    
    return () => {
      delete window.editMenuItem;
    };
  }, [menuItems]);

  // Right sidebar content
  const renderRightSidebar = () => {
    switch (activeSection) {
      case 'menu':
        return (
          <div className="control-panel">
            <h2>Menu Items</h2>
            <button className="action-btn" onClick={handleAddItem}>
              <i className="fas fa-plus"></i> Add Item
            </button>
            {menuItems.length > 0 && (
              <div className="menu-items-list">
                {menuItems.map(item => (
                  <div key={item.id} className="menu-item-row" onClick={() => handleEditItem(item)}>
                    {item.image_url ? (
                      <img src={item.image_url} alt={item.name} className="item-thumbnail" />
                    ) : item.icon_url ? (
                      <img src={item.icon_url} alt={item.name} className="item-thumbnail" />
                    ) : (
                      <div className="item-thumbnail-placeholder">No Image</div>
                    )}
                    <div className="item-details">
                      <h3>{item.name}</h3>
                      <p className="item-price">${parseFloat(item.price).toFixed(2)}</p>
                      <p className="item-category">{item.category_name || 'Uncategorized'}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      case 'categories':
        return (
          <div className="control-panel">
            <h2>Categories</h2>
            <Categories 
              categories={categories} 
              onUpdate={async () => {
                await fetchCategories();
              }} 
            />
          </div>
        );
      case 'theme':
        return (
          <div className="control-panel">
            <Theme />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="app-builder">
      <div className="top-bar">
        <Link to="/phone">Phone View</Link>
        <Link to="/builder">Menu Builder</Link>
        <Link to="/countertop">Back to Countertop</Link>
      </div>
      <div className="main-content">
        {/* Left Sidebar - Navigation */}
        <div className="sidebar left-sidebar">
          {user?.isAdminUser && (
            <button 
              className="sidebar-btn dashboard-link"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = '/dashboard';
              }}
            >
              <i className="fas fa-tachometer-alt"></i>
              <span>Admin Dashboard</span>
            </button>
          )}

          <button 
            className={`sidebar-btn ${activeSection === 'menu' ? 'active' : ''}`}
            onClick={() => handleSectionClick('menu')}
          >
            <i className="fas fa-utensils"></i>
            <span>Menu Items</span>
          </button>
          <button 
            className={`sidebar-btn ${activeSection === 'categories' ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              console.log('Categories button clicked - USING RENDERER FUNCTION');
              
              // Use the same function that works for the phone tab
              if (window.renderCategoriesDirectly) {
                window.renderCategoriesDirectly();
              } else {
                // Fallback to the basic approach if the function isn't available
                navigate('/builder/categories');
                setActiveSection('categories');
              }
            }}
          >
            <i className="fas fa-tags"></i>
            <span>Categories</span>
          </button>
          <button 
            className={`sidebar-btn ${activeSection === 'theme' ? 'active' : ''}`}
            onClick={() => handleSectionClick('theme')}
          >
            <i className="fas fa-paint-brush"></i>
            <span>Theme</span>
          </button>
          <button 
            className={`sidebar-btn ${activeSection === 'preview' ? 'active' : ''}`}
            onClick={() => handleSectionClick('preview')}
          >
            <i className="fas fa-mobile-alt"></i>
            <span>Preview</span>
          </button>
          
          {/* Spacer to push the countertop link to the bottom */}
          <div style={{ flex: 1 }}></div>
          
          <button 
            className="sidebar-btn countertop-link"
            onClick={(e) => {
              e.preventDefault();
              navigate('/countertop');
            }}
          >
            <i className="fas fa-home"></i>
            <span>Back to Countertop</span>
          </button>
        </div>

        {/* Center - Phone Preview */}
        <div className="preview-container">
          <PhoneFrame content={getPhoneContent()} />
        </div>

        {/* Right Sidebar - Controls */}
        <div className="sidebar right-sidebar">
          {renderRightSidebar()}
        </div>
      </div>

      {/* Add Item Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={() => setIsModalOpen(false)}>×</button>
            <MenuItemForm 
              categories={categories} 
              onSave={(newItem) => {
                setMenuItems(prev => [...prev, newItem]);
                setIsModalOpen(false);
                if (window.refreshPhonePreview) {
                  window.refreshPhonePreview();
                }
              }}
              onCancel={() => setIsModalOpen(false)}
            />
          </div>
        </div>
      )}
      
      {/* Edit Item Modal */}
      {isEditModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={handleEditCancel}>×</button>
            <MenuItemEditForm 
              item={editingItem}
              categories={categories} 
              onSave={handleEditSave}
              onCancel={handleEditCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AppBuilder; 