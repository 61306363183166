import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Auth.css';
import { API_ENDPOINTS } from '../../config/api';

const Registration = () => {
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: ''
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validations, setValidations] = useState({
    username: { isValid: false, message: '' },
    email: { isValid: false, message: '' },
    password: { isValid: false, message: '' },
    confirmPassword: { isValid: false, message: '' },
    phone: { isValid: true, message: '' }
  });
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });

  // Execute reCAPTCHA when component mounts
  useEffect(() => {
    if (recaptchaToken) {
      const handleReCaptchaVerify = async () => {
        try {
          console.log('Executing reCAPTCHA on mount...');
          const token = await recaptchaToken;
          console.log('reCAPTCHA token generated:', token ? 'success' : 'failed');
          setRecaptchaToken(token);
        } catch (error) {
          console.error('reCAPTCHA error:', error);
          setErrors(prev => ({
            ...prev,
            recaptcha: 'Failed to verify reCAPTCHA'
          }));
        }
      };
      handleReCaptchaVerify();
    }
  }, [recaptchaToken]);

  // Add debounce function to prevent too many API calls
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // Check email availability
  const checkEmail = useCallback(async (email) => {
    try {
        const response = await fetch(`/api/check_email.php?email=${encodeURIComponent(email)}`);
        const data = await response.json();
        
        if (response.ok) {
            setValidations(prev => ({
                ...prev,
                email: {
                    isValid: data.available,
                    message: data.available ? 'Email is available' : 'Email already registered'
                }
            }));
        } else {
            setValidations(prev => ({
                ...prev,
                email: {
                    isValid: false,
                    message: data.message || 'Error checking email'
                }
            }));
        }
    } catch (error) {
        console.error('Error checking email:', error);
        setValidations(prev => ({
            ...prev,
            email: {
                isValid: false,
                message: 'Error checking email'
            }
        }));
    }
    setIsCheckingEmail(false);
  }, []);

  // Create debounced version of the email check
  const debouncedCheckEmail = useCallback(
    debounce((email) => checkEmail(email), 500),
    []
  );

  // Check username availability
  const checkUsernameAvailability = async (username) => {
    if (!username || username.length < 3) return;
    
    setIsCheckingUsername(true);
    try {
      console.log('Checking username availability for:', username);
      const response = await fetch(`/api/check_username.php?username=${encodeURIComponent(username)}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Cache-Control': 'no-cache'
        }
      });

      console.log('Username check response status:', response.status);
      const data = await response.json();
      console.log('Username check response data:', data);
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to check username availability');
      }

      setValidations(prev => ({
        ...prev,
        username: {
          isValid: data.available,
          message: data.message
        }
      }));
    } catch (error) {
      console.error('Error checking username:', error);
      setValidations(prev => ({
        ...prev,
        username: {
          isValid: false,
          message: 'Error checking username availability'
        }
      }));
    } finally {
      setIsCheckingUsername(false);
    }
  };

  // Create debounced version of the check
  const debouncedCheckUsername = useCallback(
    debounce((username) => checkUsernameAvailability(username), 500),
    []
  );

  const validateForm = () => {
    const newErrors = {};
    
    // Username validation
    if (!formData.username.trim()) {
      newErrors.username = 'Username is required';
    } else if (formData.username.length < 3) {
      newErrors.username = 'Username must be at least 3 characters';
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }

    // Confirm password validation
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    // Phone validation - only validate if a phone number is provided
    if (formData.phone) {
      const numbersOnly = formData.phone.replace(/\D/g, '');
      if (numbersOnly.length > 0 && numbersOnly.length !== 10) {
        newErrors.phone = 'Phone number must be 10 digits';
      }
    }

    // reCAPTCHA validation
    if (!recaptchaToken) {
      newErrors.recaptcha = 'Please complete the reCAPTCHA';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = useCallback(async (e) => {
    const { name, value } = e.target;
    
    // Special handling for phone number
    if (name === 'phone') {
      handlePhoneChange(e);
      return;
    }

    setFormData(prev => ({ ...prev, [name]: value }));

    // Clear any existing errors for this field
    setErrors(prev => ({ ...prev, [name]: '' }));

    // Validate fields
    switch (name) {
      case 'username':
        if (value.length >= 3) {
          debouncedCheckUsername(value);
        } else {
          setValidations(prev => ({
            ...prev,
            username: {
              isValid: false,
              message: value.length > 0 ? 'Username must be at least 3 characters' : ''
            }
          }));
        }
        break;

      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(value)) {
          debouncedCheckEmail(value);
        } else {
          setValidations(prev => ({
            ...prev,
            email: {
              isValid: false,
              message: value.length > 0 ? 'Please enter a valid email address' : ''
            }
          }));
        }
        break;

      case 'password':
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumbers = /\d/.test(value);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
        const isValidLength = value.length >= 8;
        
        const isValid = hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar && isValidLength;
        let message = '';
        
        if (value.length > 0) {
          if (!isValidLength) message = 'Password must be at least 8 characters';
          else if (!hasUpperCase) message = 'Password must contain an uppercase letter';
          else if (!hasLowerCase) message = 'Password must contain a lowercase letter';
          else if (!hasNumbers) message = 'Password must contain a number';
          else if (!hasSpecialChar) message = 'Password must contain a special character';
          else message = 'Password meets requirements';
        }

        setValidations(prev => ({
          ...prev,
          password: { isValid, message }
        }));

        // Also validate confirm password if it has a value
        if (formData.confirmPassword) {
          setValidations(prev => ({
            ...prev,
            confirmPassword: {
              isValid: value === formData.confirmPassword,
              message: value === formData.confirmPassword ? 'Passwords match' : 'Passwords do not match'
            }
          }));
        }
        break;

      case 'confirmPassword':
        setValidations(prev => ({
          ...prev,
          confirmPassword: {
            isValid: value === formData.password,
            message: value.length > 0 ? (value === formData.password ? 'Passwords match' : 'Passwords do not match') : ''
          }
        }));
        break;

      case 'phone':
        if (value) {
          const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
          const isValid = phoneRegex.test(value);
          setValidations(prev => ({
            ...prev,
            phone: {
              isValid,
              message: isValid ? '' : 'Please enter a valid phone number'
            }
          }));
        } else {
          // Phone is optional, so empty is valid
          setValidations(prev => ({
            ...prev,
            phone: { isValid: true, message: '' }
          }));
        }
        break;

      default:
        break;
    }
  }, [formData.password, formData.confirmPassword]);

  const formatPhoneNumber = (value) => {
    const numbers = value.replace(/\D/g, '');
    if (numbers.length === 0) return '';
    if (numbers.length <= 3) return `(${numbers}`;
    if (numbers.length <= 6) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
    return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setFormData(prev => ({ ...prev, phone: formattedNumber }));

    // Validate phone number
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValid = phoneRegex.test(formattedNumber);
    setValidations(prev => ({
      ...prev,
      phone: {
        isValid: formattedNumber === '' || isValid, // Empty is valid since phone is optional
        message: formattedNumber && !isValid ? 'Please enter a valid phone number' : ''
      }
    }));
  };

  const isFormValid = () => {
    return (
      validations.username.isValid &&
      validations.email.isValid &&
      validations.password.isValid &&
      validations.confirmPassword.isValid &&
      validations.phone.isValid &&
      !isCheckingUsername &&
      !isCheckingEmail
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!recaptchaToken) {
      setErrors(prev => ({ ...prev, recaptcha: 'Please complete the reCAPTCHA verification' }));
      return;
    }

    if (!isFormValid()) {
      setErrors(prev => ({ ...prev, submit: 'Please fix all validation errors before submitting' }));
      return;
    }

    setIsLoading(true);
    setErrors({});

    try {
      const response = await fetch(API_ENDPOINTS.createAccount, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: formData.username,
          email: formData.email,
          password: formData.password,
          phone: formData.phone.replace(/\D/g, ''),
          recaptchaToken
        })
      });

      const data = await response.json();

      if (response.ok) {
        // Redirect to email verification page
        navigate('/verify', { 
          state: { 
            email: formData.email,
            message: data.message || 'Please check your email to verify your account.'
          }
        });
      } else {
        setErrors(prev => ({ 
          ...prev, 
          submit: data.message || 'An error occurred while creating your account. Please try again.'
        }));
      }
    } catch (error) {
      console.error('Error creating account:', error);
      setErrors(prev => ({ 
        ...prev, 
        submit: 'A network error occurred. Please check your connection and try again.'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setErrors(prev => ({ ...prev, recaptcha: '' }));
  };

  const generateStrongPassword = () => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData(prev => ({
      ...prev,
      password: password,
      confirmPassword: password
    }));

    // Trigger password validation
    setValidations(prev => ({
      ...prev,
      password: {
        isValid: true,
        message: 'Strong password generated'
      },
      confirmPassword: {
        isValid: true,
        message: 'Passwords match'
      }
    }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleSubmit}>
        <h2>Create Account</h2>
        
        {errors.submit && (
          <div className="error-message">{errors.submit}</div>
        )}

        <div className="form-group">
          <label htmlFor="username">
            Username
            {isCheckingUsername && <span className="checking-indicator">(checking...)</span>}
            {validations.username.message && (
              <span className={`validation-message ${validations.username.isValid ? 'valid' : 'invalid'}`}>
                {validations.username.message}
              </span>
            )}
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className={validations.username.message ? (validations.username.isValid ? 'valid' : 'invalid') : ''}
            />
            {formData.username && validations.username.message && (
              <span className={`validation-icon ${validations.username.isValid ? 'valid' : 'invalid'}`}>
                <FontAwesomeIcon icon={validations.username.isValid ? faCheck : faTimes} />
              </span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="email">
            Email
            {isCheckingEmail && <span className="checking-indicator">(checking...)</span>}
            {validations.email.message && (
              <span className={`validation-message ${validations.email.isValid ? 'valid' : 'invalid'}`}>
                {validations.email.message}
                {validations.email.message === 'Email already registered' && (
                  <Link to="/forgot-password" className="forgot-password-link">
                    Forgot Password?
                  </Link>
                )}
              </span>
            )}
          </label>
          <div className="input-wrapper">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={validations.email.message ? (validations.email.isValid ? 'valid' : 'invalid') : ''}
            />
            {formData.email && validations.email.message && (
              <span className={`validation-icon ${validations.email.isValid ? 'valid' : 'invalid'}`}>
                <FontAwesomeIcon icon={validations.email.isValid ? faCheck : faTimes} />
              </span>
            )}
          </div>
          {errors.email && <span className="error-text">{errors.email}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="password">
            Password
            {validations.password.message && (
              <span className={`validation-message ${validations.password.isValid ? 'valid' : 'invalid'}`}>
                {validations.password.message}
              </span>
            )}
          </label>
          <div className="input-wrapper">
            <div className="password-input-group">
              <div className="password-input-wrapper">
                <input
                  type={showPassword.password ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={validations.password.message ? (validations.password.isValid ? 'valid' : 'invalid') : ''}
                />
                {formData.password && (
                  <span className={`validation-icon ${validations.password.isValid ? 'valid' : 'invalid'}`}>
                    <FontAwesomeIcon icon={validations.password.isValid ? faCheck : faTimes} />
                  </span>
                )}
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('password')}
                  className="toggle-password-btn"
                >
                  <FontAwesomeIcon icon={showPassword.password ? faEyeSlash : faEye} />
                </button>
              </div>
              <button 
                type="button" 
                onClick={generateStrongPassword}
                className="generate-password-btn"
              >
                Generate Strong Password
              </button>
            </div>
          </div>
          {errors.password && <span className="error-text">{errors.password}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">
            Confirm Password
            {validations.confirmPassword.message && (
              <span className={`validation-message ${validations.confirmPassword.isValid ? 'valid' : 'invalid'}`}>
                {validations.confirmPassword.message}
              </span>
            )}
          </label>
          <div className="input-wrapper">
            <div className="password-input-group">
              <div className="password-input-wrapper">
                <input
                  type={showPassword.confirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className={validations.confirmPassword.message ? (validations.confirmPassword.isValid ? 'valid' : 'invalid') : ''}
                />
                {formData.confirmPassword && (
                  <span className={`validation-icon ${validations.confirmPassword.isValid ? 'valid' : 'invalid'}`}>
                    <FontAwesomeIcon icon={validations.confirmPassword.isValid ? faCheck : faTimes} />
                  </span>
                )}
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('confirmPassword')}
                  className="toggle-password-btn"
                >
                  <FontAwesomeIcon icon={showPassword.confirmPassword ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>
          </div>
          {errors.confirmPassword && <span className="error-text">{errors.confirmPassword}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number (Optional)</label>
          <div className="input-wrapper">
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handlePhoneChange}
              placeholder="(123) 456-7890"
              className={validations.phone.isValid ? 'valid' : errors.phone ? 'invalid' : ''}
            />
            {formData.phone && formData.phone.replace(/\D/g, '').length === 10 && (
              <span className="validation-icon valid">
                <FontAwesomeIcon icon={faCheck} />
              </span>
            )}
          </div>
          {errors.phone && <span className="error-text">{errors.phone}</span>}
        </div>

        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          />
          {errors.recaptcha && (
            <div className="error-message">{errors.recaptcha}</div>
          )}
        </div>

        <button 
          type="submit" 
          disabled={!isFormValid() || isLoading || !recaptchaToken}
          className={!isFormValid() || isLoading || !recaptchaToken ? 'disabled' : ''}
        >
          {isLoading ? 'Creating Account...' : 'Create Account'}
        </button>

        <p className="auth-link">
          Already have an account? <a href="/login">Log in</a>
        </p>
      </form>
    </div>
  );
};

export default Registration; 