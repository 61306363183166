import React, { useState, useEffect } from 'react';
import { Card, Select, Spin, Alert, Table, Tag, Space, Progress, Tooltip } from 'antd';
import { LineChart, Line, PieChart, Pie, XAxis, YAxis, CartesianGrid, Legend, Cell } from 'recharts';
import { 
    NotificationOutlined, 
    CheckCircleOutlined, 
    CloseCircleOutlined, 
    MailOutlined 
} from '@ant-design/icons';
import { AdminService } from '../../services/AdminService';

const NotificationMonitoring = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('24h');
    const [stats, setStats] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState('all');

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    useEffect(() => {
        fetchNotificationStats();
    }, [timeRange, selectedChannel]);

    const fetchNotificationStats = async () => {
        try {
            setLoading(true);
            const data = await AdminService.getNotificationStats(timeRange, selectedChannel);
            setStats(data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch notification statistics');
            console.error('Error fetching notification stats:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error) {
        return <Alert type="error" message={error} />;
    }

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>Notification Monitoring</h2>
                <Space>
                    <Select
                        value={timeRange}
                        onChange={setTimeRange}
                        style={{ width: 120 }}
                    >
                        <Select.Option value="1h">Last Hour</Select.Option>
                        <Select.Option value="24h">Last 24 Hours</Select.Option>
                        <Select.Option value="7d">Last 7 Days</Select.Option>
                        <Select.Option value="30d">Last 30 Days</Select.Option>
                    </Select>
                    <Select
                        value={selectedChannel}
                        onChange={setSelectedChannel}
                        style={{ width: 120 }}
                    >
                        <Select.Option value="all">All Channels</Select.Option>
                        <Select.Option value="email">Email</Select.Option>
                        <Select.Option value="push">Push</Select.Option>
                        <Select.Option value="sms">SMS</Select.Option>
                        <Select.Option value="in-app">In-App</Select.Option>
                    </Select>
                </Space>
            </div>

            <div className="cards-grid">
                <Card title="Total Notifications" className="metric-card">
                    <h3>
                        <NotificationOutlined style={{ marginRight: '8px', color: '#1890ff' }}/>
                        {stats?.totalNotifications || 0}
                    </h3>
                    <p>Sent in the last {timeRange}</p>
                </Card>

                <Card title="Delivery Rate" className="metric-card">
                    <h3>
                        <CheckCircleOutlined style={{ marginRight: '8px', color: '#52c41a' }}/>
                        {stats?.deliveryRate || 0}%
                    </h3>
                    <p>Successfully delivered</p>
                </Card>

                <Card title="Bounce Rate" className="metric-card">
                    <h3>
                        <CloseCircleOutlined style={{ marginRight: '8px', color: '#ff4d4f' }}/>
                        {stats?.bounceRate || 0}%
                    </h3>
                    <p>Failed deliveries</p>
                </Card>

                <Card title="Open Rate" className="metric-card">
                    <h3>
                        <MailOutlined style={{ marginRight: '8px', color: '#722ed1' }}/>
                        {stats?.openRate || 0}%
                    </h3>
                    <p>Of delivered notifications</p>
                </Card>
            </div>

            <div className="chart-row">
                <Card title="Notification Volume" className="chart-card">
                    <LineChart
                        width={500}
                        height={300}
                        data={stats?.volumeTrends || []}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="timestamp" />
                        <YAxis />
                        <Legend />
                        <Line 
                            type="monotone" 
                            dataKey="sent" 
                            stroke="#1890ff" 
                            name="Sent"
                        />
                        <Line 
                            type="monotone" 
                            dataKey="delivered" 
                            stroke="#52c41a" 
                            name="Delivered"
                        />
                        <Line 
                            type="monotone" 
                            dataKey="failed" 
                            stroke="#ff4d4f" 
                            name="Failed"
                        />
                    </LineChart>
                </Card>

                <Card title="Channel Distribution" className="chart-card">
                    <PieChart width={500} height={300}>
                        <Pie
                            data={stats?.channelDistribution || []}
                            cx={250}
                            cy={150}
                            innerRadius={60}
                            outerRadius={120}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            label
                        >
                            {stats?.channelDistribution?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend />
                    </PieChart>
                </Card>
            </div>

            <div className="cards-grid">
                <Card title="Engagement Metrics" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div>
                            <span>Click-through Rate:</span>
                            <Progress 
                                percent={stats?.engagementMetrics?.ctr || 0} 
                                size="small"
                                strokeColor="#1890ff"
                            />
                        </div>
                        <div>
                            <span>Conversion Rate:</span>
                            <Progress 
                                percent={stats?.engagementMetrics?.conversionRate || 0} 
                                size="small"
                                strokeColor="#52c41a"
                            />
                        </div>
                        <div>
                            <span>Opt-out Rate:</span>
                            <Progress 
                                percent={stats?.engagementMetrics?.optOutRate || 0} 
                                size="small"
                                strokeColor="#ff4d4f"
                            />
                        </div>
                    </Space>
                </Card>

                <Card title="A/B Testing Results" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        {stats?.abTests?.map((test, index) => (
                            <Tooltip key={index} title={`p-value: ${test.pValue}`}>
                                <div>
                                    <span>{test.name}:</span>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ flex: 1, marginRight: '8px' }}>
                                            <Progress 
                                                percent={test.variantA} 
                                                size="small"
                                                strokeColor="#1890ff"
                                            />
                                            <small>Variant A</small>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <Progress 
                                                percent={test.variantB} 
                                                size="small"
                                                strokeColor="#52c41a"
                                            />
                                            <small>Variant B</small>
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                        ))}
                    </Space>
                </Card>
            </div>

            <Card title="Notification Log" className="table-card">
                <Table
                    dataSource={stats?.notificationLog || []}
                    columns={[
                        {
                            title: 'Timestamp',
                            dataIndex: 'timestamp',
                            key: 'timestamp',
                            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
                        },
                        {
                            title: 'Type',
                            dataIndex: 'type',
                            key: 'type',
                            filters: [
                                { text: 'Transactional', value: 'transactional' },
                                { text: 'Marketing', value: 'marketing' },
                                { text: 'System', value: 'system' },
                            ],
                            onFilter: (value, record) => record.type === value,
                        },
                        {
                            title: 'Channel',
                            dataIndex: 'channel',
                            key: 'channel',
                            filters: [
                                { text: 'Email', value: 'email' },
                                { text: 'Push', value: 'push' },
                                { text: 'SMS', value: 'sms' },
                                { text: 'In-App', value: 'in-app' },
                            ],
                            onFilter: (value, record) => record.channel === value,
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: status => (
                                <Tag color={
                                    status === 'delivered' ? 'success' :
                                    status === 'failed' ? 'error' :
                                    'processing'
                                }>
                                    {status.toUpperCase()}
                                </Tag>
                            ),
                        },
                        {
                            title: 'Recipient',
                            dataIndex: 'recipient',
                            key: 'recipient',
                            ellipsis: true,
                        },
                        {
                            title: 'Engagement',
                            dataIndex: 'engagement',
                            key: 'engagement',
                            render: engagement => (
                                <Space>
                                    {engagement.opened && (
                                        <Tag color="blue">OPENED</Tag>
                                    )}
                                    {engagement.clicked && (
                                        <Tag color="green">CLICKED</Tag>
                                    )}
                                    {engagement.converted && (
                                        <Tag color="purple">CONVERTED</Tag>
                                    )}
                                </Space>
                            ),
                        },
                    ]}
                    expandable={{
                        expandedRowRender: record => (
                            <Space direction="vertical">
                                <p><strong>Subject:</strong> {record.subject}</p>
                                <p><strong>Content:</strong> {record.content}</p>
                                {record.metadata && (
                                    <div>
                                        <p><strong>Metadata:</strong></p>
                                        <pre>
                                            {JSON.stringify(record.metadata, null, 2)}
                                        </pre>
                                    </div>
                                )}
                                {record.error && (
                                    <Alert
                                        type="error"
                                        message="Delivery Error"
                                        description={record.error}
                                    />
                                )}
                            </Space>
                        ),
                    }}
                    pagination={{ pageSize: 10 }}
                />
            </Card>
        </div>
    );
};

export default NotificationMonitoring; 