import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './Auth.css';

const EXPIRY_TIME = 10 * 60; // 10 minutes in seconds

const Verify = () => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(EXPIRY_TIME);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendCountdown, setResendCountdown] = useState(60); // 60 seconds before resend is enabled
  const location = useLocation();
  const navigate = useNavigate();
  const { checkAuthStatus } = useAuth();
  
  // Get email from location state or localStorage
  const email = location.state?.email || localStorage.getItem('verificationEmail');
  
  useEffect(() => {
    // If email exists in location state, save it to localStorage
    if (location.state?.email) {
      localStorage.setItem('verificationEmail', location.state.email);
      localStorage.setItem('verificationStartTime', Date.now().toString());
    }

    // If no email at all, redirect to registration
    if (!email) {
      navigate('/register');
      return;
    }

    // Check if user was redirected from verification link
    const params = new URLSearchParams(location.search);
    if (params.get('verified') === 'true') {
      const verifySuccess = async () => {
        // Wait for auth data to be available
        await new Promise(resolve => setTimeout(resolve, 1000));
        await checkAuthStatus();
        
        // Clear verification data
        localStorage.removeItem('verificationEmail');
        localStorage.removeItem('verificationStartTime');
        
        // Navigate to dashboard
        navigate('/dashboard');
      };
      
      verifySuccess();
      return;
    }

    // Set up expiry timer
    const startTime = parseInt(localStorage.getItem('verificationStartTime') || Date.now());
    const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
    const remainingTime = Math.max(0, EXPIRY_TIME - elapsedSeconds);
    setTimeLeft(remainingTime);

    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Set up resend timer
    const resendTimer = setInterval(() => {
      setResendCountdown(prevTime => {
        if (prevTime <= 1) {
          clearInterval(resendTimer);
          setResendDisabled(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Clean up timers
    return () => {
      clearInterval(timer);
      clearInterval(resendTimer);
    };
  }, [email, navigate, location, checkAuthStatus]);

  // Format time left into minutes and seconds
  const formatTimeLeft = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleResend = async () => {
    setIsLoading(true);
    setError('');
    
    try {
      const response = await fetch('/api/resend-code.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email
        })
      });

      const data = await response.json();

      if (data.status === 'success') {
        // Reset timer
        localStorage.setItem('verificationStartTime', Date.now().toString());
        setTimeLeft(EXPIRY_TIME);
        setError('');
        // Disable resend button for 60 seconds
        setResendDisabled(true);
        setResendCountdown(60);
      } else {
        setError(data.message || 'Failed to resend verification email');
      }
    } catch (error) {
      console.error('Error resending verification email:', error);
      setError('Unable to connect to server. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!email) {
    return (
      <div className="auth-container">
        <div className="auth-form">
          <h2>Email Verification</h2>
          <p>Invalid verification link.</p>
          <button onClick={() => navigate('/login')} className="auth-button">
            Go to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-container">
      <div className="auth-form verification-form">
        <h2>Email Verification</h2>
        
        <div className="verification-message">
          <p>A verification link has been sent to:</p>
          <p className="email-address">{email}</p>
          
          <div className="instructions">
            <p>Please check your email and click on the verification link to complete your registration.</p>
            <p>The link will expire in <span className={timeLeft <= 60 ? 'expiring' : ''}>{formatTimeLeft(timeLeft)}</span></p>
          </div>
          
          {error && <div className="error-message">{error}</div>}
          
          <div className="resend-container">
            <p>Didn't receive the email?</p>
            <button 
              onClick={handleResend} 
              disabled={isLoading || resendDisabled}
              className={isLoading || resendDisabled ? 'disabled' : ''}
            >
              {isLoading ? 'Sending...' : resendDisabled ? `Resend in ${formatTimeLeft(resendCountdown)}` : 'Resend Email'}
            </button>
          </div>
          
          <div className="back-to-login">
            <button onClick={() => navigate('/login')} className="text-button">
              Return to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify; 