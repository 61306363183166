import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import './AccountSecurity.css';

const AccountSecurity = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    twoFactorEnabled: false,
    notificationSettings: {
      orderConfirmations: true,
      marketingEmails: false,
      systemAlerts: true,
      promotions: false
    }
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [twoFactorSetupInProgress, setTwoFactorSetupInProgress] = useState(false);
  const [twoFactorQrCode, setTwoFactorQrCode] = useState('');
  const [twoFactorSecret, setTwoFactorSecret] = useState('');
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [twoFactorError, setTwoFactorError] = useState('');
  const [twoFactorSuccess, setTwoFactorSuccess] = useState('');
  const [orderNotifications, setOrderNotifications] = useState(true);
  const [systemAlerts, setSystemAlerts] = useState(true);

  useEffect(() => {
    // In real implementation, fetch data from API
    // Simulating API call
    setTimeout(() => {
      setFormData({
        email: user?.email || '',
        phone: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        twoFactorEnabled: false,
        notificationSettings: {
          orderConfirmations: true,
          marketingEmails: false,
          systemAlerts: true,
          promotions: false
        }
      });
      setLoading(false);
    }, 800);

    // Fetch 2FA status when component mounts
    fetchTwoFactorStatus();
  }, [user]);

  const fetchTwoFactorStatus = async () => {
    try {
      const response = await fetch('/api/two-factor.php?action=status', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      const data = await response.json();
      
      if (data.success) {
        setFormData(prev => ({
          ...prev,
          twoFactorEnabled: data.enabled
        }));
        
        // If there's a pending setup, initiate the setup process
        if (data.setupPending) {
          setupTwoFactor();
        }
      }
    } catch (error) {
      console.error('Error fetching 2FA status:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    
    // Handle nested notification settings
    if (name.startsWith('notification_')) {
      const settingName = name.replace('notification_', '');
      setFormData(prev => ({
        ...prev,
        notificationSettings: {
          ...prev.notificationSettings,
          [settingName]: val
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: val
      }));
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    // Reset messages
    setErrorMessage('');
    setSuccessMessage('');
    
    // Validate passwords
    if (formData.newPassword !== formData.confirmPassword) {
      setErrorMessage('New passwords do not match.');
      return;
    }
    
    if (formData.newPassword.length < 8) {
      setErrorMessage('New password must be at least 8 characters long.');
      return;
    }
    
    try {
      setSaving(true);
      
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Reset password fields
      setFormData(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
      
      setSuccessMessage('Password changed successfully!');
    } catch (error) {
      setErrorMessage('Error changing password. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    
    // Reset messages
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      setSaving(true);
      
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setSuccessMessage('Profile updated successfully!');
    } catch (error) {
      setErrorMessage('Error updating profile. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleNotificationUpdate = async (e) => {
    e.preventDefault();
    
    // Reset messages
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      setSaving(true);
      
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setSuccessMessage('Notification preferences updated!');
    } catch (error) {
      setErrorMessage('Error updating notification preferences.');
    } finally {
      setSaving(false);
    }
  };

  const handleTwoFactorToggle = () => {
    if (formData.twoFactorEnabled) {
      disableTwoFactor();
    } else {
      setupTwoFactor();
    }
  };

  const setupTwoFactor = async () => {
    try {
      // Reset states
      setTwoFactorError('');
      setTwoFactorSuccess('');
      setTwoFactorSetupInProgress(true);
      
      const response = await fetch('/api/two-factor.php?action=setup', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      const data = await response.json();
      
      if (data.success) {
        setTwoFactorQrCode(data.qrCode);
        setTwoFactorSecret(data.secret);
      } else {
        setTwoFactorError(data.message || 'Failed to setup two-factor authentication');
        setTwoFactorSetupInProgress(false);
      }
    } catch (error) {
      setTwoFactorError('Error setting up two-factor authentication');
      setTwoFactorSetupInProgress(false);
      console.error(error);
    }
  };

  const verifyTwoFactor = async (e) => {
    e.preventDefault();
    
    if (!twoFactorCode) {
      setTwoFactorError('Please enter the verification code');
      return;
    }
    
    try {
      const formData = new FormData();
      formData.append('code', twoFactorCode);
      
      const response = await fetch('/api/two-factor.php?action=verify', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      });
      
      const data = await response.json();
      
      if (data.success) {
        // Verification successful, now enable 2FA
        await enableTwoFactor();
      } else {
        setTwoFactorError(data.message || 'Invalid verification code');
      }
    } catch (error) {
      setTwoFactorError('Error verifying code');
      console.error(error);
    }
  };

  const enableTwoFactor = async () => {
    try {
      const response = await fetch('/api/two-factor.php?action=enable', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      const data = await response.json();
      
      if (data.success) {
        setFormData(prev => ({
          ...prev,
          twoFactorEnabled: true
        }));
        setTwoFactorSetupInProgress(false);
        setTwoFactorSuccess('Two-factor authentication enabled successfully');
        setTwoFactorQrCode('');
        setTwoFactorSecret('');
        setTwoFactorCode('');
      } else {
        setTwoFactorError(data.message || 'Failed to enable two-factor authentication');
      }
    } catch (error) {
      setTwoFactorError('Error enabling two-factor authentication');
      console.error(error);
    }
  };

  const disableTwoFactor = async () => {
    try {
      const response = await fetch('/api/two-factor.php?action=disable', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      const data = await response.json();
      
      if (data.success) {
        setFormData(prev => ({
          ...prev,
          twoFactorEnabled: false
        }));
        setTwoFactorSuccess('Two-factor authentication disabled successfully');
        setTimeout(() => setTwoFactorSuccess(''), 3000);
      } else {
        setTwoFactorError(data.message || 'Failed to disable two-factor authentication');
      }
    } catch (error) {
      setTwoFactorError('Error disabling two-factor authentication');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading account settings...</p>
      </div>
    );
  }

  return (
    <div className="account-security-container">
      <div className="section-card">
        <h2>Account & Security</h2>
        <p className="section-description">
          Manage your account details, security settings, and notification preferences.
        </p>
        
        {errorMessage && (
          <div className="alert alert-error">
            <i className="fas fa-exclamation-circle"></i> {errorMessage}
          </div>
        )}
        
        {successMessage && (
          <div className="alert alert-success">
            <i className="fas fa-check-circle"></i> {successMessage}
          </div>
        )}
        
        <div className="form-section">
          <h3>Profile Information</h3>
          <form onSubmit={handleProfileUpdate}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="(555) 555-5555"
              />
              <p className="help-text">
                Used for important account updates and order notifications.
              </p>
            </div>
            
            <div className="form-actions">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={saving}
              >
                {saving ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> Saving...
                  </>
                ) : 'Update Profile'}
              </button>
            </div>
          </form>
        </div>
        
        <div className="form-section">
          <h3>Change Password</h3>
          <form onSubmit={handlePasswordChange}>
            <div className="form-group">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                required
              />
              <p className="help-text">
                Password must be at least 8 characters long.
              </p>
            </div>
            
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-actions">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={saving}
              >
                {saving ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> Saving...
                  </>
                ) : 'Change Password'}
              </button>
            </div>
          </form>
        </div>
        
        <div className="form-section">
          <h3>Two-Factor Authentication</h3>
          <div className="form-group switch-group">
            <label className="switch-label">
              Enable Two-Factor Authentication
              <div className="switch">
                <input
                  type="checkbox"
                  name="twoFactorEnabled"
                  checked={formData.twoFactorEnabled}
                  onChange={handleTwoFactorToggle}
                />
                <span className="slider"></span>
              </div>
            </label>
            <p className="help-text">
              Adds an extra layer of security by requiring a code from your phone when you sign in.
            </p>
          </div>
          
          {twoFactorError && <p className="error-message">{twoFactorError}</p>}
          {twoFactorSuccess && <p className="success-message">{twoFactorSuccess}</p>}
          
          {twoFactorSetupInProgress && (
            <div className="two-factor-setup">
              <p>Scan this QR code with your authenticator app:</p>
              <div className="qr-code" dangerouslySetInnerHTML={{ __html: twoFactorQrCode }}></div>
              <div className="manual-entry">
                <p>Or enter this code manually:</p>
                <div className="secret-key">{twoFactorSecret}</div>
              </div>
              <form onSubmit={verifyTwoFactor} className="verification-form">
                <div className="form-group">
                  <label htmlFor="verificationCode">Enter the 6-digit code from your app:</label>
                  <input
                    type="text"
                    id="verificationCode"
                    value={twoFactorCode}
                    onChange={(e) => setTwoFactorCode(e.target.value)}
                    placeholder="000000"
                    maxLength={6}
                    pattern="[0-9]{6}"
                    required
                    autoComplete="one-time-code"
                  />
                </div>
                
                <button type="submit" className="btn-primary">
                  Verify
                </button>
                
                <button 
                  type="button" 
                  className="btn-outline" 
                  onClick={() => {
                    setTwoFactorSetupInProgress(false);
                    setTwoFactorQrCode('');
                    setTwoFactorSecret('');
                    setTwoFactorCode('');
                  }}
                >
                  Cancel
                </button>
              </form>
            </div>
          )}
          
          <p className="recommendation">We recommend using Google Authenticator or Authy.</p>
        </div>
        
        <div className="form-section">
          <h3>Notification Preferences</h3>
          <form onSubmit={handleNotificationUpdate}>
            <div className="form-group switch-group">
              <label className="switch-label">
                Order Confirmations & Updates
                <div className="switch">
                  <input
                    type="checkbox"
                    name="notification_orderConfirmations"
                    checked={formData.notificationSettings.orderConfirmations}
                    onChange={handleChange}
                  />
                  <span className="slider"></span>
                </div>
              </label>
              <p className="help-text">
                Receive notifications about your orders, including confirmations and status updates.
              </p>
            </div>
            
            <div className="form-group switch-group">
              <label className="switch-label">
                System Alerts
                <div className="switch">
                  <input
                    type="checkbox"
                    name="notification_systemAlerts"
                    checked={formData.notificationSettings.systemAlerts}
                    onChange={handleChange}
                  />
                  <span className="slider"></span>
                </div>
              </label>
              <p className="help-text">
                Important system alerts about your account or website.
              </p>
            </div>
            
            <div className="form-group switch-group">
              <label className="switch-label">
                Marketing Emails
                <div className="switch">
                  <input
                    type="checkbox"
                    name="notification_marketingEmails"
                    checked={formData.notificationSettings.marketingEmails}
                    onChange={handleChange}
                  />
                  <span className="slider"></span>
                </div>
              </label>
              <p className="help-text">
                Stay updated with new features, tips, and best practices.
              </p>
            </div>
            
            <div className="form-group switch-group">
              <label className="switch-label">
                Promotions & Special Offers
                <div className="switch">
                  <input
                    type="checkbox"
                    name="notification_promotions"
                    checked={formData.notificationSettings.promotions}
                    onChange={handleChange}
                  />
                  <span className="slider"></span>
                </div>
              </label>
              <p className="help-text">
                Special offers, discounts, and seasonal promotions.
              </p>
            </div>
            
            <div className="form-actions">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={saving}
              >
                {saving ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> Saving...
                  </>
                ) : 'Save Preferences'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountSecurity; 