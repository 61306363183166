import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { getApiUrl, fetchWithAuth } from '../../utils/apiUtils';
import './Categories.css';

// Custom confirmation modal component
const ConfirmationModal = ({ isOpen, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;
  
  return (
    <div className="modal-overlay active">
      <div className="modal-content confirmation-modal">
        <h3>Confirm Action</h3>
        <p>{message}</p>
        <div className="modal-actions">
          <button onClick={onCancel} className="cancel-btn">
            Cancel
          </button>
          <button onClick={onConfirm} className="delete-btn">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const SortableItem = ({ 
  id, 
  category, 
  handleDeleteCategory,
  setEditingCategory,
  setShowEditModal 
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditingCategory(category);
    setShowEditModal(true);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleDeleteCategory(category);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      data-dragging={isDragging}
      className={`category-item ${category.category_type === 'base' ? 'base-category' : 'user-category'}`}
    >
      <div className="category-content" {...attributes} {...listeners}>
        <i className="fas fa-grip-vertical drag-handle"></i>
        <span className="category-name">{category.name}</span>
        <div className="category-actions">
          <button
            className="edit-category-btn"
            onMouseDown={handleEdit}
            onClick={handleEdit}
            title="Edit category"
          >
            <i className="fas fa-edit"></i>
          </button>
          {category.category_type !== 'base' && (
            <button
              className="delete-category-btn"
              onMouseDown={handleDelete}
              onClick={handleDelete}
              title="Delete category"
            >
              <i className="fas fa-trash"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const Categories = ({ categories: propCategories, onUpdate, isPreview = false }) => {
  const [categories, setCategories] = useState(propCategories || []);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [editingCategory, setEditingCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    message: '',
    categoryToDelete: null
  });
  
  // Use a ref to track if we've already attempted to load categories
  const hasTriedLoadingRef = useRef(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Update local categories when props change
  useEffect(() => {
    if (propCategories && propCategories.length > 0) {
      console.log('Setting categories from props:', propCategories.length);
      setCategories(propCategories);
    }
  }, [propCategories]);

  // Function to show confirmation modal
  const showDeleteConfirmation = (category) => {
    if (category.category_type === 'base') {
      setNotification({
        type: 'error',
        message: 'Cannot delete base categories'
      });
      return;
    }
    
    setConfirmModal({
      isOpen: true,
      message: `Are you sure you want to delete "${category.name}"?`,
      categoryToDelete: category
    });
  };

  // Function to handle actual deletion after confirmation
  const confirmDelete = async () => {
    const category = confirmModal.categoryToDelete;
    
    // Close the modal first
    setConfirmModal({
      isOpen: false,
      message: '',
      categoryToDelete: null
    });
    
    if (!category) return;
    
    try {
      const response = await fetchWithAuth(`${getApiUrl('categories.php')}?id=${category.id}&action=delete`, {
        method: 'POST'
      });

      if (!response.ok) {
        throw new Error('Failed to delete category');
      }

      const data = await response.json();
      
      if (data.status === 'success') {
        // Call onUpdate instead of updating local state directly
        if (onUpdate) {
          await onUpdate();
        }
        setNotification({
          type: 'success',
          message: 'Category deleted successfully'
        });
      } else {
        throw new Error(data.message || 'Failed to delete category');
      }
    } catch (error) {
      console.error('Error deleting category:', error);
      setNotification({
        type: 'error',
        message: error.message || 'Failed to delete category'
      });
    }
  };

  // Replace handleDeleteCategory with showDeleteConfirmation
  const handleDeleteCategory = useCallback((category) => {
    showDeleteConfirmation(category);
  }, []);

  // Function to deduplicate categories based on name
  const deduplicateCategories = useCallback((cats) => {
    const uniqueCategories = [];
    const seen = new Set();
    
    // Sort by sort_order first to ensure we keep the correct order
    cats.sort((a, b) => a.sort_order - b.sort_order);
    
    cats.forEach(cat => {
      if (!seen.has(cat.name)) {
        seen.add(cat.name);
        uniqueCategories.push({
          ...cat,
          display_order: uniqueCategories.length + 1
        });
      }
    });
    
    return uniqueCategories;
  }, []); // Empty dependency array since this function doesn't depend on any state

  // Function to load categories
  const loadCategories = useCallback(async (abortController) => {
    // Prevent multiple simultaneous requests
    if (isLoading) return;
    
    // Set loading state to prevent parallel requests
    setIsLoading(true);
    setLoading(true);
    
    try {
      console.log('Fetching categories from API...');
      // Using proper API endpoint with signal for abort control
      const response = await fetchWithAuth(getApiUrl('categories.php') + '?action=getAllCategories', {
        signal: abortController?.signal,
        method: 'GET',
        // Add cache busting parameter
        headers: {
          'Cache-Control': 'no-cache, no-store',
          'Pragma': 'no-cache'
        }
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch categories: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === 'success') {
        const uniqueCategories = deduplicateCategories(data.categories || []);
        setCategories(uniqueCategories);
      } else {
        throw new Error(data.message || 'Failed to fetch categories');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
        return;
      }
      console.error('Error fetching categories:', error);
      setNotification({
        type: 'error',
        message: error.message || 'Failed to load categories'
      });
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  }, [deduplicateCategories, isLoading]);

  // Memoize the preview content
  const phonePreviewContent = useMemo(() => {
    // Get item counts for each category
    const categoryItemCounts = {};
    if (window.menuItems) {
      window.menuItems.forEach(item => {
        if (item.category_id) {
          categoryItemCounts[item.category_id] = (categoryItemCounts[item.category_id] || 0) + 1;
        }
      });
    }

    return (
      <div className="preview-categories">
        <div className="preview-header">
          <h1>Menu Categories</h1>
        </div>
        {categories.map(category => (
          <div 
            key={category.id} 
            className="preview-category"
            onClick={() => {
              if (window.setActiveSection && window.setSelectedCategory) {
                window.setActiveSection('menu');
                window.setSelectedCategory(category.id);
              }
            }}
          >
            <div>
              <span className="category-name">{category.name}</span>
              <div className="item-description">
                {categoryItemCounts[category.id] || 0} items
                {category.description && (
                  <>
                    <span className="bullet-separator">•</span>
                    {category.description}
                  </>
                )}
              </div>
            </div>
            <div className="preview-category-actions">
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
        ))}
        <div className="preview-bottom-nav">
          <div 
            className="nav-item"
            onClick={() => {
              if (window.setActiveSection) {
                window.setActiveSection('menu');
              }
            }}
          >
            <i className="fas fa-utensils"></i>
            <span>Items</span>
          </div>
          <div className="nav-item active">
            <i className="fas fa-list"></i>
            <span>Categories</span>
          </div>
        </div>
      </div>
    );
  }, [categories]);

  // Initial load of categories
  useEffect(() => {
    // Only attempt to load categories if they're not already provided via props
    // and if we haven't already loaded them
    if (propCategories?.length > 0 || categories.length > 0) {
      console.log('Categories already loaded from props or state, skipping fetch');
      return;
    }

    // Only try to load once per component mount
    if (hasTriedLoadingRef.current) {
      console.log('Already attempted to load categories once, preventing loop');
      return;
    }
    
    const abortController = new AbortController();
    hasTriedLoadingRef.current = true;
    
    console.log('Attempting to load categories once');
    loadCategories(abortController).catch(error => {
      if (error.name !== 'AbortError') {
        console.error('Error loading categories:', error);
      }
    });
    
    return () => {
      abortController.abort(); // Cleanup function to cancel the fetch if component unmounts
    };
  }, [loadCategories, propCategories, categories.length]);

  // Update preview whenever categories change
  useEffect(() => {
    if (!window.updatePhonePreview || loading) return;
    // Only update if not in direct preview mode
    if (!isPreview) {
      window.updatePhonePreview(phonePreviewContent);
    }
  }, [phonePreviewContent, loading, isPreview]);

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      const oldIndex = categories.findIndex((cat) => cat.id === active.id);
      const newIndex = categories.findIndex((cat) => cat.id === over.id);
      
      const newCategories = arrayMove(categories, oldIndex, newIndex);
      
      // Filter out base categories and create simplified array with just id and sort_order
      const updatedCategories = newCategories
        .filter(category => category.category_type !== 'base')
        .map((category, index) => ({
          id: category.id,
          sort_order: index + 1
        }));
      
      // Update local state immediately for responsive UI
      setCategories(newCategories.map((category, index) => ({
        ...category,
        sort_order: index + 1,
        display_order: index + 1
      })));
      
      try {
        console.log('Sending reorder request:', { categories: updatedCategories });
        
        const response = await fetchWithAuth(getApiUrl('categories.php'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            action: 'reorder',
            categories: updatedCategories 
          })
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || `Failed to update category order: ${response.status}`);
        }

        const data = await response.json();
        console.log('Reorder response:', data);
        
        if (data.status === 'success') {
          setNotification({
            type: 'success',
            message: 'Categories reordered successfully'
          });
        } else {
          throw new Error(data.message || 'Failed to update category order');
        }
      } catch (error) {
        console.error('Error updating category order:', error);
        setNotification({
          type: 'error',
          message: error.message || 'Failed to update category order'
        });
        // Revert categories to their previous state
        setCategories(categories);
      }
    }
  };

  // Handle adding a new category
  const handleAddCategory = async (newCategory) => {
    if (!newCategory || !newCategory.trim()) {
      setNotification({
        type: 'error',
        message: 'Category name is required'
      });
      return;
    }
    
    try {
      const response = await fetchWithAuth(getApiUrl('categories.php'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          name: newCategory,
          description: '',
          action: 'create'
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to create category: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.status === 'success') {
        setNewCategoryName('');
        setShowAddModal(false);
        
        // Call onUpdate instead of updating local state directly
        if (onUpdate) {
          await onUpdate();
        }
        
        setNotification({
          type: 'success',
          message: 'Category added successfully'
        });
      } else {
        throw new Error(data.message || 'Failed to add category');
      }
    } catch (error) {
      console.error('Error adding category:', error);
      setNotification({
        type: 'error',
        message: error.message || 'Failed to add category'
      });
    }
  };
  
  // Handle editing an existing category
  const handleEditCategory = async () => {
    if (!editingCategory) return;
    
    try {
      // Log the data we're sending for debugging
      console.log('Sending category update:', {
        category: {
          id: editingCategory.id,
          name: editingCategory.name,
          description: editingCategory.description || ''
        },
        action: 'update'
      });
      
      const response = await fetchWithAuth(getApiUrl('categories.php'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          category: {
            id: editingCategory.id,
            name: editingCategory.name,
            description: editingCategory.description || ''
          },
          action: 'update'
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to update category: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.status === 'success') {
        setShowEditModal(false);
        
        // Call onUpdate instead of updating local state directly
        if (onUpdate) {
          await onUpdate();
        }
        
        setNotification({
          type: 'success',
          message: 'Category updated successfully'
        });
      } else {
        throw new Error(data.message || 'Failed to update category');
      }
    } catch (error) {
      console.error('Error updating category:', error);
      setNotification({
        type: 'error',
        message: error.message || 'Failed to update category'
      });
    }
  };

  const showNotification = (message, type = 'info') => {
    setNotification({ message, type });
    
    // Auto-hide notification after 5 seconds
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  };

  if (loading) return <div className="categories-loading">Loading categories...</div>;

  // Show error state if categories couldn't be loaded
  if (categories.length === 0) {
    return (
      <div className="categories-container">
        {notification && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
        
        <div className="categories-error">
          <h3>Could not load categories</h3>
          <p>There was a problem connecting to the database. Please check:</p>
          <ul>
            <li>The database server is running</li>
            <li>The database connection settings are correct</li>
            <li>The required tables exist (base_categories and user_categories)</li>
          </ul>
          <button 
            onClick={() => {
              // Reset the load attempt tracking so we can try again
              hasTriedLoadingRef.current = false;
              // Try loading once more
              const abortController = new AbortController();
              loadCategories(abortController).catch(error => {
                if (error.name !== 'AbortError') {
                  console.error('Error in retry attempt:', error);
                }
              });
            }}
            className="retry-btn"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  // If we're in preview mode, render the preview content directly
  if (isPreview) {
    return phonePreviewContent;
  }

  // Default render for the main component
  return (
    <div className="categories-container">
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
      
      {/* Add ConfirmationModal */}
      <ConfirmationModal 
        isOpen={confirmModal.isOpen}
        message={confirmModal.message}
        onConfirm={confirmDelete}
        onCancel={() => setConfirmModal({isOpen: false, message: '', categoryToDelete: null})}
      />

      <div className="categories-header">
        <h2>Menu Categories</h2>
        <button onClick={() => setShowAddModal(true)} className="add-category-btn">
          <i className="fas fa-plus"></i>
        </button>
      </div>

      {showAddModal && (
        <div className={`modal-overlay ${showAddModal ? 'active' : ''}`}>
          <div className="modal-content">
            <h3>Add New Category</h3>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter category name"
              autoFocus
            />
            <div className="modal-actions">
              <button onClick={() => setShowAddModal(false)} className="cancel-btn">
                Cancel
              </button>
              <button onClick={() => {
                setShowAddModal(false);
                handleAddCategory({
                  name: newCategoryName.trim(),
                  description: '',
                  category_type: 'user',
                  display_order: categories.length + 1
                });
              }} className="save-btn" disabled={!newCategoryName.trim()}>
                Add Category
              </button>
            </div>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className={`modal-overlay ${showEditModal ? 'active' : ''}`}>
          <div className="modal-content">
            <h3>Edit Category</h3>
            <input
              type="text"
              value={editingCategory?.name || ''}
              onChange={(e) => setEditingCategory({
                ...editingCategory,
                name: e.target.value
              })}
              placeholder="Category name"
              autoFocus
            />
            <textarea
              value={editingCategory?.description || ''}
              onChange={(e) => setEditingCategory({
                ...editingCategory,
                description: e.target.value
              })}
              placeholder="Category description"
              rows={3}
            />
            <div className="modal-actions">
              <button 
                onClick={() => {
                  setShowEditModal(false);
                  setEditingCategory(null);
                }} 
                className="cancel-btn"
              >
                Cancel
              </button>
              <button 
                onClick={handleEditCategory} 
                className="save-btn" 
                disabled={!editingCategory?.name?.trim()}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={categories.map(cat => cat.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="categories-list">
            {categories.map((category) => (
              <SortableItem
                key={category.id}
                id={category.id}
                category={category}
                handleDeleteCategory={handleDeleteCategory}
                setEditingCategory={setEditingCategory}
                setShowEditModal={setShowEditModal}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default Categories;