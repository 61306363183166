import React, { useState, useEffect } from 'react';
import { Card, Progress, Spin, Alert, Table } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { AdminService } from '../../services/AdminService';

const SystemHealth = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState(null);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                setLoading(true);
                const data = await AdminService.getSystemHealthStats();
                setStats(data);
                setError(null);
            } catch (err) {
                setError('Failed to fetch system health statistics');
                console.error('Error fetching system health:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
        // Set up polling every 30 seconds
        const interval = setInterval(fetchStats, 30000);
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error) {
        return <Alert type="error" message={error} />;
    }

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>System Health</h2>
            </div>

            <div className="cards-grid">
                <Card title="CPU Usage">
                    <Progress
                        type="dashboard"
                        percent={Math.round(stats?.cpu?.usage || 0)}
                        format={percent => `${percent}%`}
                        status={stats?.cpu?.usage > 80 ? 'exception' : 'normal'}
                    />
                    <p>{stats?.cpu?.cores || 1} Cores</p>
                </Card>
                <Card title="Memory Usage">
                    <Progress
                        type="dashboard"
                        percent={Math.round((stats?.memory?.used / stats?.memory?.total) * 100 || 0)}
                        format={percent => `${percent}%`}
                        status={(stats?.memory?.used / stats?.memory?.total) > 0.8 ? 'exception' : 'normal'}
                    />
                    <p>{Math.round(stats?.memory?.used / 1024 / 1024)}GB / {Math.round(stats?.memory?.total / 1024 / 1024)}GB</p>
                </Card>
                <Card title="Database Connections">
                    <Progress
                        type="dashboard"
                        percent={Math.round((stats?.database?.active_connections / stats?.database?.max_connections) * 100 || 0)}
                        format={percent => `${percent}%`}
                        status={(stats?.database?.active_connections / stats?.database?.max_connections) > 0.8 ? 'exception' : 'normal'}
                    />
                    <p>{stats?.database?.active_connections} / {stats?.database?.max_connections}</p>
                </Card>
                <Card title="System Info">
                    <p><strong>PHP Version:</strong> {stats?.php_version}</p>
                    <p><strong>Server:</strong> {stats?.server_software}</p>
                    <p><strong>Database:</strong> {stats?.database?.version}</p>
                </Card>
            </div>

            {/* Performance Trend Chart */}
            {stats?.performanceTrend && (
                <Card title="Performance Trend" className="chart-card">
                    <LineChart
                        width={800}
                        height={300}
                        data={stats.performanceTrend}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line 
                            type="monotone" 
                            dataKey="cpu" 
                            stroke="#8884d8" 
                            name="CPU Usage %" 
                        />
                        <Line 
                            type="monotone" 
                            dataKey="memory" 
                            stroke="#82ca9d" 
                            name="Memory Usage %" 
                        />
                        <Line 
                            type="monotone" 
                            dataKey="connections" 
                            stroke="#ffc658" 
                            name="DB Connections" 
                        />
                    </LineChart>
                </Card>
            )}

            {/* System Events Table */}
            <Card title="Recent System Events" className="table-card">
                <Table 
                    columns={[
                        {
                            title: 'Time',
                            dataIndex: 'time',
                            key: 'time',
                        },
                        {
                            title: 'Type',
                            dataIndex: 'type',
                            key: 'type',
                        },
                        {
                            title: 'Message',
                            dataIndex: 'message',
                            key: 'message',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                        }
                    ]} 
                    dataSource={stats?.recentEvents || []}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
            </Card>
        </div>
    );
};

export default SystemHealth; 