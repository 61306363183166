import React, { useState, useEffect } from 'react';
import './OptionComponents.css';
import OptionSetBuilder from './OptionSetBuilder';

const OptionSetsManager = ({ optionSets, menuItemId, onChange }) => {
  const [showOptionSetBuilder, setShowOptionSetBuilder] = useState(false);
  const [currentOptionSet, setCurrentOptionSet] = useState(null);
  const [groupedOptions, setGroupedOptions] = useState([]);
  
  // Use menu item ID for debugging
  useEffect(() => {
    if (menuItemId) {
      console.log('OptionSetsManager initialized with menu item ID:', menuItemId);
    }
  }, [menuItemId]);
  
  // When option sets change, regroup them
  useEffect(() => {
    if (!optionSets) return;
    
    // Group options by option_group
    const groups = new Map();
    
    optionSets.forEach(option => {
      const groupName = option.option_group || 'Uncategorized';
      
      if (!groups.has(groupName)) {
        // Create a new group entry
        groups.set(groupName, {
          title: groupName,
          type: option.option_type === 'choice' ? 'single' : 'multiple',
          options: [],
          allowCustom: false
        });
      }
      
      // Add to existing group
      const group = groups.get(groupName);
      group.options.push({
        name: option.name,
        price: option.price,
        default: option.is_default
      });
    });
    
    // Convert map to array
    setGroupedOptions(Array.from(groups.values()));
  }, [optionSets]);
  
  // When component mounts, add a debug function to the window object
  useEffect(() => {
    // Add debug function to window object
    window.debugSaveOptions = (targetItemId) => {
      console.log('Manual debug save called for item ID:', targetItemId);
      
      // Convert grouped options to flat array
      const flatDebugOptions = [];
      groupedOptions.forEach(group => {
        group.options.forEach(option => {
          flatDebugOptions.push({
            name: option.name,
            price: option.price,
            is_default: option.default,
            option_type: group.type === 'single' ? 'choice' : 'add-on',
            option_group: group.title,
            menu_item_id: targetItemId
          });
        });
      });
      
      console.log('Debug flat options to save:', flatDebugOptions);
      
      // Use options-only update approach
      const token = localStorage.getItem('token');
      const directApiData = new FormData();
      directApiData.append('id', targetItemId);
      directApiData.append('options', JSON.stringify(flatDebugOptions));
      
      fetch('http://localhost:9000/api/menu_items_update.php', {
        method: 'POST',
        body: directApiData,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
      })
      .then(response => {
        console.log('Debug API response status:', response.status);
        return response.text();
      })
      .then(text => {
        try {
          const data = JSON.parse(text);
          console.log('Debug API response:', data);
          return data;
        } catch (e) {
          console.log('Debug API raw response:', text);
          throw e;
        }
      })
      .catch(error => {
        console.error('Debug API error:', error);
      });
      
      return flatDebugOptions;
    };
    
    // Clean up function when component unmounts
    return () => {
      delete window.debugSaveOptions;
    };
  }, [groupedOptions]);  // Re-create when grouped options change
  
  const handleAddOptionSet = () => {
    setCurrentOptionSet(null);
    setShowOptionSetBuilder(true);
  };
  
  const handleEditOptionSet = (optionSetIndex) => {
    setCurrentOptionSet({
      ...groupedOptions[optionSetIndex],
      index: optionSetIndex
    });
    setShowOptionSetBuilder(true);
  };
  
  const handleSaveOptionSet = async (optionSetData) => {
    try {
      console.log('OptionSetsManager receiving option set data:', optionSetData);
      
      let newGroupedOptions;
      
      if (currentOptionSet !== null) {
        // Update existing option set
        console.log('Updating existing option set at index:', currentOptionSet.index);
        newGroupedOptions = [...groupedOptions];
        newGroupedOptions[currentOptionSet.index] = {
          title: optionSetData.title,
          type: optionSetData.type,
          options: optionSetData.options.map(opt => ({
            name: opt.name,
            price: opt.price,
            default: opt.is_default || false
          })),
          allowCustom: optionSetData.allowCustom
        };
        
        setGroupedOptions(newGroupedOptions);
      } else {
        // Add new option set
        console.log('Adding new option set');
        newGroupedOptions = [
          ...groupedOptions,
          {
            title: optionSetData.title,
            type: optionSetData.type,
            options: optionSetData.options.map(opt => ({
              name: opt.name,
              price: opt.price,
              default: opt.is_default || false
            })),
            allowCustom: optionSetData.allowCustom
          }
        ];
        
        setGroupedOptions(newGroupedOptions);
      }
      
      // Convert groupedOptions back to flat array for parent component
      const flatOptions = [];
      newGroupedOptions.forEach(group => {
        group.options.forEach(option => {
          flatOptions.push({
            name: option.name,
            price: option.price,
            is_default: option.default,
            option_type: group.type === 'single' ? 'choice' : 'add-on',
            option_group: group.title
          });
        });
      });
      
      console.log('OptionSetsManager sending flat options to parent:', flatOptions);
      
      // DIRECT API SAVE: Try saving directly to API to bypass any parent form issues
      try {
        console.log('Attempting direct API save of options for menu item...');
        
        // Try multiple ways to get the item ID
        let itemId = menuItemId; // Use the passed prop first
        
        // Method 1: Get from URL (if prop not available)
        if (!itemId) {
          const urlParams = new URLSearchParams(window.location.search);
          itemId = urlParams.get('id');
        }
        
        // Method 2: Try to extract from the current path
        if (!itemId) {
          const path = window.location.pathname;
          const matches = path.match(/\/(\d+)(\/|$)/);
          if (matches && matches[1]) {
            itemId = matches[1];
          }
        }
        
        // Method 3: Check if any option already has a menu_item_id
        if (!itemId && flatOptions.length > 0 && flatOptions[0].menu_item_id) {
          itemId = flatOptions[0].menu_item_id;
        }
        
        // Method 4: Get from DOM by looking for hidden inputs
        if (!itemId) {
          const hiddenInputs = document.querySelectorAll('input[type="hidden"][name="id"]');
          if (hiddenInputs.length > 0) {
            itemId = hiddenInputs[0].value;
          }
        }
        
        if (itemId) {
          console.log('Found item ID:', itemId);
          
          const token = localStorage.getItem('token');
          
          // Use options-only update approach
          const directApiData = new FormData();
          directApiData.append('id', itemId);
          directApiData.append('options', JSON.stringify(flatOptions));
          
          // Debugging - log what we're sending
          console.log('Direct API save data (options-only):', {
            id: itemId,
            options_count: flatOptions.length,
            first_option: flatOptions.length > 0 ? flatOptions[0] : null
          });
          
          // Send a minimal options-only update request
          fetch('http://localhost:9000/api/menu_items_update.php', {
            method: 'POST',
            body: directApiData,
            headers: {
              'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
          })
          .then(directResponse => {
            console.log('Direct API save response status:', directResponse.status);
            
            if (directResponse.ok) {
              return directResponse.json().then(data => {
                console.log('Direct API save succeeded:', data);
              });
            } else {
              return directResponse.text().then(errorText => {
                console.error('Direct API save failed:', errorText);
              });
            }
          })
          .catch(apiError => {
            console.error('Direct API save error:', apiError);
          });
        } else {
          console.warn('Could not find item ID for direct API save');
        }
      } catch (apiError) {
        console.error('Direct API save error:', apiError);
      }
      
      // Call parent's onChange
      onChange(flatOptions);
      console.log('Parent onChange function called. Total options:', flatOptions.length);
      
      // Return a success response so OptionSetBuilder can show success message
      return { success: true, optionCount: flatOptions.length };
    } catch (error) {
      console.error('Error in OptionSetsManager saving option set:', error);
      throw error; // Let OptionSetBuilder handle the error
    }
  };
  
  const handleDeleteOptionSet = (index) => {
    const newGroupedOptions = groupedOptions.filter((_, i) => i !== index);
    setGroupedOptions(newGroupedOptions);
    
    // Convert and notify parent
    const flatOptions = [];
    newGroupedOptions.forEach(group => {
      group.options.forEach(option => {
        flatOptions.push({
          name: option.name,
          price: option.price,
          is_default: option.default,
          option_type: group.type === 'single' ? 'choice' : 'add-on',
          option_group: group.title
        });
      });
    });
    
    onChange(flatOptions);
  };
  
  return (
    <div className="option-sets-manager">
      <p className="option-helper-text">Add different types of options like sizes, toppings, or modifiers</p>
      
      {showOptionSetBuilder ? (
        <OptionSetBuilder
          onSave={handleSaveOptionSet}
          onCancel={() => setShowOptionSetBuilder(false)}
          existingSet={currentOptionSet}
        />
      ) : (
        <>
          {groupedOptions.length > 0 ? (
            <div className="option-groups-list">
              {groupedOptions.map((group, index) => (
                <div key={index} className="option-group-interface">
                  <div className="option-group-header">
                    <div>
                      <h3 className="option-group-title">
                        {group.title}
                        <span className={`option-group-type-indicator option-group-type-${group.type}`}>
                          {group.type === 'multiple' ? 'Checkboxes' : 
                           group.type === 'single' ? 'Radio Buttons' : 'Text Input'}
                        </span>
                      </h3>
                    </div>
                    <div className="option-group-controls">
                      <button 
                        type="button" 
                        className="option-group-edit-btn"
                        onClick={() => handleEditOptionSet(index)}
                      >
                        Edit
                      </button>
                      <button 
                        type="button" 
                        className="option-group-delete-btn"
                        onClick={() => handleDeleteOptionSet(index)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div className="option-group-body">
                    <div className="option-group-preview">
                      {group.type === 'multiple' && (
                        <div className="option-group-preview-multiple">
                          {group.options.map((option, optIdx) => (
                            <div 
                              key={optIdx} 
                              className={`option-group-preview-item ${option.default ? 'is-default' : ''}`}
                            >
                              <span className="option-group-preview-name">{option.name}</span>
                              {parseFloat(option.price) > 0 && (
                                <span className="option-group-preview-price">
                                  +${parseFloat(option.price).toFixed(2)}
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      
                      {group.type === 'single' && (
                        <div className="option-group-preview-single">
                          {group.options.map((option, optIdx) => (
                            <div 
                              key={optIdx} 
                              className={`option-group-preview-item ${option.default ? 'is-default' : ''}`}
                            >
                              <span className="option-group-preview-name">{option.name}</span>
                              {parseFloat(option.price) > 0 && (
                                <span className="option-group-preview-price">
                                  +${parseFloat(option.price).toFixed(2)}
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      
                      {group.type === 'text' && (
                        <div className="option-group-preview-text">
                          Special instructions text field will be shown
                        </div>
                      )}
                    </div>
                    
                    {group.allowCustom && (
                      <div className="option-group-custom-note">
                        Includes special instructions field for custom requests
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-option-sets-message">
              <p>No option sets added yet. Add option sets to allow customers to customize this item.</p>
            </div>
          )}
          
          <button 
            type="button" 
            className="add-option-btn" 
            onClick={handleAddOptionSet}
          >
            <span>+</span> Add Option Set
          </button>
        </>
      )}
    </div>
  );
};

export default OptionSetsManager; 