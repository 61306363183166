import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { getApiUrl, getAuthHeaders } from '../../../utils/apiUtils';
import './BusinessInfo.css';

const BusinessInfo = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    businessName: '',
    legalName: '',
    ein: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: ''
    },
    phone: '',
    businessType: 'restaurant',
    taxSettings: {
      collectSalesTax: false,
      taxRate: '0.00'
    },
    businessHours: [
      { day: 'Monday', open: '09:00', close: '17:00', closed: false },
      { day: 'Tuesday', open: '09:00', close: '17:00', closed: false },
      { day: 'Wednesday', open: '09:00', close: '17:00', closed: false },
      { day: 'Thursday', open: '09:00', close: '17:00', closed: false },
      { day: 'Friday', open: '09:00', close: '17:00', closed: false },
      { day: 'Saturday', open: '10:00', close: '15:00', closed: false },
      { day: 'Sunday', open: '10:00', close: '15:00', closed: true }
    ]
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const businessTypes = [
    { id: 'restaurant', name: 'Restaurant' },
    { id: 'cafe', name: 'Cafe' },
    { id: 'bakery', name: 'Bakery' },
    { id: 'foodTruck', name: 'Food Truck' },
    { id: 'caterer', name: 'Caterer' },
    { id: 'cloudKitchen', name: 'Cloud Kitchen' },
    { id: 'other', name: 'Other' }
  ];

  useEffect(() => {
    const fetchBusinessInfo = async () => {
      try {
        setLoading(true);
        
        // Use business-settings endpoint instead of business-info
        const apiUrl = getApiUrl('business-settings');
        
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: getAuthHeaders()
        });
        
        if (!response.ok) {
          // If no data exists yet, use default values
          if (response.status === 404) {
            setFormData({
              businessName: user?.username ? `${user.username}'s Restaurant` : 'My Restaurant',
              legalName: '',
              ein: '',
              address: {
                street: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              businessType: 'restaurant',
              taxSettings: {
                collectSalesTax: false,
                taxRate: '0.00'
              },
              businessHours: [
                { day: 'Monday', open: '09:00', close: '17:00', closed: false },
                { day: 'Tuesday', open: '09:00', close: '17:00', closed: false },
                { day: 'Wednesday', open: '09:00', close: '17:00', closed: false },
                { day: 'Thursday', open: '09:00', close: '17:00', closed: false },
                { day: 'Friday', open: '09:00', close: '17:00', closed: false },
                { day: 'Saturday', open: '10:00', close: '15:00', closed: false },
                { day: 'Sunday', open: '10:00', close: '15:00', closed: true }
              ]
            });
            return;
          }
          
          throw new Error('Failed to fetch business information');
        }
        
        const data = await response.json();
        
        if (data) {
          console.log('Loaded business information:', data);
          // Map the data from business-settings to our form format
          setFormData({
            businessName: data.business_name || '',
            legalName: data.legal_name || '',
            ein: data.tax_id || '',
            address: {
              street: data.street_address || '',
              city: data.city || '',
              state: data.state || '',
              zip: data.zip_code || ''
            },
            phone: data.business_phone || '',
            businessType: data.business_type || 'restaurant',
            taxSettings: {
              collectSalesTax: !!data.collect_sales_tax,
              taxRate: data.tax_rate ? data.tax_rate.toString() : '0.00'
            },
            businessHours: data.business_hours ? JSON.parse(data.business_hours) : [
              { day: 'Monday', open: '09:00', close: '17:00', closed: false },
              { day: 'Tuesday', open: '09:00', close: '17:00', closed: false },
              { day: 'Wednesday', open: '09:00', close: '17:00', closed: false },
              { day: 'Thursday', open: '09:00', close: '17:00', closed: false },
              { day: 'Friday', open: '09:00', close: '17:00', closed: false },
              { day: 'Saturday', open: '10:00', close: '15:00', closed: false },
              { day: 'Sunday', open: '10:00', close: '15:00', closed: true }
            ]
          });
        } else {
          throw new Error('No business information found');
        }
      } catch (error) {
        console.error('Error fetching business information:', error);
        
        // Set default values if fetch fails
        setFormData({
          businessName: user?.username ? `${user.username}'s Restaurant` : 'My Restaurant',
          legalName: '',
          ein: '',
          address: {
            street: '',
            city: '',
            state: '',
            zip: ''
          },
          phone: '',
          businessType: 'restaurant',
          taxSettings: {
            collectSalesTax: false,
            taxRate: '0.00'
          },
          businessHours: [
            { day: 'Monday', open: '09:00', close: '17:00', closed: false },
            { day: 'Tuesday', open: '09:00', close: '17:00', closed: false },
            { day: 'Wednesday', open: '09:00', close: '17:00', closed: false },
            { day: 'Thursday', open: '09:00', close: '17:00', closed: false },
            { day: 'Friday', open: '09:00', close: '17:00', closed: false },
            { day: 'Saturday', open: '10:00', close: '15:00', closed: false },
            { day: 'Sunday', open: '10:00', close: '15:00', closed: true }
          ]
        });
      } finally {
        setLoading(false);
      }
    };
    
    fetchBusinessInfo();
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    
    // Handle nested fields
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: val
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: val
      }));
    }
  };

  const handleHoursChange = (index, field, value) => {
    const updatedHours = [...formData.businessHours];
    updatedHours[index] = {
      ...updatedHours[index],
      [field]: field === 'closed' ? !updatedHours[index].closed : value
    };
    
    setFormData(prev => ({
      ...prev,
      businessHours: updatedHours
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset messages
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      setSaving(true);
      
      // Use save-settings endpoint
      const apiUrl = getApiUrl('save-settings');
      console.log('Using API URL for saving:', apiUrl);
      
      // Ensure tax rate is a valid number
      const formattedData = {
        ...formData,
        taxSettings: {
          ...formData.taxSettings,
          taxRate: formData.taxSettings.taxRate ? formData.taxSettings.taxRate.toString().replace(/[^0-9.]/g, '') : '0.00'
        }
      };
      
      console.log('Saving business information:', formattedData);
      
      // Log tax settings specifically for debugging
      console.log('Tax settings being saved:', JSON.stringify(formattedData.taxSettings));
      
      // Use regular fetch
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formattedData)
      });
      
      const responseText = await response.text();
      let responseData;
      
      try {
        // Try to parse the response as JSON
        responseData = JSON.parse(responseText);
      } catch (e) {
        console.error('Error parsing response:', e);
        console.log('Raw response:', responseText);
        throw new Error('Server returned invalid JSON: ' + responseText.substring(0, 100));
      }
      
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to save business information');
      }
      
      console.log('Business information saved successfully:', responseData);
      setSuccessMessage('Business information saved successfully!');
      
      // Optional: Update form data with the returned data from the server
      if (responseData.data) {
        setFormData(responseData.data);
      }
    } catch (error) {
      console.error('Error saving business information:', error);
      setErrorMessage(`Error saving business information: ${error.message}`);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading business information...</p>
      </div>
    );
  }

  return (
    <div className="business-info-container">
      <div className="section-card">
        <h2>Business Information</h2>
        <p className="section-description">
          Provide your business details for tax and legal purposes.
        </p>
        
        {errorMessage && (
          <div className="alert alert-error">
            <i className="fas fa-exclamation-circle"></i> {errorMessage}
          </div>
        )}
        
        {successMessage && (
          <div className="alert alert-success">
            <i className="fas fa-check-circle"></i> {successMessage}
          </div>
        )}
        
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <h3>Basic Information</h3>
            
            <div className="form-group">
              <label htmlFor="businessName">Business Name</label>
              <input
                type="text"
                id="businessName"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                required
              />
              <p className="help-text">
                The name that will appear on your website and receipts.
              </p>
            </div>
            
            <div className="form-group">
              <label htmlFor="legalName">Legal Business Name</label>
              <input
                type="text"
                id="legalName"
                name="legalName"
                value={formData.legalName}
                onChange={handleChange}
              />
              <p className="help-text">
                If different from your Business Name. Used for tax purposes.
              </p>
            </div>
            
            <div className="form-group">
              <label htmlFor="ein">Federal EIN (Optional)</label>
              <input
                type="text"
                id="ein"
                name="ein"
                value={formData.ein}
                onChange={handleChange}
                placeholder="XX-XXXXXXX"
              />
              <p className="help-text">
                Your Employer Identification Number for tax reporting.
              </p>
            </div>
            
            <div className="form-group">
              <label htmlFor="businessType">Business Type</label>
              <select
                id="businessType"
                name="businessType"
                value={formData.businessType}
                onChange={handleChange}
              >
                {businessTypes.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="form-section">
            <h3>Business Address</h3>
            
            <div className="form-group">
              <label htmlFor="address.street">Street Address</label>
              <input
                type="text"
                id="address.street"
                name="address.street"
                value={formData.address.street}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="address.city">City</label>
                <input
                  type="text"
                  id="address.city"
                  name="address.city"
                  value={formData.address.city}
                  onChange={handleChange}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="address.state">State</label>
                <input
                  type="text"
                  id="address.state"
                  name="address.state"
                  value={formData.address.state}
                  onChange={handleChange}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="address.zip">ZIP Code</label>
                <input
                  type="text"
                  id="address.zip"
                  name="address.zip"
                  value={formData.address.zip}
                  onChange={handleChange}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="phone">Business Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="(555) 555-5555"
              />
            </div>
          </div>
          
          <div className="form-section">
            <h3>Tax Settings</h3>
            
            <div className="form-group switch-group">
              <label className="switch-label">
                Collect Sales Tax
                <div className="switch">
                  <input
                    type="checkbox"
                    name="taxSettings.collectSalesTax"
                    checked={formData.taxSettings.collectSalesTax}
                    onChange={handleChange}
                  />
                  <span className="slider"></span>
                </div>
              </label>
              <p className="help-text">
                If enabled, sales tax will be added to orders based on your local rate.
              </p>
            </div>
            
            {formData.taxSettings.collectSalesTax && (
              <div className="form-group">
                <label htmlFor="taxSettings.taxRate">Sales Tax Rate (%)</label>
                <input
                  type="text"
                  id="taxSettings.taxRate"
                  name="taxSettings.taxRate"
                  value={formData.taxSettings.taxRate}
                  onChange={handleChange}
                />
                <p className="help-text">
                  Enter your local sales tax rate as a percentage (e.g., 7.00 for 7%).
                </p>
              </div>
            )}
          </div>
          
          <div className="form-section">
            <h3>Business Hours</h3>
            <p className="help-text">
              Set your operating hours to display on your website.
            </p>
            
            <div className="business-hours">
              {formData.businessHours.map((day, index) => (
                <div key={day.day} className="hours-row">
                  <div className="day-name">
                    <span>{day.day}</span>
                  </div>
                  <div className="hours-inputs">
                    <div className="closed-toggle">
                      <label className="switch-label">
                        Closed
                        <div className="switch">
                          <input
                            type="checkbox"
                            checked={day.closed}
                            onChange={() => handleHoursChange(index, 'closed', !day.closed)}
                          />
                          <span className="slider"></span>
                        </div>
                      </label>
                    </div>
                    
                    {!day.closed && (
                      <>
                        <div className="time-input">
                          <label>Opens</label>
                          <input
                            type="time"
                            value={day.open}
                            onChange={(e) => handleHoursChange(index, 'open', e.target.value)}
                          />
                        </div>
                        <div className="time-input">
                          <label>Closes</label>
                          <input
                            type="time"
                            value={day.close}
                            onChange={(e) => handleHoursChange(index, 'close', e.target.value)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="form-actions">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={saving}
            >
              {saving ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Saving...
                </>
              ) : 'Save Business Information'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessInfo; 