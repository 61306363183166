import React, { useState, useEffect } from 'react';
import { Card, Select, Spin, Alert, Table, Progress, Space, Tooltip } from 'antd';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, XAxis, YAxis, CartesianGrid, Legend, Cell } from 'recharts';
import { 
    RiseOutlined, 
    ShoppingOutlined, 
    UserOutlined, 
    DollarOutlined 
} from '@ant-design/icons';
import { AdminService } from '../../services/AdminService';

const BusinessIntelligence = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('30d');
    const [stats, setStats] = useState(null);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    useEffect(() => {
        fetchBusinessStats();
    }, [timeRange]);

    const fetchBusinessStats = async () => {
        try {
            setLoading(true);
            const data = await AdminService.getBusinessStats(timeRange);
            setStats(data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch business statistics');
            console.error('Error fetching business stats:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error) {
        return <Alert type="error" message={error} />;
    }

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>Business Intelligence</h2>
                <Select
                    value={timeRange}
                    onChange={setTimeRange}
                    style={{ width: 120 }}
                >
                    <Select.Option value="7d">Last 7 Days</Select.Option>
                    <Select.Option value="30d">Last 30 Days</Select.Option>
                    <Select.Option value="90d">Last 90 Days</Select.Option>
                    <Select.Option value="1y">Last Year</Select.Option>
                </Select>
            </div>

            <div className="cards-grid">
                <Card title="Total Revenue" className="metric-card">
                    <h3>
                        <DollarOutlined style={{ marginRight: '8px', color: '#52c41a' }}/>
                        ${stats?.totalRevenue || 0}
                    </h3>
                    <p>Growth: {stats?.revenueGrowth || 0}%</p>
                </Card>

                <Card title="Active Users" className="metric-card">
                    <h3>
                        <UserOutlined style={{ marginRight: '8px', color: '#1890ff' }}/>
                        {stats?.activeUsers || 0}
                    </h3>
                    <p>Growth: {stats?.userGrowth || 0}%</p>
                </Card>

                <Card title="Orders" className="metric-card">
                    <h3>
                        <ShoppingOutlined style={{ marginRight: '8px', color: '#722ed1' }}/>
                        {stats?.totalOrders || 0}
                    </h3>
                    <p>Conversion Rate: {stats?.conversionRate || 0}%</p>
                </Card>

                <Card title="Average Order Value" className="metric-card">
                    <h3>
                        <RiseOutlined style={{ marginRight: '8px', color: '#fa8c16' }}/>
                        ${stats?.averageOrderValue || 0}
                    </h3>
                    <p>Change: {stats?.aovChange || 0}%</p>
                </Card>
            </div>

            <div className="chart-row">
                <Card title="Revenue Trends" className="chart-card">
                    <LineChart
                        width={500}
                        height={300}
                        data={stats?.revenueTrends || []}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line 
                            type="monotone" 
                            dataKey="revenue" 
                            stroke="#52c41a" 
                            name="Revenue"
                        />
                        <Line 
                            type="monotone" 
                            dataKey="prediction" 
                            stroke="#1890ff" 
                            strokeDasharray="5 5" 
                            name="Predicted"
                        />
                    </LineChart>
                </Card>

                <Card title="User Acquisition Channels" className="chart-card">
                    <PieChart width={500} height={300}>
                        <Pie
                            data={stats?.acquisitionChannels || []}
                            cx={250}
                            cy={150}
                            innerRadius={60}
                            outerRadius={120}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            label
                        >
                            {stats?.acquisitionChannels?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </Card>
            </div>

            <Card title="Product Performance" className="chart-card">
                <BarChart
                    width={1000}
                    height={300}
                    data={stats?.productPerformance || []}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke="#82ca9d" />
                    <YAxis yAxisId="right" orientation="right" stroke="#8884d8" />
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="left" dataKey="revenue" fill="#82ca9d" name="Revenue" />
                    <Bar yAxisId="right" dataKey="orders" fill="#8884d8" name="Orders" />
                </BarChart>
            </Card>

            <div className="cards-grid">
                <Card title="Customer Segments" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        {stats?.customerSegments?.map((segment, index) => (
                            <Tooltip key={index} title={`Revenue: $${segment.revenue}`}>
                                <div>
                                    <span>{segment.name}:</span>
                                    <Progress 
                                        percent={segment.percentage} 
                                        size="small"
                                        strokeColor={COLORS[index % COLORS.length]}
                                    />
                                </div>
                            </Tooltip>
                        ))}
                    </Space>
                </Card>

                <Card title="Growth Metrics" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div>
                            <span>Customer Acquisition Cost:</span>
                            <h4>${stats?.growthMetrics?.cac || 0}</h4>
                        </div>
                        <div>
                            <span>Customer Lifetime Value:</span>
                            <h4>${stats?.growthMetrics?.ltv || 0}</h4>
                        </div>
                        <div>
                            <span>LTV/CAC Ratio:</span>
                            <h4>{stats?.growthMetrics?.ltvCacRatio || 0}</h4>
                        </div>
                    </Space>
                </Card>

                <Card title="Predictive Analytics" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div>
                            <span>Projected Growth:</span>
                            <Progress 
                                percent={stats?.predictions?.growth || 0} 
                                size="small"
                                strokeColor="#52c41a"
                            />
                        </div>
                        <div>
                            <span>Churn Risk:</span>
                            <Progress 
                                percent={stats?.predictions?.churnRisk || 0} 
                                size="small"
                                strokeColor="#ff4d4f"
                            />
                        </div>
                        <div>
                            <span>Market Opportunity:</span>
                            <Progress 
                                percent={stats?.predictions?.opportunity || 0} 
                                size="small"
                                strokeColor="#1890ff"
                            />
                        </div>
                    </Space>
                </Card>
            </div>

            <Card title="Key Performance Indicators" className="table-card">
                <Table
                    dataSource={stats?.kpiMetrics || []}
                    columns={[
                        {
                            title: 'Metric',
                            dataIndex: 'metric',
                            key: 'metric',
                        },
                        {
                            title: 'Current',
                            dataIndex: 'current',
                            key: 'current',
                            render: value => typeof value === 'number' ? 
                                `${value}${value > 100 ? '' : '%'}` : value,
                        },
                        {
                            title: 'Previous',
                            dataIndex: 'previous',
                            key: 'previous',
                            render: value => typeof value === 'number' ? 
                                `${value}${value > 100 ? '' : '%'}` : value,
                        },
                        {
                            title: 'Change',
                            dataIndex: 'change',
                            key: 'change',
                            render: value => (
                                <span style={{ color: value >= 0 ? '#52c41a' : '#ff4d4f' }}>
                                    {value >= 0 ? '+' : ''}{value}%
                                </span>
                            ),
                        },
                    ]}
                    pagination={false}
                />
            </Card>
        </div>
    );
};

export default BusinessIntelligence; 