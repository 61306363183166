import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import AppBuilder from './pages/AppBuilder/AppBuilder';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Login from './components/Auth/Login';
import Registration from './components/Auth/Registration';
import Verify from './components/Auth/Verify';
import VerifyEmailLink from './components/Auth/VerifyEmailLink';
import VerifySuccess from './components/Auth/VerifySuccess';
import Welcome from './components/Welcome/Welcome';
import Landing from './components/Landing/Landing';
import Dashboard from './admin/Dashboard';
import Countertop from './components/Countertop/Countertop';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import FixPassword from './components/FixPassword';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';

// Helper component to handle auth state
function AutoLoginHandler() {
  const { user, loginWithToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check for URL parameters (for backward compatibility)
    const params = new URLSearchParams(location.search);
    const authToken = params.get('authToken');
    const username = params.get('username');
    const userId = params.get('user_id');
    
    // Check localStorage for tokens set by the verification page
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    const storedUserId = localStorage.getItem('userId');
    
    console.log('AutoLoginHandler - Auth data check:', {
      fromURL: { authToken: authToken?.substring(0, 10) + '...', username, userId },
      fromLocalStorage: { 
        token: token ? (token.substring(0, 10) + '...') : null, 
        username: storedUsername, 
        userId: storedUserId 
      },
      currentUser: user
    });
    
    // First priority: If we have auth data in URL parameters
    if (authToken && username) {
      console.log("Auth params detected in URL - logging in");
      loginWithToken(authToken, username, userId);
      
      // Remove the query parameters from URL
      navigate(location.pathname, { replace: true });
      return;
    }
    
    // Second priority: If we have localStorage data but no user yet
    if (token && storedUsername && !user) {
      console.log("Auth data found in localStorage - logging in");
      loginWithToken(token, storedUsername, storedUserId);
    }
  }, [location, loginWithToken, navigate, user]);
  
  return null;
}

function App() {
  // Add debugging for routes
  const [lastRoute, setLastRoute] = useState(window.location.pathname);
  
  useEffect(() => {
    // Debug route changes at the application level
    const handleRouteChange = () => {
      const currentRoute = window.location.pathname;
      console.log(`App: Route changed from ${lastRoute} to ${currentRoute}`);
      setLastRoute(currentRoute);
    };
    
    window.addEventListener('popstate', handleRouteChange);
    
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [lastRoute]);

  return (
    <AuthProvider>
      <Router>
        <AutoLoginHandler />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/verify-email" element={<VerifyEmailLink />} />
          <Route path="/verify-success" element={<VerifySuccess />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/fix-password" element={<FixPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          
          {/* Countertop route for regular users */}
          <Route path="/countertop/*" element={
            <ProtectedRoute>
              <Countertop />
            </ProtectedRoute>
          } />
          
          {/* Dashboard routes - accessible only by admin users */}
          <Route path="/dashboard/*" element={
            <ProtectedRoute requireAdmin={true}>
              <Dashboard />
            </ProtectedRoute>
          } />
          
          {/* Builder routes */}
          <Route path="/builder/*" element={
            <ProtectedRoute>
              <AppBuilder />
            </ProtectedRoute>
          } />
          
          {/* Admin routes */}
          <Route path="/admin/*" element={
            <ProtectedRoute requireAdmin={true}>
              <Dashboard />
            </ProtectedRoute>
          } />
          
          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
