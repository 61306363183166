import React, { useState, useEffect, useRef } from 'react';
import { Card, Tabs, Select, Button, Checkbox, Space, Alert, Spin, Empty, Tooltip, Badge, Modal } from 'antd';
import { 
    ReloadOutlined, 
    DownloadOutlined, 
    DeleteOutlined, 
    InfoCircleOutlined, 
    WarningOutlined, 
    CloseCircleOutlined
} from '@ant-design/icons';
import { ERROR_CATEGORIES } from '../../utils/logger';
import './LogViewer.css';

const { TabPane } = Tabs;
const { Option } = Select;

const LogViewer = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [lines, setLines] = useState(100);
    const [logExists, setLogExists] = useState(false);
    const [config, setConfig] = useState({});
    const [selectedLevels, setSelectedLevels] = useState(['INFO', 'WARN', 'ERROR']);
    const logContainerRef = useRef(null);
    const autoRefreshIntervalRef = useRef(null);
    const isDev = process.env.NODE_ENV === 'development';

    useEffect(() => {
        if (isDev) {
            // In development, use categories from ERROR_CATEGORIES
            const errorCategoriesArray = Object.values(ERROR_CATEGORIES);
            setCategories(['all', ...errorCategoriesArray]);
            setConfig({
                enabled: true,
                categories: errorCategoriesArray.reduce((acc, cat) => ({
                    ...acc,
                    [cat]: true
                }), {})
            });
            setSelectedCategory('all');
        } else {
            // In production, fetch categories from backend
            fetchCategories();
        }
        
        return () => {
            if (autoRefreshIntervalRef.current) {
                clearInterval(autoRefreshIntervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            if (isDev) {
                fetchLocalLogs(selectedCategory);
            } else {
                fetchLogs(selectedCategory);
            }
        }
    }, [selectedCategory, lines]);

    useEffect(() => {
        if (autoRefreshIntervalRef.current) {
            clearInterval(autoRefreshIntervalRef.current);
        }

        if (autoRefresh && selectedCategory) {
            autoRefreshIntervalRef.current = setInterval(() => {
                if (isDev) {
                    fetchLocalLogs(selectedCategory, false);
                } else {
                    fetchLogs(selectedCategory, false);
                }
            }, 5000); // Refresh every 5 seconds
        }

        return () => {
            if (autoRefreshIntervalRef.current) {
                clearInterval(autoRefreshIntervalRef.current);
            }
        };
    }, [autoRefresh, selectedCategory]);

    useEffect(() => {
        // Auto-scroll to bottom when logs update if auto-scroll is enabled
        if (autoRefresh && logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    }, [logs, autoRefresh]);

    // Fetch client-side logs from localStorage
    const fetchLocalLogs = (category, showLoading = true) => {
        try {
            if (showLoading) setLoading(true);
            
            // Get logs from localStorage
            const storedErrors = JSON.parse(localStorage.getItem('appErrors') || '[]');
            
            // Filter by category if not "all"
            const filteredErrors = category !== 'all' 
                ? storedErrors.filter(error => error.category === category)
                : storedErrors;
                
            // Format logs to match expected format: [TIMESTAMP] [LEVEL] MESSAGE CONTEXT
            const formattedLogs = filteredErrors.map(error => {
                const level = error.details?.severity?.toUpperCase() || 'ERROR';
                const context = { ...error.details };
                delete context.severity; // Remove severity as it's used for the level
                
                return `[${new Date(error.timestamp).toLocaleString()}] [${level}] ${error.message} ${JSON.stringify(context)}`;
            });
            
            setLogs(formattedLogs);
            setLogExists(formattedLogs.length > 0);
            setError(null);
        } catch (err) {
            setError('Error fetching local logs: ' + err.message);
            console.error('Error fetching local logs:', err);
        } finally {
            if (showLoading) setLoading(false);
        }
    };

    // Original fetchCategories for production
    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await fetch('/api/admin/logs.php');
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            if (data.success) {
                setCategories(data.categories);
                setConfig(data.config);
                if (data.categories.length > 0) {
                    setSelectedCategory(data.categories[0]);
                }
            } else {
                throw new Error(data.message || 'Failed to fetch categories');
            }
        } catch (err) {
            setError(err.message);
            console.error('Error fetching categories:', err);
        } finally {
            setLoading(false);
        }
    };

    // Original fetchLogs for production
    const fetchLogs = async (category, showLoading = true) => {
        try {
            if (showLoading) setLoading(true);
            const response = await fetch(`/api/admin/logs.php?category=${category}&lines=${lines}`);
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            if (data.success) {
                setLogs(data.logs);
                setLogExists(data.logExists);
                setError(null);
            } else {
                throw new Error(data.message || 'Failed to fetch logs');
            }
        } catch (err) {
            setError(err.message);
            console.error('Error fetching logs:', err);
        } finally {
            if (showLoading) setLoading(false);
        }
    };

    // Clear local logs (for development mode)
    const clearLocalLogs = () => {
        Modal.confirm({
            title: 'Clear Logs',
            content: 'Are you sure you want to clear all client-side logs? This cannot be undone.',
            onOk: () => {
                localStorage.removeItem('appErrors');
                fetchLocalLogs(selectedCategory);
            }
        });
    };

    // Toggle logging is only for production
    const toggleLogging = async (category) => {
        if (isDev) {
            // No-op in development mode
            return;
        }
        
        try {
            const action = config.categories[category] ? 'disable' : 'enable';
            const response = await fetch('/api/admin/logs.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action, category })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            if (data.success) {
                // Update the UI
                setConfig({
                    ...config,
                    categories: {
                        ...config.categories,
                        [category]: !config.categories[category]
                    }
                });
            } else {
                throw new Error(data.message || 'Failed to toggle logging');
            }
        } catch (err) {
            setError(err.message);
            console.error('Error toggling logging:', err);
        }
    };

    // Simulate log rotation for development by clearing logs older than 30 days
    const rotateLogs = async () => {
        if (isDev) {
            Modal.confirm({
                title: 'Rotate Logs',
                content: 'This will delete all logs older than 30 days from localStorage.',
                onOk: () => {
                    try {
                        const storedErrors = JSON.parse(localStorage.getItem('appErrors') || '[]');
                        const thirtyDaysAgo = new Date();
                        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
                        
                        const filteredErrors = storedErrors.filter(error => {
                            const errorDate = new Date(error.timestamp);
                            return errorDate >= thirtyDaysAgo;
                        });
                        
                        localStorage.setItem('appErrors', JSON.stringify(filteredErrors));
                        fetchLocalLogs(selectedCategory);
                    } catch (err) {
                        setError('Error rotating logs: ' + err.message);
                        console.error('Error rotating logs:', err);
                    }
                }
            });
            return;
        }
        
        try {
            Modal.confirm({
                title: 'Rotate Logs',
                content: 'Are you sure you want to rotate logs? This will delete all logs older than 30 days.',
                onOk: async () => {
                    setLoading(true);
                    const response = await fetch('/api/admin/logs.php', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ action: 'rotate' })
                    });
    
                    if (!response.ok) {
                        throw new Error(`Error: ${response.statusText}`);
                    }
    
                    const data = await response.json();
                    if (data.success) {
                        // Refresh the logs
                        fetchLogs(selectedCategory);
                    } else {
                        throw new Error(data.message || 'Failed to rotate logs');
                    }
                }
            });
        } catch (err) {
            setError(err.message);
            console.error('Error rotating logs:', err);
        } finally {
            setLoading(false);
        }
    };

    // Download logs as a text file (development mode)
    const downloadLocalLogs = () => {
        try {
            const storedErrors = JSON.parse(localStorage.getItem('appErrors') || '[]');
            let filteredErrors = storedErrors;
            
            // Filter by category if not "all"
            if (selectedCategory !== 'all') {
                filteredErrors = storedErrors.filter(error => error.category === selectedCategory);
            }
            
            // Format logs to match expected format: [TIMESTAMP] [LEVEL] MESSAGE CONTEXT
            const formattedLogs = filteredErrors.map(error => {
                const level = error.details?.severity?.toUpperCase() || 'ERROR';
                return `[${new Date(error.timestamp).toLocaleString()}] [${level}] ${error.message} ${JSON.stringify(error.details)}`;
            }).join('\n');
            
            // Create and download the file
            const blob = new Blob([formattedLogs], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${selectedCategory}-logs-${new Date().toISOString().split('T')[0]}.txt`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (err) {
            setError('Error downloading logs: ' + err.message);
            console.error('Error downloading logs:', err);
        }
    };

    // Original downloadLog for production
    const downloadLog = async () => {
        if (isDev) {
            downloadLocalLogs();
            return;
        }
        
        try {
            const response = await fetch('/api/admin/logs.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action: 'download', category: selectedCategory })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            if (data.success && data.downloadUrl) {
                // Open the download URL in a new tab/window
                window.open(data.downloadUrl, '_blank');
            } else {
                throw new Error(data.message || 'Failed to download log');
            }
        } catch (err) {
            setError(err.message);
            console.error('Error downloading log:', err);
        }
    };

    // Parse log line into structured data
    const parseLogLine = (line) => {
        try {
            // Match pattern: [TIMESTAMP] [LEVEL] MESSAGE CONTEXT
            const match = line.match(/\[(.*?)\] \[(.*?)\] (.*?)( {.*})?$/);
            if (match) {
                return {
                    timestamp: match[1],
                    level: match[2],
                    message: match[3].trim(),
                    context: match[4] ? JSON.parse(match[4]) : null
                };
            }
            return { raw: line };
        } catch (err) {
            console.error('Error parsing log line:', err);
            return { raw: line };
        }
    };

    const handleLevelChange = (checkedValues) => {
        setSelectedLevels(checkedValues);
    };

    const filteredLogs = logs.filter(log => {
        const parsed = parseLogLine(log);
        return parsed.level ? selectedLevels.includes(parsed.level) : true;
    });

    const renderLogLevel = (level) => {
        if (!level) return null;
        
        switch (level) {
            case 'ERROR':
                return <Badge status="error" text={level} />;
            case 'WARN':
                return <Badge status="warning" text={level} />;
            case 'INFO':
                return <Badge status="success" text={level} />;
            default:
                return <Badge status="default" text={level} />;
        }
    };

    const getLevelIcon = (level) => {
        if (!level) return null;
        
        switch (level) {
            case 'ERROR':
                return <CloseCircleOutlined style={{ color: '#ff4d4f' }} />;
            case 'WARN':
                return <WarningOutlined style={{ color: '#faad14' }} />;
            case 'INFO':
                return <InfoCircleOutlined style={{ color: '#52c41a' }} />;
            default:
                return null;
        }
    };

    const renderLogContent = () => {
        if (loading && logs.length === 0) {
            return <Spin size="large" className="logs-spinner" />;
        }

        if (error) {
            return <Alert type="error" message={error} className="logs-error" />;
        }

        if (!selectedCategory) {
            return <Empty description="Select a category to view logs" />;
        }

        if (!logExists) {
            return (
                <div>
                    <Alert 
                        type="info" 
                        message={isDev ? 
                            `No logs exist for ${selectedCategory}. Generate logs by using the logError function.` :
                            `No logs exist for ${selectedCategory} today.`
                        } 
                    />
                    {isDev && (
                        <div style={{ marginTop: 20, textAlign: 'center' }}>
                            <p>Example code to generate a test error:</p>
                            <pre style={{ textAlign: 'left', background: '#f5f5f5', padding: 10, borderRadius: 4 }}>
                                {`import { logError, ERROR_CATEGORIES } from '../utils/logger';

// Log a test error
logError(
  ERROR_CATEGORIES.${selectedCategory === 'all' ? 'AUTH' : selectedCategory.toUpperCase()}, 
  'Test error message', 
  { severity: 'medium', details: 'This is a test error' }
);`}
                            </pre>
                        </div>
                    )}
                </div>
            );
        }

        if (filteredLogs.length === 0) {
            return <Empty description="No logs match the selected filters" />;
        }

        return (
            <div className="log-content" ref={logContainerRef}>
                {filteredLogs.map((line, index) => {
                    const parsedLog = parseLogLine(line);
                    
                    if (parsedLog.raw) {
                        return (
                            <div key={index} className="log-line">
                                <pre>{parsedLog.raw}</pre>
                            </div>
                        );
                    }
                    
                    return (
                        <div key={index} className={`log-line ${parsedLog.level?.toLowerCase()}`}>
                            <span className="log-timestamp">[{parsedLog.timestamp}]</span>
                            <span className="log-level">
                                {getLevelIcon(parsedLog.level)} {parsedLog.level}
                            </span>
                            <span className="log-message">{parsedLog.message}</span>
                            {parsedLog.context && (
                                <details className="log-context">
                                    <summary>Context</summary>
                                    <pre>{JSON.stringify(parsedLog.context, null, 2)}</pre>
                                </details>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <Card className="log-viewer-card">
            <div className="log-viewer-header">
                <Space>
                    <Select
                        placeholder="Select a category"
                        value={selectedCategory}
                        onChange={setSelectedCategory}
                        style={{ width: 150 }}
                    >
                        {categories.map(category => (
                            <Option key={category} value={category}>
                                {category === 'all' ? 'All Categories' : category}
                                {!isDev && config.categories && (
                                    config.categories[category] 
                                        ? <Badge status="success" style={{ marginLeft: 8 }} />
                                        : <Badge status="default" style={{ marginLeft: 8 }} />
                                )}
                            </Option>
                        ))}
                    </Select>
                    
                    <Select
                        value={lines}
                        onChange={setLines}
                        style={{ width: 140 }}
                    >
                        <Option value={50}>Last 50 lines</Option>
                        <Option value={100}>Last 100 lines</Option>
                        <Option value={200}>Last 200 lines</Option>
                        <Option value={500}>Last 500 lines</Option>
                    </Select>
                    
                    <Checkbox.Group 
                        options={[
                            { label: 'INFO', value: 'INFO' },
                            { label: 'WARN', value: 'WARN' },
                            { label: 'ERROR', value: 'ERROR' }
                        ]}
                        value={selectedLevels}
                        onChange={handleLevelChange}
                    />
                    
                    <Checkbox 
                        checked={autoRefresh} 
                        onChange={(e) => setAutoRefresh(e.target.checked)}
                    >
                        Auto-refresh
                    </Checkbox>
                </Space>
                
                <Space>
                    <Tooltip title="Refresh Logs">
                        <Button 
                            icon={<ReloadOutlined />} 
                            onClick={() => isDev ? fetchLocalLogs(selectedCategory) : fetchLogs(selectedCategory)}
                            disabled={!selectedCategory}
                        />
                    </Tooltip>
                    
                    <Tooltip title="Download Log">
                        <Button 
                            icon={<DownloadOutlined />} 
                            onClick={downloadLog}
                            disabled={!selectedCategory || !logExists}
                        />
                    </Tooltip>
                    
                    {!isDev && (
                        <Tooltip title="Toggle Logging">
                            <Button 
                                type={config.categories && config.categories[selectedCategory] ? "primary" : "default"}
                                onClick={() => toggleLogging(selectedCategory)}
                                disabled={!selectedCategory}
                            >
                                {config.categories && config.categories[selectedCategory] ? "Enabled" : "Disabled"}
                            </Button>
                        </Tooltip>
                    )}
                    
                    <Tooltip title={isDev ? "Clear All Logs" : "Rotate Old Logs"}>
                        <Button 
                            icon={<DeleteOutlined />} 
                            onClick={isDev ? clearLocalLogs : rotateLogs}
                            danger
                        />
                    </Tooltip>
                </Space>
            </div>
            
            <div className="log-viewer-content">
                {renderLogContent()}
            </div>
        </Card>
    );
};

export default LogViewer; 