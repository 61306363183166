import React, { useState, useEffect } from 'react';
import { AdminService } from '../../services/AdminService';
import './AuthMonitoring.css';

const AuthMonitoring = ({ section = 'auth' }) => {
  const [stats, setStats] = useState({
    loginAttempts: {
      total: 0,
      successful: 0,
      failed: 0,
      trend: 'neutral'
    },
    passwordResets: {
      total: 0,
      completed: 0,
      pending: 0,
      trend: 'neutral'
    },
    newAccounts: {
      total: 0,
      verified: 0,
      pending: 0,
      trend: 'neutral'
    },
    activeUsers: {
      total: 0,
      trend: 'neutral'
    }
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('24h');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        const data = await AdminService.getAuthStats(timeRange);
        
        setStats(data || stats);
        setError(null);
      } catch (err) {
        console.error(`Error fetching stats:`, err);
        setError(`Failed to load statistics: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange]);

  if (loading) {
    return (
      <div className="section-container">
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
          <span>Loading authentication data...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="section-container">
      <div className="section-header">
        <h1>User Authentication Monitoring</h1>
        <div className="time-range-selector">
          <select value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
            <option value="24h">Last 24 Hours</option>
            <option value="7d">Last 7 Days</option>
            <option value="30d">Last 30 Days</option>
          </select>
        </div>
      </div>

      <div className="section-content">
        <div className="cards-grid">
          <div className="stat-card">
            <h3>Login Attempts</h3>
            <div className="value">{stats.loginAttempts.total}</div>
          </div>
        </div>

        <div className="table-container">
          <h3>Recent Authentication Events</h3>
          <table className="data-table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Event Type</th>
                <th>User</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Placeholder</td>
                <td>Login Attempt</td>
                <td>user@example.com</td>
                <td>Success</td>
                <td>IP: 192.168.1.1</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AuthMonitoring; 