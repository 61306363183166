import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import './Notifications.css';

const Notifications = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState('all');
  const [notificationSettings, setNotificationSettings] = useState({
    orderAlerts: true,
    systemAlerts: true,
    marketingAlerts: false,
    emailNotifications: true,
    pushNotifications: false,
    soundAlerts: true
  });
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Sample notifications data
  const sampleNotifications = [
    {
      id: 1,
      type: 'order',
      title: 'New Order Received',
      message: 'Order #ORD-2023-5679 has been placed for $34.50',
      createdAt: new Date(Date.now() - 15 * 60000).toISOString(), // 15 minutes ago
      read: false,
      actionLink: '/countertop/orders'
    },
    {
      id: 2,
      type: 'system',
      title: 'Payment Processor Connected',
      message: 'Your Stripe account has been successfully connected',
      createdAt: new Date(Date.now() - 3 * 60 * 60000).toISOString(), // 3 hours ago
      read: true,
      actionLink: '/countertop/settings/payments'
    },
    {
      id: 3,
      type: 'order',
      title: 'Order Feedback Received',
      message: 'A customer left a 5-star review for order #ORD-2023-5670',
      createdAt: new Date(Date.now() - 6 * 60 * 60000).toISOString(), // 6 hours ago
      read: false,
      actionLink: '/countertop/messages'
    },
    {
      id: 4,
      type: 'system',
      title: 'Menu Updated',
      message: 'Your menu changes have been published to your website',
      createdAt: new Date(Date.now() - 1 * 24 * 60 * 60000).toISOString(), // 1 day ago
      read: true,
      actionLink: '/builder'
    },
    {
      id: 5,
      type: 'marketing',
      title: 'Holiday Promotion Ideas',
      message: 'Check out our guide for holiday promotions to boost your sales',
      createdAt: new Date(Date.now() - 2 * 24 * 60 * 60000).toISOString(), // 2 days ago
      read: true,
      actionLink: null
    }
  ];

  useEffect(() => {
    // In real implementation, fetch data from API
    // Simulating API call
    setTimeout(() => {
      setNotifications(sampleNotifications);
      setLoading(false);
    }, 800);
  }, []);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSettingChange = (e) => {
    const { name, checked } = e.target;
    setNotificationSettings(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleSaveSettings = async () => {
    setSaving(true);

    try {
      // In real implementation, save settings to API
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setSuccessMessage('Notification settings saved successfully!');
      
      // Hide success message after a few seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error saving notification settings:', error);
    } finally {
      setSaving(false);
    }
  };

  const markAsRead = (id) => {
    const updatedNotifications = notifications.map(notification => 
      notification.id === id ? { ...notification, read: true } : notification
    );
    setNotifications(updatedNotifications);
  };

  const markAllAsRead = () => {
    const updatedNotifications = notifications.map(notification => ({
      ...notification,
      read: true
    }));
    setNotifications(updatedNotifications);
  };

  const deleteNotification = (id) => {
    const updatedNotifications = notifications.filter(notification => notification.id !== id);
    setNotifications(updatedNotifications);
  };

  const clearAllNotifications = () => {
    setNotifications([]);
  };

  const filteredNotifications = notifications.filter(notification => {
    if (filter === 'all') return true;
    if (filter === 'unread') return !notification.read;
    return notification.type === filter;
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now - date;
    const diffMins = Math.floor(diffMs / 60000);
    const diffHours = Math.floor(diffMs / 3600000);
    const diffDays = Math.floor(diffMs / 86400000);
    
    if (diffMins < 60) {
      return `${diffMins} ${diffMins === 1 ? 'minute' : 'minutes'} ago`;
    } else if (diffHours < 24) {
      return `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      return `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'order':
        return 'fas fa-shopping-cart';
      case 'system':
        return 'fas fa-cog';
      case 'marketing':
        return 'fas fa-bullhorn';
      default:
        return 'fas fa-bell';
    }
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading notifications...</p>
      </div>
    );
  }

  return (
    <div className="notifications-container">
      <div className="notifications-header">
        <h2>Notifications</h2>
        <p className="section-description">
          View and manage order alerts, system messages, and marketing updates.
        </p>
      </div>
      
      <div className="notifications-content">
        <div className="notifications-list">
          <div className="list-header">
            <div className="filter-tabs">
              <button 
                className={`filter-tab ${filter === 'all' ? 'active' : ''}`}
                onClick={() => handleFilterChange('all')}
              >
                All
              </button>
              <button 
                className={`filter-tab ${filter === 'unread' ? 'active' : ''}`}
                onClick={() => handleFilterChange('unread')}
              >
                Unread
              </button>
              <button 
                className={`filter-tab ${filter === 'order' ? 'active' : ''}`}
                onClick={() => handleFilterChange('order')}
              >
                Orders
              </button>
              <button 
                className={`filter-tab ${filter === 'system' ? 'active' : ''}`}
                onClick={() => handleFilterChange('system')}
              >
                System
              </button>
            </div>
            
            <div className="list-actions">
              <button 
                className="btn-text"
                onClick={markAllAsRead}
                disabled={notifications.every(n => n.read)}
              >
                Mark all as read
              </button>
              <button 
                className="btn-text"
                onClick={clearAllNotifications}
                disabled={notifications.length === 0}
              >
                Clear all
              </button>
            </div>
          </div>
          
          <div className="notifications-items">
            {filteredNotifications.length === 0 ? (
              <div className="no-notifications">
                <i className="fas fa-bell-slash"></i>
                <p>No notifications</p>
              </div>
            ) : (
              filteredNotifications.map(notification => (
                <div 
                  key={notification.id} 
                  className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                >
                  <div className="notification-icon">
                    <i className={getNotificationIcon(notification.type)}></i>
                  </div>
                  <div className="notification-content">
                    <div className="notification-title">{notification.title}</div>
                    <div className="notification-message">{notification.message}</div>
                    <div className="notification-time">{formatDate(notification.createdAt)}</div>
                  </div>
                  <div className="notification-actions">
                    {notification.actionLink && (
                      <a href={notification.actionLink} className="notification-link">
                        <i className="fas fa-external-link-alt"></i>
                      </a>
                    )}
                    {!notification.read && (
                      <button 
                        className="notification-action mark-read"
                        onClick={() => markAsRead(notification.id)}
                        title="Mark as read"
                      >
                        <i className="fas fa-check"></i>
                      </button>
                    )}
                    <button 
                      className="notification-action delete"
                      onClick={() => deleteNotification(notification.id)}
                      title="Delete"
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        
        <div className="notification-settings">
          <div className="settings-card">
            <h3>Notification Settings</h3>
            
            {successMessage && (
              <div className="settings-success">
                <i className="fas fa-check-circle"></i> {successMessage}
              </div>
            )}
            
            <div className="settings-section">
              <h4>Notification Types</h4>
              <div className="setting-option">
                <label className="switch-label">
                  Order Alerts
                  <div className="switch">
                    <input
                      type="checkbox"
                      name="orderAlerts"
                      checked={notificationSettings.orderAlerts}
                      onChange={handleSettingChange}
                    />
                    <span className="slider"></span>
                  </div>
                </label>
                <p className="setting-description">
                  Receive notifications for new orders, order updates, and feedback.
                </p>
              </div>
              
              <div className="setting-option">
                <label className="switch-label">
                  System Alerts
                  <div className="switch">
                    <input
                      type="checkbox"
                      name="systemAlerts"
                      checked={notificationSettings.systemAlerts}
                      onChange={handleSettingChange}
                    />
                    <span className="slider"></span>
                  </div>
                </label>
                <p className="setting-description">
                  Important updates about your account, website, and technical issues.
                </p>
              </div>
              
              <div className="setting-option">
                <label className="switch-label">
                  Marketing Alerts
                  <div className="switch">
                    <input
                      type="checkbox"
                      name="marketingAlerts"
                      checked={notificationSettings.marketingAlerts}
                      onChange={handleSettingChange}
                    />
                    <span className="slider"></span>
                  </div>
                </label>
                <p className="setting-description">
                  Tips, best practices, and promotional opportunities.
                </p>
              </div>
            </div>
            
            <div className="settings-section">
              <h4>Notification Methods</h4>
              <div className="setting-option">
                <label className="switch-label">
                  Email Notifications
                  <div className="switch">
                    <input
                      type="checkbox"
                      name="emailNotifications"
                      checked={notificationSettings.emailNotifications}
                      onChange={handleSettingChange}
                    />
                    <span className="slider"></span>
                  </div>
                </label>
                <p className="setting-description">
                  Receive notifications via email.
                </p>
              </div>
              
              <div className="setting-option">
                <label className="switch-label">
                  Push Notifications
                  <div className="switch">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      checked={notificationSettings.pushNotifications}
                      onChange={handleSettingChange}
                    />
                    <span className="slider"></span>
                  </div>
                </label>
                <p className="setting-description">
                  Receive notifications in your browser or mobile app.
                </p>
              </div>
              
              <div className="setting-option">
                <label className="switch-label">
                  Sound Alerts
                  <div className="switch">
                    <input
                      type="checkbox"
                      name="soundAlerts"
                      checked={notificationSettings.soundAlerts}
                      onChange={handleSettingChange}
                    />
                    <span className="slider"></span>
                  </div>
                </label>
                <p className="setting-description">
                  Play sound alerts for important notifications.
                </p>
              </div>
            </div>
            
            <div className="settings-actions">
              <button 
                className="btn btn-primary"
                onClick={handleSaveSettings}
                disabled={saving}
              >
                {saving ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> Saving...
                  </>
                ) : 'Save Settings'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications; 