import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './Landing.css';

const Landing = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.isAdmin) {
        navigate('/admin');
      } else {
        navigate('/dashboard');
      }
    }
  }, [isAuthenticated, user, navigate]);

  return (
    <div className="landing-container">
      <header className="landing-header">
        <h1>GoodeEats</h1>
        <p className="tagline">Revolutionizing Food Service Management</p>
        {!isAuthenticated && (
          <div className="auth-buttons">
            <button onClick={() => navigate('/login')} className="btn-primary">
              Login
            </button>
            <button onClick={() => navigate('/register')} className="btn-secondary">
              Register
            </button>
          </div>
        )}
      </header>

      <main className="landing-main">
        <div className="features-section">
          <div className="feature-card">
            <i className="fas fa-utensils"></i>
            <h2>Menu Management</h2>
            <p>Create and manage your menus with ease</p>
          </div>
          
          <div className="feature-card">
            <i className="fas fa-mobile-alt"></i>
            <h2>Mobile Friendly</h2>
            <p>Access your dashboard from any device</p>
          </div>
          
          <div className="feature-card">
            <i className="fas fa-chart-line"></i>
            <h2>Analytics</h2>
            <p>Track your business performance</p>
          </div>
        </div>
      </main>

      <footer className="landing-footer">
        <p>&copy; 2024 GoodeEats. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Landing; 