/**
 * API Utilities - Provides consistent API URL handling
 * 
 * This utility ensures that API calls use the correct base URL
 * based on environment and prevents hardcoded localhost URLs
 */

import axios from 'axios';
import API_ENDPOINTS from '../config/api';

/**
 * Gets the base API URL for the current environment
 * @returns {string} The base API URL
 */
export const getApiBaseUrl = () => {
  // Use the same logic as in config/api.js
  return process.env.NODE_ENV === 'production'
    ? window.location.origin + '/api'
    : 'http://localhost:9000/api';
};

/**
 * Builds a complete API URL for the given endpoint
 * @param {string} endpoint - The API endpoint path (without base URL)
 * @returns {string} Complete API URL
 */
export const getApiUrl = (endpoint) => {
  // Remove leading slash if present
  const cleanEndpoint = endpoint.startsWith('/') ? endpoint.substring(1) : endpoint;
  
  // Don't modify endpoints that already have extensions or end with /
  if (cleanEndpoint.includes('.') || cleanEndpoint.endsWith('/')) {
    return `${getApiBaseUrl()}/${cleanEndpoint}`;
  }
  
  // In development, add .php extension
  if (process.env.NODE_ENV !== 'production') {
    return `${getApiBaseUrl()}/${cleanEndpoint}.php`;
  }
  
  // In production, don't add extension (let .htaccess handle it)
  return `${getApiBaseUrl()}/${cleanEndpoint}`;
};

/**
 * Creates headers with authorization token for API requests
 * @param {Object} additionalHeaders - Any additional headers to include
 * @returns {Object} Headers object with authorization
 */
export const getAuthHeaders = (additionalHeaders = {}) => {
  const token = localStorage.getItem('token');
  
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
    ...additionalHeaders
  };
};

/**
 * Fetches data from API with authentication headers
 * @param {string} url - The URL to fetch
 * @param {Object} options - Fetch options
 * @returns {Promise} Fetch promise
 */
export const fetchWithAuth = async (url, options = {}) => {
  // Ensure headers include auth token
  const headers = {
    ...getAuthHeaders(),
    ...(options.headers || {})
  };
  
  return fetch(url, {
    ...options,
    headers,
    credentials: 'include'
  });
};

/**
 * Sets up axios interceptors for authentication and error handling
 */
export const setupAxiosInterceptors = () => {
  // Add request interceptor to include auth token
  axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  }, error => {
    return Promise.reject(error);
  });
  
  // Add response interceptor to handle auth errors
  axios.interceptors.response.use(
    response => response,
    error => {
      // Handle auth errors (401)
      if (error.response?.status === 401) {
        console.error('Authentication error - token might be invalid or expired');
        
        // Could trigger logout or redirect to login here if needed
      }
      
      return Promise.reject(error);
    }
  );
};

export default {
  getApiBaseUrl,
  getApiUrl,
  getAuthHeaders,
  fetchWithAuth,
  setupAxiosInterceptors
}; 