import React, { useState } from 'react';
import './MenuItemForm.css';
import useAIDescription from './AIDescription';

const MenuItemForm = ({ onSubmit, onCancel, categories, userId, isLoading }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    selectedCategories: new Set(),
    image: null,
    imageFileName: '',
    imagePreview: null
  });
  const [errors, setErrors] = useState({});

  // Setup AI description functionality
  useAIDescription((newDescription) => {
    setFormData(prev => ({
      ...prev,
      description: newDescription
    }));
  });

  const generateImageFileName = (originalName, itemName) => {
    const timestamp = Date.now();
    const uniqueId = Math.random().toString(36).substring(2, 8);
    const extension = originalName.split('.').pop().toLowerCase();
    // Convert item name to URL-friendly format
    const sanitizedName = itemName
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
    
    return `${sanitizedName}-${timestamp}-${uniqueId}.${extension}`;
  };

  const formatPrice = (value) => {
    // Remove all non-digits
    const digits = value.replace(/\D/g, '');
    
    // Convert to cents (move decimal point 2 places right)
    const cents = parseInt(digits) || 0;
    
    // Convert back to dollars with 2 decimal places
    return (cents / 100).toFixed(2);
  };

  const handlePriceChange = (e) => {
    const rawValue = e.target.value;
    // Only keep digits
    const digitsOnly = rawValue.replace(/\D/g, '');
    
    // Format the price
    const formattedPrice = formatPrice(digitsOnly);
    
    setFormData(prev => ({
      ...prev,
      price: formattedPrice
    }));
    
    // Clear error when user starts typing
    setErrors(prev => ({ ...prev, price: '' }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'price') {
      // Only allow numbers and one decimal point
      const formatted = value.replace(/[^0-9.]/g, '');
      // Ensure only one decimal point
      const parts = formatted.split('.');
      const formattedPrice = parts.length > 2 ? parts[0] + '.' + parts[1] : formatted;
      // Limit to 2 decimal places
      if (parts.length > 1 && parts[1]?.length > 2) {
        const limitedPrice = `${parts[0]}.${parts[1].substring(0, 2)}`;
        setFormData(prev => ({
          ...prev,
          [name]: limitedPrice
        }));
        return;
      }
      setFormData(prev => ({
        ...prev,
        [name]: formattedPrice
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error when user starts typing
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleCategoryChange = (categoryId) => {
    setFormData(prev => {
      const newSelectedCategories = new Set(prev.selectedCategories);
      if (newSelectedCategories.has(categoryId)) {
        newSelectedCategories.delete(categoryId);
      } else {
        newSelectedCategories.add(categoryId);
      }
      return {
        ...prev,
        selectedCategories: newSelectedCategories
      };
    });
    
    // Clear category error
    setErrors(prev => ({ ...prev, categories: '' }));
  };

  const handleImageChange = (e) => {
    console.log('File input changed');
    const file = e.target.files[0];
    
    if (file) {
      console.log('File selected:', file.name, file.type, file.size);
      
      // Validate file type
      if (!file.type.startsWith('image/')) {
        alert('Please select an image file');
        return;
      }

      // Generate SEO-friendly filename
      const newFileName = generateImageFileName(file.name, formData.name || 'menu-item');
      console.log('Generated filename:', newFileName);
      
      // Create URL for preview
      const previewUrl = URL.createObjectURL(file);
      
      setFormData(prev => {
        console.log('Updating form data with image');
        return {
          ...prev,
          image: file, // Store the actual file object
          imageFileName: newFileName,
          imagePreview: previewUrl
        };
      });
    } else {
      console.log('No file selected');
    }
  };

  // Cleanup preview URL on unmount
  React.useEffect(() => {
    return () => {
      if (formData.imagePreview) {
        URL.revokeObjectURL(formData.imagePreview);
      }
    };
  }, []);

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    
    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = 'Item name is required';
      document.getElementById('name').classList.add('input-error');
      isValid = false;
    }
    
    // Validate price
    if (!formData.price) {
      newErrors.price = 'Price is required';
      document.getElementById('price').classList.add('input-error');
      isValid = false;
    } else if (isNaN(parseFloat(formData.price))) {
      newErrors.price = 'Price must be a valid number';
      document.getElementById('price').classList.add('input-error');
      isValid = false;
    }
    
    // Validate categories
    if (formData.selectedCategories.size === 0) {
      newErrors.categories = 'Please select at least one category';
      document.querySelector('.categories-select').classList.add('select-error');
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmitClick = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      // Create form data for multipart/form-data submission
      const submitData = new FormData();
      submitData.append('name', formData.name);
      submitData.append('description', formData.description);
      submitData.append('price', formData.price);
      submitData.append('categories', JSON.stringify(Array.from(formData.selectedCategories)));
      
      // If we have an image, append the file directly
      if (formData.image) {
        submitData.append('image', formData.image, formData.imageFileName);
      }
      
      // Log the FormData contents for debugging
      console.log('Form data being sent:');
      for (let pair of submitData.entries()) {
        console.log(pair[0], pair[1]);
      }
      
      onSubmit(submitData);
    } catch (error) {
      console.error('Error preparing form data:', error);
      setErrors({ general: 'Error preparing form data. Please try again.' });
    }
  };

  return (
    <div className="menu-item-form">
      {errors.general && <div className="form-error">{errors.general}</div>}
      
      <div className="form-group">
        <label htmlFor="name">Item Name <span className="required-field">*</span></label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          onFocus={(e) => e.target.classList.remove('input-error')}
          required
          className={errors.name ? 'input-error' : ''}
        />
        {errors.name && <div className="field-error">{errors.name}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        />
        <button type="button" id="generate-description-btn" className="suggest-ai-btn">✨ Suggest with AI</button>
      </div>

      <div id="description-modal" className="modal">
        <div className="modal-header">
          <h3>Let's Make This Dish Stand Out</h3>
          <button type="button" className="close-btn" data-action="close">×</button>
        </div>
        <div className="modal-content">
          <div className="user-prompt-container">
            <label htmlFor="user-prompt">This is your current description. You can tweak it to help the AI generate better suggestions.</label>
            <textarea 
              id="user-prompt" 
              placeholder="Add specific details about this dish (ingredients, preparation, flavor profile, etc.) to help AI generate a better description..."
              rows="3"
            ></textarea>
          </div>
          
          <div className="ai-result-container">
            <h4>AI Generated Description:</h4>
            <p id="suggested-description">Loading...</p>
            <textarea id="editable-suggestion" style={{display: 'none'}}></textarea>
          </div>
          
          <div className="modal-buttons">
            <button type="button" data-action="use">✅ Use This</button>
            <button type="button" data-action="edit">✏️ Edit & Use</button>
            <button type="button" data-action="regenerate">🔁 Regenerate</button>
            <button type="button" data-action="cancel">❌ Cancel</button>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="price">Price <span className="required-field">*</span></label>
        <div className="price-input-wrapper">
          <span className="currency-symbol">$</span>
          <input
            type="text"
            id="price"
            name="price"
            value={formData.price}
            onChange={handlePriceChange}
            placeholder="0.00"
            className={`price-input ${errors.price ? 'input-error' : ''}`}
            inputMode="decimal"
            onFocus={(e) => e.target.classList.remove('input-error')}
            required
          />
        </div>
        {errors.price && <div className="field-error">{errors.price}</div>}
      </div>

      <div className="form-group">
        <label>Categories (Select at least one) <span className="required-field">*</span></label>
        {formData.selectedCategories.size === 0 && (
          <div className="category-error-message">Need to select at least one Category</div>
        )}
        <div className={`categories-select ${errors.categories ? 'select-error' : ''}`}
             onClick={() => document.querySelector('.categories-select').classList.remove('select-error')}>
          {categories && categories.map(cat => (
            <div key={cat.id} className="category-checkbox">
              <input
                type="checkbox"
                id={`category-${cat.id}`}
                checked={formData.selectedCategories.has(cat.id)}
                onChange={() => handleCategoryChange(cat.id)}
              />
              <label htmlFor={`category-${cat.id}`}>{cat.name}</label>
            </div>
          ))}
        </div>
        {errors.categories && <div className="field-error">{errors.categories}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="image">Image</label>
        <div>
          <label className="custom-file-input" htmlFor="image">
            Choose Image
          </label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageChange}
            accept="image/*"
          />
          <span className="selected-file-name">
            {formData.imageFileName || 'No file selected'}
          </span>
        </div>
        {formData.imagePreview && (
          <div className="image-preview">
            <img src={formData.imagePreview} alt="Preview" />
          </div>
        )}
      </div>

      <div className="form-actions">
        <button 
          type="button" 
          className="cancel-btn" 
          onClick={onCancel}
          disabled={isLoading}
          style={{ padding: '12px 24px', minWidth: '140px' }}
        >
          CANCEL
        </button>
        <button 
          type="button" 
          className="save-btn"
          style={{ backgroundColor: '#4CAF50', color: 'white', padding: '12px 24px', minWidth: '140px' }}
          onClick={handleSubmitClick}
          disabled={isLoading}
        >
          {isLoading ? 'Saving...' : 'SAVE ITEM'}
        </button>
      </div>
    </div>
  );
};

export default MenuItemForm; 