// AIDescription.js - React wrapper for AI description functionality
import { useEffect } from 'react';

/**
 * Make an element draggable
 * @param {HTMLElement} element - The element to make draggable
 * @param {HTMLElement} handle - The handle element to drag with
 */
const makeDraggable = (element, handle) => {
  let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  
  if (!element || !handle) return;
  
  handle.onmousedown = dragMouseDown;

  function dragMouseDown(e) {
    e.preventDefault();
    // Get the mouse cursor position at startup
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // Call function whenever the cursor moves
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e.preventDefault();
    // Calculate the new cursor position
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // Set the element's new position
    element.style.top = (element.offsetTop - pos2) + "px";
    element.style.left = (element.offsetLeft - pos1) + "px";
    // Remove transform to use absolute positioning
    element.style.transform = "none";
  }

  function closeDragElement() {
    // Stop moving when mouse button is released
    document.onmouseup = null;
    document.onmousemove = null;
  }
};

/**
 * Debug DOM elements and events
 * @param {string} contextName - Name for context
 * @param {object} elements - Object containing elements to debug
 */
const debugElements = (contextName, elements) => {
  try {
    if (!elements) {
      console.log(`[DEBUG ${contextName}] No elements provided`);
      return;
    }
    
    console.log(`[DEBUG ${contextName}] Found elements:`, Object.keys(elements)
      .filter(key => elements[key] !== undefined)
      .map(key => `${key}: ${elements[key] ? 'YES' : 'NO'}`).join(', '));
    
    // Check button functionality
    if (elements.generateBtn) {
      console.log(`[DEBUG ${contextName}] Generate button has click listeners:`, 
        !!elements.generateBtn.onclick || elements.generateBtn._debugListener === true);
    }
    
    if (elements.descriptionModal) {
      try {
        // Ensure descriptionModal is an Element before calling querySelectorAll
        if (elements.descriptionModal.querySelectorAll) {
          const buttons = elements.descriptionModal.querySelectorAll('[data-action]');
          console.log(`[DEBUG ${contextName}] Modal has ${buttons.length} action buttons`);
          
          if (buttons && buttons.length > 0) {
            buttons.forEach(btn => {
              console.log(`- Button [${btn.getAttribute('data-action')}]: ${btn.textContent.trim()}`);
            });
          }
        } else {
          console.log(`[DEBUG ${contextName}] Modal doesn't support querySelectorAll`);
        }
      } catch (err) {
        console.error(`[DEBUG ${contextName}] Error getting modal buttons:`, err.message);
      }
    }
  } catch (err) {
    console.error(`[DEBUG ${contextName}] Error in debugElements:`, err.message);
  }
};

/**
 * Safely get DOM element by ID with error handling
 * @param {string} id - Element ID to find
 * @returns {HTMLElement|null} - The found element or null
 */
const safeGetElement = (id) => {
  try {
    return document.getElementById(id);
  } catch (err) {
    console.error(`Error getting element #${id}:`, err.message);
    return null;
  }
};

/**
 * Hook to initialize AI description functionality using onclick properties
 * @param {Function} updateDescription - Function to update the description in the parent form
 */
const useAIDescription = (updateDescription) => {
  useEffect(() => {
    console.log("AIDescription useEffect running");
    
    // DOM elements - get safely
    const generateBtn = safeGetElement('generate-description-btn');
    const descriptionModal = safeGetElement('description-modal');
    const description = safeGetElement('description');
    
    // Debug elements
    const elements = { generateBtn, descriptionModal, description };
    debugElements('Initial Setup', elements);
    
    // Exit if required elements don't exist
    if (!generateBtn || !descriptionModal || !description) {
      console.error('Required elements missing for AI description');
      return;
    }
    
    // Make the modal draggable
    try {
      const modalHeader = descriptionModal.querySelector('.modal-header');
      if (modalHeader) {
        makeDraggable(descriptionModal, modalHeader);
      }
    } catch (err) {
      console.error('Error setting up draggable modal:', err.message);
    }
    
    // Generate description with OpenAI
    const generateDescription = async (itemName, userPrompt = '') => {
      try {
        const suggestedDescription = safeGetElement('suggested-description');
        if (!suggestedDescription) return;
        
        suggestedDescription.textContent = 'Loading...';
        suggestedDescription.style.display = 'block'; // Make sure it's visible
        
        // Reset editable suggestion state
        const editableSuggestion = safeGetElement('editable-suggestion');
        if (editableSuggestion) {
          editableSuggestion.style.display = 'none';
        }
        
        // Get current description value (for context)
        const currentDescription = description.value || '';
        
        // Show the modal
        descriptionModal.style.display = 'block';
        
        try {
          // Get API URL
          let baseUrl = '/api';
          const isDevelopment = window.location.hostname === 'localhost';
          if (isDevelopment) {
            baseUrl = window.location.port === '3001' ? 'http://localhost:9000/api' : '/api';
          }
          
          // Prepare the request
          const apiUrl = `${baseUrl}/generate_description.php`;
          const token = localStorage.getItem('token');
          const headers = { 'Content-Type': 'application/json' };
          
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }
          
          // Make the request
          console.log(`Sending AI description request for "${itemName}" to ${apiUrl}`);
          console.log(`User prompt: "${userPrompt}", Current description: "${currentDescription}"`);
          
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            credentials: 'include',
            body: JSON.stringify({
              name: itemName,
              user_prompt: userPrompt,
              current_description: currentDescription,
              dev_mode: isDevelopment,
              bypass_auth: isDevelopment
            })
          });
          
          // Handle the response
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || `HTTP error: ${response.status}`);
          }
          
          const data = await response.json();
          console.log('AI response:', data);
          
          if (data.status === 'success') {
            suggestedDescription.textContent = data.description;
            if (editableSuggestion) {
              editableSuggestion.value = data.description;
            }
          } else {
            throw new Error(data.message || 'Unknown error');
          }
        } catch (error) {
          console.error('Error generating description:', error);
          suggestedDescription.textContent = `Error: ${error.message || 'Failed to generate description'}`;
        }
      } catch (err) {
        console.error('Error in generateDescription:', err.message);
      }
    };
    
    // Clear any existing event handlers
    if (generateBtn && generateBtn.onclick) generateBtn.onclick = null;
    
    // Main click handler for the generate button
    generateBtn.onclick = (e) => {
      try {
        console.log('Generate button clicked');
        e.preventDefault();
        
        const nameInput = document.querySelector('input[name="name"]');
        const itemName = nameInput ? nameInput.value : '';
        
        if (!itemName) {
          alert('Please enter an item name first.');
          return;
        }
        
        // Pre-populate the user prompt field with current description if available
        const userPromptField = safeGetElement('user-prompt');
        if (userPromptField && description.value) {
          userPromptField.value = description.value;
        }
        
        generateDescription(itemName);
      } catch (err) {
        console.error('Error in generate button click handler:', err.message);
      }
    };
    
    // Direct attachment of onclick handlers to each button in the modal
    const attachButtonHandlers = () => {
      try {
        console.log("Attaching button handlers");
        
        // Get buttons by data-action attribute
        const useBtn = descriptionModal.querySelector('button[data-action="use"]');
        const editBtn = descriptionModal.querySelector('button[data-action="edit"]');
        const regenerateBtn = descriptionModal.querySelector('button[data-action="regenerate"]');
        const customGenerateBtn = descriptionModal.querySelector('button[data-action="generate-custom"]');
        const cancelBtns = descriptionModal.querySelectorAll('button[data-action="cancel"], button[data-action="close"]');
        
        if (useBtn) {
          useBtn.onclick = (e) => {
            try {
              e.preventDefault();
              console.log("Use button clicked");
              const suggestedDescription = safeGetElement('suggested-description');
              if (suggestedDescription && !suggestedDescription.textContent.startsWith('Error:')) {
                const text = suggestedDescription.textContent;
                description.value = text;
                if (updateDescription) {
                  updateDescription(text);
                }
                descriptionModal.style.display = 'none';
              }
            } catch (err) {
              console.error('Error in use button handler:', err.message);
            }
          };
        }
        
        if (editBtn) {
          editBtn.onclick = (e) => {
            try {
              e.preventDefault();
              console.log("Edit button clicked");
              const suggestedDescription = safeGetElement('suggested-description');
              const editableSuggestion = safeGetElement('editable-suggestion');
              if (suggestedDescription && editableSuggestion) {
                editableSuggestion.value = suggestedDescription.textContent;
                suggestedDescription.style.display = 'none';
                editableSuggestion.style.display = 'block';
              }
            } catch (err) {
              console.error('Error in edit button handler:', err.message);
            }
          };
        }
        
        if (regenerateBtn) {
          regenerateBtn.onclick = (e) => {
            try {
              e.preventDefault();
              console.log("Regenerate button clicked");
              const nameInput = document.querySelector('input[name="name"]');
              const itemName = nameInput ? nameInput.value : '';
              const userPromptField = safeGetElement('user-prompt');
              const userPrompt = userPromptField ? userPromptField.value : '';
              
              if (itemName) {
                generateDescription(itemName, userPrompt);
              }
            } catch (err) {
              console.error('Error in regenerate button handler:', err.message);
            }
          };
        }
        
        // We don't need the custom generate button anymore
        if (customGenerateBtn) {
          customGenerateBtn.parentNode.removeChild(customGenerateBtn);
        }
        
        cancelBtns.forEach(btn => {
          btn.onclick = (e) => {
            try {
              e.preventDefault();
              console.log("Close/Cancel button clicked");
              descriptionModal.style.display = 'none';
            } catch (err) {
              console.error('Error in cancel button handler:', err.message);
            }
          };
        });
        
        // Handle editable suggestion keyboard shortcut
        const editableSuggestion = safeGetElement('editable-suggestion');
        if (editableSuggestion) {
          editableSuggestion.onkeydown = (e) => {
            try {
              if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                const text = editableSuggestion.value;
                description.value = text;
                if (updateDescription) {
                  updateDescription(text);
                }
                descriptionModal.style.display = 'none';
              }
            } catch (err) {
              console.error('Error in editable suggestion keydown handler:', err.message);
            }
          };
        }
      } catch (err) {
        console.error('Error in attachButtonHandlers:', err.message);
      }
    };
    
    // Attach handlers for modal buttons
    attachButtonHandlers();
    
    // Check if everything is set up correctly
    debugElements('After Setup', { 
      generateBtn: generateBtn ? !!generateBtn.onclick : false,
      descriptionModal: !!descriptionModal,
      description: !!description
    });
    
    return () => {
      try {
        // Clean up
        if (generateBtn) generateBtn.onclick = null;
        
        // Clean up any other event handlers
        if (descriptionModal) {
          const useBtn = descriptionModal.querySelector('button[data-action="use"]');
          const editBtn = descriptionModal.querySelector('button[data-action="edit"]');
          const regenerateBtn = descriptionModal.querySelector('button[data-action="regenerate"]');
          const cancelBtns = descriptionModal.querySelectorAll('button[data-action="cancel"], button[data-action="close"]');
          
          if (useBtn) useBtn.onclick = null;
          if (editBtn) editBtn.onclick = null;
          if (regenerateBtn) regenerateBtn.onclick = null;
          if (cancelBtns) cancelBtns.forEach(btn => btn.onclick = null);
        }
        
        const editableSuggestion = safeGetElement('editable-suggestion');
        if (editableSuggestion) editableSuggestion.onkeydown = null;
      } catch (err) {
        console.error('Error in cleanup function:', err.message);
      }
    };
  }, [updateDescription]);
};

export default useAIDescription; 