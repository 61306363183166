import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import './Reports.css';

const Reports = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('week');
  const [reportData, setReportData] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

  // Sample data for demonstration
  const generateSampleData = (range) => {
    let data = {
      summary: {
        revenue: 0,
        orders: 0,
        averageOrderValue: 0,
        topSellingItems: []
      },
      dailyRevenue: [],
      ordersByHour: Array(24).fill(0),
      completedOrders: 0,
      canceledOrders: 0,
      preparationTimes: {
        average: 0,
        min: 0,
        max: 0
      }
    };

    // Generate different data based on selected range
    let days = 7;
    if (range === 'month') days = 30;
    if (range === 'year') days = 365;

    // Daily revenue data
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - days);
    
    let totalRevenue = 0;
    let totalOrders = 0;
    
    for (let i = 0; i < days; i++) {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      
      // Generate random but somewhat realistic data
      const dayRevenue = Math.floor(Math.random() * 1000) + 500;
      const dayOrders = Math.floor(dayRevenue / 25);
      
      totalRevenue += dayRevenue;
      totalOrders += dayOrders;
      
      data.dailyRevenue.push({
        date: date.toISOString().split('T')[0],
        revenue: dayRevenue,
        orders: dayOrders
      });
      
      // Distribute orders by hour (more during lunch and dinner)
      for (let j = 0; j < dayOrders; j++) {
        // More orders during lunch (11-14) and dinner (17-20)
        let hour;
        const rand = Math.random();
        if (rand < 0.4) {
          // Lunch hours
          hour = Math.floor(Math.random() * 4) + 11;
        } else if (rand < 0.8) {
          // Dinner hours
          hour = Math.floor(Math.random() * 4) + 17;
        } else {
          // Other hours
          hour = Math.floor(Math.random() * 24);
        }
        data.ordersByHour[hour]++;
      }
    }
    
    // Summary calculations
    data.summary.revenue = totalRevenue;
    data.summary.orders = totalOrders;
    data.summary.averageOrderValue = totalRevenue / totalOrders;
    
    // Completed vs canceled orders
    data.completedOrders = Math.floor(totalOrders * 0.96); // 96% completion rate
    data.canceledOrders = totalOrders - data.completedOrders;
    
    // Preparation times
    data.preparationTimes = {
      average: Math.floor(Math.random() * 5) + 15, // 15-20 minutes
      min: Math.floor(Math.random() * 5) + 10, // 10-15 minutes
      max: Math.floor(Math.random() * 10) + 25 // 25-35 minutes
    };
    
    // Top selling items
    const menuItems = [
      { name: 'Classic Cheeseburger', category: 'Burgers' },
      { name: 'Chicken Caesar Salad', category: 'Salads' },
      { name: 'Margherita Pizza', category: 'Pizzas' },
      { name: 'BBQ Pulled Pork Sandwich', category: 'Sandwiches' },
      { name: 'Chocolate Brownie Sundae', category: 'Desserts' },
      { name: 'Buffalo Wings', category: 'Appetizers' },
      { name: 'Veggie Wrap', category: 'Wraps' },
      { name: 'French Fries', category: 'Sides' }
    ];
    
    data.summary.topSellingItems = menuItems
      .sort(() => 0.5 - Math.random()) // Shuffle
      .slice(0, 5) // Take first 5
      .map((item, index) => ({
        ...item,
        quantity: Math.floor(Math.random() * 100) + 50,
        revenue: Math.floor(Math.random() * 1000) + 500
      }))
      .sort((a, b) => b.quantity - a.quantity); // Sort by quantity
    
    return data;
  };

  useEffect(() => {
    // In real implementation, fetch data from API
    // Simulating API call
    setTimeout(() => {
      setReportData(generateSampleData(timeRange));
      setLoading(false);
    }, 800);
  }, [timeRange]);

  const handleTimeRangeChange = (range) => {
    setLoading(true);
    setTimeRange(range);
  };

  const handleExportCSV = async () => {
    setExportLoading(true);
    
    // Simulate export delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // In a real implementation, you would create and download a CSV
    // This is a simplified example
    const csvContent = "data:text/csv;charset=utf-8," 
      + "Date,Revenue,Orders\n"
      + reportData.dailyRevenue.map(day => {
          return `${day.date},${day.revenue},${day.orders}`;
        }).join("\n");
    
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `goode-eats-report-${timeRange}.csv`);
    document.body.appendChild(link);
    
    link.click();
    document.body.removeChild(link);
    
    setExportLoading(false);
  };

  const formatCurrency = (value) => {
    return `$${value.toFixed(2)}`;
  };

  const formatNumber = (value) => {
    return value.toLocaleString();
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading reports...</p>
      </div>
    );
  }

  return (
    <div className="reports-container">
      <div className="reports-header">
        <h2>Reports & Analytics</h2>
        <p className="section-description">
          View sales reports, customer data, and business metrics.
        </p>
      </div>
      
      <div className="reports-controls">
        <div className="time-filters">
          <button 
            className={`time-filter ${timeRange === 'week' ? 'active' : ''}`}
            onClick={() => handleTimeRangeChange('week')}
          >
            Week
          </button>
          <button 
            className={`time-filter ${timeRange === 'month' ? 'active' : ''}`}
            onClick={() => handleTimeRangeChange('month')}
          >
            Month
          </button>
          <button 
            className={`time-filter ${timeRange === 'year' ? 'active' : ''}`}
            onClick={() => handleTimeRangeChange('year')}
          >
            Year
          </button>
        </div>
        
        <button 
          className="btn btn-outline"
          onClick={handleExportCSV}
          disabled={exportLoading}
        >
          {exportLoading ? (
            <>
              <i className="fas fa-spinner fa-spin"></i> Exporting...
            </>
          ) : (
            <>
              <i className="fas fa-download"></i> Export CSV
            </>
          )}
        </button>
      </div>
      
      <div className="reports-summary">
        <div className="summary-card">
          <div className="summary-icon">
            <i className="fas fa-dollar-sign"></i>
          </div>
          <div className="summary-data">
            <h3>Revenue</h3>
            <div className="summary-value">{formatCurrency(reportData.summary.revenue)}</div>
          </div>
        </div>
        
        <div className="summary-card">
          <div className="summary-icon">
            <i className="fas fa-shopping-cart"></i>
          </div>
          <div className="summary-data">
            <h3>Orders</h3>
            <div className="summary-value">{formatNumber(reportData.summary.orders)}</div>
          </div>
        </div>
        
        <div className="summary-card">
          <div className="summary-icon">
            <i className="fas fa-receipt"></i>
          </div>
          <div className="summary-data">
            <h3>Average Order</h3>
            <div className="summary-value">{formatCurrency(reportData.summary.averageOrderValue)}</div>
          </div>
        </div>
        
        <div className="summary-card">
          <div className="summary-icon">
            <i className="fas fa-clock"></i>
          </div>
          <div className="summary-data">
            <h3>Avg. Prep Time</h3>
            <div className="summary-value">{reportData.preparationTimes.average} min</div>
          </div>
        </div>
      </div>
      
      <div className="reports-charts">
        <div className="chart-container">
          <h3>Revenue Trend</h3>
          <div className="chart-placeholder">
            <div className="chart-bars">
              {reportData.dailyRevenue.slice(-10).map((day, index) => (
                <div key={index} className="chart-bar-container">
                  <div 
                    className="chart-bar" 
                    style={{ height: `${day.revenue / 10}%` }}
                    title={`${day.date}: ${formatCurrency(day.revenue)}`}
                  ></div>
                  <div className="chart-label">{day.date.split('-')[2]}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="chart-container">
          <h3>Orders by Hour</h3>
          <div className="chart-placeholder">
            <div className="chart-bars">
              {reportData.ordersByHour.map((count, hour) => (
                <div key={hour} className="chart-bar-container">
                  <div 
                    className="chart-bar" 
                    style={{ height: `${count * 100 / Math.max(...reportData.ordersByHour)}%` }}
                    title={`${hour}:00 - ${hour + 1}:00: ${count} orders`}
                  ></div>
                  <div className="chart-label">{hour}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      <div className="reports-details">
        <div className="detail-table">
          <h3>Top Selling Items</h3>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Category</th>
                <th>Quantity</th>
                <th>Revenue</th>
              </tr>
            </thead>
            <tbody>
              {reportData.summary.topSellingItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.category}</td>
                  <td>{formatNumber(item.quantity)}</td>
                  <td>{formatCurrency(item.revenue)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        <div className="detail-card">
          <h3>Order Completion Rate</h3>
          <div className="completion-stats">
            <div className="completion-stat">
              <span className="stat-label">Completed Orders</span>
              <span className="stat-value completed">{formatNumber(reportData.completedOrders)}</span>
            </div>
            <div className="completion-stat">
              <span className="stat-label">Canceled Orders</span>
              <span className="stat-value canceled">{formatNumber(reportData.canceledOrders)}</span>
            </div>
            <div className="completion-rate">
              <div 
                className="rate-bar" 
                style={{ width: `${(reportData.completedOrders / reportData.summary.orders) * 100}%` }}
              ></div>
            </div>
            <div className="rate-label">
              {((reportData.completedOrders / reportData.summary.orders) * 100).toFixed(1)}% Completion Rate
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports; 