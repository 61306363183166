import React, { useState, useEffect } from 'react';
import { Card, Select, Spin, Alert, Table } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { AdminService } from '../../services/AdminService';

const UserActivity = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('24h');
    const [stats, setStats] = useState(null);

    useEffect(() => {
        fetchActivityStats();
    }, [timeRange]);

    const fetchActivityStats = async () => {
        try {
            setLoading(true);
            const data = await AdminService.getUserActivityStats(timeRange);
            setStats(data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch user activity statistics');
            console.error('Error fetching activity stats:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error) {
        return <Alert type="error" message={error} />;
    }

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>User Activity</h2>
                <Select
                    value={timeRange}
                    onChange={setTimeRange}
                    style={{ width: 120 }}
                >
                    <Select.Option value="24h">Last 24 Hours</Select.Option>
                    <Select.Option value="7d">Last 7 Days</Select.Option>
                    <Select.Option value="30d">Last 30 Days</Select.Option>
                </Select>
            </div>

            <div className="cards-grid">
                <Card title="Active Users">
                    <h3>{stats?.activeUsers || 0}</h3>
                    <p>In the last {timeRange}</p>
                </Card>
                <Card title="Total Actions">
                    <h3>{stats?.totalActions || 0}</h3>
                    <p>User interactions</p>
                </Card>
                <Card title="Average Session">
                    <h3>{stats?.avgSessionTime || '0m'}</h3>
                    <p>Session duration</p>
                </Card>
                <Card title="New Users">
                    <h3>{stats?.newUsers || 0}</h3>
                    <p>Recent signups</p>
                </Card>
            </div>

            {/* Activity Trend Chart */}
            {stats?.activityTrend && (
                <Card title="Activity Trend" className="chart-card">
                    <LineChart
                        width={800}
                        height={300}
                        data={stats.activityTrend}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line 
                            type="monotone" 
                            dataKey="activeUsers" 
                            stroke="#8884d8" 
                            name="Active Users" 
                        />
                        <Line 
                            type="monotone" 
                            dataKey="actions" 
                            stroke="#82ca9d" 
                            name="Actions" 
                        />
                    </LineChart>
                </Card>
            )}

            {/* Recent Activity Table */}
            <Card title="Recent Activity" className="table-card">
                <Table 
                    columns={[
                        {
                            title: 'User',
                            dataIndex: 'user',
                            key: 'user',
                        },
                        {
                            title: 'Action',
                            dataIndex: 'action',
                            key: 'action',
                        },
                        {
                            title: 'Details',
                            dataIndex: 'details',
                            key: 'details',
                        },
                        {
                            title: 'Time',
                            dataIndex: 'time',
                            key: 'time',
                        }
                    ]} 
                    dataSource={stats?.recentActivity || []}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
            </Card>
        </div>
    );
};

export default UserActivity; 