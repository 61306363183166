import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheck, faTimes, faKey } from '@fortawesome/free-solid-svg-icons';
import './Auth.css';
import { API_ENDPOINTS } from '../../config/api';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    email: ''
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });
  const [validations, setValidations] = useState({
    password: { isValid: false, message: '' },
    confirmPassword: { isValid: false, message: '' }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  // Add useEffect for fetching email
  useEffect(() => {
    const fetchEmailFromToken = async () => {
      if (!token) return;

      try {
        const response = await fetch(`${API_ENDPOINTS.validateResetToken}?token=${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        const data = await response.json();
        if (response.ok && data.email) {
          setFormData(prev => ({
            ...prev,
            email: data.email
          }));
        }
      } catch (error) {
        console.error('Error fetching email:', error);
      }
    };

    fetchEmailFromToken();
  }, [token]);

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password.length >= 8;
    
    const isValid = hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar && isValidLength;
    let message = '';
    
    if (password.length > 0) {
      if (!isValidLength) message = 'Password must be at least 8 characters';
      else if (!hasUpperCase) message = 'Password must contain an uppercase letter';
      else if (!hasLowerCase) message = 'Password must contain a lowercase letter';
      else if (!hasNumbers) message = 'Password must contain a number';
      else if (!hasSpecialChar) message = 'Password must contain a special character';
      else message = 'Password meets requirements';
    }

    return { isValid, message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'password') {
      const validation = validatePassword(value);
      setValidations(prev => ({
        ...prev,
        password: validation,
        confirmPassword: formData.confirmPassword ? {
          isValid: value === formData.confirmPassword,
          message: value === formData.confirmPassword ? 'Passwords match' : 'Passwords do not match'
        } : prev.confirmPassword
      }));
    } else if (name === 'confirmPassword') {
      setValidations(prev => ({
        ...prev,
        confirmPassword: {
          isValid: value === formData.password,
          message: value ? (value === formData.password ? 'Passwords match' : 'Passwords do not match') : ''
        }
      }));
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!token) {
      setMessage({ type: 'error', text: 'Invalid or missing reset token' });
      return;
    }

    if (!validations.password.isValid || !validations.confirmPassword.isValid) {
      setMessage({ type: 'error', text: 'Please fix all validation errors' });
      return;
    }

    setIsLoading(true);
    setMessage({ type: '', text: '' });

    try {
      const response = await fetch(API_ENDPOINTS.resetPassword, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          password: formData.password,
          email: formData.email
        }),
        credentials: 'include'
      });

      const data = await response.json();

      if (response.ok) {
        setMessage({
          type: 'success',
          text: 'Password has been reset successfully. Redirecting to dashboard...'
        });
        
        // Submit the form again to trigger browser password save
        const dummySubmit = document.createElement('form');
        dummySubmit.style.display = 'none';
        dummySubmit.method = 'post';
        dummySubmit.action = window.location.origin + '/login';
        dummySubmit.innerHTML = `
          <input type="email" name="email" value="${formData.email}" autocomplete="username" />
          <input type="password" name="password" value="${formData.password}" autocomplete="new-password" />
          <input type="submit" />
        `;
        document.body.appendChild(dummySubmit);
        
        // Trigger form submission in a way that better prompts password save
        const submitEvent = new SubmitEvent('submit', {
          bubbles: true,
          cancelable: true
        });
        dummySubmit.dispatchEvent(submitEvent);
        
        // Give browser time to save password, then redirect to dashboard
        setTimeout(() => {
          document.body.removeChild(dummySubmit);
          navigate('/dashboard', { 
            replace: true 
          });
        }, 2000);
      } else {
        setMessage({
          type: 'error',
          text: data.message || 'An error occurred. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage({
        type: 'error',
        text: 'A network error occurred. Please check your connection and try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const generateStrongPassword = () => {
    const length = 16;
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*(),.?":{}|<>';
    
    const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;
    
    // Ensure at least one of each type
    let password = 
      uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)] +
      lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)] +
      numberChars[Math.floor(Math.random() * numberChars.length)] +
      specialChars[Math.floor(Math.random() * specialChars.length)];
    
    // Fill the rest randomly
    for (let i = password.length; i < length; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }
    
    // Shuffle the password
    password = password.split('').sort(() => Math.random() - 0.5).join('');
    
    setFormData(prev => ({
      ...prev,
      password: password,
      confirmPassword: password
    }));
    
    // Trigger validation
    const validation = validatePassword(password);
    setValidations(prev => ({
      password: validation,
      confirmPassword: {
        isValid: true,
        message: 'Passwords match'
      }
    }));
  };

  if (!token) {
    return (
      <div className="auth-container">
        <div className="auth-form">
          <h2>Invalid Reset Link</h2>
          <p>This password reset link is invalid or has expired.</p>
          <p className="auth-link">
            <a href="/forgot-password">Request a new reset link</a>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-container">
      <form 
        className="auth-form" 
        onSubmit={handleSubmit}
        autoComplete="on"
        name="resetPasswordForm"
      >
        <h2>Reset Password</h2>
        
        {message.text && (
          <div className={`message ${message.type}`}>
            {message.text}
          </div>
        )}

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            readOnly
            className="readonly-input"
            autoComplete="username"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">
            New Password
            {validations.password.message && (
              <span className={`validation-message ${validations.password.isValid ? 'valid' : 'invalid'}`}>
                {validations.password.message}
              </span>
            )}
          </label>
          <div className="input-wrapper">
            <div className="password-input-group">
              <div className="password-input-wrapper">
                <input
                  type={showPassword.password ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={validations.password.message ? (validations.password.isValid ? 'valid' : 'invalid') : ''}
                  autoComplete="new-password"
                  required
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('password')}
                  className="toggle-password-btn"
                >
                  <FontAwesomeIcon icon={showPassword.password ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="generate-password-btn"
            onClick={generateStrongPassword}
          >
            <FontAwesomeIcon icon={faKey} /> Generate Strong Password
          </button>
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">
            Confirm New Password
            {validations.confirmPassword.message && (
              <span className={`validation-message ${validations.confirmPassword.isValid ? 'valid' : 'invalid'}`}>
                {validations.confirmPassword.message}
              </span>
            )}
          </label>
          <div className="input-wrapper">
            <div className="password-input-wrapper">
              <input
                type={showPassword.confirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className={validations.confirmPassword.message ? (validations.confirmPassword.isValid ? 'valid' : 'invalid') : ''}
                autoComplete="new-password"
                required
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility('confirmPassword')}
                className="toggle-password-btn"
              >
                <FontAwesomeIcon icon={showPassword.confirmPassword ? faEyeSlash : faEye} />
              </button>
            </div>
          </div>
        </div>

        <button 
          type="submit" 
          className="auth-button"
          disabled={!validations.password.isValid || !validations.confirmPassword.isValid || isLoading}
        >
          {isLoading ? 'Resetting Password...' : 'Reset Password'}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword; 