/**
 * Cart Service
 * Manages cart operations and state
 */

import { 
  saveCartItemsToStorage, 
  loadCartItemsFromStorage, 
  generateLocalItemId,
  isUserLoggedIn,
  CART_STORAGE_KEY
} from './cartUtils';

import axios from 'axios';
import { getApiUrl } from '../../utils/apiUtils';

// Local storage key for cart
const GUEST_SESSION_ID_KEY = 'guest_session_id';

// Session ID for anonymous carts
let sessionId = null;

// Helper to get guest session ID
const getGuestSessionId = () => {
  return document.cookie.split('; ').find(row => row.startsWith('guest_session_id='))
    ?.split('=')[1] || localStorage.getItem(GUEST_SESSION_ID_KEY);
};

/**
 * Get cart items from API or local storage as fallback
 * @returns {Promise<Array>} Cart items
 */
export const getCartItems = async () => {
  try {
    console.log('Fetching cart items');
    // Make API request
    const token = isUserLoggedIn() ? localStorage.getItem('token') : null;
    const headers = {
      'Accept': 'application/json'
    };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    
    const response = await fetch(getApiUrl(), {
      method: 'GET',
      credentials: 'include',
      headers
    });
    
    if (response.ok) {
      const data = await response.json();
      console.log('Cart data received:', data);
      
      if (data.status === 'success') {
        // Store cart in localStorage as a backup
        const cartItems = Array.isArray(data.items) ? data.items : [];
        localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cartItems));
        return cartItems;
      }
    }
    
    console.warn('Failed to fetch cart from server, falling back to local storage');
    // Fallback to localStorage
    const storedCart = localStorage.getItem(CART_STORAGE_KEY);
    return storedCart ? JSON.parse(storedCart) : [];
  } catch (error) {
    console.error('Error getting cart items:', error);
    // Fallback to localStorage on error
    const storedCart = localStorage.getItem(CART_STORAGE_KEY);
    return storedCart ? JSON.parse(storedCart) : [];
  }
};

/**
 * Add item to cart
 * @param {Object} item - Item to add to cart
 * @returns {Promise<Array>} Updated cart items
 */
export const addToCart = async (item) => {
  try {
    console.log('Adding item to cart:', item);
    const token = isUserLoggedIn() ? localStorage.getItem('token') : null;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    
    // Prepare cart item data
    const cartItemData = {
      menu_item_id: item.id,
      quantity: item.quantity || 1,
      special_instructions: item.special_instructions || '',
      options: item.options || {}
    };
    
    // Send to server
    const response = await fetch(getApiUrl('add'), {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify(cartItemData)
    });
    
    if (response.ok) {
      const result = await response.json();
      console.log('Add to cart response:', result);
      
      if (result.status === 'success') {
        // Update local cart by fetching the latest version
        return await getCartItems();
      }
    }
    
    // If server request fails, fall back to local storage
    console.warn('Failed to add item to server cart, using local storage');
    const currentCart = await getCartItems();
    
    // Generate a unique cart item ID
    const cartItemId = `local_${item.id}_${Date.now()}`;
    const itemWithId = { 
      ...item, 
      cart_item_id: cartItemId,
      total_price: (parseFloat(item.price) * (item.quantity || 1)).toFixed(2)
    };
    
    const updatedCart = [...currentCart, itemWithId];
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(updatedCart));
    
    return updatedCart;
  } catch (error) {
    console.error('Error adding item to cart:', error);
    return await getCartItems();
  }
};

/**
 * Remove item from cart
 * @param {string} cartItemId - ID of cart item to remove
 * @returns {Promise<Array>} Updated cart items
 */
export const removeFromCart = async (cartItemId) => {
  try {
    console.log('Removing item from cart:', cartItemId);
    
    // Check if it's a server-side cart item or local-only
    if (cartItemId.startsWith('local_')) {
      // Local only cart item, just update localStorage
      const currentCart = await getCartItems();
      const updatedCart = currentCart.filter(item => item.cart_item_id !== cartItemId);
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(updatedCart));
      return updatedCart;
    }
    
    // Extract actual ID from cart_XX format
    const itemId = cartItemId.replace('cart_', '');
    const token = isUserLoggedIn() ? localStorage.getItem('token') : null;
    const headers = {
      'Accept': 'application/json'
    };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    
    // Use POST request with action=remove instead of DELETE for better compatibility
    const response = await fetch(getApiUrl(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify({
        action: 'remove',
        cartItemId: itemId
      })
    });
    
    if (response.ok) {
      const result = await response.json();
      console.log('Remove from cart response:', result);
      
      if (result.status === 'success') {
        // Update local cart by fetching the latest version
        return await getCartItems();
      }
    }
    
    // If server request fails, fall back to local storage
    console.warn('Failed to remove item from server cart, using local storage');
    const currentCart = await getCartItems();
    const updatedCart = currentCart.filter(item => item.cart_item_id !== cartItemId);
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(updatedCart));
    
    return updatedCart;
  } catch (error) {
    console.error('Error removing item from cart:', error);
    return await getCartItems();
  }
};

/**
 * Update item quantity in cart
 * @param {string} cartItemId - ID of cart item to update
 * @param {number} newQuantity - New quantity
 * @returns {Promise<Array>} Updated cart items
 */
export const updateCartItemQuantity = async (cartItemId, newQuantity) => {
  try {
    console.log('Updating cart item quantity:', cartItemId, newQuantity);
    
    if (newQuantity <= 0) {
      // If quantity is zero or negative, remove item
      return await removeFromCart(cartItemId);
    }
    
    // Check if it's a server-side cart item or local-only
    if (cartItemId.startsWith('local_')) {
      // Local only cart item, just update localStorage
      const currentCart = await getCartItems();
      const updatedCart = currentCart.map(item => {
        if (item.cart_item_id === cartItemId) {
          const unitPrice = parseFloat(item.price);
          const totalPrice = unitPrice * newQuantity;
          return { 
            ...item, 
            quantity: newQuantity,
            total_price: totalPrice.toFixed(2)
          };
        }
        return item;
      });
      
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(updatedCart));
      return updatedCart;
    }
    
    const token = isUserLoggedIn() ? localStorage.getItem('token') : null;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    
    // Send update request
    const response = await fetch(getApiUrl(), {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({ 
        action: 'update',
        cartItemId,
        quantity: newQuantity
      })
    });
    
    if (response.ok) {
      const result = await response.json();
      console.log('Update cart response:', result);
      
      if (result.status === 'success') {
        // Update local cart by fetching the latest version
        return await getCartItems();
      }
    }
    
    // If server request fails, fall back to local storage
    console.warn('Failed to update item in server cart, using local storage');
    const currentCart = await getCartItems();
    const updatedCart = currentCart.map(item => {
      if (item.cart_item_id === cartItemId) {
        const unitPrice = parseFloat(item.price || item.unit_price);
        const totalPrice = unitPrice * newQuantity;
        return { 
          ...item, 
          quantity: newQuantity,
          total_price: totalPrice.toFixed(2)
        };
      }
      return item;
    });
    
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(updatedCart));
    return updatedCart;
  } catch (error) {
    console.error('Error updating cart item quantity:', error);
    return await getCartItems();
  }
};

/**
 * Get cart total
 * @returns {Promise<number>} Cart total
 */
export const getCartTotal = async () => {
  try {
    const cartItems = await getCartItems();
    
    return cartItems.reduce((total, item) => {
      return total + parseFloat(item.total_price || 0);
    }, 0);
  } catch (error) {
    console.error('Error calculating cart total:', error);
    return 0;
  }
};

/**
 * Clear cart
 * @returns {Promise<Array>} Empty cart
 */
export const clearCart = async () => {
  try {
    console.log('Clearing cart');
    const token = isUserLoggedIn() ? localStorage.getItem('token') : null;
    const headers = {
      'Accept': 'application/json'
    };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    
    // Send clear request
    const response = await fetch(getApiUrl('clear'), {
      method: 'POST',
      credentials: 'include',
      headers
    });
    
    // Always clear localStorage cart
    localStorage.removeItem(CART_STORAGE_KEY);
    
    if (response.ok) {
      const result = await response.json();
      console.log('Clear cart response:', result);
    }
    
    return [];
  } catch (error) {
    console.error('Error clearing cart:', error);
    // Still clear local storage even on error
    localStorage.removeItem(CART_STORAGE_KEY);
    return [];
  }
};

/**
 * Merge local cart with server cart on login
 * Call this function when a user logs in
 * @returns {Promise<Array>} Merged cart items
 */
export const mergeCartsOnLogin = async () => {
  try {
    console.log('Merging carts after login');
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.warn('Cannot merge carts: user not logged in');
      return await getCartItems();
    }
    
    const sessionId = getGuestSessionId();
    
    if (!sessionId) {
      console.warn('No guest session found to merge');
      return await getCartItems();
    }
    
    // Send merge request
    const response = await fetch(getApiUrl('merge'), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ 
        action: 'merge',
        session_id: sessionId
      })
    });
    
    if (response.ok) {
      const result = await response.json();
      console.log('Merge carts response:', result);
      
      if (result.status === 'success') {
        // Update local storage with merged cart from server
        return await getCartItems();
      }
    }
    
    console.warn('Cart merge failed, keeping local cart');
    return await getCartItems();
  } catch (error) {
    console.error('Error merging carts:', error);
    return await getCartItems();
  }
};

/**
 * Get business settings including tax information
 * @returns {Promise<Object>} Business settings
 */
export const getBusinessSettings = async () => {
  try {
    // Use the utility function that handles development vs production environments
    const apiUrl = typeof getApiUrl === 'function' 
      ? getApiUrl('business-settings')  // Use the utility if available
      : `${process.env.REACT_APP_API_URL || 'http://localhost:9000'}/api/business-settings`;
    
    console.log('Fetching business settings from API:', apiUrl);
    
    const response = await fetch(apiUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    
    console.log('Business settings API response status:', response.status);
    
    if (!response.ok) {
      console.warn(`Business settings API returned error status: ${response.status}`);
      throw new Error(`API error: ${response.status}`);
    }
    
    // Get the raw response text for debugging
    const responseText = await response.clone().text();
    console.log('Business settings raw response:', responseText);
    
    // Parse the response
    const data = await response.json();
    console.log('Business settings parsed response:', data);
    
    // Check if there's an error in the response
    if (data.error) {
      throw new Error(data.error);
    }
    
    // Return business data with explicit type conversion for tax fields
    return {
      business_name: data.business_name || '',
      collect_sales_tax: data.collect_sales_tax === 1 || data.collect_sales_tax === '1' || 
                       data.collect_sales_tax === true ? 1 : 0,
      tax_rate: parseFloat(data.tax_rate) || 0
    };
  } catch (error) {
    console.error('Error fetching business settings:', error);
    throw error;
  }
};

/**
 * Process a customer order
 * @param {Object} orderData - Order data with delivery and payment information
 * @returns {Promise<Object>} Order result with status and order ID
 */
export const processOrder = async (orderData) => {
  try {
    const token = isUserLoggedIn() ? localStorage.getItem('token') : null;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    
    // Get current cart items and totals to include with order
    const cartItems = await getCartItems();
    const subtotal = await getCartTotal();
    
    // Get business settings for tax calculation
    const businessSettings = await getBusinessSettings();
    
    // Calculate tax
    let taxAmount = 0;
    if (businessSettings.collect_sales_tax && parseFloat(businessSettings.tax_rate) > 0) {
      taxAmount = parseFloat(subtotal) * (parseFloat(businessSettings.tax_rate) / 100);
    }
    
    // Calculate grand total
    const total = parseFloat(subtotal) + taxAmount;
    
    // Prepare complete order data
    const completeOrderData = {
      ...orderData,
      cart_items: cartItems,
      subtotal: subtotal.toFixed(2),
      tax_amount: taxAmount.toFixed(2),
      total: total.toFixed(2),
      business_settings: {
        business_name: businessSettings.business_name,
        tax_rate: businessSettings.tax_rate
      }
    };
    
    // For now, we'll simulate a server call since the actual API endpoint may not exist yet
    console.log('Submitting order:', completeOrderData);
    
    // In a real implementation, this would be an API call:
    /*
    // Use getApiUrl for consistent URL handling across environments
    const apiUrl = getApiUrl('orders/create');
    const response = await fetch(apiUrl, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify(completeOrderData)
    });
    
    if (response.ok) {
      const result = await response.json();
      if (result.status === 'success') {
        // Clear cart after successful order
        await clearCart();
        return {
          success: true,
          orderId: result.order_id,
          message: result.message || 'Order placed successfully!'
        };
      } else {
        throw new Error(result.message || 'Failed to place order');
      }
    } else {
      throw new Error(`HTTP error ${response.status}`);
    }
    */
    
    // Simulate success or failure based on the payment method and card number
    // Card numbers ending in "0000" will be declined
    const isDeclined = orderData.paymentMethod === 'card' && 
                       orderData.cardNumber && 
                       orderData.cardNumber.endsWith('0000');
    
    // Simulate processing delay
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    if (isDeclined) {
      return {
        success: false,
        message: 'Payment declined. Please try another card.'
      };
    }
    
    return {
      success: true,
      orderId: `ORD-${Math.floor(Math.random() * 10000)}`,
      message: 'Order placed successfully!'
    };
  } catch (error) {
    console.error('Error processing order:', error);
    return {
      success: false,
      message: `Error processing order: ${error.message}`
    };
  }
};

/**
 * Update item options in cart
 * @param {string} cartItemId - ID of cart item to update
 * @param {Object} updatedOptions - New options configuration
 * @returns {Promise<Array>} Updated cart items
 */
export const updateCartItemOptions = async (cartItemId, updatedOptions) => {
  try {
    console.log('Updating cart item options:', cartItemId, JSON.stringify(updatedOptions));
    
    // Check if it's a server-side cart item or local-only
    if (cartItemId.startsWith('local_')) {
      // Local only cart item, just update localStorage
      const currentCart = await getCartItems();
      const updatedCart = currentCart.map(item => {
        if (item.cart_item_id === cartItemId) {
          return { 
            ...item, 
            options: updatedOptions
          };
        }
        return item;
      });
      
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(updatedCart));
      return updatedCart;
    }
    
    const token = isUserLoggedIn() ? localStorage.getItem('token') : null;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    // Extract the correct ID from the cartItemId (remove 'cart_' prefix)
    const cartId = cartItemId.replace('cart_', '');
    
    // Get the API URL
    const apiUrl = getApiUrl();
    console.log('Sending update options request to:', apiUrl);
    
    // Prepare the request body
    const requestBody = { 
      action: 'update_options',
      cartItemId: cartId,
      options: updatedOptions
    };
    console.log('Request body:', JSON.stringify(requestBody));
    
    // Send update request using axios instead of fetch
    try {
      const response = await axios.post(apiUrl, requestBody, {
        headers,
        withCredentials: true
      });
      
      console.log('Update options response:', response.data);
      
      if (response.data && response.data.status === 'success') {
        // Update local cart by fetching the latest version
        return await getCartItems();
      } else {
        console.error('Server error updating options:', 
          response.data && response.data.message ? response.data.message : 'Unknown error');
      }
    } catch (axiosError) {
      console.error('Network error updating options:', axiosError.message);
      if (axiosError.response) {
        console.error('Error status:', axiosError.response.status);
        console.error('Error data:', axiosError.response.data);
      }
    }
    
    // If server request fails, fall back to local storage
    console.warn('Failed to update options in server cart, using local storage');
    const currentCart = await getCartItems();
    const updatedCart = currentCart.map(item => {
      if (item.cart_item_id === cartItemId) {
        return { 
          ...item, 
          options: updatedOptions
        };
      }
      return item;
    });
    
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(updatedCart));
    return updatedCart;
  } catch (error) {
    console.error('Error updating cart item options:', error);
    return await getCartItems();
  }
}; 