import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { logError, ERROR_CATEGORIES } from '../../utils/logger';
import './Auth.css';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { login, user } = useAuth();
    const [message, setMessage] = useState(
        location.state?.message || { type: '', text: '' }
    );
    const [showPassword, setShowPassword] = useState(false);

    // Get the intended destination from location state, or use default
    const from = location.state?.from?.pathname || '/dashboard';

    // Redirect if already logged in
    useEffect(() => {
        if (user) {
            if (user.isAdmin) {
                navigate('/admin');
            } else {
                navigate('/dashboard');
            }
        }
    }, [user, navigate]);

    // Clear location state after reading message
    useEffect(() => {
        if (location.state?.message) {
            // Only clear non-persistent messages after timeout
            if (!location.state.message.persistent) {
                const timer = setTimeout(() => {
                    setMessage({ type: '', text: '' });
                }, 5000);
                return () => clearTimeout(timer);
            }
        }
        // Clean up location state but keep message if persistent
        window.history.replaceState({}, document.title);
    }, [location.state]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            // Log what we're about to submit
            console.log("Login form submitting with:", {
                email: formData.email,
                passwordProvided: !!formData.password,
                passwordLength: formData.password?.length || 0
            });
            
            // Ensure we're passing the entire formData object
            const response = await login(formData);
            
            console.log("Login response:", response);
            
            // Check if account is unverified
            if (response.status === 'unverified') {
                // Log unverified account attempt
                logError(ERROR_CATEGORIES.AUTH, 'Login attempt with unverified account', {
                    email: formData.email,
                    status: 'unverified',
                    severity: 'low'
                });
                
                // Store email for verification
                localStorage.setItem('verificationEmail', formData.email);
                localStorage.setItem('verificationStartTime', Date.now().toString());
                
                // Redirect to verification page
                navigate('/verify-email', {
                    state: {
                        email: formData.email,
                        message: response.message || 'Please verify your email to continue.'
                    }
                });
                return;
            }
            
            // If we have a token in the response but it's not in localStorage yet
            if (response.token && !localStorage.getItem('token')) {
                console.log('Setting token in localStorage:', response.token.substring(0, 10) + '...');
                localStorage.setItem('token', response.token);
                
                // Store user ID if available
                if (response.user?.id) {
                    localStorage.setItem('userId', response.user.id);
                }
                
                // Store username if available
                if (response.user?.username) {
                    localStorage.setItem('username', response.user.username);
                }
                
                // Store admin status if available
                if (response.user?.isAdminUser) {
                    localStorage.setItem('isAdminUser', 'true');
                }
            }
            
            // Wait a brief moment to ensure token is stored before redirect
            setTimeout(() => {
                // Redirect based on user role
                console.log('Login successful, redirecting based on role:', {
                    isAdmin: response.user?.isAdmin,
                    isAdminUser: response.user?.isAdminUser,
                    user: response.user
                });
                
                // Special case for user ID 1 (admin)
                if (response.user?.id === '1' || response.user?.id === 1) {
                    console.log('Admin user detected (ID 1), routing to admin dashboard');
                    navigate('/admin');
                    return;
                }
                
                if (response.user?.isAdminUser) {
                    navigate('/admin');
                } else {
                    // Regular users go to Countertop instead of Dashboard
                    navigate('/countertop');
                }
            }, 100);
        } catch (error) {
            // Log the authentication error
            logError(ERROR_CATEGORIES.AUTH, 'Login failed', {
                email: formData.email,
                errorMessage: error.message,
                errorCode: error.code || 'UNKNOWN',
                errorType: error.type || 'AUTHENTICATION',
                timestamp: new Date().toISOString(),
                severity: 'medium',
                status: 'new',
                stack: error.stack
            });
            
            setError(error.message || 'Login failed');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="auth-container">
            <form 
                className="auth-form" 
                onSubmit={handleSubmit}
                autoComplete="on"
                name="loginForm"
            >
                <h2>Login</h2>
                
                {message.text && (
                    <div className={`message ${message.type}`}>
                        {message.text}
                    </div>
                )}

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        autoComplete="username"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="password-input-wrapper">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            autoComplete="current-password"
                            required
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="toggle-password-btn"
                        >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    <div className="forgot-password-container">
                        <Link to="/forgot-password" className="forgot-password-link">
                            Forgot Password?
                        </Link>
                    </div>
                </div>

                {error && <div className="error-message">{error}</div>}

                <button 
                    type="submit" 
                    disabled={isLoading}
                    className={`auth-button ${isLoading ? 'disabled' : ''}`}
                >
                    {isLoading ? 'Logging in...' : 'LOGIN'}
                </button>

                <p className="auth-link">
                    Don't have an account? <Link to="/register">Register</Link>
                </p>
            </form>
        </div>
    );
};

export default Login; 