import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import './Auth.css';
import { API_ENDPOINTS } from '../../config/api';

// Test site key for development - replace with your actual site key in production
const RECAPTCHA_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!recaptchaToken) {
      setMessage({ type: 'error', text: 'Please complete the reCAPTCHA verification' });
      return;
    }

    setIsLoading(true);
    setMessage({ type: '', text: '' });

    try {
      const response = await fetch(API_ENDPOINTS.requestPasswordReset, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          recaptchaToken
        })
      });

      const data = await response.json();

      if (response.ok) {
        setMessage({
          type: 'success',
          text: 'If an account exists with this email, you will receive password reset instructions.'
        });
        setEmail('');
      } else {
        setMessage({
          type: 'error',
          text: data.message || 'An error occurred. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setMessage({
        type: 'error',
        text: 'A network error occurred. Please check your connection and try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setMessage({ type: '', text: '' });
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleSubmit}>
        <h2>Reset Password</h2>
        
        {message.text && (
          <div className={`message ${message.type}`}>
            {message.text}
          </div>
        )}

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <div className="input-wrapper">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email address"
            />
          </div>
        </div>

        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          />
        </div>

        <button 
          type="submit" 
          className="auth-button"
          disabled={!email || isLoading || !recaptchaToken}
        >
          {isLoading ? 'Sending...' : 'Send Reset Link'}
        </button>

        <p className="auth-link">
          Remember your password? <a href="/login">Log in</a>
        </p>
      </form>
    </div>
  );
};

export default ForgotPassword; 