import React, { useState, useEffect } from 'react';
import './MenuItemForm.css';
import OptionSetsManager from './OptionComponents/OptionSetsManager';
import useAIDescription from './AIDescription';

const MenuItemEditForm = ({ item, categories, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    categories: [],
    image: null,
    image_url: '',
    icon_url: '',
    options: []
  });
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  
  // Setup AI description functionality
  useAIDescription((newDescription) => {
    setFormData(prev => ({
      ...prev,
      description: newDescription
    }));
  });
  
  // Load item data when component mounts
  useEffect(() => {
    if (item) {
      // Prepare categories array - handle different formats of category data
      let categoryIds = [];
      
      if (item.category_id) {
        categoryIds.push(item.category_id);
      }
      
      if (item.all_category_ids && Array.isArray(item.all_category_ids)) {
        categoryIds = [...item.all_category_ids];
      } else if (item.additional_category_ids) {
        // Parse additional categories from comma-separated string
        const additionalIds = item.additional_category_ids
          .split(',')
          .map(id => parseInt(id.trim()))
          .filter(id => !isNaN(id));
        
        categoryIds = [...new Set([...categoryIds, ...additionalIds])];
      }
      
      setFormData({
        id: item.id,
        name: item.name || '',
        description: item.description || '',
        price: item.price ? parseFloat(item.price).toFixed(2) : '',
        categories: categoryIds,
        image: null,
        image_url: item.image_url || '',
        icon_url: item.icon_url || '',
        options: item.options || []
      });
      
      if (item.image_url) {
        setPreviewUrl(item.image_url);
      }
    }
  }, [item]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'price') {
      // Only allow numbers and one decimal point
      const formatted = value.replace(/[^0-9.]/g, '');
      // Ensure only one decimal point
      const parts = formatted.split('.');
      const formattedPrice = parts.length > 2 ? parts[0] + '.' + parts[1] : formatted;
      // Limit to 2 decimal places
      if (parts.length > 1 && parts[1]?.length > 2) {
        const limitedPrice = `${parts[0]}.${parts[1].substring(0, 2)}`;
        setFormData(prev => ({ ...prev, [name]: limitedPrice }));
        return;
      }
      setFormData(prev => ({ ...prev, [name]: formattedPrice }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
    
    // Clear field error for this field
    setFieldErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleCategoryChange = (e) => {
    const categoryId = parseInt(e.target.value);
    
    setFormData(prev => {
      // If already selected, remove it, otherwise add it
      if (prev.categories.includes(categoryId)) {
        return {
          ...prev,
          categories: prev.categories.filter(id => id !== categoryId)
        };
      } else {
        return {
          ...prev,
          categories: [...prev.categories, categoryId]
        };
      }
    });
    
    // Clear category error
    setFieldErrors(prev => ({ ...prev, categories: '' }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, image: file }));
      
      // Create a preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateForm = () => {
    // Reset previous errors
    setError('');
    const newFieldErrors = {};
    let isValid = true;
    
    if (!formData.name.trim()) {
      setError('Item name is required');
      newFieldErrors.name = 'Item name is required';
      document.getElementById('name').focus();
      document.getElementById('name').classList.add('input-error');
      isValid = false;
    }
    
    if (!formData.price) {
      setError('Price is required');
      newFieldErrors.price = 'Price is required';
      document.getElementById('price').focus();
      document.getElementById('price').classList.add('input-error');
      isValid = false;
    }
    
    if (isNaN(parseFloat(formData.price))) {
      setError('Price must be a valid number');
      newFieldErrors.price = 'Price must be a valid number';
      document.getElementById('price').focus();
      document.getElementById('price').classList.add('input-error');
      isValid = false;
    }
    
    if (formData.categories.length === 0) {
      setError('Please select at least one category');
      newFieldErrors.categories = 'Please select at least one category';
      document.querySelector('.categories-select').classList.add('select-error');
      isValid = false;
    }
    
    setFieldErrors(newFieldErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const apiFormData = new FormData();
      apiFormData.append('id', formData.id);
      apiFormData.append('name', formData.name);
      apiFormData.append('description', formData.description);
      apiFormData.append('price', formData.price);
      
      console.log('Preparing to save menu item with options:', formData.options);
      
      // Ensure options is an array and sanitize it
      const optionsToSave = Array.isArray(formData.options) 
        ? formData.options.map(opt => {
            // Ensure each option has the required fields
            return {
              name: opt.name || '',
              description: opt.description || '',
              price: parseFloat(opt.price || 0).toFixed(2),
              is_default: !!opt.is_default,
              option_type: opt.option_type || 'add-on',
              option_group: opt.option_group || '',
              menu_item_id: formData.id
            };
          })
        : [];
      
      // Convert to JSON string
      const optionsJSON = JSON.stringify(optionsToSave);
      console.log('Options JSON to be sent:', optionsJSON);
      
      apiFormData.append('categories', JSON.stringify(formData.categories));
      apiFormData.append('options', optionsJSON);
      
      if (formData.image) {
        apiFormData.append('image', formData.image);
      }
      
      // For debugging, log what we're sending to the API
      console.log('Sending categories:', formData.categories);
      console.log('Sending options count:', optionsToSave.length);
      
      const token = localStorage.getItem('token');
      
      console.log('Making API request to update menu item');
      const response = await fetch('http://localhost:9000/api/menu_items_update.php', {
        method: 'POST',
        body: apiFormData,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
      });
      
      console.log('API response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('API Error Response:', errorText);
        throw new Error(`HTTP error! status: ${response.status} - ${errorText}`);
      }
      
      const data = await response.json();
      console.log('API Response data:', data);
      
      if (data.status === 'success') {
        setSuccess(true);
        setError('');
        if (onSave) {
          onSave(data.item);
        }
      } else {
        console.error('API Error:', data.message);
        setError(data.message || 'Failed to update menu item');
      }
    } catch (err) {
      console.error('Error updating menu item:', err);
      setError(`Network error: ${err.message}. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="menu-item-form-container">
      <h2>Edit Menu Item</h2>
      
      {error && <div className="form-error">{error}</div>}
      {success && <div className="form-success">Menu item updated successfully!</div>}
      
      <form onSubmit={handleSubmit} className="menu-item-form">
        <div className="form-section">
          <h3>Basic Information</h3>
          <div className="form-group">
            <label htmlFor="name">Item Name <span className="required-field">*</span></label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              onFocus={(e) => e.target.classList.remove('input-error')}
              required
              className={fieldErrors.name ? 'input-error' : ''}
            />
            <small className="field-help">Enter the name as it should appear on the menu</small>
            {fieldErrors.name && <div className="field-error">{fieldErrors.name}</div>}
          </div>
          
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows="4"
            />
            <small className="field-help">Provide a detailed description of the menu item</small>
            <button type="button" id="generate-description-btn" className="suggest-ai-btn">✨ Suggest with AI</button>
          </div>
          
          <div id="description-modal" className="modal">
            <div className="modal-header">
              <h3>Let's Make This Dish Stand Out</h3>
              <button type="button" className="close-btn" data-action="close">×</button>
            </div>
            <div className="modal-content">
              <div className="user-prompt-container">
                <label htmlFor="user-prompt">This is your current description. You can tweak it to help the AI generate better suggestions.</label>
                <textarea 
                  id="user-prompt" 
                  placeholder="Add specific details about this dish (ingredients, preparation, flavor profile, etc.) to help AI generate a better description..."
                  rows="3"
                ></textarea>
              </div>
              
              <div className="ai-result-container">
                <h4>AI Generated Description:</h4>
                <p id="suggested-description">Loading...</p>
                <textarea id="editable-suggestion" style={{display: 'none'}}></textarea>
              </div>
              
              <div className="modal-buttons">
                <button type="button" data-action="use">✅ Use This</button>
                <button type="button" data-action="edit">✏️ Edit & Use</button>
                <button type="button" data-action="regenerate">🔁 Regenerate</button>
                <button type="button" data-action="cancel">❌ Cancel</button>
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="price">Price <span className="required-field">*</span></label>
            <div className="price-input">
              <span className="price-symbol">$</span>
              <input
                type="text"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                onFocus={(e) => e.target.classList.remove('input-error')}
                required
                inputMode="decimal"
                placeholder="0.00"
                className={fieldErrors.price ? 'input-error' : ''}
              />
            </div>
            <small className="field-help">Enter the base price before any options</small>
            {fieldErrors.price && <div className="field-error">{fieldErrors.price}</div>}
          </div>
        </div>

        <div className="form-section">
          <h3>Categories</h3>
          <div className="form-group">
            <label>Menu Categories <span className="required-field">*</span></label>
            {formData.categories.length === 0 && (
              <div className="category-error-message">Please select at least one category</div>
            )}
            <div className={`categories-select ${fieldErrors.categories ? 'select-error' : ''}`}>
              {categories.map(category => (
                <label key={category.id} className="category-checkbox">
                  <input
                    type="checkbox"
                    value={category.id}
                    checked={formData.categories.includes(category.id)}
                    onChange={handleCategoryChange}
                  />
                  {category.name}
                </label>
              ))}
            </div>
            <small className="field-help">Select all categories where this item should appear</small>
            {fieldErrors.categories && <div className="field-error">{fieldErrors.categories}</div>}
          </div>
        </div>

        <div className="form-section">
          <h3>Images</h3>
          <div className="form-group">
            <label htmlFor="image">Upload Image</label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            />
            <small className="field-help">Upload a high-quality photo of the menu item</small>
            
            {previewUrl && (
              <div className="image-preview">
                <img src={previewUrl} alt="Menu item preview" />
              </div>
            )}
          </div>
          
          {/* Hidden input to maintain the image URL state */}
          <input
            type="hidden"
            id="image_url"
            name="image_url"
            value={formData.image_url}
          />
        </div>

        <div className="form-section">
          <h3>Item Options</h3>
          <div className="form-group">
            <label>Item Options and Customizations</label>
            <OptionSetsManager
              optionSets={formData.options}
              menuItemId={formData.id}
              onChange={(newOptions) => {
                console.log('OptionSetsManager onChange called with options:', newOptions);
                // Ensure menu_item_id is set on all options
                const optionsWithId = newOptions.map(opt => ({
                  ...opt,
                  menu_item_id: formData.id
                }));
                console.log('Setting options with menu_item_id:', optionsWithId);
                setFormData(prev => ({ ...prev, options: optionsWithId }));
              }}
            />
          </div>
        </div>

        <div className="button-container">
          <div className="buttons-wrapper">
            <button type="submit" className="save-button" disabled={loading}>
              {loading ? 'SAVING...' : 'SAVE CHANGES'}
            </button>
            <button type="button" className="cancel-button" onClick={onCancel}>
              CANCEL
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MenuItemEditForm;