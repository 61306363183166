/**
 * Central utility for logging errors across the application
 */

// Helper to get user info if available
const getUserInfoIfAvailable = () => {
  try {
    const userId = localStorage.getItem('userId');
    const username = localStorage.getItem('username');
    
    if (userId || username) {
      return { userId, username };
    }
    return null;
  } catch (e) {
    return null;
  }
};

/**
 * Log an error to the backend or console (in development)
 * @param {string} category - Error category (auth, menu, image-upload, etc.)
 * @param {string} message - Error message
 * @param {Object} details - Additional error details
 * @returns {Promise|void} - Promise from fetch request or void in development
 */
export const logError = (category, message, details = {}) => {
  const errorData = {
    timestamp: new Date().toISOString(),
    category,
    message,
    details,
    userInfo: getUserInfoIfAvailable(),
    browser: navigator.userAgent,
    url: window.location.href
  };
  
  // In development, log to console
  if (process.env.NODE_ENV === 'development') {
    console.error(`[${category}] ${message}`, details);
    
    // Also store in localStorage for dev testing
    const storedErrors = JSON.parse(localStorage.getItem('appErrors') || '[]');
    storedErrors.push(errorData);
    localStorage.setItem('appErrors', JSON.stringify(storedErrors.slice(-100))); // Keep last 100 errors
    return;
  }
  
  // In production, send to backend endpoint
  return fetch('/api/log/error', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(errorData)
  }).catch(err => console.error('Failed to log error:', err));
};

/**
 * Retrieve logged errors (from localStorage in development)
 * @param {string} category - Optional category filter
 * @param {number} limit - Maximum number of errors to retrieve
 * @returns {Array} - Array of error objects
 */
export const getLoggedErrors = (category = null, limit = 100) => {
  // In development, get from localStorage
  if (process.env.NODE_ENV === 'development') {
    const storedErrors = JSON.parse(localStorage.getItem('appErrors') || '[]');
    const filteredErrors = category 
      ? storedErrors.filter(error => error.category === category)
      : storedErrors;
    return filteredErrors.slice(-limit);
  }
  
  // In production, this would typically be handled by the backend API
  return [];
};

// Constants for error categories
export const ERROR_CATEGORIES = {
  AUTH: 'auth',
  IMAGE_UPLOAD: 'image-upload',
  MENU_CRUD: 'menu-crud',
  CATEGORY_CRUD: 'category-crud',
  ORDER_PROCESSING: 'order-processing',
  PAYMENT: 'payment',
  API: 'api',
  RENDERING: 'rendering',
  NAVIGATION: 'navigation',
  FORM: 'form'
}; 