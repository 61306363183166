import React from 'react';
import { Link } from 'react-router-dom';
import './Auth.css';

const VerifySuccess = () => {
  return (
    <div className="auth-container">
      <div className="auth-form">
        <h2>Email Verified!</h2>
        <div className="success-message">
          <p>Your email has been successfully verified.</p>
          <p>You can now log in to your account.</p>
        </div>
        <Link to="/login" className="auth-button">
          Go to Login
        </Link>
      </div>
    </div>
  );
};

export default VerifySuccess; 