import React, { useState, useEffect } from 'react';
import { Card, Select, Spin, Alert, Table } from 'antd';
import { AdminService } from '../../services/AdminService';

const OrderProcessing = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('24h');
    const [stats, setStats] = useState(null);

    useEffect(() => {
        fetchOrderStats();
    }, [timeRange]);

    const fetchOrderStats = async () => {
        try {
            setLoading(true);
            const data = await AdminService.getOrderStats(timeRange);
            setStats(data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch order statistics');
            console.error('Error fetching order stats:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error) {
        return <Alert type="error" message={error} />;
    }

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>Order Processing</h2>
                <Select
                    value={timeRange}
                    onChange={setTimeRange}
                    style={{ width: 120 }}
                >
                    <Select.Option value="24h">Last 24 Hours</Select.Option>
                    <Select.Option value="7d">Last 7 Days</Select.Option>
                    <Select.Option value="30d">Last 30 Days</Select.Option>
                </Select>
            </div>

            <div className="cards-grid">
                <Card title="Total Orders">
                    <h3>{stats?.totalOrders || 0}</h3>
                    <p>{stats?.ordersTrend}% from previous period</p>
                </Card>
                <Card title="Processing Time">
                    <h3>{stats?.avgProcessingTime || '0m'}</h3>
                    <p>Average processing time</p>
                </Card>
                <Card title="Success Rate">
                    <h3>{stats?.successRate || '0'}%</h3>
                    <p>Order completion rate</p>
                </Card>
                <Card title="Active Orders">
                    <h3>{stats?.activeOrders || 0}</h3>
                    <p>Currently processing</p>
                </Card>
            </div>

            {/* Order Status Table */}
            <Card title="Recent Orders" className="table-card">
                <Table 
                    columns={[
                        {
                            title: 'Order ID',
                            dataIndex: 'id',
                            key: 'id',
                        },
                        {
                            title: 'Customer',
                            dataIndex: 'customer',
                            key: 'customer',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                        },
                        {
                            title: 'Amount',
                            dataIndex: 'amount',
                            key: 'amount',
                        },
                        {
                            title: 'Time',
                            dataIndex: 'time',
                            key: 'time',
                        }
                    ]} 
                    dataSource={stats?.recentOrders || []}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
            </Card>
        </div>
    );
};

export default OrderProcessing; 