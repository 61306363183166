import React, { useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './AdminNavigation.css';

const AdminNavigation = ({ onLogout, onGoToBuilder, user, showDropdown, setShowDropdown, currentSection }) => {
    const location = useLocation();
    const navigate = useNavigate();
    
    // Determine if we're on the dashboard or admin path
    const isDashboardPath = location.pathname.startsWith('/dashboard');
    const isAdminPath = location.pathname.startsWith('/admin');
    
    // Get the base path for all navigation links
    const basePath = isDashboardPath ? '/dashboard' : '/admin';
    
    // Extract the current section from the URL
    const currentPath = location.pathname;
    
    const goToCountertop = () => {
        navigate('/countertop');
    };
    
    useEffect(() => {
        console.log("AdminNavigation - Current location:", currentPath, "Current section:", currentSection, "Base path:", basePath);
    }, [currentPath, currentSection, basePath]);

    return (
        <nav className="admin-navigation">
            <div className="nav-sections">
                <NavLink to={`${basePath}`} className={`nav-item ${currentSection === 'auth' ? 'active' : ''}`}>
                    <i className="fas fa-user-shield"></i>
                <span>Authentication</span>
            </NavLink>
                <NavLink to={`${basePath}/menu`} className={`nav-item ${currentSection === 'menu' ? 'active' : ''}`}>
                <i className="fas fa-utensils"></i>
                <span>Menu Management</span>
            </NavLink>
                <NavLink to={`${basePath}/orders`} className={`nav-item ${currentSection === 'orders' ? 'active' : ''}`}>
                    <i className="fas fa-clipboard-list"></i>
                <span>Order Processing</span>
            </NavLink>
                <NavLink to={`${basePath}/users`} className={`nav-item ${currentSection === 'users' ? 'active' : ''}`}>
                <i className="fas fa-users"></i>
                <span>User Activity</span>
            </NavLink>
                <NavLink to={`${basePath}/subscriptions`} className={`nav-item ${currentSection === 'subscriptions' ? 'active' : ''}`}>
                <i className="fas fa-credit-card"></i>
                <span>Subscriptions</span>
            </NavLink>
                <NavLink to={`${basePath}/system`} className={`nav-item ${currentSection === 'system' ? 'active' : ''}`}>
                <i className="fas fa-server"></i>
                <span>System Health</span>
            </NavLink>
                <NavLink to={`${basePath}/security`} className={`nav-item ${currentSection === 'security' ? 'active' : ''}`}>
                    <i className="fas fa-shield-alt"></i>
                <span>Security</span>
            </NavLink>
                <NavLink to={`${basePath}/business`} className={`nav-item ${currentSection === 'business' ? 'active' : ''}`}>
                <i className="fas fa-chart-line"></i>
                <span>Business Intelligence</span>
            </NavLink>
                <NavLink to={`${basePath}/errors`} className={`nav-item ${currentSection === 'errors' ? 'active' : ''}`}>
                <i className="fas fa-exclamation-triangle"></i>
                <span>Error Tracking</span>
            </NavLink>
                <NavLink to={`${basePath}/notifications`} className={`nav-item ${currentSection === 'notifications' ? 'active' : ''}`}>
                <i className="fas fa-bell"></i>
                <span>Notifications</span>
            </NavLink>
            </div>

            <div className="nav-actions">
                <button className="nav-button" onClick={onGoToBuilder}>
                    <i className="fas fa-tools"></i>
                    <span>Builder</span>
                </button>
                
                <button className="nav-button" onClick={goToCountertop}>
                    <i className="fas fa-store"></i>
                    <span>Countertop</span>
                </button>
                
                <button className="nav-button" onClick={onLogout}>
                        <i className="fas fa-sign-out-alt"></i>
                        <span>Logout</span>
                </button>
                
                <div className="user-info">
                    <span>{user?.username}</span>
                    <small>Admin</small>
                </div>
            </div>
        </nav>
    );
};

export default AdminNavigation; 