import React, { useState, useEffect } from 'react';
import './MenuItemDetail.css';

const MenuItemDetail = ({ 
  item, 
  onBack, 
  onAddToCart 
}) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [itemOptions, setItemOptions] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);

  // Fetch item options when component mounts
  useEffect(() => {
    if (item?.id) {
      fetchItemOptions(item.id);
    }
  }, [item]);

  // Initialize with base price when component mounts
  useEffect(() => {
    if (item?.price) {
      setTotalPrice(parseFloat(item.price) * quantity);
    }
  }, [item]);

  // Reset image state when item changes
  useEffect(() => {
    setImageLoaded(false);
    setImageError(false);
  }, [item]);

  // Calculate total price whenever quantity or selected options change
  useEffect(() => {
    if (!item) return;
    
    let basePrice = parseFloat(item.price) || 0;
    let optionsPrice = 0;
    
    // Calculate price from selected options
    Object.entries(selectedOptions).forEach(([groupId, options]) => {
      if (Array.isArray(options)) {
        // For checkboxes (multiple selections)
        options.forEach(optionId => {
          const option = itemOptions.find(opt => 
            opt.id === parseInt(optionId) && opt.option_group === groupId
          );
          if (option) {
            optionsPrice += parseFloat(option.price) || 0;
          }
        });
      } else if (options) {
        // For radio buttons (single selection)
        const option = itemOptions.find(opt => 
          opt.id === parseInt(options) && opt.option_group === groupId
        );
        if (option) {
          optionsPrice += parseFloat(option.price) || 0;
        }
      }
    });
    
    setTotalPrice((basePrice + optionsPrice) * quantity);
  }, [item, quantity, selectedOptions, itemOptions]);

  const fetchItemOptions = async (itemId) => {
    setLoading(true);
    setError(null);
    
    try {
      // Get auth token from localStorage if available
      const token = localStorage.getItem('token');
      
      // Use the same endpoint and approach as the edit page
      // This is the endpoint that successfully loads the options in edit mode
      const response = await fetch(`/api/menu_items.php?id=${itemId}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Authorization': token ? `Bearer ${token}` : ''
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch item options');
      }
      
      const data = await response.json();
      
      // Check if response has the data we need
      if (data.status === 'success' && data.data) {
        const itemData = data.data;
        
        // Check if the item has options
        if (itemData.options && Array.isArray(itemData.options)) {
          console.log('Options loaded successfully:', itemData.options);
          setItemOptions(itemData.options);
          
          // Initialize selected options with defaults
          const defaultOptions = {};
          itemData.options.forEach(option => {
            if (option.is_default) {
              const groupKey = option.option_group || 'Default';
              
              if (option.option_type === 'choice') {
                defaultOptions[groupKey] = option.id.toString();
              } else if (option.option_type === 'add-on') {
                if (!defaultOptions[groupKey]) {
                  defaultOptions[groupKey] = [];
                }
                defaultOptions[groupKey].push(option.id.toString());
              }
            }
          });
          
          setSelectedOptions(defaultOptions);
        } else {
          // No options found for this item
          console.log('No options found for this item');
          setItemOptions([]);
        }
      } else {
        // Item not found or no data
        console.error('Item data not found in response:', data);
        setError('Could not load item options');
        setItemOptions([]);
      }
    } catch (error) {
      console.error('Error fetching item options:', error);
      setError('Could not load item options');
      setItemOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (groupId, optionId, isMultiple) => {
    setSelectedOptions(prev => {
      const newOptions = { ...prev };
      
      if (isMultiple) {
        // Handle checkboxes (multiple selections)
        if (!newOptions[groupId]) {
          newOptions[groupId] = [];
        }
        
        if (Array.isArray(newOptions[groupId])) {
          if (newOptions[groupId].includes(optionId)) {
            // Remove if already selected
            newOptions[groupId] = newOptions[groupId].filter(id => id !== optionId);
          } else {
            // Add if not selected
            newOptions[groupId] = [...newOptions[groupId], optionId];
          }
        }
      } else {
        // Handle radio buttons (single selection)
        newOptions[groupId] = optionId;
      }
      
      return newOptions;
    });
  };

  const isOptionSelected = (groupId, optionId, isMultiple) => {
    if (!selectedOptions[groupId]) return false;
    
    if (isMultiple) {
      return Array.isArray(selectedOptions[groupId]) && 
        selectedOptions[groupId].includes(optionId);
    } else {
      return selectedOptions[groupId] === optionId;
    }
  };

  const handleQuantityChange = (delta) => {
    setQuantity(prev => {
      const newQty = prev + delta;
      return newQty > 0 ? newQty : 1;
    });
  };

  const handleAddToCart = () => {
    if (!item) return;
    
    const cartItem = {
      id: item.id,
      name: item.name,
      price: item.price,
      image_url: item.image_url,
      quantity,
      options: selectedOptions,
      special_instructions: specialInstructions,
      total_price: totalPrice
    };
    
    onAddToCart(cartItem);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    setImageError(false);
  };

  const handleImageError = () => {
    setImageLoaded(false);
    setImageError(true);
  };

  const toggleSpecialInstructions = () => {
    setShowSpecialInstructions(prev => !prev);
  };

  // Group options by their option_group
  const groupedOptions = itemOptions.reduce((groups, option) => {
    const group = option.option_group || 'Default';
    if (!groups[group]) {
      groups[group] = {
        name: group,
        type: option.option_type,
        options: []
      };
    }
    groups[group].options.push(option);
    return groups;
  }, {});

  if (!item) {
    return <div className="menu-item-detail">No item selected</div>;
  }

  return (
    <div className="menu-item-detail">
      <div className="menu-item-detail-image">
        {item.image_url && !imageError ? (
          <img 
            src={item.image_url} 
            alt={item.name}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        ) : (
          <div className="no-image">No Image Available</div>
        )}
        <button className="back-button image-back-button" onClick={onBack}>←</button>
      </div>
      
      <div className="menu-item-detail-info">
        <h2 className="menu-item-detail-name">{item.name}</h2>
        <div className="menu-item-detail-price">${parseFloat(item.price).toFixed(2)}</div>
        <p className="menu-item-detail-description">{item.description || 'No description available.'}</p>
      </div>
      
      {loading ? (
        <div className="loading">Loading options...</div>
      ) : (
        <>
          {Object.keys(groupedOptions).length > 0 ? (
            <div className="menu-item-options">
              {Object.values(groupedOptions).map((group) => (
                <div key={group.name} className="option-group">
                  <h3 className="option-group-title">{group.name}</h3>
                  <div className="option-items-grid">
                    {group.options.map((option) => {
                      const isMultiple = group.type === 'add-on';
                      const inputType = isMultiple ? 'checkbox' : 'radio';
                      const optionId = option.id.toString();
                      const isSelected = isOptionSelected(group.name, optionId, isMultiple);
                      
                      return (
                        <div 
                          key={option.id} 
                          className={`option-item ${isSelected ? 'selected' : ''}`}
                          onClick={() => handleOptionChange(group.name, optionId, isMultiple)}
                        >
                          <input
                            type={inputType}
                            id={`option-${option.id}`}
                            name={group.name}
                            checked={isSelected}
                            onChange={() => {}} // Handled by the div click
                            onClick={(e) => e.stopPropagation()} // Prevent double firing
                          />
                          <label 
                            htmlFor={`option-${option.id}`}
                            className="option-item-label"
                          >
                            <span className="option-item-name">{option.name}</span>
                            {parseFloat(option.price) > 0 && (
                              <span className="option-item-price">
                                +${parseFloat(option.price).toFixed(2)}
                              </span>
                            )}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          ) : error ? (
            <div className="options-error">{error}</div>
          ) : null}
          
          <div style={{padding: '0 15px'}}>
            <button 
              onClick={toggleSpecialInstructions}
              style={{
                background: 'none',
                border: 'none',
                color: '#2a9d8f',
                padding: '8px 0',
                fontSize: '0.9rem',
                fontWeight: '600',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span style={{marginRight: '5px'}}>
                {showSpecialInstructions ? '−' : '+'}
              </span>
              Add special instructions
            </button>
          </div>
          
          {showSpecialInstructions && (
            <div className="special-instructions">
              <textarea
                id="special-instructions"
                value={specialInstructions}
                onChange={(e) => setSpecialInstructions(e.target.value)}
                placeholder="Add notes (e.g., allergies, special preparation)"
              />
            </div>
          )}
          
          <div className="total-price">
            <div className="total-price-label">Total</div>
            <div className="total-price-value">${totalPrice.toFixed(2)}</div>
          </div>
          
          <div className="cart-controls">
            <div className="quantity-controls">
              <button 
                className="quantity-button"
                onClick={() => handleQuantityChange(-1)}
                disabled={quantity <= 1}
              >-</button>
              <span className="quantity-display">{quantity}</span>
              <button 
                className="quantity-button"
                onClick={() => handleQuantityChange(1)}
              >+</button>
            </div>
            <button 
              className="add-to-cart-button"
              onClick={handleAddToCart}
            >Add to Cart</button>
          </div>
        </>
      )}
    </div>
  );
};

export default MenuItemDetail; 