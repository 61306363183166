import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import './Messages.css';

const Messages = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [sending, setSending] = useState(false);
  const [filter, setFilter] = useState('all');
  const [search, setSearch] = useState('');

  // Sample data for demonstration
  const sampleMessages = [
    {
      id: 1,
      customer: {
        name: 'Sarah Johnson',
        email: 'sarah.j@example.com',
        phone: '555-123-4567'
      },
      order: {
        id: 'ORD-2023-1234',
        date: '2023-04-15T14:30:00Z',
        total: 42.75
      },
      subject: 'Order feedback',
      message: 'The food was delicious but arrived a bit cold. Everything else was perfect though!',
      rating: 4,
      status: 'unread',
      createdAt: '2023-04-15T16:45:00Z',
      replies: []
    },
    {
      id: 2,
      customer: {
        name: 'Mike Peterson',
        email: 'mike.p@example.com',
        phone: '555-987-6543'
      },
      order: {
        id: 'ORD-2023-1198',
        date: '2023-04-10T18:15:00Z',
        total: 35.50
      },
      subject: 'Missing item in order',
      message: 'I ordered a side of fries but they weren\'t included in my delivery. Could I get a refund for that item?',
      rating: 3,
      status: 'unread',
      createdAt: '2023-04-10T19:30:00Z',
      replies: []
    },
    {
      id: 3,
      customer: {
        name: 'Emily Chen',
        email: 'emily.c@example.com',
        phone: '555-444-3333'
      },
      order: {
        id: 'ORD-2023-1122',
        date: '2023-04-05T12:45:00Z',
        total: 27.90
      },
      subject: 'Compliment to the chef',
      message: 'Just wanted to say that your new seasonal menu is fantastic! The vegetarian options are really creative and delicious.',
      rating: 5,
      status: 'read',
      createdAt: '2023-04-05T14:20:00Z',
      replies: [
        {
          id: 1,
          from: 'business',
          message: 'Thank you so much for your kind words! We\'re glad you enjoyed our new vegetarian options. We\'ll pass your compliments to our chef!',
          createdAt: '2023-04-05T16:30:00Z'
        }
      ]
    }
  ];

  useEffect(() => {
    // In real implementation, fetch messages from API
    // Simulating API call
    setTimeout(() => {
      setMessages(sampleMessages);
      setLoading(false);
    }, 800);
  }, []);

  const handleSelectMessage = (message) => {
    // Mark as read if unread
    if (message.status === 'unread') {
      const updatedMessages = messages.map(msg => 
        msg.id === message.id ? { ...msg, status: 'read' } : msg
      );
      setMessages(updatedMessages);
    }
    
    setSelectedMessage(message);
    setReplyText('');
  };

  const handleReplyChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleSendReply = async () => {
    if (!replyText.trim()) return;
    
    setSending(true);
    
    try {
      // In real implementation, send reply to API
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const newReply = {
        id: Date.now(),
        from: 'business',
        message: replyText,
        createdAt: new Date().toISOString()
      };
      
      // Update messages with new reply
      const updatedMessages = messages.map(msg => 
        msg.id === selectedMessage.id 
          ? { 
              ...msg, 
              replies: [...msg.replies, newReply],
              status: 'resolved'
            } 
          : msg
      );
      
      setMessages(updatedMessages);
      
      // Update selected message
      setSelectedMessage({
        ...selectedMessage,
        replies: [...selectedMessage.replies, newReply],
        status: 'resolved'
      });
      
      setReplyText('');
    } catch (error) {
      console.error('Error sending reply:', error);
    } finally {
      setSending(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredMessages = messages.filter(message => {
    // Filter by status
    if (filter === 'unread' && message.status !== 'unread') return false;
    if (filter === 'resolved' && message.status !== 'resolved') return false;
    
    // Filter by search term
    if (search) {
      const searchLower = search.toLowerCase();
      return (
        message.customer.name.toLowerCase().includes(searchLower) ||
        message.subject.toLowerCase().includes(searchLower) ||
        message.message.toLowerCase().includes(searchLower)
      );
    }
    
    return true;
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading messages...</p>
      </div>
    );
  }

  return (
    <div className="messages-container">
      <div className="messages-header">
        <h2>Messages & Reviews</h2>
        <p className="section-description">
          Manage customer feedback, reviews, and messages.
        </p>
      </div>
      
      <div className="messages-controls">
        <div className="filters">
          <select value={filter} onChange={handleFilterChange}>
            <option value="all">All Messages</option>
            <option value="unread">Unread</option>
            <option value="resolved">Resolved</option>
          </select>
        </div>
        <div className="search">
          <input
            type="text"
            placeholder="Search messages..."
            value={search}
            onChange={handleSearchChange}
          />
          <i className="fas fa-search"></i>
        </div>
      </div>
      
      <div className="messages-content">
        <div className="messages-list">
          {filteredMessages.length === 0 ? (
            <div className="no-messages">
              <i className="fas fa-inbox"></i>
              <p>No messages found</p>
            </div>
          ) : (
            filteredMessages.map(message => (
              <div 
                key={message.id} 
                className={`message-item ${message.status} ${selectedMessage?.id === message.id ? 'selected' : ''}`}
                onClick={() => handleSelectMessage(message)}
              >
                <div className="message-item-header">
                  <div className="customer-name">{message.customer.name}</div>
                  <div className="message-date">{formatDate(message.createdAt)}</div>
                </div>
                <div className="message-item-subject">{message.subject}</div>
                <div className="message-item-preview">
                  {message.message.substring(0, 60)}
                  {message.message.length > 60 ? '...' : ''}
                </div>
                <div className="message-item-footer">
                  <div className="message-rating">
                    {[...Array(5)].map((_, i) => (
                      <i 
                        key={i} 
                        className={`fas fa-star ${i < message.rating ? 'filled' : ''}`}
                      />
                    ))}
                  </div>
                  <div className="message-status">
                    {message.status === 'unread' ? (
                      <span className="status-badge unread">New</span>
                    ) : message.status === 'resolved' ? (
                      <span className="status-badge resolved">Resolved</span>
                    ) : (
                      <span className="status-badge read">Read</span>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        
        <div className="message-detail">
          {selectedMessage ? (
            <>
              <div className="detail-header">
                <h3>{selectedMessage.subject}</h3>
                <div className="detail-meta">
                  <div className="customer-info">
                    <strong>From:</strong> {selectedMessage.customer.name} ({selectedMessage.customer.email})
                  </div>
                  <div className="order-info">
                    <strong>Order:</strong> {selectedMessage.order.id}
                  </div>
                  <div className="date-info">
                    <strong>Date:</strong> {formatDate(selectedMessage.createdAt)}
                  </div>
                </div>
              </div>
              
              <div className="message-thread">
                <div className="message-bubble customer">
                  <div className="message-content">{selectedMessage.message}</div>
                  <div className="message-time">{formatDate(selectedMessage.createdAt)}</div>
                </div>
                
                {selectedMessage.replies.map(reply => (
                  <div key={reply.id} className="message-bubble business">
                    <div className="message-content">{reply.message}</div>
                    <div className="message-time">{formatDate(reply.createdAt)}</div>
                  </div>
                ))}
              </div>
              
              <div className="reply-form">
                <textarea
                  placeholder="Type your reply..."
                  value={replyText}
                  onChange={handleReplyChange}
                ></textarea>
                <button 
                  className="btn btn-primary"
                  onClick={handleSendReply}
                  disabled={!replyText.trim() || sending}
                >
                  {sending ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> Sending...
                    </>
                  ) : (
                    <>
                      <i className="fas fa-paper-plane"></i> Send Reply
                    </>
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className="no-message-selected">
              <i className="fas fa-comment-alt"></i>
              <p>Select a message to view details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages; 