import React, { useState, useEffect } from 'react';
import { AdminService } from '../../services/AdminService';
import { Card, Select, Spin, Alert, Table } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const MenuMonitoring = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('24h');
    const [stats, setStats] = useState(null);

    useEffect(() => {
        fetchMenuStats();
    }, [timeRange]);

    const fetchMenuStats = async () => {
        try {
            setLoading(true);
            const data = await AdminService.getMenuStats(timeRange);
            setStats(data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch menu statistics');
            console.error('Error fetching menu stats:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error) {
        return <Alert type="error" message={error} />;
    }

    const columns = [
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            key: 'business_name',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Items Count',
            dataIndex: 'items_count',
            key: 'items_count',
            sorter: (a, b) => a.items_count - b.items_count,
        },
        {
            title: 'Last Updated',
            dataIndex: 'last_updated',
            key: 'last_updated',
            sorter: (a, b) => new Date(a.last_updated) - new Date(b.last_updated),
        }
    ];

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>Menu Monitoring</h2>
                <Select
                    value={timeRange}
                    onChange={setTimeRange}
                    style={{ width: 120 }}
                >
                    <Select.Option value="24h">Last 24 Hours</Select.Option>
                    <Select.Option value="7d">Last 7 Days</Select.Option>
                    <Select.Option value="30d">Last 30 Days</Select.Option>
                </Select>
            </div>

            <div className="cards-grid">
                <Card title="Total Menu Items">
                    <h3>{stats?.totalItems || 0}</h3>
                    <p>{stats?.itemsTrend}% from previous period</p>
                </Card>
                <Card title="Active Categories">
                    <h3>{stats?.activeCategories || 0}</h3>
                    <p>{stats?.categoriesTrend}% from previous period</p>
                </Card>
                <Card title="Menu Updates">
                    <h3>{stats?.updates || 0}</h3>
                    <p>In the last {timeRange}</p>
                </Card>
                <Card title="Average Items per Business">
                    <h3>{stats?.avgItemsPerBusiness || 0}</h3>
                </Card>
            </div>

            {stats?.updateTrend && (
                <Card title="Menu Update Trend" className="chart-card">
                    <LineChart
                        width={800}
                        height={300}
                        data={stats.updateTrend}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line 
                            type="monotone" 
                            dataKey="updates" 
                            stroke="#8884d8" 
                            name="Menu Updates" 
                        />
                    </LineChart>
                </Card>
            )}

            <Card title="Business Menu Statistics" className="table-card">
                <Table 
                    columns={columns} 
                    dataSource={stats?.businessStats || []} 
                    rowKey="business_id"
                    pagination={{ pageSize: 10 }}
                />
            </Card>
        </div>
    );
};

export default MenuMonitoring; 