import React, { useEffect, useState } from 'react';
import Cart from '../Cart/Cart';
import { useAuth } from '../../contexts/AuthContext';
import './PhoneFrame.css';

const PhoneFrame = ({ content }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { isAuthenticated } = useAuth();

  // Get current time for the phone status bar
  const getTime = () => {
    const now = new Date();
    return now.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  // Handle cart open state
  useEffect(() => {
    const phoneFrame = document.querySelector('.phone-frame');
    const handleCartStateChange = (e) => {
      if (e.detail && e.detail.isCartOpen !== undefined) {
        if (e.detail.isCartOpen) {
          phoneFrame?.classList.add('cart-open');
          setIsCartOpen(true);
        } else {
          phoneFrame?.classList.remove('cart-open');
          setIsCartOpen(false);
        }
      }
    };

    window.addEventListener('cartStateChange', handleCartStateChange);
    return () => window.removeEventListener('cartStateChange', handleCartStateChange);
  }, []);

  // Force cart button to be visible
  useEffect(() => {
    console.log("PhoneFrame mounted - ensuring cart button is visible");
  }, []);

  return (
    <div className={`phone-frame ${isCartOpen ? 'cart-open' : ''}`}>
      <div className="phone-notch">
        <div className="notch-content"></div>
      </div>
      <div className="status-bar">
        <div className="status-left">{getTime()}</div>
        <div className="status-right">
          <i className="fas fa-signal"></i>
          <i className="fas fa-wifi"></i>
          <i className="fas fa-battery-full"></i>
        </div>
      </div>
      <div className="phone-content">
        {content || (
          <div className="placeholder-content">
            <i className="fas fa-utensils"></i>
            <p>Your menu will appear here</p>
          </div>
        )}
        
        {/* Always render cart component directly in phone frame */}
        <div className="phone-frame-cart-container">
          <Cart 
            isOpen={isCartOpen} 
            onClose={() => setIsCartOpen(!isCartOpen)} 
            isAuthenticated={isAuthenticated}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneFrame; 