import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import './SiteSettings.css';
import { getApiUrl, getAuthHeaders } from '../../../utils/apiUtils';

const SiteSettings = () => {
  const { user } = useAuth();
  const modalRef = useRef(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    siteEnabled: false,
    businessPath: '',
    siteName: '',
    siteDescription: '',
    logo: null,
    logoPreview: null,
    hostOnVps: false
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [pathExists, setPathExists] = useState(false);
  const [pathChecked, setPathChecked] = useState(false);
  const [isCheckingPath, setIsCheckingPath] = useState(false);
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);
  const [aiPrompt, setAiPrompt] = useState('');
  const [aiResult, setAiResult] = useState('');
  const [initialBusinessPath, setInitialBusinessPath] = useState('');

  useEffect(() => {
    // Fetch real business data from the API
    const fetchBusinessData = async () => {
      try {
        setLoading(true);
        
        // Use getApiUrl for proper URL construction based on environment
        const apiUrl = getApiUrl('business-settings');
        console.log('Fetching business settings from:', apiUrl);
        
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch business settings: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Business data loaded:', data);
        
        // Map API data to form fields
        setFormData({
          siteEnabled: data.website ? true : false,
          businessPath: data.business_name || '',
          siteName: data.site_name || '',
          siteDescription: data.description || '',
          logo: null,
          logoPreview: data.logo_url || null,
          hostOnVps: data.host_on_vps ? true : false
        });
        setInitialBusinessPath(data.business_name || '');
      } catch (error) {
        console.error('Error loading business data:', error);
        
        // Set default values if no data available
        setFormData({
          siteEnabled: false,
          businessPath: '',
          siteName: user?.username ? `${user.username}'s Food Spot` : 'My Food Spot',
          siteDescription: 'Delicious food made with passion',
          logo: null,
          logoPreview: null,
          hostOnVps: false
        });
        setInitialBusinessPath('');
      } finally {
        setLoading(false);
      }
    };
    
    fetchBusinessData();
  }, [user]);

  // Setup AI Description Modal
  useEffect(() => {
    console.log("Setting up AI modal event handlers");
    
    const openAIModal = () => {
      console.log("Opening AI modal");
      if (modalRef.current) {
        modalRef.current.style.display = 'block';
        
        // Use existing site description as the starting point if it exists
        // and isn't just the default text
        if (formData.siteDescription && 
            formData.siteDescription !== 'Delicious food made with passion' && 
            formData.siteDescription.trim().length > 0) {
          console.log("Using existing description as prompt:", formData.siteDescription);
          setAiPrompt(formData.siteDescription);
        } else {
          setAiPrompt('');
        }
        
        setAiResult('');
      }
    };

    const closeAIModal = () => {
      console.log("Closing AI modal");
      if (modalRef.current) {
        modalRef.current.style.display = 'none';
        setIsGeneratingAI(false);
      }
    };

    const generateAIHandler = async () => {
      console.log("Generate AI button clicked");
      await generateAIDescription();
    };

    const useAIHandler = () => {
      console.log("Use AI button clicked");
      useAIResult();
    };
    
    // Add draggable functionality to the modal
    const makeDraggable = () => {
      const modal = document.querySelector('#ai-description-modal .modal-content');
      const header = document.querySelector('.modal-header');
      
      if (!modal || !header) {
        console.log("Modal or header not found for draggable");
        return;
      }
      
      let isDragging = false;
      let startX, startY;
      let offsetX, offsetY;
      
      const startDrag = (e) => {
        // Only drag from header
        if (e.target.closest('.modal-header')) {
          e.preventDefault();
          isDragging = true;
          
          // Calculate cursor offset from modal position
          const rect = modal.getBoundingClientRect();
          offsetX = e.clientX - rect.left;
          offsetY = e.clientY - rect.top;
          
          // Save starting position
          startX = e.clientX;
          startY = e.clientY;
          
          console.log("Starting drag", { offsetX, offsetY, startX, startY });
          
          // Prevent text selection during drag
          document.body.style.userSelect = 'none';
        }
      };
      
      const onDrag = (e) => {
        if (!isDragging) return;
        
        // Calculate new position
        const x = e.clientX - offsetX;
        const y = e.clientY - offsetY;
        
        // Update position with transform instead of left/top
        modal.style.transform = `translate(${x}px, ${y}px)`;
        
        console.log("Dragging", { x, y });
      };
      
      const endDrag = () => {
        if (isDragging) {
          console.log("End drag");
          isDragging = false;
          document.body.style.userSelect = '';
        }
      };
      
      header.addEventListener('mousedown', startDrag);
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', endDrag);
      
      // Clean up function
      return () => {
        header.removeEventListener('mousedown', startDrag);
        document.removeEventListener('mousemove', onDrag);
        document.removeEventListener('mouseup', endDrag);
      };
    };

    // Wait for the DOM to be fully rendered
    setTimeout(() => {
      // Add event handlers for buttons
      const generateButton = document.getElementById('ai-generate-button');
      const closeButton = document.getElementById('ai-modal-close');
      const useButton = document.getElementById('ai-use-button');
      const cancelButton = document.getElementById('ai-cancel-button');
      const generateDescButton = document.getElementById('generate-desc-button');

      console.log("Found buttons:", { 
        generateButton: !!generateButton, 
        closeButton: !!closeButton,
        useButton: !!useButton,
        cancelButton: !!cancelButton,
        generateDescButton: !!generateDescButton
      });

      if (generateButton) {
        generateButton.addEventListener('click', generateAIHandler);
      }

      if (closeButton) {
        closeButton.addEventListener('click', closeAIModal);
      }

      if (useButton) {
        useButton.addEventListener('click', useAIHandler);
      }

      if (cancelButton) {
        cancelButton.addEventListener('click', closeAIModal);
      }

      if (generateDescButton) {
        generateDescButton.addEventListener('click', openAIModal);
      }
      
      // Initialize draggable functionality
      const cleanupDraggable = makeDraggable();
      
      return cleanupDraggable;
    }, 500);

    return () => {
      // Clean up event listeners
      const generateButton = document.getElementById('ai-generate-button');
      const closeButton = document.getElementById('ai-modal-close');
      const useButton = document.getElementById('ai-use-button');
      const cancelButton = document.getElementById('ai-cancel-button');
      const generateDescButton = document.getElementById('generate-desc-button');

      if (generateButton) {
        generateButton.removeEventListener('click', generateAIHandler);
      }
      
      if (closeButton) {
        closeButton.removeEventListener('click', closeAIModal);
      }
      
      if (useButton) {
        useButton.removeEventListener('click', useAIHandler);
      }
      
      if (cancelButton) {
        cancelButton.removeEventListener('click', closeAIModal);
      }
      
      if (generateDescButton) {
        generateDescButton.removeEventListener('click', openAIModal);
      }
    };
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: val
    }));
  };

  const handleAIPromptChange = (e) => {
    setAiPrompt(e.target.value);
  };

  const handleAIResultChange = (e) => {
    setAiResult(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Logo file selected:", file.name, file.size, file.type);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          logo: file,
          logoPreview: reader.result
        }));
        console.log("Logo preview generated");
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Stop event propagation
    console.log("Triggering file input click");
    if (fileInputRef.current) {
      try {
        // Direct click on the input element
        fileInputRef.current.click();
      } catch (error) {
        console.error("Error clicking file input:", error);
      }
    } else {
      console.error("File input reference is null");
    }
  };

  // Add direct file input handler
  const handleFileBrowse = () => {
    console.log("Browse button clicked directly");
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    
    // Add file size limit validation
    fileInput.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        // Check file size (limit to 2MB)
        if (file.size > 2 * 1024 * 1024) {
          setErrorMessage('Logo image is too large. Maximum size is 2MB.');
          return;
        }
        
        // Check file type
        if (!file.type.match('image.*')) {
          setErrorMessage('Please select an image file (JPG, PNG, GIF, etc).');
          return;
        }
        
        handleImageChange(e);
      }
    };
    
    fileInput.click();
  };

  const handleRemoveLogo = () => {
    setFormData(prev => ({
      ...prev,
      logo: null,
      logoPreview: null
    }));
  };

  const handleBusinessPathChange = async (e) => {
    const businessPath = e.target.value.toLowerCase();
    setFormData(prev => ({
      ...prev,
      businessPath,
      pathChecked: false,
      pathExists: false,
    }));

    if (businessPath) {
      setIsCheckingPath(true);
      try {
        // Use getApiUrl for consistent URL handling
        const apiUrl = getApiUrl('check-path');
        const response = await fetch(`${apiUrl}?path=${businessPath}`);
        const data = await response.json();
        setPathExists(data.exists);
        setPathChecked(true);
      } catch (error) {
        console.error('Error checking path:', error);
      } finally {
        setIsCheckingPath(false);
      }
    }
  };

  const useAIResult = () => {
    if (aiResult) {
      setFormData(prev => ({
        ...prev,
        siteDescription: aiResult
      }));
      setSuccessMessage('AI-generated description applied!');
      setTimeout(() => setSuccessMessage(''), 3000);
      
      // Close the modal
      if (modalRef.current) {
        modalRef.current.style.display = 'none';
      }
      setIsGeneratingAI(false);
    }
  };

  const generateAIDescription = async () => {
    console.log("Starting AI description generation");
    setIsGeneratingAI(true);
    setErrorMessage('');
    setAiResult('');
    
    try {
      // Get the business name to use as part of the context, with a more specific default
      const businessName = formData.siteName || formData.businessPath || 'my restaurant';
      
      // Clean user input - remove any "Welcome to food business" phrases
      let cleanPrompt = aiPrompt || `Create a description for ${businessName} highlighting our unique qualities and specialties`;
      cleanPrompt = cleanPrompt.replace(/welcome to food business,?\s*/gi, '');
      
      console.log("User business description:", cleanPrompt);
      
      // Use getApiUrl utility for consistent URL handling across environments
      const apiUrl = getApiUrl('ai-generate');
      console.log(`Sending AI request to ${apiUrl} for business "${businessName}"`);
      
      // Make a request to the AI generation endpoint with user's business description as priority
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          prompt: `Generate a complete, SEO-friendly description for a restaurant or food business named "${businessName}". 
          THE USER HAS PROVIDED THIS SPECIFIC INFO ABOUT THEIR BUSINESS: "${cleanPrompt}".
          Use this information as the primary source for the description.
          Create a unique, personalized description that captures the essence of THIS specific business.
          Avoid generic phrases like "Welcome to [business name]" or "We are pleased to serve you".
          The description should highlight their specific offerings mentioned above, while incorporating quality food, excellent service, and unique characteristics.
          IMPORTANT: DO NOT include "Welcome to food business" anywhere in the description.`,
          type: 'description',
          max_length: 300
        })
      });
      
      console.log("Response status:", response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response:", errorText);
        throw new Error(`Failed to generate description: ${response.status} ${errorText}`);
      }
      
      const data = await response.json();
      console.log("AI response data:", data);
      
      if (data.description) {
        setAiResult(data.description);
      } else if (data.success && data.description) {
        setAiResult(data.description);
      } else {
        throw new Error('No description was generated in the response');
      }
    } catch (error) {
      console.error('Error generating description:', error);
      setErrorMessage('Failed to generate description: ' + error.message);
      setAiResult('An error occurred while generating the description. Please try again.');
    } finally {
      setIsGeneratingAI(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset messages
    setErrorMessage('');
    setSuccessMessage('');
    
    // Validation
    if (formData.businessPath && !pathChecked) {
      setErrorMessage('Please wait for the path check to complete.');
      return;
    }
    
    if (formData.businessPath && pathExists) {
      setErrorMessage('This URL path is already taken. Please choose another.');
      return;
    }
    
    try {
      setSaving(true);
      
      // Use the utility function to get the proper API URL for the current environment
      const apiUrl = getApiUrl('save-settings');
      console.log("Using API URL for saving:", apiUrl);
      
      // Create FormData for file upload instead of JSON
      const formDataToSend = new FormData();
      formDataToSend.append('businessPath', formData.businessPath);
      formDataToSend.append('business_name', formData.businessPath);
      formDataToSend.append('siteEnabled', formData.siteEnabled ? '1' : '0');
      formDataToSend.append('siteName', formData.siteName);
      formDataToSend.append('site_name', formData.siteName);
      formDataToSend.append('siteDescription', formData.siteDescription);
      formDataToSend.append('description', formData.siteDescription);
      formDataToSend.append('hostOnVps', formData.hostOnVps ? '1' : '0');
      
      // Handle logo upload
      if (formData.logo) {
        console.log("Adding logo file to form data:", formData.logo.name, formData.logo.size, formData.logo.type);
        formDataToSend.append('logo', formData.logo);
      } else if (formData.logoPreview && formData.logoPreview.startsWith('/images/')) {
        // If we have an existing logo URL, pass that
        console.log("Using existing logo path:", formData.logoPreview);
        formDataToSend.append('logoUrl', formData.logoPreview);
      }
      
      console.log("Sending form data to:", apiUrl);
      
      // Send the form data to the server using FormData instead of JSON
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
          // No Content-Type header - the browser will set it with the boundary for multipart/form-data
        },
        body: formDataToSend
      });
      
      let result;
      try {
        const text = await response.text();
        result = text ? JSON.parse(text) : {};
        console.log("Settings save response:", result);
      } catch (parseError) {
        console.error("Error parsing response:", parseError);
        throw new Error("Server returned an invalid response");
      }
      
      if (response.ok) {
        setSuccessMessage('Settings saved successfully');
        
        // Check if we're changing path and need to force a reload
        if (formData.businessPath !== initialBusinessPath && formData.businessPath !== '') {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      } else {
        throw new Error(result.message || 'Failed to save settings');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      setErrorMessage('Failed to save settings: ' + error.message);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading site settings...</p>
      </div>
    );
  }

  return (
    <div className="site-settings-container">
      {/* AI Description Modal (DOM-based, not React state-based) */}
      <div id="ai-description-modal" ref={modalRef} style={{ display: 'none' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h3>Let Us Help Describe Your Business</h3>
            <button 
              id="ai-modal-close" 
              className="modal-close"
              type="button"
              onClick={() => {
                console.log("Close button clicked directly");
                modalRef.current.style.display = 'none';
                setIsGeneratingAI(false);
              }}
            >×</button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="aiPrompt">Tell us about your business</label>
              <textarea
                id="aiPrompt"
                value={aiPrompt}
                onChange={handleAIPromptChange}
                placeholder="Example: We're an authentic Italian restaurant specializing in traditional pasta dishes, wood-fired pizza, and regional specialties from Tuscany. We use imported Italian ingredients and family recipes passed down for generations. Our casual dining atmosphere is perfect for families."
                rows={4}
                style={{ color: '#333', backgroundColor: '#fff' }}
              />
            </div>
            
            <div className="ai-generate-container">
              <button 
                id="ai-generate-button"
                className="btn btn-primary ai-generate-btn"
                disabled={isGeneratingAI}
                type="button"
                style={{ backgroundColor: '#8a2be2', color: 'white' }}
                onClick={async (e) => {
                  e.preventDefault();
                  console.log("Generate button clicked directly");
                  await generateAIDescription();
                }}
              >
                {isGeneratingAI ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> Writing...
                  </>
                ) : (
                  <>
                    <i className="fas fa-magic"></i> Write
                  </>
                )}
              </button>
            </div>
            
            <div className="form-group">
              <label htmlFor="aiResult">Your Custom Business Description</label>
              <textarea
                id="aiResult"
                value={aiResult}
                onChange={handleAIResultChange}
                className={aiResult ? 'has-content' : ''}
                placeholder="Your customized business description will appear here..."
                rows={8}
                style={{ color: '#333', backgroundColor: '#fff' }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button 
              id="ai-cancel-button" 
              className="btn btn-outline"
              type="button"
              style={{ backgroundColor: '#f8f9fa', color: '#333', border: '1px solid #ddd' }}
              onClick={() => {
                console.log("Cancel button clicked directly");
                modalRef.current.style.display = 'none';
                setIsGeneratingAI(false);
              }}
            >
              Cancel
            </button>
            <button 
              id="ai-use-button"
              className="btn btn-primary"
              type="button"
              disabled={!aiResult}
              style={{ backgroundColor: '#2a9d8f', color: 'white' }}
              onClick={() => {
                console.log("Use button clicked directly");
                if (aiResult) {
                  setFormData(prev => ({
                    ...prev,
                    siteDescription: aiResult
                  }));
                  setSuccessMessage('AI-generated description applied!');
                  setTimeout(() => setSuccessMessage(''), 3000);
                  
                  // Close the modal
                  modalRef.current.style.display = 'none';
                  setIsGeneratingAI(false);
                }
              }}
            >
              Use
            </button>
          </div>
        </div>
      </div>
      
      <div className="section-card">
        <h2>Site Settings</h2>
        <p className="section-description">
          Configure your GoodeEats website appearance, domain, and features.
        </p>
        
        {errorMessage && (
          <div className="alert alert-error">
            <i className="fas fa-exclamation-circle"></i> {errorMessage}
          </div>
        )}
        
        {successMessage && (
          <div className="alert alert-success">
            <i className="fas fa-check-circle"></i> {successMessage}
          </div>
        )}
        
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <h3>Site Visibility</h3>
            
            <div className="form-group switch-group">
              <label className="switch-label">
                Enable My Website
                <div className="switch">
                  <input
                    type="checkbox"
                    name="siteEnabled"
                    checked={formData.siteEnabled}
                    onChange={handleChange}
                  />
                  <span className="slider"></span>
                </div>
              </label>
              <p className="help-text">
                When enabled, your site is live and accessible to customers. 
                When disabled, a "Coming Soon" page is shown.
              </p>
            </div>
          </div>
          
          <div className="form-section">
            <h3>Business URL Path</h3>
            
            <div className="url-path-container">
              <div className="input-group">
                <div className="url-prefix">goodeeats.com/</div>
                <input 
                  type="text" 
                  id="businessPath" 
                  name="businessPath"
                  className={`form-control ${pathExists ? 'is-invalid' : pathChecked ? 'is-valid' : ''}`}
                  value={formData.businessPath || ''}
                  onChange={handleBusinessPathChange}
                  placeholder="your-business-name"
                />
                {isCheckingPath && <div className="checking-indicator"><i className="fas fa-spinner fa-spin"></i></div>}
              </div>
              
              {pathExists && (
                <div className="text-danger mt-1">
                  <i className="fas fa-exclamation-triangle"></i> This URL path is already taken. Please choose another.
                </div>
              )}
              
              {pathChecked && !pathExists && (
                <div className="text-success mt-1">
                  <i className="fas fa-check-circle"></i> This URL path is available.
                </div>
              )}
              
              <p className="help-text">
                This will be your business URL on GoodeEats. It should be short, memorable, and related to your business name.
              </p>
              <p className="help-text">
                Example: goodeeats.com/your-business-name
              </p>
            </div>
          </div>
          
          <div className="form-section">
            <h3>Hosting Options</h3>
            
            <div className="form-group">
              <label className="switch-label">
                Host on GoodeEats VPS
                <div className="switch">
                  <input
                    type="checkbox"
                    name="hostOnVps"
                    checked={formData.hostOnVps}
                    onChange={handleChange}
                  />
                  <span className="slider"></span>
                </div>
              </label>
              <p className="help-text">
                Enable to host your website directly on our server. This allows your business to run seamlessly from our platform.
              </p>
            </div>
            
            {formData.hostOnVps && (
              <div className="hosting-details">
                <div className="form-group">
                  <p><i className="fas fa-info-circle"></i> Your website will be hosted on our GoDaddy VPS at no additional cost.</p>
                  <p className="benefits-list">Benefits include:</p>
                  <ul>
                    <li>Fast loading times</li>
                    <li>Automatic maintenance and updates</li>
                    <li>Full integration with GoodeEats platform</li>
                    <li>Simplified management through a single dashboard</li>
                    <li>Shared SSL certificate</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          
          <div className="form-section">
            <h3>Site Appearance</h3>
            
            <div className="form-group">
              <label htmlFor="siteName">Site Name</label>
              <input
                type="text"
                id="siteName"
                name="siteName"
                value={formData.siteName}
                onChange={handleChange}
                placeholder="My Restaurant"
                required
              />
            </div>
            
            <div className="form-group">
              <div className="description-header">
                <label htmlFor="siteDescription">Site Description</label>
                <button 
                  type="button" 
                  id="generate-desc-button"
                  className="btn-link ai-link"
                  onClick={() => {
                    console.log("Generate with AI button clicked directly");
                    if (modalRef.current) {
                      modalRef.current.style.display = 'block';
                      
                      // Use existing site description as the starting point if it exists
                      // and isn't just the default text
                      if (formData.siteDescription && 
                          formData.siteDescription !== 'Delicious food made with passion' && 
                          formData.siteDescription.trim().length > 0) {
                        console.log("Using existing description as prompt:", formData.siteDescription);
                        setAiPrompt(formData.siteDescription);
                      } else {
                        setAiPrompt('');
                      }
                      
                      setAiResult('');
                    }
                  }}
                >
                  <i className="fas fa-magic"></i> Let Us Help Write This
                </button>
              </div>
              <textarea
                id="siteDescription"
                name="siteDescription"
                value={formData.siteDescription}
                onChange={handleChange}
                placeholder="Brief description of your business"
                rows={3}
              />
              <p className="help-text">
                This description appears in search results and helps customers find you.
              </p>
            </div>
            
            <div className="form-group">
              <label>Logo</label>
              <div className="logo-upload">
                {formData.logoPreview ? (
                  <div className="logo-preview">
                    <img src={formData.logoPreview} alt="Logo preview" />
                    <button
                      type="button"
                      className="remove-logo"
                      onClick={handleRemoveLogo}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                ) : (
                  <div className="upload-placeholder">
                    <i className="fas fa-cloud-upload-alt"></i>
                    <span>Upload your logo</span>
                    <p>Click to browse your files</p>
                    <button 
                      type="button" 
                      className="browse-button"
                      onClick={handleFileBrowse}
                    >
                      Browse Files
                    </button>
                    <input
                      type="file"
                      id="logo"
                      name="logo"
                      ref={fileInputRef}
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                    />
                  </div>
                )}
              </div>
              <p className="help-text">
                Recommended logo dimensions should be square or slightly rectangular.
              </p>
            </div>
          </div>
          
          <div className="form-actions">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={saving}
            >
              {saving ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Saving...
                </>
              ) : 'Save Settings'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SiteSettings; 