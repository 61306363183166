import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { getApiUrl, getAuthHeaders, fetchWithAuth } from '../../../utils/apiUtils';
import './AccountProfile.css';

const AccountProfile = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [profileData, setProfileData] = useState({
    id: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    bio: '',
    dateOfBirth: '',
    profileImage: '',
    created_at: '',
    lastLogin: '',
    accountStatus: '',
    verified: false,
    emailVerified: false
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const response = await fetchWithAuth(getApiUrl('direct_user_update'), {
        method: 'GET'
      });

      if (!response.ok) {
        console.error('API Error response:', response.status, response.statusText);
        throw new Error(`Failed to fetch profile: ${response.status}`);
      }

      const data = await response.json();
      console.log('User profile loaded:', data);
      
      const safeData = {
        id: data.id || '',
        username: data.username || '',
        email: data.email || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        phone: data.phone || '',
        street: data.street || '',
        city: data.city || '',
        state: data.state || '',
        zipCode: data.zipCode || '',
        country: data.country || '',
        bio: data.bio || '',
        dateOfBirth: data.dateOfBirth || '',
        profileImage: data.profileImage || '',
        created_at: data.created_at || '',
        lastLogin: data.lastLogin || '',
        accountStatus: data.accountStatus || 'Active',
        verified: Boolean(data.verified),
        emailVerified: Boolean(data.emailVerified)
      };
      
      setProfileData(safeData);
    } catch (error) {
      console.error('Error loading profile:', error);
      setErrorMessage(`Error loading profile: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset messages
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      setSaving(true);
      console.log('Sending profile data:', profileData);
      
      // Use our fetchWithAuth utility for consistent auth
      const response = await fetchWithAuth(getApiUrl('direct_user_update'), {
        method: 'PUT',
        body: JSON.stringify(profileData)
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to update profile: ${response.status}`);
      }
      
      const updatedData = await response.json();
      setProfileData(updatedData);
      setSuccessMessage('Profile updated successfully!');
      setIsEditing(false);
      
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage(`Error updating profile: ${error.message}`);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading account profile...</p>
      </div>
    );
  }

  return (
    <div className="account-security-container">
      <div className="section-card">
        <h2>My Account Profile</h2>
        <p className="section-description">
          View and update your profile information.
        </p>
        
        {errorMessage && (
          <div className="alert alert-error">
            <i className="fas fa-exclamation-circle"></i> {errorMessage}
          </div>
        )}
        
        {successMessage && (
          <div className="alert alert-success">
            <i className="fas fa-check-circle"></i> {successMessage}
          </div>
        )}
        
        <div className="profile-header">
          <div className="profile-image">
            {profileData.profileImage ? (
              <img src={profileData.profileImage} alt={`${profileData.firstName} ${profileData.lastName}`} />
            ) : (
              <div className="profile-placeholder">
                <i className="fas fa-user"></i>
              </div>
            )}
          </div>
          <div className="profile-info">
            <h3>{profileData.firstName} {profileData.lastName}</h3>
            <p className="username">@{profileData.username}</p>
            <p className="account-status">Status: {profileData.accountStatus || 'Active'}</p>
            <p className="joined-date">
              Member since: {profileData.created_at ? new Date(profileData.created_at).toLocaleDateString() : 'N/A'}
            </p>
            {profileData.lastLogin && (
              <p className="last-login">
                Last login: {new Date(profileData.lastLogin).toLocaleDateString()}
              </p>
            )}
            <div className="profile-actions">
              {!isEditing && (
                <button 
                  className="btn btn-secondary" 
                  onClick={() => setIsEditing(true)}
                >
                  Edit Profile
                </button>
              )}
            </div>
          </div>
        </div>
        
        <div className="form-section">
          <h3>{isEditing ? 'Edit Profile Information' : 'Profile Information'}</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={profileData.firstName}
                  onChange={handleChange}
                  required
                  disabled={!isEditing}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={profileData.lastName}
                  onChange={handleChange}
                  required
                  disabled={!isEditing}
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profileData.email}
                  onChange={handleChange}
                  required
                  disabled={!isEditing}
                />
                <p className="verification-status">
                  {profileData.emailVerified ? 
                    <span className="verified"><i className="fas fa-check-circle"></i> Verified</span> : 
                    <span className="unverified"><i className="fas fa-times-circle"></i> Not Verified</span>
                  }
                </p>
              </div>
              
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={profileData.phone}
                  onChange={handleChange}
                  placeholder="(555) 555-5555"
                  disabled={!isEditing}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <input
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                value={profileData.dateOfBirth}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="bio">Bio</label>
              <textarea
                id="bio"
                name="bio"
                rows="3"
                value={profileData.bio || ''}
                onChange={handleChange}
                placeholder="Tell us about yourself"
                disabled={!isEditing}
              ></textarea>
            </div>
            
            <h3>Address Information</h3>
            
            <div className="form-group">
              <label htmlFor="street">Street Address</label>
              <input
                type="text"
                id="street"
                name="street"
                value={profileData.street || ''}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={profileData.city || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={profileData.state || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="zipCode">Zip Code</label>
                <input
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  value={profileData.zipCode || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={profileData.country || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </div>
            </div>
            
            {isEditing && (
              <div className="form-actions">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => setIsEditing(false)}
                  disabled={saving}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={saving}
                >
                  {saving ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> Saving...
                    </>
                  ) : 'Save Profile'}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountProfile; 