import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import API_ENDPOINTS from '../config/api';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Configure axios defaults and check for token in cookies or localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  // Update isAuthenticated whenever user changes
  useEffect(() => {
    setIsAuthenticated(!!user);
  }, [user]);

  const checkAuthStatus = async () => {
    console.log('AuthContext: Checking auth status...');
    try {
      // Check both localStorage and cookies for tokens
      const localToken = localStorage.getItem('token');
      const cookieToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('authToken='))
        ?.split('=')[1];
      
      // Use any token that exists
      const token = localToken || cookieToken;
      
      const username = localStorage.getItem('username') || 
                       document.cookie.split('; ').find(row => row.startsWith('username='))?.split('=')[1];
      const userId = localStorage.getItem('userId') || 
                     document.cookie.split('; ').find(row => row.startsWith('userId='))?.split('=')[1];
      
      console.log('AuthContext: Auth data:', {
        hasLocalToken: !!localToken,
        hasCookieToken: !!cookieToken,
        hasUsername: !!username,
        hasUserId: !!userId,
        token: token ? token.substring(0, 10) + '...' : null
      });

      if (!token) {
        console.log('AuthContext: No token found, setting user to null');
        setUser(null);
        setLoading(false);
        return;
      }

      // If we have a token but no user info in localStorage, try to fetch it
      if (!username || !userId) {
        try {
          const response = await axios.get('/api/auth.php?action=me', {
            headers: { 'Authorization': `Bearer ${token}` },
            withCredentials: true // Important for sending cookies
          });
          
          if (response.data.success) {
            const userData = response.data.user;
            localStorage.setItem('userId', userData.id);
            localStorage.setItem('username', userData.username);
            
            // Store if the user is an admin user (in admin_users table)
            const isAdminUser = userData.isAdminUser || false;
            console.log('AuthContext: Setting admin status from API response:', { 
              isAdmin: userData.isAdmin,
              isAdminUser: isAdminUser
            });
            localStorage.setItem('isAdminUser', isAdminUser ? 'true' : 'false');
            
            setUser({
              id: userData.id,
              username: userData.username,
              isVerified: true,
              isAdmin: userData.isAdmin,
              isAdminUser: isAdminUser
            });
          }
        } catch (err) {
          console.error('Failed to fetch user data:', err);
          setUser(null);
        }
        setLoading(false);
        return;
      }

      // Check if we have stored admin status
      const isAdmin = localStorage.getItem('isAdmin') === 'true';
      const isAdminUser = localStorage.getItem('isAdminUser') === 'true';
      
      console.log('AuthContext: Admin status from localStorage:', { isAdmin, isAdminUser });
      
      // Create user object from localStorage data
      console.log('AuthContext: Creating user from stored data');
      const newUser = {
        id: userId,
        username: username,
        isVerified: true,
        isAdmin: isAdmin,
        isAdminUser: isAdminUser
      };
      
      setUser(newUser);
      
      // Configure axios defaults for future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      axios.defaults.withCredentials = true; // Always send cookies with requests
      console.log('AuthContext: Set auth header with token');
      
    } catch (err) {
      console.error('AuthContext: Error in checkAuthStatus:', err);
      setUser(null);
      setError(err.message);
    } finally {
      console.log('AuthContext: Auth check complete, setting loading to false');
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const login = async (credentials) => {
    try {
      console.log("AuthContext: login called with credentials:", {
        email: credentials.email,
        passwordProvided: !!credentials.password,
        passwordLength: credentials.password?.length || 0
      });
      
      // Log what we're about to send
      console.log("AuthContext: Sending POST request to:", API_ENDPOINTS.login);
      console.log("AuthContext: With headers:", { 'Content-Type': 'application/json' });
      
      const response = await axios.post(API_ENDPOINTS.login, credentials, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      
      console.log("AuthContext: Received response:", response.status, response.statusText);
      console.log("AuthContext: Response data:", response.data);
      
      const data = response.data;
      
      // If login successful, set user and token
      if (data.success || data.status === 'success') {
        // Ensure we have a token
        if (data.token) {
          localStorage.setItem('token', data.token);
          // Set cookie as backup authentication method
          document.cookie = `authToken=${data.token}; path=/; max-age=${7 * 24 * 60 * 60}; SameSite=Lax`;
          
          // Configure axios for future requests
          axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
        }
        
        // Store user data
        if (data.user) {
          localStorage.setItem('userId', data.user.id);
          localStorage.setItem('username', data.user.username);
          localStorage.setItem('isAdmin', data.user.isAdmin ? 'true' : 'false');
          localStorage.setItem('isAdminUser', data.user.isAdminUser ? 'true' : 'false');
          localStorage.setItem('user', JSON.stringify(data.user));
          
          // Handle special case for user ID 1 (admin)
          if (data.user.id === '1' || data.user.id === 1) {
            localStorage.setItem('isAdminUser', 'true');
          }
          
          // Set user state
          setUser(data.user);
          setIsAuthenticated(true);
        }
      }
      
      return data;
    } catch (error) {
      console.error('Login error:', error);
      return {
        success: false,
        message: 'Failed to log in. Please try again later.'
      };
    }
  };
  
  const loginWithToken = async (token, username, userId = null) => {
    console.log('AuthContext: Logging in with token', { 
      token: token ? token.substring(0, 10) + '...' : null, 
      username, 
      userId 
    });
    
    if (!token) {
      console.error('Token is required for loginWithToken');
      return false;
    }
    
    try {
      // Store auth data in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      if (userId) localStorage.setItem('userId', userId);
      
      // Also set cookie for backup authentication
      document.cookie = `authToken=${token}; path=/; max-age=${7 * 24 * 60 * 60}; SameSite=Lax`;
      
      // Set up axios defaults
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Handle admin user (ID 1) special case
      if (userId === '1' || userId === 1) {
        localStorage.setItem('isAdminUser', 'true');
      }
      
      // Create user object
      const userData = {
        id: userId,
        username: username,
        isVerified: true,
        isAdmin: userId === '1' || userId === 1,
        isAdminUser: userId === '1' || userId === 1 || localStorage.getItem('isAdminUser') === 'true'
      };
      
      // Update user state which will trigger isAuthenticated update
      setUser(userData);
      console.log('AuthContext: User set after token login', userData);
      
      return true;
    } catch (error) {
      console.error('AuthContext: Error in loginWithToken:', error);
      return false;
    }
  };

  const logout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        // Send logout request to the server
        await axios.post(`${API_ENDPOINTS.logout}`, {}, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
      }
    } catch (err) {
      console.error('Logout error:', err);
    } finally {
      // Clear all auth data from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      
      // Clear user state
      setUser(null);
      setIsAuthenticated(false);
      
      // Remove Authorization header
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  const value = {
    user,
    loading,
    error,
    isAuthenticated,
    login,
    logout,
    loginWithToken,
    checkAuthStatus
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Configure axios defaults
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
} 