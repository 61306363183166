import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './Countertop.css';

// Import sections (will create these next)
import Dashboard from './sections/Dashboard';
import SiteSettings from './sections/SiteSettings';
import Payments from './sections/Payments';
import AccountSecurity from './sections/AccountSecurity';
import AccountProfile from './sections/AccountProfile';
import BusinessInfo from './sections/BusinessInfo';
import Messages from './sections/Messages';
import Reports from './sections/Reports';
import Orders from './sections/Orders';
import Notifications from './sections/Notifications';
import TeamAccess from './sections/TeamAccess';

const Countertop = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    // Could fetch any user-specific data here if needed
    document.title = 'Countertop - GoodeEats';
  }, []);

  const goToBuilder = () => {
    navigate('/builder');
  };

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const navItems = [
    { id: 'dashboard', title: 'Dashboard', icon: 'fa-tachometer-alt', path: '/countertop' },
    { id: 'profile', title: 'My Profile', icon: 'fa-user', path: '/countertop/settings/profile' },
    { id: 'builder', title: 'Menu Builder', icon: 'fa-utensils', path: '/builder' },
    { id: 'site', title: 'Site Settings', icon: 'fa-globe', path: '/countertop/settings/site' },
    { id: 'payments', title: 'Payments', icon: 'fa-credit-card', path: '/countertop/settings/payments' },
    { id: 'account', title: 'Account & Security', icon: 'fa-user-shield', path: '/countertop/settings/account' },
    { id: 'business', title: 'Business Info', icon: 'fa-store', path: '/countertop/settings/business' },
    { id: 'messages', title: 'Messages & Reviews', icon: 'fa-comment-alt', path: '/countertop/messages' },
    { id: 'reports', title: 'Reports', icon: 'fa-chart-bar', path: '/countertop/reports' },
    { id: 'orders', title: 'Orders', icon: 'fa-shopping-cart', path: '/countertop/orders' },
    { id: 'notifications', title: 'Notifications', icon: 'fa-bell', path: '/countertop/notifications' },
    { id: 'team', title: 'Team Access', icon: 'fa-users', path: '/countertop/settings/team' }
  ];

  if (loading) {
    return (
      <div className="countertop-loading">
        <div className="spinner"></div>
        <p>Loading countertop...</p>
      </div>
    );
  }

  return (
    <div className="countertop">
      <header className="countertop-header">
        <div className="brand">
          <button 
            className="sidebar-toggle" 
            onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          >
            <i className={`fas ${sidebarCollapsed ? 'fa-bars' : 'fa-times'}`}></i>
          </button>
          <h1>GoodeEats</h1>
        </div>
        <div className="user-menu">
          <div className="user-info">
            <span>{user?.username}</span>
            <small>ID: {user?.id}</small>
          </div>
          <button className="logout-button" onClick={handleLogout}>
            <i className="fas fa-sign-out-alt"></i> Logout
          </button>
        </div>
      </header>
      
      <div className="countertop-content">
        <nav className={`countertop-sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
          <div className="sidebar-header">
            <h3>Business Tools</h3>
          </div>
          <ul className="sidebar-menu">
            {navItems.map(item => (
              <li key={item.id}>
                <Link 
                  to={item.path} 
                  className={`sidebar-item ${activeSection === item.id ? 'active' : ''}`}
                  onClick={() => setActiveSection(item.id)}
                >
                  <i className={`fas ${item.icon}`}></i>
                  <span className="sidebar-text">{item.title}</span>
                </Link>
              </li>
            ))}
            {user?.isAdminUser && (
              <li>
                <Link 
                  to="/dashboard" 
                  className="sidebar-item"
                >
                  <i className="fas fa-tools"></i>
                  <span className="sidebar-text">Admin Dashboard</span>
                </Link>
              </li>
            )}
          </ul>
        </nav>
        
        <main className={`countertop-main ${sidebarCollapsed ? 'expanded' : ''}`}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/settings/site" element={<SiteSettings />} />
            <Route path="/settings/payments" element={<Payments />} />
            <Route path="/settings/account" element={<AccountSecurity />} />
            <Route path="/settings/profile" element={<AccountProfile />} />
            <Route path="/settings/business" element={<BusinessInfo />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/settings/team" element={<TeamAccess />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Countertop; 