import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import './Dashboard.css';

const Dashboard = () => {
  const { user } = useAuth();
  const [stats, setStats] = useState({
    orders: 0,
    revenue: 0,
    customers: 0,
    messages: 0
  });

  useEffect(() => {
    // In a real implementation, you'd fetch this from an API
    // Simulating data for demonstration
    const fetchStats = async () => {
      // This would be replaced with actual API calls
      setStats({
        orders: 142,
        revenue: 2850.75,
        customers: 98,
        messages: 5
      });
    };

    fetchStats();
  }, []);

  return (
    <div className="dashboard-container">
      <section className="welcome-section">
        <div className="section-card">
          <h2>Welcome to GoodeEats</h2>
          <p>
            GoodeEats is an AI-driven system that helps small restaurants and food trucks 
            streamline their entire operation, from menu creation to automated marketing 
            and personalized customer engagement.
          </p>
          <p>
            Use the navigation menu to build your menu, customize your site, manage orders,
            and grow your business.
          </p>
        </div>
      </section>

      <section className="stats-overview">
        <div className="stats-grid">
          <div className="stat-card">
            <div className="stat-icon">
              <i className="fas fa-shopping-cart"></i>
            </div>
            <div className="stat-info">
              <h3>Orders</h3>
              <p className="stat-value">{stats.orders}</p>
            </div>
          </div>
          <div className="stat-card">
            <div className="stat-icon">
              <i className="fas fa-dollar-sign"></i>
            </div>
            <div className="stat-info">
              <h3>Revenue</h3>
              <p className="stat-value">${stats.revenue.toFixed(2)}</p>
            </div>
          </div>
          <div className="stat-card">
            <div className="stat-icon">
              <i className="fas fa-users"></i>
            </div>
            <div className="stat-info">
              <h3>Customers</h3>
              <p className="stat-value">{stats.customers}</p>
            </div>
          </div>
          <div className="stat-card">
            <div className="stat-icon">
              <i className="fas fa-comment"></i>
            </div>
            <div className="stat-info">
              <h3>Messages</h3>
              <p className="stat-value">{stats.messages}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="quick-actions">
        <div className="section-card">
          <h2>Quick Actions</h2>
          <div className="actions-grid">
            <Link to="/builder" className="action-card">
              <div className="action-icon">
                <i className="fas fa-utensils"></i>
              </div>
              <div className="action-info">
                <h3>Menu Builder</h3>
                <p>Create or edit your menu</p>
              </div>
            </Link>
            <Link to="/countertop/orders" className="action-card">
              <div className="action-icon">
                <i className="fas fa-shopping-cart"></i>
              </div>
              <div className="action-info">
                <h3>Orders</h3>
                <p>Manage customer orders</p>
              </div>
            </Link>
            <Link to="/countertop/settings/site" className="action-card">
              <div className="action-icon">
                <i className="fas fa-globe"></i>
              </div>
              <div className="action-info">
                <h3>Site Settings</h3>
                <p>Configure your website</p>
              </div>
            </Link>
            <Link to="/countertop/settings/payments" className="action-card">
              <div className="action-icon">
                <i className="fas fa-credit-card"></i>
              </div>
              <div className="action-info">
                <h3>Payment Settings</h3>
                <p>Setup payment methods</p>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <section className="getting-started">
        <div className="section-card">
          <h2>Getting Started</h2>
          <div className="steps-container">
            <div className="step">
              <div className="step-number">1</div>
              <div className="step-content">
                <h3>Create Your Menu</h3>
                <p>Build your menu with the Menu Builder. Add items, categorize them, and set prices.</p>
                <Link to="/builder" className="btn btn-outline">
                  Go to Menu Builder
                </Link>
              </div>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <div className="step-content">
                <h3>Setup Your Site</h3>
                <p>Configure your site settings including domain, appearance, and business info.</p>
                <Link to="/countertop/settings/site" className="btn btn-outline">
                  Configure Site
                </Link>
              </div>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <div className="step-content">
                <h3>Connect Payments</h3>
                <p>Set up payment processing with Stripe, PayPal, or PayArc.</p>
                <Link to="/countertop/settings/payments" className="btn btn-outline">
                  Setup Payments
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard; 