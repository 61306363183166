import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import './Payments.css';

const Payments = () => {
  // eslint-disable-next-line no-unused-vars
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState('stripe');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  // Payment processors settings
  const [stripeSettings, setStripeSettings] = useState({
    enabled: false,
    accountConnected: false,
    accountId: '',
    publishableKey: '',
    secretKey: '',
    testMode: true
  });
  
  const [paypalSettings, setPaypalSettings] = useState({
    enabled: false,
    accountConnected: false,
    clientId: '',
    clientSecret: '',
    testMode: true
  });
  
  const [payarcSettings, setPayarcSettings] = useState({
    enabled: false,
    accountConnected: false,
    merchantId: '',
    apiKey: '',
    secretKey: '',
    callbackUrl: '',
    testMode: true
  });
  
  // Fee information for each processor
  const processorFees = {
    stripe: {
      percentage: 3.4, // 2.9% + 0.5% markup
      fixed: 0.30,
      note: 'Includes GoodeEats markup: 0.5%'
    },
    paypal: {
      percentage: 3.99, // 3.49% + 0.5% markup
      fixed: 0.49,
      note: 'Includes GoodeEats markup: 0.5%'
    },
    payarc: {
      percentage: 3.19, // 2.69% + 0.5% markup
      fixed: 0.25,
      note: 'Includes GoodeEats markup: 0.5%'
    }
  };

  useEffect(() => {
    // In real implementation, fetch data from an API
    // Simulating API call
    const loadSettings = async () => {
      try {
        setLoading(true);
        
        // Load PayArc settings
        try {
          const payarcResponse = await fetch('http://localhost:9000/api/payarc/settings.php', {
            method: 'GET',
            credentials: 'include'
          });
          
          const payarcData = await payarcResponse.json();
          
          if (payarcData.status === 'success' && payarcData.data) {
            // Convert form field names to match state
            const settings = {
              enabled: payarcData.data.enabled || false,
              merchantId: payarcData.data.merchant_id || '',
              apiKey: payarcData.data.api_key || '',
              secretKey: payarcData.data.secret_key || '',
              callbackUrl: payarcData.data.callback_url || '',
              testMode: payarcData.data.test_mode || true,
              accountConnected: payarcData.data.account_connected || false
            };
            
            setPayarcSettings(settings);
          }
        } catch (error) {
          console.error('Error loading PayArc settings:', error);
        }
        
        // You would load Stripe and PayPal settings in a similar way
        
      } catch (error) {
        console.error('Error loading payment settings:', error);
      } finally {
        setLoading(false);
      }
    };
    
    loadSettings();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleToggleProcessor = async (processor) => {
    // Reset all other processors first
    if (processor === 'stripe') {
      setStripeSettings(prev => ({ ...prev, enabled: !prev.enabled }));
      if (!stripeSettings.enabled) {
        setPaypalSettings(prev => ({ ...prev, enabled: false }));
        setPayarcSettings(prev => ({ ...prev, enabled: false }));
      }
    } else if (processor === 'paypal') {
      setPaypalSettings(prev => ({ ...prev, enabled: !prev.enabled }));
      if (!paypalSettings.enabled) {
        setStripeSettings(prev => ({ ...prev, enabled: false }));
        setPayarcSettings(prev => ({ ...prev, enabled: false }));
      }
    } else if (processor === 'payarc') {
      const newEnabled = !payarcSettings.enabled;
      setPayarcSettings(prev => ({ ...prev, enabled: newEnabled }));
      if (!payarcSettings.enabled) {
        setStripeSettings(prev => ({ ...prev, enabled: false }));
        setPaypalSettings(prev => ({ ...prev, enabled: false }));
      }
      
      // Auto-save PayArc toggle state
      if (payarcSettings.accountConnected) {
        try {
          setSaving(true);
          const endpoint = 'http://localhost:9000/api/payarc/settings.php';
          // Format data to match backend expectations
          const data = {
            enabled: newEnabled ? 1 : 0,
            merchant_id: payarcSettings.merchantId,
            api_key: payarcSettings.apiKey,
            secret_key: payarcSettings.secretKey,
            callback_url: payarcSettings.callbackUrl,
            test_mode: payarcSettings.testMode ? 1 : 0,
            account_connected: payarcSettings.accountConnected ? 1 : 0
          };
          
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include'
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          
          const result = await response.json();
          
          if (result.status === 'success') {
            setSuccessMessage('PayArc ' + (newEnabled ? 'enabled' : 'disabled') + ' successfully');
            setTimeout(() => setSuccessMessage(''), 3000);
          } else {
            throw new Error(result.message || 'Failed to save settings');
          }
        } catch (error) {
          setErrorMessage(`Error updating PayArc: ${error.message}`);
          console.error('Error saving PayArc settings:', error);
          // Revert the UI state if the save failed
          setPayarcSettings(prev => ({ ...prev, enabled: !newEnabled }));
        } finally {
          setSaving(false);
        }
      }
    }
  };

  const handleStripeChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    
    setStripeSettings(prev => ({
      ...prev,
      [name]: val
    }));
  };

  const handlePayPalChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    
    setPaypalSettings(prev => ({
      ...prev,
      [name]: val
    }));
  };

  const handlePayArcChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    
    // Update state immediately
    setPayarcSettings(prev => ({
      ...prev,
      [name]: val
    }));
    
    // Auto-save when testMode changes (if account is connected)
    if (name === 'testMode' && payarcSettings.accountConnected) {
      try {
        setSaving(true);
        const endpoint = 'http://localhost:9000/api/payarc/settings.php';
        
        // Format data to match backend expectations
        const data = {
          enabled: payarcSettings.enabled ? 1 : 0,
          merchant_id: payarcSettings.merchantId,
          api_key: payarcSettings.apiKey,
          secret_key: payarcSettings.secretKey,
          callback_url: payarcSettings.callbackUrl,
          test_mode: val ? 1 : 0,
          account_connected: payarcSettings.accountConnected ? 1 : 0
        };
        
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const result = await response.json();
        
        if (result.status === 'success') {
          setSuccessMessage('Test mode ' + (val ? 'enabled' : 'disabled') + ' successfully');
          setTimeout(() => setSuccessMessage(''), 3000);
        } else {
          throw new Error(result.message || 'Failed to save settings');
        }
      } catch (error) {
        setErrorMessage(`Error updating test mode: ${error.message}`);
        console.error('Error saving PayArc settings:', error);
        // Revert the UI state if the save failed
        setPayarcSettings(prev => ({ ...prev, testMode: !val }));
      } finally {
        setSaving(false);
      }
    }
  };

  const handleConnectStripe = () => {
    // In real implementation, redirect to Stripe OAuth connect flow
    window.open('https://connect.stripe.com/oauth/authorize?response_type=code&client_id=YOUR_CLIENT_ID&scope=read_write', '_blank');
  };

  const handleConnectPayPal = () => {
    // In real implementation, redirect to PayPal OAuth connect flow
    window.open('https://www.paypal.com/connect?flowEntry=static&client_id=YOUR_CLIENT_ID&scope=openid', '_blank');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset messages
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      setSaving(true);
      
      // Determine which processor is active and save its settings
      let endpoint;
      let data;
      
      if (activeTab === 'stripe' && stripeSettings.enabled) {
        endpoint = 'http://localhost:9000/api/stripe/settings.php';
        data = stripeSettings;
      } else if (activeTab === 'paypal' && paypalSettings.enabled) {
        endpoint = 'http://localhost:9000/api/paypal/settings.php';
        data = paypalSettings;
      } else if (activeTab === 'payarc' && payarcSettings.enabled) {
        endpoint = 'http://localhost:9000/api/payarc/settings.php';
        // Format data to match backend expectations
        data = {
          enabled: payarcSettings.enabled ? 1 : 0,
          merchant_id: payarcSettings.merchantId,
          api_key: payarcSettings.apiKey,
          secret_key: payarcSettings.secretKey,
          callback_url: payarcSettings.callbackUrl,
          test_mode: payarcSettings.testMode ? 1 : 0,
          account_connected: payarcSettings.accountConnected ? 1 : 0
        };
      } else {
        setErrorMessage('No payment processor is enabled');
        setSaving(false);
        return;
      }
      
      console.log('Saving payment settings with data:', JSON.stringify({
        ...data,
        api_key: data.api_key ? '[REDACTED]' : '',
        secret_key: data.secret_key ? '[REDACTED]' : ''
      }));
      
      // Make API call to save settings
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
      });
      
      // Check for basic HTTP errors
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      // Get response text
      const responseText = await response.text();
      
      // Log response but redact sensitive info
      console.log('Response from server:', responseText.replace(/"api_key":"[^"]*"/g, '"api_key":"[REDACTED]"')
        .replace(/"secret_key":"[^"]*"/g, '"secret_key":"[REDACTED]"'));
      
      let result;
      try {
        result = JSON.parse(responseText);
      } catch (error) {
        throw new Error(`Invalid JSON response: ${responseText.substring(0, 100)}...`);
      }
      
      if (result.status === 'success') {
        setSuccessMessage(result.message || 'Payment settings saved successfully!');
      } else {
        throw new Error(result.message || 'Failed to save settings');
      }
    } catch (error) {
      setErrorMessage(`Error saving payment settings: ${error.message}`);
      console.error('Error saving payment settings:', error);
    } finally {
      setSaving(false);
    }
  };

  const calculateOrderTotal = (subtotal) => {
    let processorFee = 0;
    
    if (stripeSettings.enabled) {
      processorFee = (subtotal * (processorFees.stripe.percentage / 100)) + processorFees.stripe.fixed;
    } else if (paypalSettings.enabled) {
      processorFee = (subtotal * (processorFees.paypal.percentage / 100)) + processorFees.paypal.fixed;
    } else if (payarcSettings.enabled) {
      processorFee = (subtotal * (processorFees.payarc.percentage / 100)) + processorFees.payarc.fixed;
    }
    
    return {
      processorFee: processorFee.toFixed(2),
      total: (subtotal - processorFee).toFixed(2)
    };
  };

  // Add PayArc specific functions
  const testPayArcConnection = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setSaving(true);
    
    try {
      // In a real implementation, you would make an actual API call to test the connection
      // For now, we'll simulate a successful test
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setPayarcSettings(prev => ({
        ...prev,
        accountConnected: true
      }));
      
      setSuccessMessage('Successfully connected to PayArc API!');
    } catch (error) {
      setErrorMessage('Failed to connect to PayArc: ' + error.message);
    } finally {
      setSaving(false);
    }
  };

  const handleDisconnectPayArc = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setSaving(true);
    
    try {
      const endpoint = 'http://localhost:9000/api/payarc/settings.php';
      
      // Format data to match backend expectations
      const data = {
        enabled: 0, // Disable when disconnecting
        merchant_id: payarcSettings.merchantId,
        api_key: payarcSettings.apiKey,
        secret_key: payarcSettings.secretKey,
        callback_url: payarcSettings.callbackUrl,
        test_mode: payarcSettings.testMode ? 1 : 0,
        account_connected: 0 // Set to disconnected
      };
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      
      if (result.status === 'success') {
        // Update the UI state
        setPayarcSettings(prev => ({
          ...prev,
          enabled: false,
          accountConnected: false
        }));
        setSuccessMessage('PayArc account disconnected successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        throw new Error(result.message || 'Failed to disconnect PayArc account');
      }
    } catch (error) {
      setErrorMessage(`Error disconnecting PayArc account: ${error.message}`);
      console.error('Error disconnecting PayArc account:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleCreateSandboxAccount = () => {
    window.open('https://testportal.payarc.net/accounts/create/test', '_blank');
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading payment settings...</p>
      </div>
    );
  }

  // Sample order calculation for fee display
  const sampleOrder = calculateOrderTotal(50);

  return (
    <div className="payments-container">
      <div className="section-card">
        <h2>Payment Settings</h2>
        <p className="section-description">
          Configure how you accept payments from your customers.
        </p>
        
        {errorMessage && (
          <div className="alert alert-error">
            <i className="fas fa-exclamation-circle"></i> {errorMessage}
          </div>
        )}
        
        {successMessage && (
          <div className="alert alert-success">
            <i className="fas fa-check-circle"></i> {successMessage}
          </div>
        )}
        
        <div className="payment-tabs">
          <div
            className={`payment-tab ${activeTab === 'stripe' ? 'active' : ''}`}
            onClick={() => handleTabChange('stripe')}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg"
              alt="Stripe"
              className="processor-logo"
            />
            <span>Stripe</span>
          </div>
          <div
            className={`payment-tab ${activeTab === 'paypal' ? 'active' : ''}`}
            onClick={() => handleTabChange('paypal')}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
              alt="PayPal"
              className="processor-logo"
            />
            <span>PayPal</span>
          </div>
          <div
            className={`payment-tab ${activeTab === 'payarc' ? 'active' : ''}`}
            onClick={() => handleTabChange('payarc')}
          >
            <i className="fas fa-credit-card processor-icon"></i>
            <span>PayArc</span>
          </div>
        </div>
        
        <div className="processor-content">
          {/* Stripe Settings */}
          {activeTab === 'stripe' && (
            <div className="processor-section">
              <div className="processor-header">
                <div className="processor-info">
                  <h3>Stripe Connect</h3>
                  <p>
                    Accept credit cards, Apple Pay, Google Pay and more with Stripe.
                    Fees: {processorFees.stripe.percentage}% + ${processorFees.stripe.fixed} per transaction.
                  </p>
                </div>
                <div className="processor-toggle">
                  <label className="switch-label">
                    <div className="switch">
                      <input
                        type="checkbox"
                        name="enabled"
                        checked={stripeSettings.enabled}
                        onChange={() => handleToggleProcessor('stripe')}
                      />
                      <span className="slider"></span>
                    </div>
                  </label>
                </div>
              </div>
              
              {stripeSettings.enabled && (
                <form onSubmit={handleSubmit}>
                  {!stripeSettings.accountConnected ? (
                    <div className="connect-section">
                      <p>Connect your Stripe account to start accepting payments.</p>
                      <button
                        type="button"
                        className="btn btn-processor stripe-connect"
                        onClick={handleConnectStripe}
                      >
                        <i className="fab fa-stripe"></i> Connect with Stripe
                      </button>
                    </div>
                  ) : (
                    <div className="form-section">
                      <div className="connected-account">
                        <div className="account-info">
                          <i className="fas fa-check-circle"></i>
                          <span>Connected to Stripe account: {stripeSettings.accountId}</span>
                        </div>
                        <button type="button" className="btn btn-outline">
                          Disconnect
                        </button>
                      </div>
                      
                      <div className="form-group switch-group">
                        <label className="switch-label">
                          Test Mode
                          <div className="switch">
                            <input
                              type="checkbox"
                              name="testMode"
                              checked={stripeSettings.testMode}
                              onChange={handleStripeChange}
                            />
                            <span className="slider"></span>
                          </div>
                        </label>
                        <p className="help-text">
                          In test mode, no real charges will be made. Use for testing your setup.
                        </p>
                      </div>
                      
                      <div className="form-group">
                        <label htmlFor="publishableKey">Publishable Key</label>
                        <input
                          type="text"
                          id="publishableKey"
                          name="publishableKey"
                          value={stripeSettings.publishableKey}
                          onChange={handleStripeChange}
                          placeholder="pk_test_..."
                        />
                      </div>
                      
                      <div className="form-group">
                        <label htmlFor="secretKey">Secret Key</label>
                        <input
                          type="password"
                          id="secretKey"
                          name="secretKey"
                          value={stripeSettings.secretKey}
                          onChange={handleStripeChange}
                          placeholder="sk_test_..."
                        />
                        <p className="help-text">
                          Your secret key is encrypted and stored securely.
                        </p>
                      </div>
                    </div>
                  )}
                  
                  <div className="form-actions">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={saving || (!stripeSettings.accountConnected && !stripeSettings.publishableKey)}
                    >
                      {saving ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i> Saving...
                        </>
                      ) : 'Save Settings'}
                    </button>
                  </div>
                </form>
              )}
            </div>
          )}
          
          {/* PayPal Settings */}
          {activeTab === 'paypal' && (
            <div className="processor-section">
              <div className="processor-header">
                <div className="processor-info">
                  <h3>PayPal Commerce</h3>
                  <p>
                    Accept PayPal, Venmo, credit cards and more with PayPal.
                    Fees: {processorFees.paypal.percentage}% + ${processorFees.paypal.fixed} per transaction.
                  </p>
                </div>
                <div className="processor-toggle">
                  <label className="switch-label">
                    <div className="switch">
                      <input
                        type="checkbox"
                        name="enabled"
                        checked={paypalSettings.enabled}
                        onChange={() => handleToggleProcessor('paypal')}
                      />
                      <span className="slider"></span>
                    </div>
                  </label>
                </div>
              </div>
              
              {paypalSettings.enabled && (
                <form onSubmit={handleSubmit}>
                  {!paypalSettings.accountConnected ? (
                    <div className="connect-section">
                      <p>Connect your PayPal account to start accepting payments.</p>
                      <button
                        type="button"
                        className="btn btn-processor paypal-connect"
                        onClick={handleConnectPayPal}
                      >
                        <i className="fab fa-paypal"></i> Connect with PayPal
                      </button>
                    </div>
                  ) : (
                    <div className="form-section">
                      <div className="connected-account">
                        <div className="account-info">
                          <i className="fas fa-check-circle"></i>
                          <span>Connected to PayPal account</span>
                        </div>
                        <button type="button" className="btn btn-outline">
                          Disconnect
                        </button>
                      </div>
                      
                      <div className="form-group switch-group">
                        <label className="switch-label">
                          Test Mode
                          <div className="switch">
                            <input
                              type="checkbox"
                              name="testMode"
                              checked={paypalSettings.testMode}
                              onChange={handlePayPalChange}
                            />
                            <span className="slider"></span>
                          </div>
                        </label>
                        <p className="help-text">
                          In test mode, no real charges will be made. Use for testing your setup.
                        </p>
                      </div>
                      
                      <div className="form-group">
                        <label htmlFor="clientId">Client ID</label>
                        <input
                          type="text"
                          id="clientId"
                          name="clientId"
                          value={paypalSettings.clientId}
                          onChange={handlePayPalChange}
                          placeholder="Client ID from PayPal Developer dashboard"
                        />
                      </div>
                      
                      <div className="form-group">
                        <label htmlFor="clientSecret">Client Secret</label>
                        <input
                          type="password"
                          id="clientSecret"
                          name="clientSecret"
                          value={paypalSettings.clientSecret}
                          onChange={handlePayPalChange}
                          placeholder="Client Secret from PayPal Developer dashboard"
                        />
                        <p className="help-text">
                          Your secret is encrypted and stored securely.
                        </p>
                      </div>
                    </div>
                  )}
                  
                  <div className="form-actions">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={saving}
                    >
                      {saving ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i> Saving...
                        </>
                      ) : 'Save Settings'}
                    </button>
                  </div>
                </form>
              )}
            </div>
          )}
          
          {/* PayArc Settings */}
          {activeTab === 'payarc' && (
            <div className="processor-section">
              <div className="processor-header">
                <div className="processor-info">
                  <h3>PayArc Processing</h3>
                  <p>
                    Process credit cards with competitive rates using PayArc.
                    Fees: {processorFees.payarc.percentage}% + ${processorFees.payarc.fixed} per transaction.
                  </p>
                </div>
                <div className="processor-toggle">
                  <label className="switch-label">
                    <div className="switch">
                      <input
                        type="checkbox"
                        name="enabled"
                        checked={payarcSettings.enabled}
                        onChange={() => handleToggleProcessor('payarc')}
                        disabled={saving}
                      />
                      <span className="slider"></span>
                    </div>
                  </label>
                </div>
              </div>
              
              {payarcSettings.enabled && (
                <form onSubmit={handleSubmit}>
                  <div className="form-section">
                    <div className="form-group switch-group">
                      <label className="switch-label">
                        Test Mode
                        <div className="switch">
                          <input
                            type="checkbox"
                            name="testMode"
                            checked={payarcSettings.testMode}
                            onChange={handlePayArcChange}
                          />
                          <span className="slider"></span>
                        </div>
                      </label>
                      <p className="help-text">
                        In test mode, no real charges will be made. Use for testing your setup.
                      </p>
                      {payarcSettings.testMode && (
                        <div className="test-mode-info">
                          <button
                            type="button"
                            className="btn btn-outline-info"
                            onClick={handleCreateSandboxAccount}
                          >
                            <i className="fas fa-external-link-alt"></i> Create Sandbox Account
                          </button>
                          <p className="mt-2">
                            For testing, you can use card number: 4111 1111 1111 1111, 
                            expiry date: any future date, CVV: any 3 digits
                          </p>
                        </div>
                      )}
                    </div>

                    {payarcSettings.accountConnected && (
                      <div className="connected-account">
                        <div className="account-info">
                          <i className="fas fa-check-circle"></i>
                          <span>Connected to PayArc - Merchant ID: {payarcSettings.merchantId}</span>
                        </div>
                        <button 
                          type="button" 
                          className="btn btn-outline"
                          onClick={handleDisconnectPayArc}
                          disabled={saving}
                        >
                          {saving ? (
                            <>
                              <i className="fas fa-spinner fa-spin"></i> Disconnecting...
                            </>
                          ) : 'Disconnect'}
                        </button>
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="merchantId">Merchant ID</label>
                      <input
                        type="text"
                        id="merchantId"
                        name="merchantId"
                        value={payarcSettings.merchantId}
                        onChange={handlePayArcChange}
                        placeholder="Your PayArc Merchant ID"
                      />
                      <p className="help-text">
                        Find your Merchant ID in your PayArc dashboard.
                      </p>
                    </div>
                    
                    <div className="form-group">
                      <label htmlFor="apiKey">API Key</label>
                      <input
                        type="password"
                        id="apiKey"
                        name="apiKey"
                        value={payarcSettings.apiKey === '••••••••' ? '' : payarcSettings.apiKey}
                        onChange={handlePayArcChange}
                        placeholder={payarcSettings.apiKey === '••••••••' ? "API Key is saved and masked" : "Your PayArc API Key"}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="secretKey">Secret Key</label>
                      <input
                        type="password"
                        id="secretKey"
                        name="secretKey"
                        value={payarcSettings.secretKey === '••••••••' ? '' : payarcSettings.secretKey}
                        onChange={handlePayArcChange}
                        placeholder={payarcSettings.secretKey === '••••••••' ? "Secret Key is saved and masked" : "Your PayArc Secret Key"}
                      />
                      <p className="help-text">
                        Your keys are encrypted and stored securely.
                        {payarcSettings.apiKey === '••••••••' || payarcSettings.secretKey === '••••••••' ? 
                          " Leave field empty to keep using saved keys." : ""}
                      </p>
                    </div>

                    <div className="form-group">
                      <label htmlFor="callbackUrl">Callback URL</label>
                      <input
                        type="text"
                        id="callbackUrl"
                        name="callbackUrl"
                        value={payarcSettings.callbackUrl}
                        onChange={handlePayArcChange}
                        placeholder="https://yourdomain.com/payarc/callback"
                      />
                      <p className="help-text">
                        URL where PayArc will send transaction results.
                      </p>
                    </div>

                    {!payarcSettings.accountConnected && (
                      <button
                        type="button"
                        className="btn btn-secondary mb-4"
                        onClick={testPayArcConnection}
                        disabled={!payarcSettings.merchantId || !payarcSettings.apiKey || !payarcSettings.secretKey}
                      >
                        {saving ? (
                          <>
                            <i className="fas fa-spinner fa-spin"></i> Testing...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-plug"></i> Test Connection
                          </>
                        )}
                      </button>
                    )}

                    <div className="payarc-features mt-4">
                      <h4>Features You'll Get</h4>
                      <ul className="feature-list">
                        <li>
                          <i className="fas fa-check text-success"></i>
                          <span>PCI Compliant Payment Processing</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-success"></i>
                          <span>Credit Card & ACH Payments</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-success"></i>
                          <span>Secure Customer Vault</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-success"></i>
                          <span>Advanced Fraud Protection</span>
                        </li>
                      </ul>
                    </div>

                    <div className="api-docs-link mt-3 mb-3">
                      <a 
                        href="https://docs.payarc.net/docs/pay-by-card" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="btn btn-link"
                      >
                        <i className="fas fa-book"></i> View PayArc API Documentation
                      </a>
                    </div>
                  </div>
                  
                  {payarcSettings.accountConnected && (
                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={saving}
                      >
                        {saving ? (
                          <>
                            <i className="fas fa-spinner fa-spin"></i> Saving...
                          </>
                        ) : 'Save Settings'}
                      </button>
                    </div>
                  )}
                </form>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Fee Calculator */}
      <div className="section-card fee-calculator">
        <h2>Fee Calculator</h2>
        <p className="section-description">
          See how payment processor fees affect your revenue with this example:
        </p>
        
        <div className="fee-example">
          <div className="fee-row">
            <div className="fee-label">Sample Order Total:</div>
            <div className="fee-value">${50.00}</div>
          </div>
          <div className="fee-row">
            <div className="fee-label">Payment Processor Fee (includes markup):</div>
            <div className="fee-value">-${sampleOrder.processorFee}</div>
          </div>
          <div className="fee-row total">
            <div className="fee-label">You Receive:</div>
            <div className="fee-value">${sampleOrder.total}</div>
          </div>
        </div>
        
        <div className="fee-note">
          <p>
            <i className="fas fa-info-circle"></i>
            Fees vary by processor and transaction type. This is an estimate for comparison.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Payments; 