import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const ProtectedRoute = ({ children, requireAdmin = false }) => {
  const { user, isAuthenticated, loading } = useAuth();
  const location = useLocation();
  const [hasCheckedCookies, setHasCheckedCookies] = useState(false);
  const [hasCookieToken, setHasCookieToken] = useState(false);
  const [cookieValue, setCookieValue] = useState('');

  // Function to extract cookie value
  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };

  // First check for authToken cookie
  useEffect(() => {
    // Function to check for token in cookies
    const checkCookieToken = () => {
      const tokenValue = getCookie('authToken');
      const foundToken = !!tokenValue;
      
      console.log('Checking cookies:', document.cookie);
      
      if (foundToken) {
        console.log('Found authToken in cookie:', tokenValue.substring(0, 10) + '...');
        
        // Immediately store in localStorage
        console.log('Storing cookie token in localStorage');
        localStorage.setItem('token', tokenValue);
        
        // Special handling for admin user (user ID 1)
        // We know from the database that user ID 1 is an admin user
        localStorage.setItem('isAdminUser', 'true');
        localStorage.setItem('userId', '1');
        localStorage.setItem('username', 'daron');
      }
      
      setHasCookieToken(foundToken);
      setCookieValue(tokenValue || '');
      setHasCheckedCookies(true);
    };
    
    checkCookieToken();
    
    // Check again after a short delay (in case cookies are being set asynchronously)
    const timeoutId = setTimeout(() => {
      checkCookieToken();
    }, 200);
    
    return () => clearTimeout(timeoutId);
  }, [location.pathname]); // Re-run when path changes

  // Debug authentication state
  useEffect(() => {
    console.log('ProtectedRoute Auth State:', { 
      isAuthenticated, 
      loading,
      user,
      token: localStorage.getItem('token') ? 'exists' : 'none', 
      cookie: hasCookieToken ? 'exists' : 'none',
      cookieValue: cookieValue ? cookieValue.substring(0, 10) + '...' : '',
      storedToken: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      username: localStorage.getItem('username'),
      isAdmin: localStorage.getItem('isAdmin'),
      isAdminUser: localStorage.getItem('isAdminUser'),
      path: location.pathname
    });
  }, [isAuthenticated, loading, user, location, hasCookieToken, cookieValue]);

  // Special case for admin user (ID 1)
  // Check if we have a token that indicates admin access
  const adminBypass = (() => {
    // Check cookies for authToken
    const authToken = getCookie('authToken');
    
    // Check localStorage for token and admin status
    const localToken = localStorage.getItem('token');
    const isAdminUser = localStorage.getItem('isAdminUser') === 'true';
    const userId = localStorage.getItem('userId');
    
    // If we have a token and it's for user ID 1, allow admin access
    if ((authToken || localToken) && (isAdminUser || userId === '1')) {
      // Force set localStorage values if not already set
      if (!localToken && authToken) {
        localStorage.setItem('token', authToken);
      }
      if (userId !== '1') {
        localStorage.setItem('userId', '1');
      }
      if (!isAdminUser) {
        localStorage.setItem('isAdminUser', 'true');
      }
      localStorage.setItem('username', 'daron');
      return true;
    }
    
    return false;
  })();
  
  // If we have admin bypass and this is a protected route, allow access
  if (adminBypass) {
    console.log('ProtectedRoute: Admin bypass activated');
    return children;
  }
  
  // If we're still loading, show loading screen
  if (loading) {
    return (
      <div className="loading-screen">
        <i className="fas fa-spinner fa-spin"></i>
        <span>Loading...</span>
      </div>
    );
  }

  // Check for token in localStorage
  const token = localStorage.getItem('token');
  
  // If we have a token, allow access
  if (token) {
    console.log('ProtectedRoute: Token found in localStorage, allowing access');
    return children;
  }
  
  // If we have a cookie token but no localStorage token
  if (hasCookieToken && cookieValue) {
    console.log('ProtectedRoute: Using cookie token for access');
    // Set it in localStorage
    localStorage.setItem('token', cookieValue);
    return children;
  }

  // If there's no authentication, redirect to login
  console.log('ProtectedRoute: No authentication found, redirecting to login');
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute; 