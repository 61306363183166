import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  getCartItems, 
  removeFromCart, 
  updateCartItemQuantity, 
  getCartTotal, 
  clearCart,
  mergeCartsOnLogin,
  getBusinessSettings,
  processOrder,
  updateCartItemOptions
} from '../../services/cart/cartService';
import { getApiUrl } from '../../utils/apiUtils';
import './Cart.css';

// Add these as new components outside the main Cart component
// RadioOption component for Bun Type selection
const RadioOptionGroup = ({ groupName, groupOptions, selectedOptions, cartItemId, editingItem, setEditingItem, handleToggleOption }) => {
  const [localSelectedOption, setLocalSelectedOption] = React.useState(selectedOptions);
  
  React.useEffect(() => {
    setLocalSelectedOption(selectedOptions);
  }, [selectedOptions]);

  console.log(`Rendering radio options for group ${groupName}:`, selectedOptions);
  console.log(`Available options:`, groupOptions.map(opt => `${opt.id}:${opt.name}`).join(', '));
  
  return (
    <div className="radio-options">
      {groupOptions.map(option => {
        const optionId = String(option.id);
        const currentSelected = String(localSelectedOption);
        const isSelected = currentSelected === optionId;
        
        console.log(`Option ${optionId} (${option.name}) selected (local): ${isSelected}`);
        
        return (
          <div 
            key={option.id} 
            className={`editable-option ${isSelected ? 'selected' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              
              console.log(`Clicking on option ${optionId} (${option.name})`);
              setLocalSelectedOption(optionId);
              
              // Create a direct DOM change for visual feedback
              const radioInput = e.currentTarget.querySelector('input[type="radio"]');
              if (radioInput) {
                document.querySelectorAll(`input[name="${groupName}-${cartItemId}"]`)
                  .forEach(input => input.checked = false);
                radioInput.checked = true;
              }
              
              // Update parent state
              if (editingItem) {
                const updatedOptions = {...editingItem.options};
                updatedOptions[groupName] = optionId;
                console.log(`Setting ${groupName} directly to ${optionId}`);
                setEditingItem({
                  ...editingItem,
                  options: updatedOptions
                });
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <div className="option-radio">
              <input 
                type="radio" 
                name={`${groupName}-${cartItemId}`}
                checked={isSelected}
                onChange={(e) => {
                  console.log("Radio input changed");
                  setLocalSelectedOption(optionId);
                  handleToggleOption(optionId, groupName, true);
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <span className="option-name">{option.name}</span>
              <span className="price-value">${parseFloat(option.price).toFixed(2)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

// CheckboxOption component for toppings selection
const CheckboxOptionGroup = ({ groupName, groupOptions, selectedOptions, editingItem, setEditingItem }) => {
  const [localSelectedOptions, setLocalSelectedOptions] = React.useState(
    Array.isArray(selectedOptions) ? [...selectedOptions] : []
  );
  
  React.useEffect(() => {
    setLocalSelectedOptions(
      Array.isArray(selectedOptions) ? [...selectedOptions] : []
    );
  }, [selectedOptions]);
  
  // Check if option is selected
  const isOptionSelected = (optionId) => {
    return localSelectedOptions.includes(optionId);
  };
  
  // Toggle option selection
  const toggleOption = (optionId) => {
    const newOptions = [...localSelectedOptions];
    const index = newOptions.indexOf(optionId);
    
    if (index > -1) {
      // Remove if exists
      newOptions.splice(index, 1);
    } else {
      // Add if doesn't exist
      newOptions.push(optionId);
    }
    
    // Update local state immediately
    setLocalSelectedOptions(newOptions);
    
    // Update parent state
    const updatedOptions = {...editingItem.options};
    updatedOptions[groupName] = newOptions;
    setEditingItem({
      ...editingItem,
      options: updatedOptions
    });
  };

  console.log(`Rendering ${groupName} checkbox options:`, groupOptions.map(opt => opt.name).join(', '));
  
  return (
    <div className="checkbox-options">
      {groupOptions.map(option => {
        const optionId = String(option.id);
        const isSelected = isOptionSelected(optionId);
        
        return (
          <div key={optionId} className={`editable-option ${isSelected ? 'selected' : ''}`}>
            <label className="option-checkbox" style={{ cursor: 'pointer' }}>
              <input 
                type="checkbox" 
                checked={isSelected}
                onChange={(e) => {
                  console.log(`Checkbox for ${option.name} changed, now: ${!isSelected}`);
                  toggleOption(optionId);
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <span className="option-name">{option.name}</span>
              <span className="price-value">${parseFloat(option.price).toFixed(2)}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

const Cart = ({ isOpen, onClose, isAuthenticated }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState({
    business_name: '',
    collect_sales_tax: 0,
    tax_rate: 0
  });
  const [salesTax, setSalesTax] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const businessSettingsLoaded = useRef(false);
  const [checkoutMode, setCheckoutMode] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [deliveryMethod, setDeliveryMethod] = useState('delivery');
  const [checkoutFormData, setCheckoutFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    cardNumber: '',
    cardExpiry: '',
    cardCVV: '',
    specialInstructions: ''
  });
  const [processingPayment, setProcessingPayment] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  // Load business settings
  useEffect(() => {
    const fetchBusinessSettings = async () => {
      try {
        if (!businessSettingsLoaded.current) {
          console.log("Fetching business settings...");
          
          try {
            // First try regular business-settings endpoint
            const settings = await getBusinessSettings();
            console.log("Business settings loaded successfully:", settings);
            
            setBusiness({
              business_name: settings.business_name,
              collect_sales_tax: settings.collect_sales_tax,
              tax_rate: settings.tax_rate
            });
          } catch (fetchError) {
            console.error("Error fetching business settings:", fetchError);
            
            // Try to get raw database values as fallback
            try {
              const token = localStorage.getItem('token');
              
              // Use getApiUrl to handle environment differences
              const rawDbCheckUrl = typeof getApiUrl === 'function'
                ? getApiUrl('raw_db_check')
                : '/api/raw_db_check';
              
              const response = await fetch(rawDbCheckUrl, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              });
              
              if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
              }
              
              const jsonResponse = await response.json();
              console.log("Raw database response:", jsonResponse);
              
              // Extract the business data from the response
              if (jsonResponse && jsonResponse.data) {
                const rawData = jsonResponse.data;
                
                setBusiness({
                  business_name: rawData.business_name || '',
                  collect_sales_tax: parseInt(rawData.collect_sales_tax) || 0,
                  tax_rate: parseFloat(rawData.tax_rate) || 0
                });
              } else {
                // Default to empty values if nothing from DB
                setBusiness({
                  business_name: '',
                  collect_sales_tax: 0,
                  tax_rate: 0
                });
              }
            } catch (rawError) {
              console.error("Error fetching raw DB data:", rawError);
              // Default to empty values
              setBusiness({
                business_name: '',
                collect_sales_tax: 0,
                tax_rate: 0
              });
            }
          }
          
          // Mark as loaded to prevent refetching
          businessSettingsLoaded.current = true;
        }
      } catch (error) {
        console.error('Error in business settings loading process:', error);
        // Default to empty values
        setBusiness({
          business_name: '',
          collect_sales_tax: 0,
          tax_rate: 0
        });
        
        // Still mark as loaded to prevent refetching
        businessSettingsLoaded.current = true;
      }
    };
    
    fetchBusinessSettings();
  }, []);

  // Calculate sales tax and order total when cartTotal or business changes
  useEffect(() => {
    console.log("Tax calculation - cart total:", cartTotal, "business settings:", business);
    
    // Use values from database
    const isTaxEnabled = business.collect_sales_tax === 1 || business.collect_sales_tax === '1' || business.collect_sales_tax === true;
    const taxRate = parseFloat(business.tax_rate) || 0;
    
    console.log(`Tax values for calculation: isTaxEnabled=${isTaxEnabled}, taxRate=${taxRate}`);
    
    // Calculate tax when collection is enabled and we have a rate
    if (isTaxEnabled && taxRate > 0 && cartTotal > 0) {
      const taxAmount = parseFloat(cartTotal) * (taxRate / 100);
      console.log(`Calculating tax: ${cartTotal} × ${taxRate}% = ${taxAmount}`);
      setSalesTax(taxAmount);
      setOrderTotal(parseFloat(cartTotal) + taxAmount);
    } else {
      console.log("No tax being applied");
      setSalesTax(0);
      setOrderTotal(parseFloat(cartTotal));
    }
  }, [cartTotal, business]);

  // Debug render
  console.log("Cart component rendering, isOpen:", isOpen, "items:", cartItems.length);

  // Load cart items when component mounts or cart is opened
  const loadCartItems = useCallback(async () => {
    setLoading(true);
    try {
      console.log("Fetching cart items...");
      const items = await getCartItems();
      console.log("Cart items loaded:", items);
      setCartItems(items);
      
      const total = await getCartTotal();
      console.log("Cart total:", total);
      setCartTotal(total);
    } catch (error) {
      console.error('Error loading cart:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial load and when cart is opened
  useEffect(() => {
    if (isOpen) {
      console.log("Cart was opened, loading items...");
      loadCartItems();
    }
  }, [isOpen, loadCartItems]);

  // Check for cart items on mount
  useEffect(() => {
    console.log("Cart component mounted - loading initial cart items");
    loadCartItems();
    
    // Make sure the cart button is visible by logging a message
    console.log("Cart icon should be visible now");
    
    // Force an additional check after a timeout to ensure cart items load
    const timer = setTimeout(() => {
      console.log("Running delayed cart refresh");
      loadCartItems();
    }, 1000);
    
    return () => clearTimeout(timer);
  }, [loadCartItems]);

  // Handle login state changes
  useEffect(() => {
    const handleLoginStateChange = async () => {
      const token = localStorage.getItem('token');
      if (token && isAuthenticated) {
        // If user just logged in, merge carts
        console.log("User logged in, merging carts");
        await mergeCartsOnLogin();
        await loadCartItems();
      }
    };

    handleLoginStateChange();
  }, [isAuthenticated, loadCartItems]);

  // Dispatch cart state change event
  useEffect(() => {
    const event = new CustomEvent('cartStateChange', {
      detail: { isCartOpen: isOpen }
    });
    window.dispatchEvent(event);
  }, [isOpen]);

  const handleRemoveItem = async (cartItemId) => {
    try {
      const updatedCart = await removeFromCart(cartItemId);
      setCartItems(updatedCart);
      
      const total = await getCartTotal();
      setCartTotal(total);
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };

  const handleEditItem = (item) => {
    console.log('Starting to edit item:', item);
    
    // Ensure we have valid options structures
    const editItem = {...item};
    
    // Normalize options
    if (!editItem.options) {
      editItem.options = {};
    }
    
    // Ensure Toppings array exists
    if (!editItem.options['Toppings']) {
      editItem.options['Toppings'] = [];
    } else if (!Array.isArray(editItem.options['Toppings'])) {
      // Convert to array if it's not already
      editItem.options['Toppings'] = [editItem.options['Toppings']];
    }
    
    // Ensure Bun Type is a string
    if (!editItem.options['Bun Type']) {
      editItem.options['Bun Type'] = '63'; // Default to White
    }
    
    // Ensure Side Items array exists
    if (!editItem.options['Side Items']) {
      editItem.options['Side Items'] = [];
    } else if (!Array.isArray(editItem.options['Side Items'])) {
      // Convert to array if it's not already
      editItem.options['Side Items'] = [editItem.options['Side Items']];
    }
    
    // Detect the item type to determine which option groups should be shown
    const isBurger = editItem.name && editItem.name.toLowerCase().includes('deluxe');
    const isChickenFriedSteak = editItem.name && (
      editItem.name.toLowerCase().includes('steak') || 
      editItem.name.toLowerCase().includes('cream')
    );
    
    // Remove unnecessary option groups based on item type
    if (isBurger) {
      // Ensure burger has Toppings and Bun Type, but not Side Items
      delete editItem.options['Side Items'];
    } else if (isChickenFriedSteak) {
      // Ensure chicken fried steak has Side Items, but not Toppings or Bun Type
      delete editItem.options['Toppings'];
      delete editItem.options['Bun Type'];
    }
    
    console.log('Normalized edit item:', editItem);
    setEditingItem(editItem);
  };

  const handleSaveEdit = async (cartItemId, updatedOptions) => {
    try {
      console.log("Saving options for item:", cartItemId);
      console.log("Original options:", JSON.stringify(updatedOptions));
      
      // Ensure options have the correct format
      const cleanedOptions = {};
      
      // Process each option group
      Object.entries(updatedOptions).forEach(([groupName, values]) => {
        // For Bun Type or other single selection groups
        if (groupName === 'Bun Type') {
          // Ensure we have a valid string value
          cleanedOptions[groupName] = values ? String(values) : '';
          console.log(`Saving ${groupName} as ${cleanedOptions[groupName]} (${typeof cleanedOptions[groupName]})`);
        } else {
          // For multi-selection groups, ensure we have an array
          if (Array.isArray(values)) {
            cleanedOptions[groupName] = values.map(v => String(v));
          } else if (values) {
            // If it's a string or number, convert to array
            cleanedOptions[groupName] = [String(values)];
          } else {
            cleanedOptions[groupName] = [];
          }
        }
      });
      
      console.log("Final cleaned options:", JSON.stringify(cleanedOptions));
      
      // Check if the cart ID is in the right format
      const formattedCartId = cartItemId.startsWith('cart_') ? cartItemId : `cart_${cartItemId}`;
      console.log("Using cart ID:", formattedCartId);
      
      // Call API with cleaned options
      const updatedCart = await updateCartItemOptions(formattedCartId, cleanedOptions);
      setCartItems(updatedCart);
      setEditingItem(null);
      
      const total = await getCartTotal();
      setCartTotal(total);
      
      // Force a refresh of the cart state to ensure UI is up to date
      setTimeout(() => {
        getCartItems().then(items => {
          setCartItems(items);
        });
      }, 500);
    } catch (error) {
      console.error('Error updating item options:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
  };

  const handleToggleOption = (optionId, groupName, isSingleSelection = false) => {
    if (!editingItem) return;
    
    console.log(`Toggling option: ${optionId} in group ${groupName}, isSingle: ${isSingleSelection}`);
    
    const updatedOptions = {...editingItem.options};
    
    // If it's a single selection (like radio buttons for bun type)
    if (isSingleSelection) {
      // Explicitly convert to string to ensure matches
      updatedOptions[groupName] = String(optionId); 
      console.log(`Set ${groupName} to ${optionId}`);
    } else {
      // Handle multiple selection (toppings)
      if (!Array.isArray(updatedOptions[groupName])) {
        updatedOptions[groupName] = [];
      }
      
      const options = updatedOptions[groupName];
      const index = options.indexOf(optionId);
      if (index > -1) {
        // Remove the option if it exists
        updatedOptions[groupName] = options.filter(id => id !== optionId);
      } else {
        // Add the option if it doesn't exist
        updatedOptions[groupName] = [...options, optionId];
      }
    }
    
    // Update the editingItem state
    setEditingItem({
      ...editingItem,
      options: updatedOptions
    });
  };

  const handleQuantityChange = async (cartItemId, delta) => {
    try {
      const item = cartItems.find(item => item.cart_item_id === cartItemId);
      if (!item) return;
      
      const newQuantity = item.quantity + delta;
      if (newQuantity <= 0) {
        return handleRemoveItem(cartItemId);
      }
      
      const updatedCart = await updateCartItemQuantity(cartItemId, newQuantity);
      setCartItems(updatedCart);
      
      const total = await getCartTotal();
      setCartTotal(total);
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  const handleClearCart = async () => {
    try {
      await clearCart();
      setCartItems([]);
      setCartTotal(0);
    } catch (error) {
      console.error('Error clearing cart:', error);
    }
  };

  const handleClose = () => {
    // If in checkout mode, go back to cart view
    if (checkoutMode) {
      setCheckoutMode(false);
      setPaymentStatus(null);
      return;
    }
    
    // Otherwise close the cart
    if (onClose) {
      onClose();
    }
  };

  const handleProceedToCheckout = () => {
    if (!cartItems.length) return;
    setCheckoutMode(true);
  };

  const handleBackToCart = () => {
    setCheckoutMode(false);
    setPaymentStatus(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCheckoutFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDeliveryMethodChange = (method) => {
    setDeliveryMethod(method);
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleSubmitOrder = async (e) => {
    e.preventDefault();
    setProcessingPayment(true);
    
    try {
      // Prepare order data from form inputs
      const orderData = {
        delivery_method: deliveryMethod,
        payment_method: paymentMethod,
        contact_info: {
          name: checkoutFormData.name,
          phone: checkoutFormData.phone,
          email: checkoutFormData.email
        },
        address: deliveryMethod === 'delivery' ? checkoutFormData.address : '',
        special_instructions: checkoutFormData.specialInstructions,
        is_authenticated: isAuthenticated
      };
      
      // Add payment details if paying by card
      if (paymentMethod === 'card') {
        orderData.payment_details = {
          cardNumber: checkoutFormData.cardNumber,
          cardExpiry: checkoutFormData.cardExpiry,
          cardCVV: checkoutFormData.cardCVV
        };
      }
      
      // Process the order using the service function
      const result = await processOrder(orderData);
      
      // Update payment status based on the result
      setPaymentStatus({
        success: result.success,
        message: result.message,
        orderId: result.orderId
      });
      
      // Cart clearing is handled by the processOrder function on success
      if (result.success) {
        setCartItems([]);
        setCartTotal(0);
      }
    } catch (error) {
      console.error('Error processing order:', error);
      setPaymentStatus({
        success: false,
        message: 'An error occurred while processing your order.'
      });
    } finally {
      setProcessingPayment(false);
    }
  };

  const renderOptionName = (item, groupId, optionId) => {
    // Try to get option details if available
    if (item.option_details && item.option_details[optionId]) {
      const option = item.option_details[optionId];
      const price = parseFloat(option.price);
      
      // Just render as text without any special styling
      return (
        <div className="option-row">
          <span className="option-indent">{option.name || `Option ${optionId}`}</span>
          <span className="price-value">${price.toFixed(2)}</span>
        </div>
      );
    }
    
    // Fallback to database values from item_options table based on the screenshots
    switch(optionId) {
      case '59': return <div className="option-row">
                          <span className="option-indent">Cheese</span>
                          <span className="price-value">$0.00</span>
                        </div>;
      case '60': return <div className="option-row">
                          <span className="option-indent">Onions</span>
                          <span className="price-value">$0.00</span>
                        </div>;
      case '61': return <div className="option-row">
                          <span className="option-indent">Bacon</span>
                          <span className="price-value">$2.00</span>
                        </div>;
      case '62': return <div className="option-row">
                          <span className="option-indent">Wheat</span>
                          <span className="price-value">$0.00</span>
                        </div>;
      case '63': return <div className="option-row">
                          <span className="option-indent">White</span>
                          <span className="price-value">$0.00</span>
                        </div>;
      case '64': return <div className="option-row">
                          <span className="option-indent">Mashed Potatoes</span>
                          <span className="price-value">$2.00</span>
                        </div>;
      case '65': return <div className="option-row">
                          <span className="option-indent">Fries</span>
                          <span className="price-value">$1.00</span>
                        </div>;
      case '66': return <div className="option-row">
                          <span className="option-indent">Salad</span>
                          <span className="price-value">$5.00</span>
                        </div>;
      default: return <div className="option-row">
                        <span className="option-indent">Option {optionId}</span>
                        <span className="price-value">$0.00</span>
                      </div>;
    }
  };

  const renderCartItemOptions = (item) => {
    if (!item.options || Object.keys(item.options).length === 0) {
      return null;
    }

    // If we're editing this item, show the editable options view
    if (editingItem && editingItem.cart_item_id === item.cart_item_id) {
      return renderEditableOptions(item);
    }

    return (
      <div className="cart-item-options">
        {Object.entries(item.options).map(([groupName, options]) => (
          <div key={groupName} className="cart-item-option-group">
            <strong>{groupName}:</strong>
            {Array.isArray(options) ? (
              // For multiple selections
              options.map(optionId => (
                <div key={optionId} className="cart-item-option">
                  {renderOptionName(item, groupName, optionId)}
                </div>
              ))
            ) : (
              // For single selection
              <div className="cart-item-option">
                {renderOptionName(item, groupName, options)}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  // Replace the renderRadioOptions function with this:
  const renderRadioOptions = (groupName, groupOptions, selectedOptions, cartItemId) => {
    return (
      <RadioOptionGroup 
        groupName={groupName}
        groupOptions={groupOptions}
        selectedOptions={selectedOptions}
        cartItemId={cartItemId}
        editingItem={editingItem}
        setEditingItem={setEditingItem}
        handleToggleOption={handleToggleOption}
      />
    );
  };

  // New function to render editable options
  const renderEditableOptions = (item) => {
    // Get all available options from the database to show
    const allOptionsByGroup = {};
    
    // Detect item type for proper option group display
    const isBurger = item.name && item.name.toLowerCase().includes('deluxe');
    const isChickenFriedSteak = item.name && (
      item.name.toLowerCase().includes('steak') || 
      item.name.toLowerCase().includes('cream')
    );
    
    console.log(`Item type detection: ${item.name}, isBurger: ${isBurger}, isChickenFriedSteak: ${isChickenFriedSteak}`);
    
    // Process all option details into respective groups
    if (item.option_details) {
      Object.entries(item.option_details).forEach(([optionId, optionInfo]) => {
        const groupName = optionInfo.group;
        if (!allOptionsByGroup[groupName]) {
          allOptionsByGroup[groupName] = [];
        }
        allOptionsByGroup[groupName].push({
          id: optionId,
          name: optionInfo.name,
          price: optionInfo.price
        });
      });
    }
    
    // Add predefined options for groups that might be missing
    // These are the standard options we might not have in the cart item
    const predefinedOptions = {
      'Toppings': [
        { id: '59', name: 'Cheese', price: '0.00' },
        { id: '60', name: 'Onions', price: '0.00' },
        { id: '61', name: 'Bacon', price: '2.00' }
      ],
      'Bun Type': [
        { id: '62', name: 'Wheat', price: '0.00' },
        { id: '63', name: 'White', price: '0.00' }
      ],
      'Side Items': [
        { id: '64', name: 'Mashed Potatoes', price: '2.00' },
        { id: '65', name: 'Fries', price: '1.00' },
        { id: '66', name: 'Salad', price: '5.00' }
      ]
    };
    
    // Only include relevant option groups based on item type
    const relevantGroups = {};
    if (isBurger) {
      relevantGroups['Toppings'] = true;
      relevantGroups['Bun Type'] = true;
    } else if (isChickenFriedSteak) {
      relevantGroups['Side Items'] = true;
    } else {
      // For unknown items, include all possible groups
      Object.keys(predefinedOptions).forEach(key => {
        relevantGroups[key] = true;
      });
    }
    
    console.log('Relevant option groups for this item:', Object.keys(relevantGroups).join(', '));
    
    // ALWAYS include the predefined options regardless of what's in option_details
    // but only for relevant groups
    Object.entries(predefinedOptions).forEach(([groupName, options]) => {
      // Skip groups that aren't relevant for this item type
      if (!relevantGroups[groupName]) {
        return;
      }
      
      if (!allOptionsByGroup[groupName]) {
        allOptionsByGroup[groupName] = [];
      }
      
      options.forEach(option => {
        // Check if we already have this option
        const exists = allOptionsByGroup[groupName].some(
          existingOption => existingOption.id === option.id
        );
        
        if (!exists) {
          allOptionsByGroup[groupName].push(option);
        }
      });

      // Sort options by ID to ensure consistent display order
      allOptionsByGroup[groupName].sort((a, b) => parseInt(a.id) - parseInt(b.id));
    });
    
    console.log('All available options by group:', 
      Object.entries(allOptionsByGroup).map(([group, opts]) => 
        `${group}: ${opts.map(o => o.name).join(', ')}`
      ).join(' | ')
    );
    
    return (
      <div className="cart-item-options editable">
        {Object.entries(allOptionsByGroup).map(([groupName, groupOptions]) => {
          // Skip groups that aren't relevant for this item type
          if (!relevantGroups[groupName]) {
            return null;
          }
          
          // Determine if this is a single selection group (like Bun Type)
          const isSingleSelection = groupName === 'Bun Type';
          
          // Get current selected options for this group
          const selectedOptions = item.options[groupName] || (isSingleSelection ? '' : []);
          
          console.log(`Group ${groupName} has options:`, groupOptions.map(o => o.name).join(', '));
          console.log(`Selected options for ${groupName}:`, selectedOptions);
          
          return (
            <div key={groupName} className="cart-item-option-group">
              <strong>{groupName}:</strong>
              
              {isSingleSelection ? 
                renderRadioOptions(groupName, groupOptions, selectedOptions, item.cart_item_id) : (
                // For multiple selections (checkboxes like toppings)
                <CheckboxOptionGroup 
                  groupName={groupName}
                  groupOptions={groupOptions}
                  selectedOptions={selectedOptions}
                  editingItem={editingItem}
                  setEditingItem={setEditingItem}
                />
              )}
            </div>
          );
        })}
        
        <div className="edit-actions">
          <button 
            className="save-edit-button" 
            onClick={() => {
              // Force a direct save with the current editingItem state
              console.log('Save button clicked');
              // Get the current options directly from state
              const currentOptions = {...editingItem.options};
              console.log('Saving directly with options:', JSON.stringify(currentOptions));
              
              // Log item ID and details
              console.log('Cart item ID:', editingItem.cart_item_id);
              console.log('Full editing item:', editingItem);
              
              // Store current options to local storage for backup
              localStorage.setItem('debug_save_options', JSON.stringify(currentOptions));
              localStorage.setItem('debug_cart_item', editingItem.cart_item_id);
              
              // Directly call update function with explicit options
              updateCartItemOptions(
                editingItem.cart_item_id, 
                currentOptions
              ).then(updatedCart => {
                console.log('Update successful:', updatedCart.length, 'items');
                setCartItems(updatedCart);
                setEditingItem(null);
                
                // Update totals
                getCartTotal().then(total => {
                  setCartTotal(total);
                });
                
                // Force refresh items
                setTimeout(() => {
                  getCartItems().then(freshItems => {
                    console.log('Refreshed cart items:', freshItems.length);
                    setCartItems(freshItems);
                  });
                }, 200);
              }).catch(err => {
                console.error('Error saving options:', err);
              });
            }}
            ref={btn => {
              if (btn) {
                btn.addEventListener('click', () => {
                  console.log('BUTTON CLICKED DIRECTLY!');
                });
              }
            }}
          >
            Save Changes
          </button>
          <button className="cancel-edit-button" onClick={handleCancelEdit}>
            Cancel
          </button>
        </div>
      </div>
    );
  };

  // Render subtotal, tax and total amounts - with tax above subtotal as shown in screenshot
  const renderAmounts = () => {
    // Get values from business settings
    const isTaxEnabled = business.collect_sales_tax === 1 || business.collect_sales_tax === '1' || business.collect_sales_tax === true;
    const taxRate = parseFloat(business.tax_rate) || 0;
    
    return (
      <>
        {/* Tax line with actual tax rate or "Tax is $0.00" */}
        <div className="cart-tax">
          {isTaxEnabled && taxRate > 0 ? (
            <span>Sales Tax ({taxRate}%):</span>
          ) : (
            <span>Tax is $0.00:</span>
          )}
          <span>${salesTax.toFixed(2)}</span>
        </div>
        
        {/* Subtotal under Sales Tax as in screenshot */}
        <div className="cart-subtotal">
          <span>Subtotal:</span>
          <span>${cartTotal.toFixed(2)}</span>
        </div>
        
        <div className="cart-separator"></div>
        
        {/* Total with red text */}
        <div className="cart-grand-total">
          <span>Total:</span>
          <span className="red-price">${orderTotal.toFixed(2)}</span>
        </div>
      </>
    );
  };

  const renderCartContent = () => {
    if (loading) {
      return <div className="loading">Loading cart...</div>;
    }
    
    if (cartItems.length === 0) {
      return (
        <div className="empty-cart">
          <div className="empty-cart-icon">🛒</div>
          <h3>Your cart is empty</h3>
          <p>Add items to get started!</p>
        </div>
      );
    }
    
    return (
      <>
        <div className="cart-items">
          {cartItems.map(item => (
            <div key={item.cart_item_id} className="cart-item">
              <div className="cart-item-image">
                {item.image_url ? (
                  <img src={item.image_url} alt={item.name} />
                ) : (
                  <div className="no-image">No Image</div>
                )}
              </div>
              
              <div className="cart-item-details">
                <h3 className="cart-item-name">{item.name || "Menu Item"}</h3>
                <div className="cart-item-price">
                  ${parseFloat(item.total_price || 0).toFixed(2)}
                </div>
                
                {renderCartItemOptions(item)}
                
                {item.special_instructions && (
                  <div className="cart-item-special">
                    <small>{item.special_instructions}</small>
                  </div>
                )}
                
                <div className="cart-item-quantity">
                  <button 
                    className="quantity-button"
                    onClick={() => handleQuantityChange(item.cart_item_id, -1)}
                  >-</button>
                  <span className="quantity-display">{item.quantity}</span>
                  <button 
                    className="quantity-button"
                    onClick={() => handleQuantityChange(item.cart_item_id, 1)}
                  >+</button>
                  
                  {editingItem?.cart_item_id === item.cart_item_id ? (
                    null // Hide remove button while editing
                  ) : (
                    <>
                      <button 
                        className="edit-item"
                        onClick={() => handleEditItem(item)}
                      >Edit</button>
                      <button 
                        className="remove-item"
                        onClick={() => handleRemoveItem(item.cart_item_id)}
                      >Remove</button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        
        <div className="cart-summary">
          <div className="cart-buttons">
            <button className="clear-cart-button" onClick={handleClearCart}>Clear Cart</button>
            <button className="checkout-button" onClick={handleProceedToCheckout}>Proceed to Checkout</button>
          </div>
          {renderAmounts()}
        </div>
      </>
    );
  };

  const renderCheckoutForm = () => {
    if (paymentStatus) {
      return (
        <div className={`payment-status ${paymentStatus.success ? 'success' : 'error'}`}>
          <div className="payment-status-icon">
            {paymentStatus.success ? '✅' : '❌'}
          </div>
          <h3>{paymentStatus.success ? 'Order Confirmed!' : 'Payment Failed'}</h3>
          <p>{paymentStatus.message}</p>
          {paymentStatus.success && (
            <div className="order-details">
              <p>Order ID: {paymentStatus.orderId}</p>
              <p>Thank you for your order!</p>
            </div>
          )}
          <div className="cart-actions">
            {paymentStatus.success ? (
              <button className="checkout-button" onClick={handleClose}>
                Continue Shopping
              </button>
            ) : (
              <button className="checkout-button" onClick={() => setPaymentStatus(null)}>
                Try Again
              </button>
            )}
          </div>
        </div>
      );
    }
    
    return (
      <form className="checkout-form" onSubmit={handleSubmitOrder}>
        <div className="checkout-section">
          <h3 className="checkout-section-title">Delivery Method</h3>
          <div className="delivery-options">
            <div 
              className={`delivery-option ${deliveryMethod === 'delivery' ? 'selected' : ''}`}
              onClick={() => handleDeliveryMethodChange('delivery')}
            >
              <div className="delivery-option-icon">🚚</div>
              <div className="delivery-option-label">Delivery</div>
            </div>
            <div 
              className={`delivery-option ${deliveryMethod === 'pickup' ? 'selected' : ''}`}
              onClick={() => handleDeliveryMethodChange('pickup')}
            >
              <div className="delivery-option-icon">🏪</div>
              <div className="delivery-option-label">Pickup</div>
            </div>
          </div>
        </div>
        
        <div className="checkout-section">
          <h3 className="checkout-section-title">Contact Information</h3>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              value={checkoutFormData.name} 
              onChange={handleInputChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input 
              type="tel" 
              id="phone" 
              name="phone" 
              value={checkoutFormData.phone} 
              onChange={handleInputChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={checkoutFormData.email} 
              onChange={handleInputChange} 
              required 
            />
          </div>
        </div>
        
        {deliveryMethod === 'delivery' && (
          <div className="checkout-section">
            <h3 className="checkout-section-title">Delivery Address</h3>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <textarea 
                id="address" 
                name="address" 
                value={checkoutFormData.address} 
                onChange={handleInputChange} 
                required 
              />
            </div>
          </div>
        )}
        
        <div className="checkout-section">
          <h3 className="checkout-section-title">Payment Method</h3>
          <div className="payment-methods">
            <div 
              className={`payment-method ${paymentMethod === 'card' ? 'selected' : ''}`}
              onClick={() => handlePaymentMethodChange('card')}
            >
              <div className="payment-method-icon">💳</div>
              <div className="payment-method-label">Credit Card</div>
            </div>
            <div 
              className={`payment-method ${paymentMethod === 'cash' ? 'selected' : ''}`}
              onClick={() => handlePaymentMethodChange('cash')}
            >
              <div className="payment-method-icon">💵</div>
              <div className="payment-method-label">Cash on Delivery</div>
            </div>
          </div>
        </div>
        
        {paymentMethod === 'card' && (
          <div className="checkout-section">
            <h3 className="checkout-section-title">Card Details</h3>
            <div className="form-group">
              <label htmlFor="cardNumber">Card Number</label>
              <input 
                type="text" 
                id="cardNumber" 
                name="cardNumber" 
                value={checkoutFormData.cardNumber} 
                onChange={handleInputChange} 
                placeholder="1234 5678 9012 3456"
                required 
              />
            </div>
            <div className="form-row">
              <div className="form-group half">
                <label htmlFor="cardExpiry">Expiry Date</label>
                <input 
                  type="text" 
                  id="cardExpiry" 
                  name="cardExpiry" 
                  value={checkoutFormData.cardExpiry} 
                  onChange={handleInputChange} 
                  placeholder="MM/YY"
                  required 
                />
              </div>
              <div className="form-group half">
                <label htmlFor="cardCVV">CVV</label>
                <input 
                  type="text" 
                  id="cardCVV" 
                  name="cardCVV" 
                  value={checkoutFormData.cardCVV} 
                  onChange={handleInputChange} 
                  placeholder="123"
                  required 
                />
              </div>
            </div>
          </div>
        )}
        
        <div className="checkout-section">
          <h3 className="checkout-section-title">Additional Instructions</h3>
          <div className="form-group">
            <label htmlFor="specialInstructions">Special Instructions (optional)</label>
            <textarea 
              id="specialInstructions" 
              name="specialInstructions" 
              value={checkoutFormData.specialInstructions} 
              onChange={handleInputChange} 
              placeholder="Any special requests for your order..."
            />
          </div>
        </div>
        
        <div className="checkout-summary">
          <div className="checkout-total">
            <span>Order Total:</span>
            <span>${orderTotal.toFixed(2)}</span>
          </div>
          <div className="checkout-actions">
            <button type="button" className="back-button" onClick={handleBackToCart}>
              Back to Cart
            </button>
            <button type="submit" className="place-order-button" disabled={processingPayment}>
              {processingPayment ? 'Processing...' : 'Place Order'}
            </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      {/* Cart icon button - only shown when cart is closed */}
      {!isOpen && (
        <button 
          className={`cart-button force-visible ${cartItems.length > 0 ? 'has-items' : ''}`}
          onClick={() => onClose(true)}
        >
          🛒
          {cartItems.length > 0 && (
            <span className="cart-count">{cartItems.length}</span>
          )}
        </button>
      )}
      
      {/* Cart panel */}
      <div 
        className={`cart-panel ${isOpen ? 'open' : ''}`}
        onClick={(e) => e.stopPropagation()} 
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className="cart-header">
          <div className="cart-header-content">
            {!business?.business_name ? (
              <h2 style={{color: 'yellow', fontWeight: 'bold'}}>⚠️ Please Set Up Your Business Name in Settings</h2>
            ) : (
              <h2>{business.business_name}</h2>
            )}
            <h3>{checkoutMode ? 'Checkout' : 'Made Special for You'}</h3>
          </div>
          <button className="close-cart" onClick={handleClose}>
            {checkoutMode ? 'Back' : '×'}
          </button>
        </div>
        
        <div className="cart-content" style={{ overflow: 'hidden' }}>
          {checkoutMode ? renderCheckoutForm() : renderCartContent()}
        </div>
      </div>
    </>
  );
};

export default Cart; 