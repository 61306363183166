import React, { useState, useEffect, useCallback, useRef } from 'react';
import MenuItemDetail from '../MenuItemDetail/MenuItemDetail';
import { addToCart } from '../../services/cart/cartService';
import { getApiUrl, fetchWithAuth } from '../../utils/apiUtils';
import { useAuth } from '../../contexts/AuthContext';
import './MobileMenuList.css';

const MobileMenuList = ({ 
  items = [], 
  categories: propCategories = [], 
  skipFetch = false,
  menuTitle = null,
  selectedCategory = null
}) => {
  const [layoutType, setLayoutType] = useState(localStorage.getItem('layoutType') || 'grid');
  const [title, setTitle] = useState(menuTitle || 'Fresh from the Kitchen');
  const [selectedCategoryId, setSelectedCategoryId] = useState(selectedCategory?.id || null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isBuilderMode, setIsBuilderMode] = useState(false);
  const [categories, setCategories] = useState(propCategories);
  const [forceShowCart, setForceShowCart] = useState(false);
  
  // Get authentication state
  const { isAuthenticated } = useAuth();
  
  // Add refs to track mount state and fetch attempts
  const isMounted = useRef(true);
  const hasAttemptedFetch = useRef(false);
  const categoriesRef = useRef(categories);

  // Force cart visibility on mount
  useEffect(() => {
    console.log("MobileMenuList mounted - dispatching cart state change for visibility");
    // Dispatch cartStateChange event to notify PhoneFrame to show cart button
    const event = new CustomEvent('cartStateChange', {
      detail: { isCartOpen: false }
    });
    window.dispatchEvent(event);
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Update categories ref when they change
  useEffect(() => {
    categoriesRef.current = categories;
  }, [categories]);

  // Check builder mode once on mount
  useEffect(() => {
    setIsBuilderMode(!!window.editMenuItem);
  }, []);

  // Update categories when propCategories change, but only if they're different
  useEffect(() => {
    if (propCategories?.length > 0 && 
        JSON.stringify(propCategories) !== JSON.stringify(categoriesRef.current)) {
      console.log("Categories updated:", propCategories.length);
      setCategories(propCategories);
    }
  }, [propCategories]);

  // Load layout preference and menu title from localStorage if available
  useEffect(() => {
    const savedLayout = localStorage.getItem('menuLayoutType');
    if (savedLayout) {
      setLayoutType(savedLayout);
    }

    // Only use saved title for regular menu view
    const savedTitle = localStorage.getItem('menuTitle');
    if (savedTitle && items.length > 0) {
      setTitle(savedTitle);
    }

    // Event listeners for menu customization
    const storageListener = (e) => {
      if (e.key === 'menuLayoutType') {
        setLayoutType(e.newValue);
      } else if (e.key === 'menuTitle' && items.length > 0) {
        setTitle(e.newValue);
      }
    };

    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, [items.length]);

  // Fetch categories only if needed
  useEffect(() => {
    // Skip if any of these conditions are true
    if (skipFetch || 
        categories.length > 0 || 
        hasAttemptedFetch.current || 
        !isMounted.current) {
      return;
    }

    const fetchCategories = async () => {
      hasAttemptedFetch.current = true;
      try {
        const response = await fetchWithAuth(getApiUrl('categories') + '?action=getAllCategories');
        
        if (!isMounted.current) return;
        
        if (response.ok) {
          const data = await response.json();
          if (data.status === 'success' && data.categories) {
            const sortedCategories = data.categories
              .sort((a, b) => (a.sort_order || 999) - (b.sort_order || 999));
            setCategories(sortedCategories);
          }
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [skipFetch]); // Only depend on skipFetch

  // Save layout preference when it changes
  useEffect(() => {
    localStorage.setItem('menuLayoutType', layoutType);
  }, [layoutType]);

  // Handle tab switching
  const handleTabClick = (tab) => {
    console.log(`Tab clicked: ${tab}`);
    
    // For categories tab, use the direct render function if available
    if (tab === 'categories' && window.renderCategoriesDirectly) {
      console.log('Directly rendering categories with window.renderCategoriesDirectly');
      window.renderCategoriesDirectly();
      return;
    }
    
    // Convert tab to appropriate section name for other tabs
    const section = tab === 'items' ? 'menu' : tab;
    
    // Use the global function for other tabs
    if (window.setActiveSection) {
      console.log(`Calling window.setActiveSection with ${section}`);
      window.setActiveSection(section);
    } else {
      console.warn('window.setActiveSection is not available');
    }
  };

  // Toggle between list and card layout
  const toggleLayout = () => {
    const newLayout = layoutType === 'grid' ? 'list' : 'grid';
    setLayoutType(newLayout);
    localStorage.setItem('layoutType', newLayout);
  };

  // Toggle filter dropdown
  const toggleFilter = () => {
    setIsFilterOpen(prev => !prev);
  };

  // Handle category selection
  const handleCategorySelect = (categoryId) => {
    setSelectedCategoryId(categoryId === selectedCategoryId ? null : categoryId);
    setIsFilterOpen(false);
  };

  useEffect(() => {
    if (items) {
      filterItems(selectedCategoryId);
    }
  }, [items, selectedCategoryId]);

  const filterItems = (category) => {
    let filtered = [...items];
    if (category && category !== 'all') {
      // Convert category to number for consistent comparison
      const targetCategoryId = parseInt(category);
      
      // Use Set to prevent duplicates
      const filteredSet = new Set();
      
      filtered = items.filter(item => {
        // Skip if we've already added this item
        if (filteredSet.has(item.id)) return false;
        
        // Check primary category
        if (parseInt(item.category_id) === targetCategoryId) {
          filteredSet.add(item.id);
          return true;
        }
        
        // Don't check additional categories - we only want items where this is the primary category
        return false;
      });
    }
    setFilteredItems(filtered);
  };

  // Group items by category
  const groupedItems = () => {
    // If a category is selected, don't group - just return filtered items
    if (selectedCategoryId) {
      return { items: filteredItems };
    }
    
    // Get all available categories from items
    const groupedByCategory = {};
    
    // Only use primary category for grouping
    items.forEach(item => {
      const categoryId = item.category_id;
      if (!groupedByCategory[categoryId]) {
        groupedByCategory[categoryId] = [];
      }
      groupedByCategory[categoryId].push(item);
    });
    
    // Sort categories by sort_order if available
    const sortedCategories = Object.keys(groupedByCategory).map(catId => ({
      id: parseInt(catId),
      name: categories.find(c => c.id === parseInt(catId))?.name || 'Uncategorized',
      sort_order: categories.find(c => c.id === parseInt(catId))?.sort_order || 999,
      items: groupedByCategory[catId]
    })).sort((a, b) => a.sort_order - b.sort_order);
    
    return { categories: sortedCategories };
  };

  // Handle item click - updated to show detail view or edit based on mode
  const handleItemClick = (item) => {
    // In user mode, show the detail view
    setSelectedItem(item);
  };

  // Handle back button in detail view
  const handleBackFromDetail = () => {
    setSelectedItem(null);
  };

  // Handle add to cart
  const handleAddToCart = async (cartItem) => {
    try {
      await addToCart(cartItem);
      // Show success message or open cart
      setSelectedItem(null); // Go back to menu list
      
      // Dispatch cartStateChange event to notify PhoneFrame to open cart
      const event = new CustomEvent('cartStateChange', {
        detail: { isCartOpen: true }
      });
      window.dispatchEvent(event);
      setIsCartOpen(true);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  // If a menu item is selected, show its detail view
  if (selectedItem) {
    return (
      <MenuItemDetail 
        item={selectedItem}
        onBack={handleBackFromDetail}
        onAddToCart={handleAddToCart}
      />
    );
  }

  // Render different layouts based on layoutType
  const renderMenuItems = () => {
    // When a category is selected, only show items from that category
    if (selectedCategoryId) {
      if (filteredItems.length === 0) {
        return <div className="loading">No menu items found in this category</div>;
      }

      // Render items in the selected layout without category grouping
      if (layoutType === 'grid') {
        return (
          <div className="menu-items-grid">
            {filteredItems.map(item => (
              <div key={item.id} className="menu-item-card-grid" onClick={() => handleItemClick(item)}>
                <div className="menu-item-image-grid">
                  {item.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <div className="no-image">No Image</div>
                  )}
                </div>
                <div className="menu-item-content-grid">
                  <div className="menu-item-name">{item.name}</div>
                  <div className="menu-item-price">${parseFloat(item.price).toFixed(2)}</div>
                  <div className="menu-item-description">{item.description || 'No description'}</div>
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div className="menu-items-list">
            {filteredItems.map(item => (
              <div key={item.id} className="menu-item-row" onClick={() => handleItemClick(item)}>
                <div className="menu-item-content">
                  <div className="menu-item-name">{item.name}</div>
                  <div className="menu-item-price">${parseFloat(item.price).toFixed(2)}</div>
                  <div className="menu-item-description">{item.description || 'No description'}</div>
                </div>
                <div className="menu-item-image">
                  {item.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <div className="no-image">No Image</div>
                  )}
                </div>
                <div className="chevron-right">›</div>
              </div>
            ))}
          </div>
        );
      }
    } else {
      // Render with category grouping
      const { categories: groupedCategories } = groupedItems();
      return (
        <div className="menu-categories-container">
          {groupedCategories.map(category => (
            <div key={category.id} className="menu-category">
              <h2 className="category-heading">{category.name}</h2>
              {layoutType === 'grid' ? (
                <div className="menu-items-grid">
                  {category.items.map(item => (
                    <div key={item.id} className="menu-item-card-grid" onClick={() => handleItemClick(item)}>
                      <div className="menu-item-image-grid">
                        {item.image_url ? (
                          <img src={item.image_url} alt={item.name} />
                        ) : (
                          <div className="no-image">No Image</div>
                        )}
                      </div>
                      <div className="menu-item-content-grid">
                        <div className="menu-item-name">{item.name}</div>
                        <div className="menu-item-price">${parseFloat(item.price).toFixed(2)}</div>
                        <div className="menu-item-description">{item.description || 'No description'}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="menu-items-list">
                  {category.items.map(item => (
                    <div key={item.id} className="menu-item-row" onClick={() => handleItemClick(item)}>
                      <div className="menu-item-content">
                        <div className="menu-item-name">{item.name}</div>
                        <div className="menu-item-price">${parseFloat(item.price).toFixed(2)}</div>
                        <div className="menu-item-description">{item.description || 'No description'}</div>
                      </div>
                      <div className="menu-item-image">
                        {item.image_url ? (
                          <img src={item.image_url} alt={item.name} />
                        ) : (
                          <div className="no-image">No Image</div>
                        )}
                      </div>
                      <div className="chevron-right">›</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="mobile-menu-container">
      <div className="mobile-menu-header">
        <h1>{title}</h1>
        <div className="header-actions">
          <button className="layout-toggle" onClick={toggleLayout}>
            <i className={`fas fa-${layoutType === 'grid' ? 'th-list' : 'th'}`}></i>
          </button>
        </div>
      </div>
      
      {isFilterOpen && (
        <div className="category-filter-dropdown">
          <div 
            className={`filter-option ${selectedCategoryId === null ? 'active' : ''}`}
            onClick={() => handleCategorySelect(null)}
          >
            All Categories
          </div>
          {categories.map(category => (
            <div 
              key={category.id}
              className={`filter-option ${selectedCategoryId === category.id ? 'active' : ''}`}
              onClick={() => handleCategorySelect(category.id)}
            >
              {category.name}
            </div>
          ))}
        </div>
      )}
      
      {renderMenuItems()}
      
      {/* Bottom Navigation - use consistent icons and styling */}
      <div className="nav-tabs">
        <div 
          className="tab active" 
          onClick={() => handleTabClick('items')}
        >
          <i className="fas fa-utensils"></i>
          <span>Items</span>
        </div>
        <div 
          className="tab"
          onClick={() => handleTabClick('categories')}
        >
          <i className="fas fa-list"></i>
          <span>Categories</span>
        </div>
      </div>
    </div>
  );
};

export default MobileMenuList; 