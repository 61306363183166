import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { useAuth } from '../contexts/AuthContext';
import AdminNavigation from './components/AdminNavigation';
import AuthMonitoring from './sections/AuthMonitoring';
import MenuMonitoring from './sections/MenuMonitoring';
import OrderProcessing from './sections/OrderProcessing';
import UserActivity from './sections/UserActivity';
import SubscriptionMonitoring from './sections/SubscriptionMonitoring';
import SystemHealth from './sections/SystemHealth';
import SecurityMonitoring from './sections/SecurityMonitoring';
import BusinessIntelligence from './sections/BusinessIntelligence';
import ErrorTracking from './sections/ErrorTracking';
import NotificationMonitoring from './sections/NotificationMonitoring';
import './Dashboard.css';

const { Content } = Layout;

// Debug component to verify section rendering
const DebugRoute = ({ section, Component }) => {
  console.log(`DEBUG: Rendering section "${section}" with component:`, Component.name);
  return <Component section={section} />;
};

const Dashboard = () => {
  const location = useLocation();
  const { isAuthenticated, user, logout } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  
  // Determine if we're on the dashboard or admin path
  const isDashboardPath = location.pathname.startsWith('/dashboard');
  const basePath = isDashboardPath ? '/dashboard' : '/admin';
  
  useEffect(() => {
    console.log("Dashboard mounted, current route:", location.pathname, "basePath:", basePath);
  }, [location.pathname, basePath]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const goToBuilder = () => {
    navigate('/builder');
  };

  // Extract the section from the URL path to highlight the active menu item
  const currentPath = location.pathname;
  
  // Get the section name from the path (e.g., /admin/errors -> errors)
  // For paths like /admin or /admin/, set section to 'auth'
  const pathParts = currentPath.split('/').filter(Boolean);
  let currentSection = 'auth';
  
  // If path is /admin/something or /dashboard/something, get the 'something'
  if (pathParts.length > 1 && (pathParts[0] === 'admin' || pathParts[0] === 'dashboard') && pathParts[1]) {
    currentSection = pathParts[1];
  }
  
  console.log("Rendering dashboard with path:", currentPath, "section:", currentSection, "basePath:", basePath);

  return (
    <Layout className="admin-layout">
      <div className="admin-header">
        <h1>Dashboard</h1>
        <AdminNavigation 
          onLogout={handleLogout}
          onGoToBuilder={goToBuilder}
          user={user}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          currentSection={currentSection}
        />
      </div>
      <Content className="admin-main">
        <Routes>
          {/* Default route - Authentication section */}
          <Route index element={<DebugRoute section="auth" Component={AuthMonitoring} />} />
          
          {/* Explicit empty path to ensure root admin path works */}
          <Route path="" element={<DebugRoute section="auth" Component={AuthMonitoring} />} />
          
          {/* Section specific routes */}
          <Route path="menu/*" element={<DebugRoute section="menu" Component={MenuMonitoring} />} />
          <Route path="orders/*" element={<DebugRoute section="orders" Component={OrderProcessing} />} />
          <Route path="users/*" element={<DebugRoute section="users" Component={UserActivity} />} />
          <Route path="subscriptions/*" element={<DebugRoute section="subscriptions" Component={SubscriptionMonitoring} />} />
          <Route path="system/*" element={<DebugRoute section="system" Component={SystemHealth} />} />
          <Route path="security/*" element={<DebugRoute section="security" Component={SecurityMonitoring} />} />
          <Route path="business/*" element={<DebugRoute section="business" Component={BusinessIntelligence} />} />
          <Route path="errors/*" element={<DebugRoute section="errors" Component={ErrorTracking} />} />
          <Route path="notifications/*" element={<DebugRoute section="notifications" Component={NotificationMonitoring} />} />
          
          {/* Catch-all route for unmatched paths */}
          <Route path="*" element={<Navigate to={basePath} replace />} />
        </Routes>
      </Content>
    </Layout>
  );
};

export default Dashboard; 