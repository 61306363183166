import axios from 'axios';

// Get API URL from environment or fallback to derived value
const API_BASE_URL = process.env.REACT_APP_API_URL 
  ? `${process.env.REACT_APP_API_URL}/api`  // Use environment variable if available
  : process.env.NODE_ENV === 'production' 
    ? `${window.location.origin}/api`  // Use origin (without port) in production
    : 'http://localhost:9000/api';     // Fallback for development

console.log('✅ API Base URL (from env):', process.env.REACT_APP_API_URL);
console.log('✅ API Base URL (resolved):', API_BASE_URL);

// Helper function to build endpoint URLs
const buildEndpoint = (path) => {
  // Don't add .php extension in either environment
  return `${API_BASE_URL}/${path.replace(/\.php$/, '')}`;
};

export const API_ENDPOINTS = {
  checkUsername: buildEndpoint('check-username'),
  categories: buildEndpoint('categories'),
  categoriesReorder: buildEndpoint('categories/reorder'),
  createAccount: buildEndpoint('create_account'),
  verifyEmail: buildEndpoint('verify'),
  initializeUser: buildEndpoint('initialize'),
  login: buildEndpoint('login'),
  logout: buildEndpoint('auth?action=logout'),
  requestPasswordReset: buildEndpoint('request_password_reset'),
  resetPassword: buildEndpoint('reset_password'),
  validateResetToken: buildEndpoint('validate_reset_token'),
  status: buildEndpoint('status'),
  healthCheck: buildEndpoint('health-check'),
  admin: buildEndpoint('admin'),
  businessInfo: buildEndpoint('business-settings'),
  menuItems: buildEndpoint('menu_items'),
  user: {
    profile: buildEndpoint('user/profile'),
    favorites: buildEndpoint('user/favorites'),
    me: buildEndpoint('user/me'),
    directUpdate: buildEndpoint('direct_user_update')
  },
};

// Log API configuration
console.log('API Base URL:', API_BASE_URL);

// Configure axios defaults
axios.defaults.withCredentials = true; // Always include credentials/cookies

// Remove the global Content-Type header as it can interfere with file uploads
// and axios will set the appropriate content type based on the data
// axios.defaults.headers.common['Content-Type'] = 'application/json';

// Add request interceptor for debugging
axios.interceptors.request.use(
  config => {
    console.log(`Making ${config.method?.toUpperCase()} request to: ${config.url}`);
    
    // Ensure withCredentials is true for all requests
    config.withCredentials = true;
    
    // Set Content-Type for JSON requests
    if (!config.headers['Content-Type'] && 
        !config.headers['content-type'] && 
        !config.formData) { // don't set for FormData
      config.headers['Content-Type'] = 'application/json';
    }
    
    // Log the full request configuration for login requests to help debug
    if (config.url?.includes('login.php')) {
      console.log('Login request configuration:', {
        url: config.url,
        method: config.method,
        withCredentials: config.withCredentials,
        headers: config.headers,
        data: config.data ? JSON.stringify(config.data) : null
      });
    }
    
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle auth errors
axios.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', error.message);
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request was made but no response was received', error.request);
    }
    
    // Handle 401 Unauthorized errors
    if (error.response && error.response.status === 401) {
      console.log('Authentication error detected');
      // Could trigger logout or redirect to login here
    }
    return Promise.reject(error);
  }
);

export default API_ENDPOINTS; 

const apiUrl = process.env.REACT_APP_API_URL; 