import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import './Orders.css';

const Orders = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [viewMode, setViewMode] = useState('active');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [filter, setFilter] = useState('all');
  const [search, setSearch] = useState('');

  // Order status options
  const statusOptions = [
    { value: 'received', label: 'Order Received', icon: 'fas fa-clipboard-check' },
    { value: 'preparing', label: 'Preparing', icon: 'fas fa-utensils' },
    { value: 'quality_check', label: 'Quality Check', icon: 'fas fa-clipboard-list' },
    { value: 'ready', label: 'Ready to Enjoy', icon: 'fas fa-check-circle' },
    { value: 'picked_up', label: 'Picked Up', icon: 'fas fa-walking' },
    { value: 'completed', label: 'Completed', icon: 'fas fa-flag-checkered' },
    { value: 'canceled', label: 'Canceled', icon: 'fas fa-times-circle' }
  ];

  // Sample orders data
  const sampleOrders = [
    {
      id: 'ORD-2023-5678',
      customer: {
        name: 'John Smith',
        email: 'john.smith@example.com',
        phone: '555-123-4567'
      },
      items: [
        { id: 1, name: 'Classic Cheeseburger', price: 8.99, quantity: 2, notes: 'No onions' },
        { id: 2, name: 'French Fries', price: 3.49, quantity: 1, notes: '' },
        { id: 3, name: 'Chocolate Milkshake', price: 4.99, quantity: 1, notes: 'Extra whipped cream' }
      ],
      status: 'preparing',
      orderType: 'pickup',
      paymentMethod: 'credit_card',
      subtotal: 26.46,
      tax: 2.12,
      total: 28.58,
      createdAt: new Date(Date.now() - 45 * 60000).toISOString(), // 45 minutes ago
      scheduledFor: new Date(Date.now() + 15 * 60000).toISOString(), // 15 minutes from now
      statusHistory: [
        { status: 'received', timestamp: new Date(Date.now() - 45 * 60000).toISOString() },
        { status: 'preparing', timestamp: new Date(Date.now() - 30 * 60000).toISOString() }
      ]
    },
    {
      id: 'ORD-2023-5677',
      customer: {
        name: 'Mary Johnson',
        email: 'mary.j@example.com',
        phone: '555-987-6543'
      },
      items: [
        { id: 4, name: 'Margherita Pizza', price: 12.99, quantity: 1, notes: 'Well done' },
        { id: 5, name: 'Caesar Salad', price: 7.99, quantity: 1, notes: 'Dressing on the side' },
        { id: 6, name: 'Garlic Bread', price: 4.49, quantity: 1, notes: '' }
      ],
      status: 'received',
      orderType: 'pickup',
      paymentMethod: 'paypal',
      subtotal: 25.47,
      tax: 2.04,
      total: 27.51,
      createdAt: new Date(Date.now() - 15 * 60000).toISOString(), // 15 minutes ago
      scheduledFor: new Date(Date.now() + 30 * 60000).toISOString(), // 30 minutes from now
      statusHistory: [
        { status: 'received', timestamp: new Date(Date.now() - 15 * 60000).toISOString() }
      ]
    },
    {
      id: 'ORD-2023-5676',
      customer: {
        name: 'Robert Williams',
        email: 'rob.w@example.com',
        phone: '555-456-7890'
      },
      items: [
        { id: 7, name: 'Chicken Wings', price: 10.99, quantity: 2, notes: 'Extra spicy' },
        { id: 8, name: 'Onion Rings', price: 4.99, quantity: 1, notes: '' },
        { id: 9, name: 'Soda', price: 1.99, quantity: 2, notes: 'No ice' }
      ],
      status: 'quality_check',
      orderType: 'pickup',
      paymentMethod: 'credit_card',
      subtotal: 30.95,
      tax: 2.48,
      total: 33.43,
      createdAt: new Date(Date.now() - 60 * 60000).toISOString(), // 60 minutes ago
      scheduledFor: new Date(Date.now() + 5 * 60000).toISOString(), // 5 minutes from now
      statusHistory: [
        { status: 'received', timestamp: new Date(Date.now() - 60 * 60000).toISOString() },
        { status: 'preparing', timestamp: new Date(Date.now() - 40 * 60000).toISOString() },
        { status: 'quality_check', timestamp: new Date(Date.now() - 10 * 60000).toISOString() }
      ]
    },
    {
      id: 'ORD-2023-5675',
      customer: {
        name: 'Patricia Brown',
        email: 'pat.brown@example.com',
        phone: '555-789-0123'
      },
      items: [
        { id: 10, name: 'Veggie Burger', price: 9.99, quantity: 1, notes: 'Add avocado' },
        { id: 11, name: 'Sweet Potato Fries', price: 4.49, quantity: 1, notes: '' },
        { id: 12, name: 'Iced Tea', price: 2.49, quantity: 1, notes: 'Lemon on the side' }
      ],
      status: 'ready',
      orderType: 'pickup',
      paymentMethod: 'credit_card',
      subtotal: 16.97,
      tax: 1.36,
      total: 18.33,
      createdAt: new Date(Date.now() - 75 * 60000).toISOString(), // 75 minutes ago
      scheduledFor: new Date(Date.now() - 5 * 60000).toISOString(), // 5 minutes ago
      statusHistory: [
        { status: 'received', timestamp: new Date(Date.now() - 75 * 60000).toISOString() },
        { status: 'preparing', timestamp: new Date(Date.now() - 60 * 60000).toISOString() },
        { status: 'quality_check', timestamp: new Date(Date.now() - 30 * 60000).toISOString() },
        { status: 'ready', timestamp: new Date(Date.now() - 15 * 60000).toISOString() }
      ]
    },
    {
      id: 'ORD-2023-5674',
      customer: {
        name: 'Jennifer Garcia',
        email: 'jen.g@example.com',
        phone: '555-321-0987'
      },
      items: [
        { id: 13, name: 'Fish Tacos', price: 11.99, quantity: 2, notes: 'Extra sauce' },
        { id: 14, name: 'Chips & Salsa', price: 4.99, quantity: 1, notes: '' },
        { id: 15, name: 'Lemonade', price: 2.99, quantity: 2, notes: '' }
      ],
      status: 'completed',
      orderType: 'pickup',
      paymentMethod: 'credit_card',
      subtotal: 34.95,
      tax: 2.80,
      total: 37.75,
      createdAt: new Date(Date.now() - 24 * 60 * 60000).toISOString(), // 1 day ago
      scheduledFor: new Date(Date.now() - 23 * 60 * 60000).toISOString(), // 23 hours ago
      statusHistory: [
        { status: 'received', timestamp: new Date(Date.now() - 24 * 60 * 60000).toISOString() },
        { status: 'preparing', timestamp: new Date(Date.now() - 23.8 * 60 * 60000).toISOString() },
        { status: 'quality_check', timestamp: new Date(Date.now() - 23.5 * 60 * 60000).toISOString() },
        { status: 'ready', timestamp: new Date(Date.now() - 23.2 * 60 * 60000).toISOString() },
        { status: 'picked_up', timestamp: new Date(Date.now() - 23 * 60 * 60000).toISOString() },
        { status: 'completed', timestamp: new Date(Date.now() - 22.8 * 60 * 60000).toISOString() }
      ]
    }
  ];

  useEffect(() => {
    // In real implementation, fetch orders from API
    // Simulating API call
    setTimeout(() => {
      setOrders(sampleOrders);
      setLoading(false);
    }, 800);
  }, []);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    setSelectedOrder(null);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectOrder = (order) => {
    setSelectedOrder(order);
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    setStatusUpdateLoading(true);
    
    try {
      // In real implementation, make API call to update status
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const now = new Date().toISOString();
      
      // Update orders list
      const updatedOrders = orders.map(order => {
        if (order.id === orderId) {
          return {
            ...order,
            status: newStatus,
            statusHistory: [
              ...order.statusHistory,
              { status: newStatus, timestamp: now }
            ]
          };
        }
        return order;
      });
      
      setOrders(updatedOrders);
      
      // Update selected order if applicable
      if (selectedOrder && selectedOrder.id === orderId) {
        setSelectedOrder({
          ...selectedOrder,
          status: newStatus,
          statusHistory: [
            ...selectedOrder.statusHistory,
            { status: newStatus, timestamp: now }
          ]
        });
      }
      
      // Show notification
      // In real implementation, you would use a proper notification system
      alert(`Order ${orderId} status updated to ${getStatusLabel(newStatus)}`);
      
    } catch (error) {
      console.error('Error updating order status:', error);
      alert('Error updating order status');
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  const getStatusLabel = (status) => {
    const statusOption = statusOptions.find(option => option.value === status);
    return statusOption ? statusOption.label : status;
  };

  const getStatusIcon = (status) => {
    const statusOption = statusOptions.find(option => option.value === status);
    return statusOption ? statusOption.icon : 'fas fa-question-circle';
  };

  const isStatusActive = (status) => {
    return ['received', 'preparing', 'quality_check', 'ready'].includes(status);
  };

  const filteredOrders = orders.filter(order => {
    // Filter by view mode
    if (viewMode === 'active' && !isStatusActive(order.status)) return false;
    if (viewMode === 'completed' && order.status !== 'completed') return false;
    if (viewMode === 'canceled' && order.status !== 'canceled') return false;
    
    // Filter by status
    if (filter !== 'all' && order.status !== filter) return false;
    
    // Filter by search term
    if (search) {
      const searchLower = search.toLowerCase();
      return (
        order.id.toLowerCase().includes(searchLower) ||
        order.customer.name.toLowerCase().includes(searchLower) ||
        order.customer.phone.includes(search)
      );
    }
    
    return true;
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    });
  };

  const calculateOrderTotal = (items) => {
    return items.reduce((total, item) => total + (item.price * item.quantity), 0);
  };

  if (loading) {
    return (
      <div className="section-loading">
        <div className="spinner"></div>
        <p>Loading orders...</p>
      </div>
    );
  }

  return (
    <div className="orders-container">
      <div className="orders-header">
        <h2>Orders</h2>
        <p className="section-description">
          Manage and track customer orders.
        </p>
      </div>
      
      <div className="view-tabs">
        <button 
          className={`view-tab ${viewMode === 'active' ? 'active' : ''}`}
          onClick={() => handleViewModeChange('active')}
        >
          <i className="fas fa-fire"></i> Active Orders
        </button>
        <button 
          className={`view-tab ${viewMode === 'completed' ? 'active' : ''}`}
          onClick={() => handleViewModeChange('completed')}
        >
          <i className="fas fa-check-circle"></i> Completed
        </button>
        <button 
          className={`view-tab ${viewMode === 'canceled' ? 'active' : ''}`}
          onClick={() => handleViewModeChange('canceled')}
        >
          <i className="fas fa-times-circle"></i> Canceled
        </button>
      </div>
      
      <div className="orders-controls">
        <div className="filters">
          <select value={filter} onChange={handleFilterChange}>
            <option value="all">All Statuses</option>
            {statusOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="search">
          <input
            type="text"
            placeholder="Search orders..."
            value={search}
            onChange={handleSearchChange}
          />
          <i className="fas fa-search"></i>
        </div>
      </div>
      
      <div className="orders-content">
        <div className="orders-list">
          {filteredOrders.length === 0 ? (
            <div className="no-orders">
              <i className="fas fa-clipboard"></i>
              <p>No orders found</p>
            </div>
          ) : (
            <div className="orders-table">
              <div className="orders-table-header">
                <div className="order-cell">Order #</div>
                <div className="order-cell">Customer</div>
                <div className="order-cell">Status</div>
                <div className="order-cell">Total</div>
                <div className="order-cell">Time</div>
              </div>
              {filteredOrders.map(order => (
                <div 
                  key={order.id} 
                  className={`order-row ${selectedOrder?.id === order.id ? 'selected' : ''}`}
                  onClick={() => handleSelectOrder(order)}
                >
                  <div className="order-cell order-id">{order.id}</div>
                  <div className="order-cell order-customer">{order.customer.name}</div>
                  <div className="order-cell order-status">
                    <span className={`status-badge ${order.status}`}>
                      <i className={getStatusIcon(order.status)}></i>
                      {getStatusLabel(order.status)}
                    </span>
                  </div>
                  <div className="order-cell order-total">${order.total.toFixed(2)}</div>
                  <div className="order-cell order-time">{formatDate(order.createdAt)}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div className="order-detail">
          {selectedOrder ? (
            <>
              <div className="detail-header">
                <div className="detail-title">
                  <h3>Order {selectedOrder.id}</h3>
                  <span className={`status-badge ${selectedOrder.status}`}>
                    <i className={getStatusIcon(selectedOrder.status)}></i>
                    {getStatusLabel(selectedOrder.status)}
                  </span>
                </div>
                <div className="detail-meta">
                  <div><strong>Created:</strong> {formatDate(selectedOrder.createdAt)}</div>
                  <div><strong>Scheduled for:</strong> {formatDate(selectedOrder.scheduledFor)}</div>
                  <div><strong>Type:</strong> {selectedOrder.orderType === 'pickup' ? 'Pickup' : 'Delivery'}</div>
                </div>
              </div>
              
              <div className="detail-content">
                <div className="detail-section">
                  <h4>Customer Information</h4>
                  <div className="customer-info">
                    <div><strong>Name:</strong> {selectedOrder.customer.name}</div>
                    <div><strong>Phone:</strong> {selectedOrder.customer.phone}</div>
                    <div><strong>Email:</strong> {selectedOrder.customer.email}</div>
                  </div>
                </div>
                
                <div className="detail-section">
                  <h4>Order Items</h4>
                  <div className="order-items">
                    {selectedOrder.items.map((item, index) => (
                      <div key={index} className="order-item">
                        <div className="item-quantity">{item.quantity}x</div>
                        <div className="item-info">
                          <div className="item-name">{item.name}</div>
                          {item.notes && <div className="item-notes">{item.notes}</div>}
                        </div>
                        <div className="item-price">${(item.price * item.quantity).toFixed(2)}</div>
                      </div>
                    ))}
                  </div>
                  
                  <div className="order-summary">
                    <div className="summary-row">
                      <div>Subtotal</div>
                      <div>${selectedOrder.subtotal.toFixed(2)}</div>
                    </div>
                    <div className="summary-row">
                      <div>Tax</div>
                      <div>${selectedOrder.tax.toFixed(2)}</div>
                    </div>
                    <div className="summary-row total">
                      <div>Total</div>
                      <div>${selectedOrder.total.toFixed(2)}</div>
                    </div>
                  </div>
                </div>
                
                <div className="detail-section">
                  <h4>Status History</h4>
                  <div className="status-timeline">
                    {selectedOrder.statusHistory.map((status, index) => (
                      <div key={index} className="timeline-item">
                        <div className="timeline-icon">
                          <i className={getStatusIcon(status.status)}></i>
                        </div>
                        <div className="timeline-content">
                          <div className="timeline-status">{getStatusLabel(status.status)}</div>
                          <div className="timeline-time">{formatDate(status.timestamp)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              
              {isStatusActive(selectedOrder.status) && (
                <div className="detail-actions">
                  <h4>Update Status</h4>
                  <div className="status-buttons">
                    {statusOptions.map(option => {
                      // Determine which buttons to show based on current status
                      const currentStatusIndex = statusOptions.findIndex(opt => opt.value === selectedOrder.status);
                      const optionIndex = statusOptions.findIndex(opt => opt.value === option.value);
                      
                      // Show next status and 'canceled' option
                      const isNextStatus = optionIndex === currentStatusIndex + 1;
                      const isCanceled = option.value === 'canceled';
                      
                      if (isNextStatus || isCanceled) {
                        return (
                          <button
                            key={option.value}
                            className={`status-button ${option.value}`}
                            onClick={() => updateOrderStatus(selectedOrder.id, option.value)}
                            disabled={statusUpdateLoading}
                          >
                            <i className={option.icon}></i>
                            {option.label}
                          </button>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="no-order-selected">
              <i className="fas fa-clipboard-list"></i>
              <p>Select an order to view details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders; 