import React, { useState, useEffect } from 'react';
import './Theme.css';
import { getApiUrl } from '../../utils/apiUtils';

const Theme = () => {
  const [theme, setTheme] = useState({
    primaryColor: '#2196f3',
    backgroundColor: '#1a1a1a',
    textColor: '#ffffff',
    priceColor: '#4caf50',
    fontStyle: 'sans-serif'
  });
  
  const [saveStatus, setSaveStatus] = useState('');
  const [phoneThemeKey, setPhoneThemeKey] = useState(Date.now());
  
  // Load saved theme on component mount
  useEffect(() => {
    const loadSavedTheme = async () => {
      try {
        // Try to load from localStorage first
        const savedTheme = localStorage.getItem('menuTheme');
        if (savedTheme) {
          setTheme(JSON.parse(savedTheme));
          return;
        }
        
        // If not in localStorage, load from API
        const response = await fetch(getApiUrl('theme.php'), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          if (data.success && data.theme) {
            setTheme(data.theme);
            localStorage.setItem('menuTheme', JSON.stringify(data.theme));
          }
        }
      } catch (error) {
        console.error('Error loading theme:', error);
      }
    };
    
    loadSavedTheme();
  }, []);
  
  // Update theme properties when user makes changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Update the theme state
    const updatedTheme = { ...theme, [name]: value };
    setTheme(updatedTheme);
    
    // Store in localStorage
    localStorage.setItem('menuTheme', JSON.stringify(updatedTheme));
    
    // Update preview
    updatePhonePreview(updatedTheme);
  };
  
  // Save theme to API
  const saveTheme = async () => {
    try {
      setSaveStatus('Saving...');
      
      const response = await fetch(getApiUrl('theme.php'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ theme })
      });
      
      if (response.ok) {
        setSaveStatus('Theme saved successfully!');
        setTimeout(() => setSaveStatus(''), 3000);
      } else {
        setSaveStatus('Error saving theme. Please try again.');
        setTimeout(() => setSaveStatus(''), 3000);
      }
    } catch (error) {
      console.error('Error saving theme:', error);
      setSaveStatus('Error saving theme. Please try again.');
      setTimeout(() => setSaveStatus(''), 3000);
    }
  };
  
  // Reset theme to default
  const resetTheme = () => {
    const defaultTheme = {
      primaryColor: '#2196f3',
      backgroundColor: '#1a1a1a',
      textColor: '#ffffff',
      priceColor: '#4caf50',
      fontStyle: 'sans-serif'
    };
    
    setTheme(defaultTheme);
    localStorage.setItem('menuTheme', JSON.stringify(defaultTheme));
    updatePhonePreview(defaultTheme);
    setSaveStatus('Theme reset to default');
    setTimeout(() => setSaveStatus(''), 3000);
  };
  
  // Update phone preview with new theme
  const updatePhonePreview = (themeData) => {
    // Apply theme to root variables
    document.documentElement.style.setProperty('--primary-color', themeData.primaryColor);
    document.documentElement.style.setProperty('--background-color', themeData.backgroundColor);
    document.documentElement.style.setProperty('--text-color', themeData.textColor);
    document.documentElement.style.setProperty('--price-color', themeData.priceColor);
    document.documentElement.style.setProperty('--font-family', getFontFamily(themeData.fontStyle));
    
    // Force a refresh of the phone preview
    setPhoneThemeKey(Date.now());
  };
  
  // Helper to get font family based on style
  const getFontFamily = (style) => {
    switch (style) {
      case 'serif':
        return "'Times New Roman', Times, serif";
      case 'sans-serif':
        return "Arial, Helvetica, sans-serif";
      case 'monospace':
        return "'Courier New', Courier, monospace";
      default:
        return "Arial, Helvetica, sans-serif";
    }
  };

  return (
    <div className="theme-container">
      <div className="theme-content">
        <h2>Theme Customization</h2>
        <p className="theme-description">
          Customize the appearance of your menu to match your brand.
        </p>
        
        <div className="theme-controls">
          <div className="control-group">
            <label htmlFor="primaryColor">Primary Color</label>
            <div className="color-input-container">
              <input 
                type="color" 
                id="primaryColor"
                name="primaryColor"
                value={theme.primaryColor}
                onChange={handleChange}
              />
              <span>{theme.primaryColor}</span>
            </div>
          </div>
          
          <div className="control-group">
            <label htmlFor="backgroundColor">Background Color</label>
            <div className="color-input-container">
              <input 
                type="color" 
                id="backgroundColor"
                name="backgroundColor"
                value={theme.backgroundColor}
                onChange={handleChange}
              />
              <span>{theme.backgroundColor}</span>
            </div>
          </div>
          
          <div className="control-group">
            <label htmlFor="textColor">Text Color</label>
            <div className="color-input-container">
              <input 
                type="color" 
                id="textColor"
                name="textColor"
                value={theme.textColor}
                onChange={handleChange}
              />
              <span>{theme.textColor}</span>
            </div>
          </div>
          
          <div className="control-group">
            <label htmlFor="priceColor">Price Color</label>
            <div className="color-input-container">
              <input 
                type="color" 
                id="priceColor"
                name="priceColor"
                value={theme.priceColor}
                onChange={handleChange}
              />
              <span>{theme.priceColor}</span>
            </div>
          </div>
          
          <div className="control-group">
            <label htmlFor="fontStyle">Font Style</label>
            <select 
              id="fontStyle"
              name="fontStyle"
              value={theme.fontStyle}
              onChange={handleChange}
            >
              <option value="default">Default</option>
              <option value="serif">Serif</option>
              <option value="sans-serif">Sans Serif</option>
              <option value="monospace">Monospace</option>
            </select>
          </div>
        </div>
        
        {saveStatus && <div className="save-status">{saveStatus}</div>}
        
        <div className="theme-actions">
          <button className="reset-theme-btn" onClick={resetTheme}>Reset to Default</button>
          <button className="save-theme-btn" onClick={saveTheme}>Save Theme</button>
        </div>
      </div>
    </div>
  );
};

export default Theme;
