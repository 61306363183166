import React, { useState } from 'react';
import './OptionComponents.css';

const OptionSetBuilder = ({ onSave, onCancel, existingSet = null }) => {
  const [formData, setFormData] = useState({
    title: existingSet?.title || '',
    type: existingSet?.type || 'multiple', // multiple, single, text
    required: existingSet?.required || false,
    options: existingSet?.options || [],
    allowCustom: existingSet?.allowCustom || false
  });
  
  const [newOption, setNewOption] = useState('');
  const [newOptionPrice, setNewOptionPrice] = useState('0.00');
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.title.trim()) {
      newErrors.title = 'Please enter a name for this option group';
    }
    
    if (formData.type !== 'text' && formData.options.length === 0) {
      newErrors.options = 'Please add at least one option';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleAddOption = () => {
    if (!newOption.trim()) return;
    
    // Format price
    const price = parseFloat(newOptionPrice).toFixed(2);
    
    setFormData(prev => ({
      ...prev,
      options: [...prev.options, {
        name: newOption.trim(),
        price: price,
        default: false
      }]
    }));
    
    setNewOption('');
    setNewOptionPrice('0.00');
  };
  
  const handleRemoveOption = (index) => {
    setFormData(prev => ({
      ...prev,
      options: prev.options.filter((_, i) => i !== index)
    }));
  };
  
  const handleSetDefault = (index) => {
    if (formData.type === 'single') {
      // For radio buttons, only one can be default
      setFormData(prev => ({
        ...prev,
        options: prev.options.map((option, i) => ({
          ...option,
          default: i === index
        }))
      }));
    } else {
      // For checkboxes, toggle the current one
      setFormData(prev => ({
        ...prev,
        options: prev.options.map((option, i) => 
          i === index ? {...option, default: !option.default} : option
        )
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    // Stop the event from bubbling up or triggering a page refresh
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    console.log('OptionSetBuilder handleSubmit called');
    
    if (!validateForm()) {
      console.log('Form validation failed');
      return;
    }
    
    try {
      setSaving(true);
      console.log('Starting to save option set with data:', formData);
      
      // Convert to the format needed for API
      const optionSetData = {
        title: formData.title,
        type: formData.type,
        required: formData.required,
        allowCustom: formData.allowCustom,
        options: formData.options.map(opt => ({
          name: opt.name,
          price: opt.price,
          is_default: opt.default,
          option_type: formData.type === 'single' ? 'choice' : 'add-on',
          option_group: formData.title
        }))
      };
      
      console.log('Formatted option set data:', optionSetData);
      
      // Debug - Test direct API call to ensure network connectivity
      try {
        const testApiCall = await fetch('http://localhost:9000/api/test_options.php', {
          method: 'GET',
          credentials: 'include'
        });
        console.log('Test API call response status:', testApiCall.status);
      } catch(apiError) {
        console.error('Test API call failed:', apiError);
      }
      
      console.log('Sending data to onSave function...');
      
      // Wait for the save to complete
      const result = await onSave(optionSetData);
      console.log('Save completed successfully with result:', result);
      
      // Show success message
      setSuccess(true);
      
      // Close after delay
      setTimeout(() => {
        console.log('Closing option set builder after successful save');
        onCancel();
      }, 1500);
      
    } catch (error) {
      console.error('Error in OptionSetBuilder while saving options:', error);
      setErrors({ submit: `Failed to save options: ${error.message || 'Unknown error'}` });
    } finally {
      setSaving(false);
    }
  };
  
  const handlePriceChange = (e) => {
    const value = e.target.value;
    // Only allow numbers and one decimal point
    const formatted = value.replace(/[^0-9.]/g, '');
    // Ensure only one decimal point
    const parts = formatted.split('.');
    const formattedPrice = parts.length > 2 ? parts[0] + '.' + parts[1] : formatted;
    // Limit to 2 decimal places
    if (parts.length > 1 && parts[1]?.length > 2) {
      setNewOptionPrice(`${parts[0]}.${parts[1].substring(0, 2)}`);
      return;
    }
    setNewOptionPrice(formattedPrice);
  };
  
  return (
    <div className="option-set-builder">
      <div className="option-editor-header">
        <h3>{existingSet ? 'Edit Options' : 'Add Options to Menu Item'}</h3>
        <button type="button" className="close-button" onClick={onCancel}>×</button>
      </div>
      
      {success && (
        <div className="success-message">
          Options saved successfully!
        </div>
      )}
      
      {errors.submit && (
        <div className="error-message">
          {errors.submit}
        </div>
      )}
      
      <div className="option-form">
        <div className="option-form-row">
          <div className="form-group">
            <label htmlFor="title">
              Option Group Name <span className="required-field">*</span>
              <span className="label-helper">Examples: Toppings, Cooking Preference, Side Choices</span>
            </label>
            <input
              type="text"
              id="title"
              value={formData.title}
              onChange={(e) => setFormData({...formData, title: e.target.value})}
              placeholder="Toppings, Cooking Preference, Side Choices"
              className={errors.title ? 'input-error' : ''}
            />
            {errors.title && <div className="field-error">{errors.title}</div>}
          </div>
        </div>
        
        <div className="option-form-row two-columns">
          <div className="form-group">
            <label htmlFor="type">
              How customers select options
              <span className="label-helper">Choose the type of selection your customers will make</span>
            </label>
            <select
              id="type"
              value={formData.type}
              onChange={(e) => setFormData({...formData, type: e.target.value})}
            >
              <option value="multiple">Multiple Items (Checkboxes)</option>
              <option value="single">One Item Only (Radio Buttons)</option>
              <option value="text">Special Instructions (Text Box)</option>
            </select>
          </div>
        </div>
        
        {formData.type !== 'text' && (
          <>
            <div className="option-form-row">
              <div className="form-group">
                <label>
                  Options <span className="required-field">*</span>
                  <span className="label-helper">
                    {formData.type === 'multiple' 
                      ? 'Add items customers can choose (e.g., Cheese, Bacon, Lettuce)' 
                      : 'Add choices customers can select (e.g., Rare, Medium, Well-Done)'}
                  </span>
                </label>
                {errors.options && <div className="field-error">{errors.options}</div>}
                
                <div className="add-option-form">
                  <table className="add-option-table">
                    <tr>
                      <td className="name-cell">
                        <input
                          type="text"
                          className="add-option-name"
                          placeholder="Add option (e.g., Cheese, Extra Sauce, Bacon)"
                          value={newOption}
                          onChange={(e) => setNewOption(e.target.value)}
                          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                        />
                      </td>
                      <td className="price-cell">
                        <div className="price-input-mini">
                          <span className="currency-symbol-price">$</span>
                          <input
                            type="text"
                            className="add-option-price"
                            placeholder="0.00"
                            value={newOptionPrice}
                            onChange={handlePriceChange}
                            onBlur={() => {
                              // Format to 2 decimal places on blur
                              setNewOptionPrice(parseFloat(newOptionPrice || 0).toFixed(2));
                            }}
                          />
                        </div>
                      </td>
                      <td className="add-btn-cell">
                        <button
                          type="button"
                          className="add-option-button"
                          onClick={handleAddOption}
                          disabled={!newOption.trim()}
                        >
                          + Add
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                
                <div className="option-list">
                  {formData.options.map((option, index) => (
                    <div key={index} className="option-list-item">
                      <div className="option-list-item-content">
                        <div className="option-name">{option.name}</div>
                        {parseFloat(option.price) > 0 && (
                          <div className="option-price">+${parseFloat(option.price).toFixed(2)}</div>
                        )}
                      </div>
                      <div className="option-list-item-actions">
                        <label className="default-checkbox">
                          <input 
                            type="checkbox" 
                            checked={option.default} 
                            onChange={() => handleSetDefault(index)}
                          />
                          <span>Default</span>
                        </label>
                        <button type="button" className="remove-option-btn" onClick={() => handleRemoveOption(index)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            <div className="option-form-row checkbox-group">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={formData.allowCustom}
                  onChange={(e) => setFormData({...formData, allowCustom: e.target.checked})}
                />
                <span className="checkbox-label">Allow customers to add special requests</span>
              </label>
            </div>
          </>
        )}
        
        <table className="button-table">
          <tr>
            <td>
              <button 
                type="button" 
                className="save-button" 
                onClick={handleSubmit}
                disabled={saving}
              >
                {saving ? 'SAVING...' : 'SAVE OPTIONS'}
              </button>
            </td>
            <td>
              <button 
                type="button" 
                className="cancel-button" 
                onClick={onCancel}
                disabled={saving}
              >
                CANCEL
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default OptionSetBuilder; 