import React, { useState, useEffect } from 'react';
import { Card, Select, Spin, Alert, Table, Badge, Space, Tag, Progress, Tooltip } from 'antd';
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { SecurityScanOutlined, WarningOutlined, SafetyCertificateOutlined, GlobalOutlined } from '@ant-design/icons';
import { AdminService } from '../../services/AdminService';

const SecurityMonitoring = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('24h');
    const [stats, setStats] = useState(null);
    const [selectedThreatLevel, setSelectedThreatLevel] = useState('all');

    const COLORS = ['#00C49F', '#FFBB28', '#FF8042', '#FF0000'];
    const RADIAN = Math.PI / 180;

    useEffect(() => {
        fetchSecurityStats();
        // Set up real-time updates for critical security events
        const wsConnection = new WebSocket('ws://localhost:8080/security-events');
        wsConnection.onmessage = (event) => {
            const securityEvent = JSON.parse(event.data);
            if (securityEvent.severity === 'critical') {
                // Update stats immediately for critical events
                fetchSecurityStats();
            }
        };
        return () => wsConnection.close();
    }, [timeRange, selectedThreatLevel]);

    const fetchSecurityStats = async () => {
        try {
            setLoading(true);
            const data = await AdminService.getSecurityStats(timeRange, selectedThreatLevel);
            setStats(data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch security statistics');
            console.error('Error fetching security stats:', err);
        } finally {
            setLoading(false);
        }
    };

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const getThreatLevelColor = (level) => {
        switch (level.toLowerCase()) {
            case 'critical': return '#ff4d4f';
            case 'high': return '#ffa39e';
            case 'medium': return '#ffd666';
            case 'low': return '#95de64';
            default: return '#d9d9d9';
        }
    };

    const getSecurityScore = () => {
        if (!stats?.securityScore) return 0;
        return Math.round(stats.securityScore * 100);
    };

    if (loading) {
        return <Spin size="large" className="section-spinner" />;
    }

    if (error) {
        return <Alert type="error" message={error} />;
    }

    return (
        <div className="section-container">
            <div className="section-header">
                <h2>Security Monitoring</h2>
                <Space>
                    <Select
                        value={timeRange}
                        onChange={setTimeRange}
                        style={{ width: 120 }}
                    >
                        <Select.Option value="1h">Last Hour</Select.Option>
                        <Select.Option value="24h">Last 24 Hours</Select.Option>
                        <Select.Option value="7d">Last 7 Days</Select.Option>
                        <Select.Option value="30d">Last 30 Days</Select.Option>
                    </Select>
                    <Select
                        value={selectedThreatLevel}
                        onChange={setSelectedThreatLevel}
                        style={{ width: 120 }}
                    >
                        <Select.Option value="all">All Threats</Select.Option>
                        <Select.Option value="critical">Critical</Select.Option>
                        <Select.Option value="high">High</Select.Option>
                        <Select.Option value="medium">Medium</Select.Option>
                        <Select.Option value="low">Low</Select.Option>
                    </Select>
                </Space>
            </div>

            {/* Security Score and Critical Metrics */}
            <div className="cards-grid">
                <Card title="Security Score" className="metric-card">
                    <Progress
                        type="dashboard"
                        percent={getSecurityScore()}
                        strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                        }}
                        format={percent => (
                            <Tooltip title="Based on multiple security factors">
                                <span style={{ fontSize: '24px' }}>{percent}</span>
                            </Tooltip>
                        )}
                    />
                </Card>
                <Card title="Active Threats" className="metric-card">
                    <h3>
                        <SecurityScanOutlined style={{ marginRight: '8px', color: '#ff4d4f' }}/>
                        {stats?.activeThreats || 0}
                    </h3>
                    <p>Requiring attention</p>
                </Card>
                <Card title="Failed Login Attempts" className="metric-card">
                    <h3>
                        <WarningOutlined style={{ marginRight: '8px', color: '#faad14' }}/>
                        {stats?.failedLogins || 0}
                    </h3>
                    <p>In the last {timeRange}</p>
                </Card>
                <Card title="SSL/TLS Status" className="metric-card">
                    <h3>
                        <SafetyCertificateOutlined style={{ marginRight: '8px', color: '#52c41a' }}/>
                        {stats?.sslStatus || 'Valid'}
                    </h3>
                    <p>Expires in {stats?.sslDaysRemaining || 0} days</p>
                </Card>
            </div>

            {/* Threat Distribution */}
            <div className="chart-row">
                <Card title="Threat Distribution" className="chart-card">
                    <PieChart width={400} height={300}>
                        <Pie
                            data={stats?.threatDistribution || []}
                            cx={200}
                            cy={150}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {stats?.threatDistribution?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend />
                        <RechartsTooltip />
                    </PieChart>
                </Card>

                {/* Geographic Attack Distribution */}
                <Card title="Geographic Attack Distribution" className="chart-card">
                    <div style={{ height: '300px', position: 'relative' }}>
                        <GlobalOutlined style={{ fontSize: '24px', marginRight: '8px' }}/>
                        <Table 
                            size="small"
                            dataSource={stats?.geoAttacks || []}
                            columns={[
                                {
                                    title: 'Country',
                                    dataIndex: 'country',
                                    key: 'country',
                                },
                                {
                                    title: 'Attacks',
                                    dataIndex: 'attacks',
                                    key: 'attacks',
                                    sorter: (a, b) => a.attacks - b.attacks,
                                },
                                {
                                    title: 'Blocked',
                                    dataIndex: 'blocked',
                                    key: 'blocked',
                                    render: blocked => (
                                        <Tag color={blocked ? 'success' : 'error'}>
                                            {blocked ? 'Yes' : 'No'}
                                        </Tag>
                                    ),
                                },
                            ]}
                            pagination={false}
                            scroll={{ y: 240 }}
                        />
                    </div>
                </Card>
            </div>

            {/* Security Events Timeline */}
            <Card title="Security Events Timeline" className="timeline-card">
                <AreaChart
                    width={1000}
                    height={300}
                    data={stats?.timelineData || []}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    <Area 
                        type="monotone" 
                        dataKey="attacks" 
                        stackId="1"
                        stroke="#ff4d4f" 
                        fill="#ff4d4f" 
                        fillOpacity={0.3}
                    />
                    <Area 
                        type="monotone" 
                        dataKey="blocked" 
                        stackId="1"
                        stroke="#52c41a" 
                        fill="#52c41a" 
                        fillOpacity={0.3}
                    />
                </AreaChart>
            </Card>

            {/* Detailed Security Events */}
            <Card title="Security Events Log" className="table-card">
                <Table 
                    columns={[
                        {
                            title: 'Timestamp',
                            dataIndex: 'timestamp',
                            key: 'timestamp',
                            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
                        },
                        {
                            title: 'Event Type',
                            dataIndex: 'type',
                            key: 'type',
                            filters: [
                                { text: 'Authentication', value: 'auth' },
                                { text: 'Access Control', value: 'access' },
                                { text: 'Data Security', value: 'data' },
                                { text: 'Network', value: 'network' },
                            ],
                            onFilter: (value, record) => record.type.indexOf(value) === 0,
                        },
                        {
                            title: 'Threat Level',
                            dataIndex: 'threatLevel',
                            key: 'threatLevel',
                            render: level => (
                                <Badge 
                                    color={getThreatLevelColor(level)} 
                                    text={level} 
                                />
                            ),
                            filters: [
                                { text: 'Critical', value: 'critical' },
                                { text: 'High', value: 'high' },
                                { text: 'Medium', value: 'medium' },
                                { text: 'Low', value: 'low' },
                            ],
                            onFilter: (value, record) => record.threatLevel === value,
                        },
                        {
                            title: 'Source',
                            dataIndex: 'source',
                            key: 'source',
                        },
                        {
                            title: 'Target',
                            dataIndex: 'target',
                            key: 'target',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: status => (
                                <Tag color={status === 'Blocked' ? 'success' : 'error'}>
                                    {status}
                                </Tag>
                            ),
                        },
                        {
                            title: 'Details',
                            dataIndex: 'details',
                            key: 'details',
                            ellipsis: true,
                        },
                    ]} 
                    dataSource={stats?.securityEvents || []}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                    expandable={{
                        expandedRowRender: record => (
                            <Space direction="vertical">
                                <p><strong>Full Details:</strong> {record.details}</p>
                                <p><strong>IP Address:</strong> {record.ipAddress}</p>
                                <p><strong>User Agent:</strong> {record.userAgent}</p>
                                <p><strong>Request Headers:</strong> {JSON.stringify(record.headers, null, 2)}</p>
                                {record.stackTrace && (
                                    <p><strong>Stack Trace:</strong> {record.stackTrace}</p>
                                )}
                            </Space>
                        ),
                    }}
                />
            </Card>

            {/* Advanced Security Metrics */}
            <div className="cards-grid">
                <Card title="WAF Statistics" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div>
                            <span>SQL Injections Blocked:</span>
                            <Progress percent={stats?.wafStats?.sqlInjections || 0} size="small" />
                        </div>
                        <div>
                            <span>XSS Attempts Blocked:</span>
                            <Progress percent={stats?.wafStats?.xssAttempts || 0} size="small" />
                        </div>
                        <div>
                            <span>Rate Limiting Applied:</span>
                            <Progress percent={stats?.wafStats?.rateLimiting || 0} size="small" />
                        </div>
                    </Space>
                </Card>
                <Card title="Authentication Analysis" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div>
                            <span>2FA Adoption:</span>
                            <Progress percent={stats?.authStats?.twoFactorAdoption || 0} size="small" />
                        </div>
                        <div>
                            <span>Password Strength:</span>
                            <Progress percent={stats?.authStats?.passwordStrength || 0} size="small" />
                        </div>
                        <div>
                            <span>Session Security:</span>
                            <Progress percent={stats?.authStats?.sessionSecurity || 0} size="small" />
                        </div>
                    </Space>
                </Card>
                <Card title="Compliance Status" className="metric-card">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div>
                            <span>GDPR Compliance:</span>
                            <Progress percent={stats?.complianceStats?.gdpr || 0} size="small" />
                        </div>
                        <div>
                            <span>PCI DSS:</span>
                            <Progress percent={stats?.complianceStats?.pciDss || 0} size="small" />
                        </div>
                        <div>
                            <span>HIPAA:</span>
                            <Progress percent={stats?.complianceStats?.hipaa || 0} size="small" />
                        </div>
                    </Space>
                </Card>
            </div>
        </div>
    );
};

export default SecurityMonitoring; 